import { Http } from '@/api/http';
const CryptoJS = require('crypto-js');
const http = new Http('/merchant/console/v1');
export default class safetyApi {
  /**
   * changeLoginPassword
   */
  changeLoginPassword(data: changeLoginPasswordParamType) {
    return http.postJson('/safety/login_passwd/change', {
      ...data,
      Passwd: String(CryptoJS.MD5(data.Passwd?.trim())),
      RepeatPasswd: String(CryptoJS.MD5(data.RepeatPasswd?.trim())),
    });
  }

  /**
   * setPayPassword
   */
  setPayPw(data: setPayPwParamType) {
    return http.postJson('/safety/pay_passwd/set', {
      ...data,
      PayPasswd: String(CryptoJS.MD5(data.PayPasswd?.trim())),
      RepeatPayPasswd: String(CryptoJS.MD5(data.RepeatPayPasswd?.trim())),
    });
  }

  /**
   * changePayPw
   */
  changePayPw(data: changePayPwParamType) {
    return http.postJson('/safety/pay_passwd/change', {
      ...data,
      PayPasswd: String(CryptoJS.MD5(data.PayPasswd?.trim())),
      RepeatPayPasswd: String(CryptoJS.MD5(data.RepeatPayPasswd?.trim())),
    });
  }

  /**
   * bind Phone
   */
  bindPhone(data: bindPhoneParamType) {
    return http.postJson('/safety/phone/set', data);
  }

  /**
   * bind Phone
   */
  changePhone(data: changePhoneParamType) {
    return http.postJson('/safety/phone/change', data);
  }

  /**
   * bind Phone
   */
  changeEmail(data: changeEmailParamType) {
    return http.postJson('/safety/email/change', data);
  }

  /**
   * get google 2fa
   */
  getGoogleFa(): Promise<getGoogleFaResType> {
    return http.postJson('/safety/2fa/get', {});
  }

  /**
   * set google 2fa
   */
  setGoogleFa(data: setGoogleFaParamType) {
    return http.postJson('/safety/2fa/set', data);
  }

  /**
   * set google 2fa
   */
  closeGoogleFa(data: closeGoogleFaParamType) {
    return http.postJson('/safety/2fa/close', data);
  }

  /**
   * sendPhoneCode
   */
  sendPhoneCode(data: sendPhoneCodeParamType) {
    return http.postJson('/safety/sms/send/code', data);
  }
}

export type getGoogleFaResType = {
  SecretKey: string;
  SecretKeyQr: string;
};

export type sendPhoneCodeParamType = {
  Phone: string;
  Area: string;
};

export type closeGoogleFaParamType = {
  MailCode?: string;
  SmsCode?: string;
  TotpCode?: string;
};

export type setGoogleFaParamType = {
  SecretKey: string;
  MailCode: string;
  SmsCode?: string;
  TotpCode: string;
};

export type changeEmailParamType = {
  Email: string;
  /** new email code */
  Code: string;
  /** old email code */
  MailCode: string;
  SmsCode?: string;
  TotpCode?: string;
};

export type changePayPwParamType = {
  PayPasswd: string;
  RepeatPayPasswd: string;
  MailCode?: string;
  SmsCode?: string;
  TotpCode?: string;
};

export type changePhoneParamType = {
  Phone: string;
  Area: string;
  /** new phone number code */
  Code: string;
  MailCode: string;
  /** old phone number code */
  SmsCode: string;
  TotpCode: string;
};

export type bindPhoneParamType = {
  Phone: string;
  Area: string;
  Code: string;
  MailCode?: string;
  TotpCode?: string;
};

export type setPayPwParamType = {
  PayPasswd: string;
  RepeatPayPasswd: string;
  MailCode?: string;
  SmsCode?: string;
  TotpCode?: string;
};

export type changeLoginPasswordParamType = {
  Passwd: string;
  RepeatPasswd: string;
  MailCode?: string;
  SmsCode?: string;
  TotpCode?: string;
};
