import { useState } from 'react';
// @mui
import { Tooltip } from '@mui/material';
// utils
// components
import { IconButtonAnimate } from '../../../components/animate';

import { useNavigate } from 'react-router';
import { MyIcon } from '@/assets/icons/MyIcon';
import { observer } from 'mobx-react';
import { useLocales } from '@/locales';
import { useAuthContext } from '@/auth/useAuthContext';



export default observer(function SettingsPopover() {
  const [openPopover] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { translate: t } = useLocales();
  const { chooseApi } = useAuthContext();

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={() => {
          navigate(
            `${[1, '1'].includes(chooseApi) ? '/account/settings' : '/wallet/account/settings'}`
          );
        }}
        sx={{ width: 40, height: 40 }}
      >
        <Tooltip title={t('common.Account_settings')}>
          <MyIcon name="header_setting" size={24} />
        </Tooltip>
      </IconButtonAnimate>
    </>
  );
});
