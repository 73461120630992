import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';
import { PATH_AFTER_LOGIN, WALLET_PATH_AFTER_LOGIN } from '@/config';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, isVerify, user, chooseApi } = useAuthContext();
  const { pathname } = useLocation();
  if ([1, '1'].includes(chooseApi)) {
    // This controls the jump after successful login
    if (isAuthenticated && isVerify) {
      if (user?.mode_type === 0) {
        return <Navigate to={'/select-platform'} />;
      }

      if (user?.mode_type === 1) {
        return <Navigate to={PATH_AFTER_LOGIN} />;
      }

      if (user?.mode_type === 2) {
        return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
      }
    }
    // Security verification must be done after login;
    if (
      isAuthenticated &&
      !isVerify &&
      (Boolean(user?.security_email) || Boolean(user?.security_phone) || user?.exists_google_2fa) &&
      pathname === '/login'
    ) {
      // debugger;

      return <Navigate to="/verification" state={{ fromLogin: 1, type: 1 }} />;
    }
  } else {
    if (isAuthenticated && isVerify) {
      return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
    }

    // Security verification must be done after login;
    if (isAuthenticated && !isVerify && user?.SafetyMode !== 0 && pathname === '/login') {
      return <Navigate to="/verification" state={{ fromLogin: 1, type: 1 }} />;
    }
  }

  if (isAuthenticated && !isVerify && pathname === '/login') {
    return <>{children}</>;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
