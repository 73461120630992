// routes
import {
  PATH_DASHBOARD,
  PATH_TRADING_ORDER,
  PATH_BALANCES,
  PATH_PAYMENT_API,
  PATH_MERCHANT_SETTIMGS,
  PATH_INVOICE,
  // PATH_SUGGESTAFEATURE,
  PATH_WEB_HOOK,
  PATH_DEVELOPER,
  PATH_WALLET,
} from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';



const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  trading_order: icon('ic_trading_order'),
  balances: icon('ic_balances'),
  invoice: icon('ic_invoice'),
  email_Billling: icon('ic_email_Billling'),
  check: icon('ic_check'),
  personal_store: icon('ic_personal_store'),
  payment_api: icon('ic_payment_api'),
  merchant_settings: icon('ic_merchant_settings'),
  support: icon('ic_support'),
  suggest: icon('ic_suggest'),
  web_hook: icon('ic_webhook'),
  developer: icon('ic_developer'),
};

const WALLETICONS = {
  overview: icon('wallet_overview'),
  transaction: icon('wallet_transaction'),
  user: icon('ic_user'),
  coin_list: icon('ic_coin_list'),
  network_configuration: icon('ic_Network_Configuration'),
  notification: icon('ic_notification'),
  developer: icon('ic_wallet_developer'),
  web_hook: icon('ic_wallet_webhook'),
  user_order: icon('wallet_user_order'),
  user_list: icon('ic_user_list'),
  balance: icon('wallet_balance'),
  asset_collection: icon('ic_collection'),
};

export const walletNavConfig = [
  // GENERAL

  {
    subheader: 'Overview',
    items: [
      { title: 'nav.Overview', path: PATH_WALLET.overview, icon: WALLETICONS.overview },
      { title: 'nav.transaction', path: PATH_WALLET.transaction, icon: WALLETICONS.transaction },
      { title: 'nav.user_list', path: PATH_WALLET.user_list, icon: WALLETICONS.user_list },
      { title: 'nav.user_order', path: PATH_WALLET.user_order, icon: WALLETICONS.user_order },
      { title: 'nav.wallet_balance', path: PATH_WALLET.balance, icon: WALLETICONS.balance },
      { title: 'nav.coin_list', path: PATH_WALLET.coinList, icon: WALLETICONS.coin_list },
      // {
      //   title: 'nav.network_configuration',
      //   path: PATH_WALLET.networkConfiguration,
      //   icon: WALLETICONS.network_configuration,
      // },
      // { title: 'nav.notification', path: PATH_WALLET., icon: WALLETICONS.notification },
      {
        title: 'nav.asset_collection',
        path: PATH_WALLET.asset_collection,
        icon: WALLETICONS.asset_collection,
      },
      { title: 'nav.Developer', path: PATH_WALLET.developer, icon: WALLETICONS.developer },
      { title: 'nav.web_hook', path: PATH_WALLET.webhook, icon: WALLETICONS.web_hook },
    ],
  },
];

const navConfig = [
  // GENERAL

  {
    subheader: 'Dashboard',
    items: [
      { title: 'nav.Dashboard', path: PATH_DASHBOARD.one, icon: ICONS.user },
      { title: 'nav.Trading_order', path: PATH_TRADING_ORDER.one, icon: ICONS.trading_order },
      { title: 'nav.payment_api', path: PATH_PAYMENT_API.one, icon: ICONS.payment_api },
      { title: 'nav.Invoice', path: PATH_INVOICE.one, icon: ICONS.invoice },
      { title: 'nav.Balances', path: PATH_BALANCES.one, icon: ICONS.balances },
      {
        title: 'nav.Merchant_settings',
        path: PATH_MERCHANT_SETTIMGS.one,
        icon: ICONS.merchant_settings,
      },
      { title: 'nav.Developer', path: PATH_DEVELOPER.one, icon: ICONS.developer },
      // { title: 'nav.email_billing', path: PATH_EMAIL_BILLING.one, icon: ICONS.email_Billling },
      // { title: 'nav.check', path: PATH_CHECK.one, icon: ICONS.check },
      // { title: 'nav.personal_store', path: PATH_PERSONAL_STORE.one, icon: ICONS.personal_store },

      { title: 'nav.web_hook', path: PATH_WEB_HOOK.one, icon: ICONS.web_hook },
      // { title: 'nav.suggest', path: PATH_SUGGESTAFEATURE.one, icon: ICONS.suggest },
      // { title: 'nav.support', path: PATH_SUPPORT.one, icon: ICONS.support },
    ],
  },

  // MANAGEMENT

  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
