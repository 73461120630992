import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import {
  Page500,
  Page403,
  Page404,
  DashboardPage,
  LoginPage,
  ForgotPasswordPage,
  RegisterPage,
  TradingOrderPage,
  AddressListPage,
  BalancesPage,
  WebHookPage,
  InvoicePage,
  InvoiceOneDetailsPage,
  RecurringDetailsPage,
  ManageDetailsPage,
  CreateInvoicePage,
  InvoicePDFpage,
  EmailBillingPage,
  CheckPage,
  PersonalStorePage,
  PaymentApiPage,
  PaymentApiConfigPage,
  PaymentApiOrderDetailsPage,
  MerchantSettingPage,
  SupportPage,
  CreateRefundPage,
  VerifitionPage,
  AccountSettingsPage,
  ReferralProgramPage,
  WithdrawRecordPage,
  WalletDeveloperPage,
  WalletBalancePage,
  CoinInfoPage,
  CoinSettingsPage,
  WalletBalanceDetailsPage,
  WalletCoinListPage,
  SelectPlatformPage,
  CoinListDetailsPage,
  NetworkConfigurationPage,
  ViewTokensPage,
  WebhookPage,
  WalletTransactionPage,
  WalletOverviewPage,
  WalletUserOrderPage,
  WalletUnclearFundsnPage,
  WalletUserListPage,
  WalletUserDetailPage,
  WalletAssetCollectionPage,
  WalletAssetConfigPage,
  WalletAssetRecordPage,
  WalletAccountSettingsPage,
} from './elements';
import ModalRoute from './modalRoute';
import DemoPage from '@/pages/DemoPage';
import RouteForward from '@/components/route-forward';
import ManageRecurringInvoice from '@/pages/Invoice/ManageRecurringInvoice';
import NetworkConfigurationLayout from '@/pages/WalletApi/pages/networkConfiguration/NetworkConfigurationLayout';
import BalanceLayout from '@/pages/WalletApi/pages/balance/BalanceLayout';
import CoinListLayout from '@/pages/WalletApi/pages/coinList/CoinListLayout';
import TransactionLayout from '@/pages/WalletApi/pages/transaction/TransactionLayout';
import UserListLayout from '@/pages/WalletApi/pages/userList/UserListLayout';
import AssetCollectionLayout from '@/pages/WalletApi/pages/asset-collection/index';
import WalletAuthGuard from '@/auth/WalletAuthGuard';
import AccountSettingLayout from '@/pages/WalletApi/pages/MerchantSetting/AccountSettingLayout';

function AppRouter() {
  const location = useLocation();
  return useRoutes(
    [
      {
        path: '/',
        children: [
          { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
          {
            path: 'login',
            element: (
              <GuestGuard>
                <LoginPage />
              </GuestGuard>
            ),
          },
          {
            path: 'forgetPassword',
            element: (
              <GuestGuard>
                <ForgotPasswordPage />
              </GuestGuard>
            ),
          },
          {
            path: 'register',
            element: (
              <GuestGuard>
                <RegisterPage />
              </GuestGuard>
            ),
          },
          {
            path: 'verification',
            element: (
              <AuthGuard>
                <VerifitionPage />
              </AuthGuard>
            ),
          },
          {
            path: 'select-platform',
            element: (
              <GuestGuard>
                <SelectPlatformPage />
              </GuestGuard>
            ),
          },
          { path: 'invoice-pdf', element: <InvoicePDFpage /> },
        ],
      },
      {
        path: '/dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
          { path: 'index', element: <DashboardPage /> },
        ],
      },
      {
        path: '/transaction',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/transaction/index'} replace />, index: true },
          { path: 'index', element: <TradingOrderPage /> },
          { path: 'create-refund', element: <CreateRefundPage /> },
          { path: 'address-list', element: <AddressListPage /> },
        ],
      },
      {
        path: '/balances',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/balances/index'} replace />, index: true },
          { path: 'index', element: <BalancesPage /> },
        ],
      },
      {
        path: '/invoice',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/invoice/list/recurring'} replace />, index: true },
          { path: 'oneTimeDetails', element: <InvoiceOneDetailsPage /> },
          { path: 'recurringDetails', element: <RecurringDetailsPage /> },
          { path: 'ManageDetails', element: <ManageDetailsPage /> },
          { path: 'createInvoice', element: <CreateInvoicePage /> },
          { path: 'ManageRecurring', element: <ManageRecurringInvoice /> },
          { path: 'list/:tab', element: <InvoicePage /> },
        ],
      },

      {
        path: '/emailBilling',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/emailBilling/index'} replace />, index: true },
          { path: 'index', element: <EmailBillingPage /> },
        ],
      },
      {
        path: '/check',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/check/index'} replace />, index: true },
          { path: 'index', element: <CheckPage /> },
        ],
      },
      {
        path: '/personalstore',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/personalstore/index'} replace />, index: true },
          { path: 'index', element: <PersonalStorePage /> },
        ],
      },
      {
        path: '/payment',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/payment/paymentApiOrder/outgoing'} replace />, index: true },
          { path: 'paymentApiOrder/:tab', element: <PaymentApiPage /> },
          { path: 'paymentApiOrderDetails', element: <PaymentApiOrderDetailsPage /> },
        ],
      },
      {
        path: '/developer',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/developer/config'} replace />, index: true },
          { path: 'config', element: <PaymentApiConfigPage /> },
        ],
      },

      {
        path: '/referral',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/referral/program'} replace />, index: true },
          { path: 'program', element: <ReferralProgramPage /> },
          { path: 'withdraw-record', element: <WithdrawRecordPage /> },
        ],
      },

      {
        path: '/account',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/account/settings'} replace />, index: true },
          { path: 'settings', element: <AccountSettingsPage /> },
        ],
      },

      {
        path: '/merchatsetting',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to="/merchatsetting/settings" replace />, index: true },
          { path: ':tab', element: <MerchantSettingPage /> },
        ],
      },
      {
        path: '/webhook',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/webhook/index'} replace />, index: true },
          { path: 'index', element: <WebHookPage /> },
        ],
      },
      {
        path: '/support',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={'/support/index'} replace />, index: true },
          { path: 'index', element: <SupportPage /> },
          { path: 'demo', element: <DemoPage /> },
        ],
      },

      //wallet
      {
        path: '/wallet',
        element: (
          <WalletAuthGuard>
            <DashboardLayout />
          </WalletAuthGuard>
        ),
        children: [
          { element: <Navigate to="/wallet/overview" replace />, index: true },
          { path: 'developer', element: <WalletDeveloperPage /> },
          {
            path: 'balance',
            element: <BalanceLayout />,
            children: [
              { element: <Navigate to={'/wallet/balance/index'} replace />, index: true },
              { path: 'index', element: <WalletBalancePage /> },
              { path: 'details', element: <WalletBalanceDetailsPage /> },
            ],
          },

          {
            path: 'coin-list',
            element: <CoinListLayout />,
            children: [
              { element: <Navigate to={'/wallet/coin-list/index'} replace />, index: true },
              { path: 'index', element: <WalletCoinListPage /> },
              { path: 'coinInfo', element: <CoinInfoPage /> },
              { path: 'coinSettings', element: <CoinSettingsPage /> },
            ],
          },

          { path: 'coinListDetails', element: <CoinListDetailsPage /> },
          {
            path: 'network-configuration',
            element: <NetworkConfigurationLayout />,
            children: [
              {
                element: <Navigate to="/wallet/network-configuration/index" replace />,
                index: true,
              },
              { path: 'index', element: <NetworkConfigurationPage /> },
              { path: 'view-tokens', element: <ViewTokensPage /> },
            ],
          },
          { path: 'webhook', element: <WebhookPage /> },
          {
            path: 'transaction',
            element: <TransactionLayout />,
            children: [
              {
                element: <Navigate to="/wallet/transaction/index" replace />,
                index: true,
              },
              { path: 'index', element: <WalletTransactionPage /> },
              { path: 'unclear-funds', element: <WalletUnclearFundsnPage /> },
            ],
          },
          {
            path: 'asset-collection',
            element: <AssetCollectionLayout />,
            children: [
              {
                element: <Navigate to="/wallet/asset-collection/index" replace />,
                index: true,
              },
              { path: 'index', element: <WalletAssetCollectionPage /> },
              { path: 'asset-config', element: <WalletAssetConfigPage /> },
              { path: 'asset-record', element: <WalletAssetRecordPage /> },
            ],
          },

          {
            path: 'user-list',
            element: <UserListLayout />,
            children: [
              {
                element: <Navigate to="/wallet/user-list/index" replace />,
                index: true,
              },
              { path: 'index', element: <WalletUserListPage /> },
              { path: 'detail/:id', element: <WalletUserDetailPage /> },
            ],
          },

          {
            path: 'overview',
            element: <WalletOverviewPage />,
            children: [{ element: <Navigate to={'/overview'} replace />, index: true }],
          },
          { path: 'user-order', element: <WalletUserOrderPage /> },
          {
            path: 'account',
            element: <AccountSettingLayout />,
            children: [
              { element: <Navigate to={'/wallet/account/settings'} replace />, index: true },
              { path: 'settings', element: <WalletAccountSettingsPage /> },
            ],
          },
        ],
      },

      {
        path: '/test',
        element: (
          <DashboardLayout />
        ),
        children: [
          {
            path: 'account',
            element: <AccountSettingLayout />,
            children: [
              { element: <Navigate to={'/test/account/settings'} replace />, index: true },
              { path: 'settings', element: <WalletAccountSettingsPage /> },
            ],
          },
        ],
      },

      {
        element: <CompactLayout />,
        children: [
          { path: '500', element: <Page500 /> },
          { path: '404', element: <Page404 /> },
          { path: '403', element: <Page403 /> },
        ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
    location.state?.backgroundLocation ?? location
  );
}

export default function Router() {
  const location = useLocation() as unknown as { state: { backgroundLocation: Location } };
  return (
    <>
      <AppRouter />
      {location.state?.backgroundLocation && <ModalRoute />}
      <RouteForward />
    </>
  );
}

