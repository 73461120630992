// @mui
import {
  Box,
  Grid,
  styled as MuiStyled,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Stack,
  Button,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import useSetState from '@/hooks/useSetState';
import { MyIcon } from '@/assets/icons/MyIcon';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocales } from '@/locales';
import { transactionApi, userListApi } from '@/pages/WalletApi/common/api/index';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { UserAddressListType } from '@/pages/WalletApi/common/api/userListApi';

type Props = {
  open: boolean;
  onClose: (v?: boolean) => void;
  queryData: {
    record_id: string;
    user_id: string;
    address: string;
    memo?: string;
  };
};

const CreditAssetModal = ({ open, onClose, queryData }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate: t } = useLocales();
  const [loading, setLoading] = useState(false);
  const [sendData, setSendData] = useSetState(queryData);
  const [addressList, setAddressList] = useState<UserAddressListType[]>([]);

  //  submit
  const submitCredit = async () => {
    setLoading(true);
    try {
      const res = await transactionApi.manualDeposit(sendData);
      onClose(true);
      console.log('res', res);
      enqueueSnackbar('Successfully credited!');
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const getUserAddressList = async (id: string) => {
    try {
      const res = await userListApi.getUserAddressList(id);
      if (res.List) {
        setAddressList(res.List);
      }
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  // Whether the input address is the current user's
  const addressIsMatch = useMemo(() => {
    let flag = false;
    const arr = addressList
      .filter((item) => item.UserId === sendData.user_id)
      .map((item) => item.Address);
    if (!sendData.user_id && !sendData.address) {
      flag = false;
    }
    if (sendData.user_id && sendData.address) {
      flag = !arr.includes(sendData.address);
    }

    return flag;
  }, [addressList, sendData.user_id, sendData.address]);

  useEffect(() => {
    setSendData(queryData);
    // eslint-disable-next-line
  }, [queryData]);

  useEffect(() => {
    if (queryData.user_id) {
      getUserAddressList(queryData.user_id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ModalWarp
      open={open}
      onClose={(e: any, r: 'backdropClick' | 'escapeKeyDown') => {
        // Control click transparent background does not close
        if (r === 'escapeKeyDown') {
          onClose();
        }
      }}
    >
      <Box sx={{ minHeight: '40px' }} component="div">
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '20px',
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: 700,
            }}
          >
            {t('wallet.credit_asset')}
          </DialogTitle>

          <IconButton
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            sx={{
              width: 30,
              height: 30,
            }}
          >
            <MyIcon name="close" size={14} />
          </IconButton>
        </Grid>
        <PageStyle>
          <CreditAssetModalWrap>
            <div className="top-title">{t('wallet.merchant_will')}</div>
            <Stack spacing={3} mb={8}>
              <TextField
                label="User ID"
                fullWidth
                autoComplete="off"
                value={sendData.user_id}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setSendData({
                    user_id: e.target.value,
                  });
                }}
                onBlur={() => {
                  if (sendData.user_id) {
                    getUserAddressList(sendData.user_id);
                  }
                }}
              />
              <TextField
                className="user-address"
                label="User Address"
                fullWidth
                autoComplete="off"
                helperText={
                  addressIsMatch ? (
                    <Box
                      sx={{
                        color: '#FF5630',
                        fontWeight: 400,
                        fontSize: '12px',
                      }}
                    >
                      {t('wallet.wrong_address')}
                    </Box>
                  ) : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {sendData.address.length > 0 ? (
                        <MyIcon
                          name="close_fill"
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSendData({
                              address: '',
                            });
                          }}
                        />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                value={sendData.address}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setSendData({
                    address: e.target.value,
                  });
                }}
              />
              {queryData?.memo && (
                <TextField
                  label="Memo"
                  fullWidth
                  autoComplete="off"
                  value={sendData.memo}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setSendData({
                      memo: e.target.value,
                    });
                  }}
                />
              )}
            </Stack>
            <Button
              variant="contained"
              className="confirm-btn"
              disabled={loading}
              onClick={submitCredit}
            >
              {loading ? (
                <CircularProgress variant="indeterminate" color="inherit" size={20} />
              ) : (
                t('wallet.credit')
              )}
            </Button>
          </CreditAssetModalWrap>
        </PageStyle>
      </Box>
    </ModalWarp>
  );
};

const ModalWarp = MuiStyled(Dialog, { skipSx: false })(({ theme }) => ({
  '.MuiDialog-paper': {
    borderRadius: 12,
    margin: 0,
  },
}));

const PageStyle = MuiStyled('div')(({ theme }) => ({
  width: 480,
  maxWidth: '95vw',
  maxHeight: '100%',
  borderRadius: 30,
}));

const CreditAssetModalWrap = styled.div`
  padding: 0px 24px 24px;
  .top-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #27291e;
    margin-bottom: 24px;
  }
  .user-address {
    .MuiOutlinedInput-root {
      padding-right: 0;
    }
    input {
      padding-right: 16px;
      overflow: hidden; // If the length of the text exceeds the limited width, the excess content will be hidden
      white-space: nowrap; // Set the text to be displayed on one line without wrapping
      text-overflow: ellipsis; // Specifies that when the text overflows, an ellipsis is displayed to represent the trimmed text
    }
  }
  .confirm-btn {
    width: 100%;
    padding: 11px 22px;
    height: 48px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }
`;

export default observer(CreditAssetModal);
