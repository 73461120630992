import { NetworkType } from '@/api/WalletApi';
import { toLocalString, tryRun, fixed_token } from '@/utils/until';
import Decimal from 'decimal.js';
import { makeObservable, observable } from 'mobx';
import { integerTokenArr } from '@/config';
import { overviewApi } from '../api';
export type tradingMenuType = {
  MerchantId: string;
  Coin: {
    Id: string;
    Symbol: string;
  };
  Status: number;
  Fee: string;
  Symbol: string;
  Name: string;
  LogoUrl: string;
  Balance: string;
  Disabled: Boolean;
  Price: string;
  Amount: string;
  Value: string;
};

type AmountNum = string | number | Decimal;

type GoSendOrder = (orderId: string) => void;
/**Currency Sort */
const coinSort = (list: tradingMenuType[]): tradingMenuType[] => {
  let list1: tradingMenuType[] = [];
  let list2: tradingMenuType[] = [];
  let list3: tradingMenuType[] = [];
  let list4: tradingMenuType[] = [];
  list.forEach((item) => {
    if (Number(item.Value) > 0) {
      list1.push(item);
    } else if (fixed_token.stableToken.includes(item.Coin.Symbol)) {
      //mainstream level stable fixed_token.stableToken
      list2.push(item);
    } else if (fixed_token.hotToken.includes(item.Coin.Symbol)) {
      //hot fixed_token.hotToken
      list3.push(item);
    } else {
      list4.push(item);
    }
  });
  list1.sort((a, b) => Number(b.Value) - Number(a.Value));
  list2.sort((a, b) => Number(b.Amount) - Number(a.Amount));
  list3.sort((a, b) => Number(b.Amount) - Number(a.Amount));
  list4.sort((a, b) => Number(b.Amount) - Number(a.Amount));
  return [...list1, ...list2, ...list3, ...list4];
};
class WalletStroe {
  inited = false;

  tokens: any[] = [];

  chains: NetworkType[] = [];

  tokenDict: Record<string, tradingMenuType> = {};

  totalBalance = '0';

  isOpenTransaction = false;

  WithdrawBtnLoading = false;

  /** Because send is also a modal, you need to keep the navigate of the blance route */
  goSendOrder: GoSendOrder = null as any;

  constructor() {
    makeObservable(this, {
      inited: observable,
      tokens: observable,
      chains: observable,
      totalBalance: observable,
      isOpenTransaction: observable,
      WithdrawBtnLoading: observable,
    });
  }

  /**Return token object according to token_id */
  getToken(token_id: string) {
    return this.tokenDict[token_id];
  }

  async setWithdrawBtnLoading(par: boolean) {
    this.WithdrawBtnLoading = par;
  }

  async setIsOpenTransaction(par: boolean) {
    this.isOpenTransaction = par;
  }

  async asyncWalletInfo() {
    let tokensList = await overviewApi.getMerchantSimple();
    this.tokens = tokensList.List;
    await this.syncBalance();
  }

  //balance list sort
  async syncBalance() {
    const { List, TotalValue } = (await overviewApi.getMerchantBalance()) || { List: [] };
    this.totalBalance = TotalValue;
    List?.forEach((item: { CoinId: string; Amount: string; Price: string; Value: string }) => {
      const index = this.tokens.findIndex((j) => j.Coin.Id === item.CoinId) || 0;
      if (index < 0) return;
      this.tokens[index].Amount = item.Amount; //Amount
      this.tokens[index].Price = item.Price; //Price
      this.tokens[index].Value = item.Value; //Calculated as the price of usdt
    });
    this.tokens.forEach((item) => {
      this.tokenDict[item.Coin.Id] = item;
    });
    this.tokens = coinSort(this.tokens);
  }

  //(<= 8)Output precision control, if it is greater than 8 digits, 8 digits will be reserved, if it is less than 8 digits, the corresponding digits will be displayed, and the default is 8 digits
  formatePrecision(n: AmountNum, precision?: number, tokenSymbol?: string) {
    if (!isNaN(Number(n))) {
      let prec = precision && precision <= 8 ? precision : 8;
      if (tokenSymbol && integerTokenArr.includes(tokenSymbol)) {
        prec = 0;
      }
      return new Decimal(Number(n)).toFixed(prec, Decimal.ROUND_DOWN);
    }
    const num = new Decimal(n).toNumber();
    return num;
  }

  /**
    * (>= 8) formatted amount, number of tokens
    * Keep 8 digits after the decimal point, a total of 10 digits
    */
  formateAmount({
    num = 0,
    precision = 8,
    tokenSymbol = '',
  }: {
    num: AmountNum;
    precision?: number;
    tokenSymbol?: string;
  }) {
    return (
      tryRun(() => {
        const dec = new Decimal(num);
        const { length } = dec.abs().floor().toString();
        const pres = precision;
        precision = precision > 8 ? 9 : precision + 1;
        if (length >= precision) {
          let value = '';
          // Here it is necessary to judge whether it is greater than 1, and if it is greater than 1, directly retain 8 decimal places
          if (dec.greaterThan(1)) {
            value = dec.toFixed(8, Decimal.ROUND_DOWN);
          } else {
            value = dec.toFixed(precision - length, Decimal.ROUND_DOWN);
          }
          value = this.formatePrecision(value, pres).toString();
          if (integerTokenArr.includes(tokenSymbol)) {
            value = new Decimal(value).toFixed(0, Decimal.ROUND_CEIL);
          }
          // Do thousandths
          let result = '';
          if (typeof value === 'string') {
            if (value.includes('.')) {
              result = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              result = toLocalString(value);
            }
          } else {
            result = value;
          }

          if (Number(num) === 0) {
            return result;
          } else {
            return dec.greaterThan(new Decimal(0)) ? result : '-' + result;
          }
        } else {
          let value = '';
          // Here it is necessary to judge whether it is greater than 1, and if it is greater than 1, directly retain 8 decimal places
          if (dec.greaterThan(1)) {
            value = dec.toFixed(8, Decimal.ROUND_DOWN);
          } else {
            value = dec.toFixed(precision - length, Decimal.ROUND_DOWN);
          }

          value = this.formatePrecision(value, pres).toString();
          // If it is a sats token, round up
          if (integerTokenArr.includes(tokenSymbol)) {
            value = new Decimal(value).toFixed(0, Decimal.ROUND_CEIL);
          }

          // Do thousandths
          let result = '';
          if (typeof value === 'string') {
            if (value.includes('.')) {
              result = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              result = toLocalString(value);
            }
          } else {
            result = value;
          }
          return result;
        }
      }) ?? '0.00'
    );
  }

  /**
   * Format the local fiat currency, with two decimal places by default
   */
  formateFaitAmount(num: AmountNum = 0) {
    return (
      tryRun(() => {
        const dec = new Decimal(num);
        const fnum = dec.toFixed(2, Decimal.ROUND_DOWN);
        // If it is greater than 1000, perform thousandths processing
        if (Number(num) >= 1000) {
          let result = this.toLocalString(fnum);
          if (!result.includes('.')) {
            result = result + '.00';
          }
          return result;
        } else {
          return fnum;
        }
      }) ?? '0.00'
    );
  }

  /**
    *string type fiat currency quantity add thousands separator
    *Number type fiat currency quantity add thousands separator using Number.prototype.toLocalString
   * @param {string} num
   * @param {string} delimiter
   * @param {number} size
   * @return {*}  {string}
   * @memberof WalletStore
   */
  toLocalString(num: string | number) {
    return Number(num).toLocaleString('en-US');
  }
}
export default WalletStroe;
