import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { walletNotificationApi } from '@/pages/WalletApi/common/api';
import { enqueueSnackbar } from 'notistack';
import { doCopy } from '@/utils/until';
import { NotificationContext } from '..';
import { TGBASEURL } from '@/config';

const tokenRuleReg = /^[\w-]+$/;

function DialogChangeInviteTgForm({
  code,
  open,
  onClose,
}: {
  code: string;
  open: boolean;
  onClose: (v?: boolean) => void;
}) {
  const { translate: t } = useLocales();
  const formContext = useForm({
    defaultValues: {
      token_code: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit } = formContext;
  const [submitLoading, setSubmitLoading] = useState(false);

  const token_code = formContext.watch('token_code');
  const { BotName } = useContext(NotificationContext);

  const onOK = async () => {
    setSubmitLoading(true);
    try {
      const res = await walletNotificationApi.botPutGroup(token_code);
      onClose(true);
      enqueueSnackbar(
        <div
          style={{
            fontSize: '14px',
            color: '#27291E',
            fontWeight: 600,
            lineHeight: '22px',
          }}
        >
          <i style={{ fontStyle: 'normal', color: '#ADCB53' }}>@{res.GroupName}</i>{' '}
          {t('wallet.account.setting.notification.dialog.toast.desc1')}
        </div>
      );
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log('error', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const passwordValidation = useMemo(
    () => ({
      required: t('wallet.account.setting.notification.dialog.input.error2'),
      validate: (val: string) => {
        if (!tokenRuleReg.test(val))
          return t('wallet.account.setting.notification.dialog.input.error2');
        return true;
      },
      deps: 'token_code',
    }),
    // eslint-disable-next-line
    [token_code]
  );

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [open]
  );

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ width: 480, mx: 'auto' }}
      PaperProps={{
        sx: { m: 0, width: '100%' },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('wallet.account.setting.notification.dialog.change.title2')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          // paddingTop: '24px !important',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E6E8E0',
            span: {
              color: '#73756D',
            },
          },
          '.MuiFormLabel-root': {
            color: '#73756D',
          },
          '.MuiFormHelperText-root': {
            color: '#73756D',
          },
        }}
      >
        <ListDescWrap>
          <li>
            {t('wallet.account.setting.notification.dialog.bind.tg.desc1')}{' '}
            <i
              onClick={() => {
                BotName && window.open(`${TGBASEURL}${BotName}`);
              }}
            >
              @{BotName || t('wallet.account.setting.notification.dialog.Merchantbot')}
            </i>{' '}
            {t('wallet.account.setting.notification.dialog.bind.tg.desc2')}
          </li>
          <li>
            {t('wallet.account.setting.notification.dialog.bind.tg.desc3')}{' '}
            <i
              onClick={() => {
                doCopy(`/code ${code}`, t, enqueueSnackbar);
              }}
            >
              /code {code}
            </i>{' '}
            {t('wallet.account.setting.notification.dialog.bind.tg.desc4')}
          </li>
          <li>{t('wallet.account.setting.notification.dialog.bind.tg.desc5')}</li>
        </ListDescWrap>
        <FormContainer formContext={formContext} handleSubmit={handleSubmit(onOK)}>
          <TextFieldElement
            name="token_code"
            placeholder={t('wallet.account.setting.notification.dialog.input.placeholder2')}
            // label={t('merchantSettings.security_password_dialog_label1')}
            type="text"
            fullWidth
            // helperText={
            //   pwStrength ? t('register.password_rule_desc') : t('common.password_intensity_tip')
            // }
            sx={{
              mb: 3,
              '.MuiFormHelperText-root': {
                // display: 'none',
                // color: pwStrength ? '#B6B8B0 !important' : '#FFAB00 !important',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #E6E8E0',
                span: {
                  color: '#73756D',
                },
              },
              input: {
                '::placeholder': {
                  color: '#73756D',
                },
              },
            }}
            validation={passwordValidation}
            autoComplete="off"
          />
        </FormContainer>
      </DialogContent>
      <DialogActions
        className={`loading-btn-green ${submitLoading ? '' : 'wallet-loading-btn-disabled'}`}
      >
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          disabled={!(token_code?.length > 0) || !tokenRuleReg.test(token_code)}
          className="btn-height48"
          onClick={onOK}
          loadingIndicator={
            <MyIcon name="loading_green" className="loading-icon-rotate" size={18} />
          }
          loading={submitLoading}
        >
          {!submitLoading && t('common.next')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const ListDescWrap = styled.ul`
  padding-left: 24px;
  li {
    color: var(--grayscale-100, #27291e);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 24px;
    }
    i {
      color: var(--primary-main, #adcb53);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      cursor: pointer;
    }
  }
`;

export default observer(DialogChangeInviteTgForm);
