import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';
import useLocalStorage from '@/hooks/useLocalStorage';
import { PATH_AFTER_LOGIN, WALLET_PATH_AFTER_LOGIN } from '@/config';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, isVerify, user, chooseApi } = useAuthContext();
  const { pathname, state } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [verifyType] = useLocalStorage('verifyType', '1');

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // No login and no authentication both go login
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={'/login'} />;
  }

  // Can only be directed to the verification screen after the login step
  if (isAuthenticated && !isVerify && pathname !== '/verification') {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={'/verification'} replace={true} state={state} />;
  }

  if (['1', 1].includes(chooseApi)) {
    // If you go to the verification page without a security verification method and you are not logged in, jump back to login, except for full verification
    if (
      isAuthenticated &&
      !isVerify &&
      pathname === '/verification' &&
      // eslint-disable-next-line
      !Boolean(user?.security_email) &&
      // eslint-disable-next-line
      !Boolean(user?.security_phone) &&
      !user?.exists_google_2fa &&
      !['2', 2].includes(verifyType)
    ) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return <Navigate to={'/login'} replace={true} state={state} />;
    }

    if (
      isAuthenticated &&
      isVerify &&
      ['/verification', '/login', '/register'].includes(pathname)
    ) {
      return <Navigate to={PATH_AFTER_LOGIN} />;
    }
    //Prevent jumping to each other
    if (
      isAuthenticated &&
      isVerify &&
      pathname.includes('wallet') &&
      ['1', 1].includes(chooseApi)
    ) {
      return <Navigate to={PATH_AFTER_LOGIN} />;
    }

    //Prevent jumping to each other
    if (
      isAuthenticated &&
      isVerify &&
      !pathname.includes('wallet') &&
      ['2', 2].includes(chooseApi)
    ) {
      return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
    }
  } else {
    if (isAuthenticated && !isVerify && pathname === '/verification' && user?.SafetyMode === 0) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }

      return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
    }

    // After the verification is successful, go to the verification page and return directly to the main page
    if (
      isAuthenticated &&
      isVerify &&
      ['/verification', '/login', '/register'].includes(pathname)
    ) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }

      return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
    }

    //Prevent jumping to each other
    if (
      isAuthenticated &&
      isVerify &&
      pathname.includes('wallet') &&
      ['1', 1].includes(chooseApi)
    ) {
      return <Navigate to={PATH_AFTER_LOGIN} />;
    }

    //Prevent jumping to each other
    if (
      isAuthenticated &&
      isVerify &&
      !pathname.includes('wallet') &&
      ['2', 2].includes(chooseApi)
    ) {
      return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
