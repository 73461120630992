import { useState } from 'react';
// @mui
// components
import { useLocales } from '@/locales';
import ConfirmDialog from '@/components/confirm-dialog';
import { LoadingButton } from '@mui/lab';
import { MyIcon } from '@/assets/icons/MyIcon';

export default function StopDialog({
  visible,
  onClose,
  handleStop: Stop,
}: {
  visible: boolean;
  onClose: (isSearch?: boolean) => void;
  handleStop: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const handleCloseConfirm = () => {
    onClose();
  };

  const handleStop = async () => {
    setLoading(true);
    try {
      await Stop();
      setLoading(false);
      onClose(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  const { translate } = useLocales();

  return (
    <>
      <ConfirmDialog
        open={visible}
        onClose={handleCloseConfirm}
        title={translate('invoices.table_action_stop')}
        content={translate('invoices.table_action_stop_desc')}
        sx={{ '.MuiDialogContent-root': { fontSize: '16px' } }}
        className="loading-btn"
        action={
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleStop}
            loading={loading}
            loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
          >
            {translate('common.Stop')}
          </LoadingButton>
        }
      />
    </>
  );
}
