import { Http } from '@/api/http';
const http = new Http('/merchant/console/v1');

export default class UserOrder {
  /**
   * withdraw list 
   */
  getWithdrawList(data: getWithdrawListParamType): Promise<getWithdrawListResType> {
    return http.postJson('/userorders/withdrawlist', data);
  }

  /**
   * withdraw details
   */
  getWithdrawDetail(BusinessId: string): Promise<getWithdrawDetailResType> {
    return http.postJson('/userorders/withdrawdetail', { BusinessId });
  }

  /**
   * get withdraw status list 
   */
  getWithdrawStatusList(): Promise<getWithdrawStatusListResType> {
    return http.postJson('/userorders/search/withdraw_status', {});
  }

  /**
   * depoist list 
   */
  getDepositList(data: getDepositListParamType): Promise<getDepositListResType> {
    return http.postJson('/userorders/depositlist', data);
  }

  /**
   * get deposit network list 
   */
  getDepositNetworkList(): Promise<getDeployChainCListResType> {
    return http.postJson('/userorders/search/getnetworks', {});
  }

  /**
   * get transfer Record list 
   */
  getTransferList(data: getTransferListParamType): Promise<getTransferListResType> {
    return http.postJson('/userorders/transferlist', data);
  }

  /**
   * get tokens list 
   */
  getCoinList(): Promise<getCoinListResType> {
    return http.postJson('/userorders/deploy/getcoins', {});
  }

  /**
   * get transfer status list 
   */
  getTransferStatusList(): Promise<getTransferStatusListResType> {
    return http.postJson('/userorders/search/transfer_status', {});
  }

  /**
   * Obtain the full data of the configuration table chains
   */
  getDeployChainCList(): Promise<getDeployChainCListResType> {
    return http.postJson('/userorders/deploy/getchains', {});
  }
}

export type getDeployChainCListResType = {
  List: {
    [key: string]: ChainListType;
  };
};

export type ChainListType = {
  Chain: string;
  Name: string;
  Symbol: string;
  Status: number;
  Explorer: string;
  TxIdUrl: string;
  ChainLogo: string;
  Type: string;
};

export type getTransferStatusListResType = {
  List: number[];
};

export type getCoinListResType = {
  List: {
    [key: string]: getCoinListType;
  };
};

export type getCoinListType = {
  Id: string;
  symbol: string;
  Symbol: string;
  Name: string;
  LogoURI: string;
  Desc: string;
  Type: number;
  Price: string;
  CMCCoinId: string;
  CMCCoinIdUpdated: boolean;
  PriceUpdated: boolean;
};

export interface Coin {
  Id: string;
  Symbol: string;
}

export type getTransferListParamType = {
  BillId: string;
  FromUserId: string;
  ToUserId: string;
  Status: number;
  CoinId: string;
  BeginAt: string;
  EndAt: string;
  PageIndex: number;
  PageSize: number;
};

export type getTransferListResType = {
  List: getTransferListType[];
  Pagination: Pagination;
};

export type getTransferListType = {
  amount: string;
  bill_id: string;
  business_id: string;
  coin_id: string;
  coin_symbol: string;
  created_at: string;
  deleted_at: any;
  id: number;
  merchant_id: string;
  reason: string;
  remarks: string;
  status: number;
  to_user_id: string;
  type: number;
  updated_at: string;
  use_frozen: number;
  user_id: string;
  logo?: string;
};

export type getDepositNetworkListResType = {
  List: getDepositNetworkListType[];
};

export type getDepositNetworkListType = {
  Id: string;
  Symbol: string;
  ChainId: string;
  Contract: string;
  Status: number;
  Type: string;
  FeeSwitch: boolean;
};

export type getDepositListParamType = {
  Uid: string;
  Address: string;
  TxId: string;
  ChainId: string;
  CoinId: string;
  BeginAt: number;
  EndAt: number;
  PageIndex: number;
  PageSize: number;
};

export type getDepositListResType = {
  List: getDepositListType[];
  Pagination: Pagination;
};

export type getDepositListType = {
  amount: string;
  chain_id: string;
  coin_id: string;
  confirmed_timestamp: number;
  from_address: string;
  status: number;
  symbol: string;
  tx_id: string;
  user_id: string;
  logo?: string;
  network?: ChainListType;
};

export type getWithdrawStatusListResType = {
  List: {
    '100': string;
    '150': string;
    '200': string;
    '210': string;
    '220': string;
    '300': string;
    '400': string;
  };
};

export type getWithdrawDetailResType = {
  Callback: {
    withdrawOuterId: string;
    status: number;
    txId: string;
    txIndex: number;
    time: number;
    fee: string;
    feeTokenId: string;
    feeChain: string;
    height: number;
  };
  Order: {
    BillId: string;
    MerchantId: string;
    BusinessId: string;
    UserId: string;
    Id: string;
    Symbol: string;
    ChainId: string;
    Contract: string;
    Address: string;
    Memo: string;
    Amount: string;
    FeeMode: string;
    Status: number;
    Type: number;
    NetworkCoin: NetworkCoin;
    NetworkCoinPrice: string;
    EstimatedNetworkFee: string;
    ProfitNetworkFee: string;
    NetworkCoinFee: string;
    Fee: string;
    CoinPrice: string;
    CoinFee: string;
    Remarks: string;
    Reason: string;
    TxId: string;
    TxConfirmedAt: number;
    StatusUpdated: boolean;
    BeforeStatus: number;
    ProfitServiceFeeUpdated: boolean;
    ReasonUpdated: boolean;
  };
};

export type getWithdrawListParamType = {
  Uid: string;
  Address: string;
  BillId: string;
  CoinId: string;
  Status: number;
  BeginAt: number;
  EndAt: number;
  PageIndex: number;
  PageSize: number;
};
export type getWithdrawListResType = {
  List: WithdrawListType[];
  Pagination: Pagination;
};

export type WithdrawListType = {
  BillId: string;
  MerchantId: string;
  BusinessId: string;
  UserId: string;
  Id: string;
  Symbol: string;
  ChainId: string;
  Contract: string;
  Address: string;
  Memo: string;
  Amount: string;
  FeeMode: string;
  Status: number;
  NetworkCoin: NetworkCoin;
  NetworkCoinPrice: string;
  EstimatedNetworkFee: string;
  ProfitNetworkFee: string;
  Fee: string;
  CoinPrice: string;
  Remarks: string;
  Reason: string;
  StatusUpdated: boolean;
  BeforeStatus: number;
  ProfitServiceFeeUpdated: boolean;
  TxConfirmedAt: number;
  logo?: string;
};

export interface NetworkCoin {
  Id: string;
  Symbol: string;
}

export interface Pagination {
  PageIndex: number;
  PageSize: number;
  Total: number;
}
