import http from './http';
const CryptoJS = require('crypto-js');

const token = {
  id: 'f36ad1cf-222a-4933-9ad0-86df8069f916',
  symbol: 'BTC',
  chain_id: 'BTC',
  contract: 'BTC',
  logo: 'https://resource.cwallet.com/token/icon/btc.png',
  name: 'Bitcoin',
  change24: '0.801202870000000',
  price: '16290.52',
  market_cap: '755720654897.2347',
  balance: '',
  value: '',
  out_balance: '',
  out_value: '',
  label: '',
  type: 1,
  token_id: '',
  status: 1,
};

export type SystemToken = typeof token;

const mToken = {
  merchant_id: 10107,
  symbol: 'BTC',
  name: 'Bitcoin',
  logo: 'https://resource.cwallet.com/token/icon/btc.png',
  id: 'f36ad1c',
};

export type MerchantToken = typeof mToken;

const chain = {
  chain_id: 'ETC',
  contract: '61',
  symbol: 'ETC',
  network: 'ETC',
  status: 1,
  token_id: '0bb7b3ef-5acb-4b0c-8fac-59d48260740c',
  network_detail: 'Ethereum Classic',
  is_support_memo: false,
  precision: 18,
  chain_logo: 'https://resource.cwallet.com/token/icon/etc.png',
  name: 'Ethereum Classic',
};

export type SystemChain = typeof chain;

export default class TokenApi {
  tokenList(type?: number): Promise<SystemToken[]> {
    return http.postJson('/token/system/list', { type: type === 2 ? 2 : 1 });
  }

  merchantTokenList(): Promise<MerchantToken[]> {
    return http.postJson('/token/support/list', {});
  }

  chainList(): Promise<SystemChain[]> {
    return http.postJson('/token/all/chain', {}).then((data) => data.lists);
  }

  //Get the merchant's token balance
  getChainBalance({
    chain_id,
    contract,
  }: {
    contract: string;
    chain_id: string;
  }): Promise<ChainBalanceInfo> {
    return http.postJson('/bill/chain/balance', { chain_id, contract });
  }

  //Get the merchant's token balance
  getNetwork(token_id: string): Promise<NetworkType[]> {
    return http.postJson('/token/chains', { token_id });
  }

  //get Network Fee
  getNetWorkFee({
    chain_id = '',
    contract = '',
    address = '',
    memo = '',
  }: {
    chain_id: string;
    contract: string;
    address: string;
    memo: string;
  }): Promise<netWorkFeeType> {
    return http.postJson('/assets/withdraw/fee', { chain_id, contract, address, memo });
  }

  //get order token balance
  getOrderRefundRemain(
    bill_id: string,
    type: string,
    symbol: string
  ): Promise<OrderRefundRemainType> {
    return http.postJson('/bill/symbol/remain', { bill_id, type, symbol });
  }

  //get refund coins list 
  getRefundBillSymbols(bill_id: string, type: string): Promise<RefundBillSymbolsType> {
    return http.postJson('/bill/symbols', { bill_id, type });
  }

  //create refund
  createRefund({
    pass = '',
    code = '',
    merchant_bill_id = '',
    refund_amount = '',
    chain_id = '',
    contract = '',
    symbol = '',
    refund_fee_amount = '',
    memo = '',
    to_address = '',
    invoice = '',
    fee_style = 1,
    network = '',
    type = 'API',
  }: {
    pass: string;
    code: string;
    merchant_bill_id: string;
    refund_amount: string;
    chain_id: string;
    contract: string;
    symbol: string;
    refund_fee_amount: string;
    memo: string;
    to_address?: string;
    invoice?: string;
    fee_style: number;
    network: string;
    type: string;
  }): Promise<createRefundType> {
    return http.postJson('/bill/refund/create', {
      merchant_bill_id,
      refund_amount,
      chain_id,
      contract,
      symbol,
      refund_fee_amount,
      memo,
      to_address,
      invoice,
      fee_style,
      network,
      pass: String(CryptoJS.MD5(pass.trim())),
      code,
      type,
    });
  }
}

export type RefundBillSymbolsType = {
  [key: string]: {
    chain_id: string;
    contract: string;
    symbol: string;
    virtual_token_id: string;
    [key: string]: any;
  };
};

export type createRefundType = {
  refund_apply_id: string;
  transaction_record_id: string;
  [key: string]: any;
};

export type OrderRefundRemainType = {
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  networks: string[];
  [key: string]: any;
};

export type ChainBalanceInfo = {
  balance: string;
  chain_id: string;
  contract: string;
  symbol: string;
  [key: string]: any;
};

export type NetworkType = {
  chain_id: string;
  contract: string;
  symbol: string;
  network: string;
  status: number;
  token_id: string;
  network_detail: string;
  is_support_memo: boolean;
  precision: number;
  chain_logo: string;
  name: string;
  [key: string]: any;
};

export type netWorkFeeType = {
  chain_id: string;
  contract: string;
  symbol: string;
  value: string;
  virtual_value: string;
  token_id: string;
  [key: string]: any;
};
