import { Http } from '@/api/http';
import CryptoJS from 'crypto-js';
const http = new Http('/merchant/console/v1');
const newHttp = new Http('/merchant/v1');

export default class balanceApi {
  /**
   * detailsList
   * CoinId string
   * @returns {string}
   */
  getDetailsList(CoinId: string): Promise<getDetailListResType> {
    return http.postJson('/merchant/coin/network/balance/list', { CoinId });
  }

  /**
   * details cross-chain select network
   * coin_id string
   * @returns {string}
   */
  getDetailsCrossNetwork(coin_id: string): Promise<getDetailsCrossResType> {
    return http.postJson('/swap/coin', { coin_id });
  }

  /**
   * details cross-chain Check the currency exchange fee after entering the amount
   * from_chain string
   * to_chain string
   * coin_id string
   * quantity string
   * @returns {string}
   */
  getDetailsCrossFee(data: crossFeeType): Promise<getDetailsCrossFeeResType> {
    return http.postJson('/swap/quote', { ...data });
  }

  /**
   * submit cross-chain submit
   * quote_id string、
   * @returns {string}
   */
  getCrossSubmit(quote_id: string) {
    return http.postJson('/swap/submit', { quote_id });
  }

  /**
   * submit order details
   * order_no string、
   * @returns {string}
   */
  getCrossOrder(order_no: string) {
    return http.postJson('/swap/order', { order_no });
  }

  /**
   * Recharge address
   * ChainId string
   * Contract string
   * @returns {string}
   */
  getDepositAdress(data: depositAdress): Promise<getDepositAdressFeeResType> {
    return http.postJson('/merchant/balance/deposit/address', { ...data });
  }

  /**
   * Recharge status
   * Address string
   * GenerateTime string
   * ChainId string
   * @returns {string}
   */
  getDepositStatus(data: depositStatus): Promise<getDepositStatusFeeResType> {
    return http.postJson('/merchant/balance/deposit/status', { ...data });
  }

  /**
   * Withdraw Check
   * ChainId string
   * Contract string
   * @returns {string}
   */
  getWithdrawVerify(data: withdrawVerify) {
    data.pay_pass = CryptoJS.MD5(data.pay_pass).toString();
    return newHttp.postJson('/withdraw/verify', { ...data });
  }

  /**
   * Withdraw submit
   * Address string
   * GenerateTime string
   * @returns {string}
   */
  getWithdraw(data: withdraw): Promise<getWithdrawResType> {
    return http.postJson('/merchant/balance/withdraw', { ...data, Passwd: data.Passwd ? String(CryptoJS.MD5(data.Passwd)) : null });
  }

  /**
   * Withdraw Get Fee
   * CoinId string
   * Chain string
   * @returns {string}
   */
  getWithdrawFee(data: withdrawFee): Promise<getWithdrawFeeResType> {
    return http.postJson('/merchant/balance/withdraw/fee', { ...data });
  }

  /**
   * Withdraw Get Chain Assets
   * CoinId string
   * ChainId string
   * Contract string
   * @returns {string}
   */
  getWithRemainBalance(data: withdrawRemain): Promise<getWithRemainResType> {
    return http.postJson('/merchant/coin/remain_balance', { ...data });
  }
}
type withdrawRemain = {
  CoinId: string;
  ChainId: string;
  Contract: string;
};

export type getWithRemainResType = {
  Address: string;
  Amount: string;
  ChainId: string;
  CoinId: string;
  Contract: string;
  DispersionAmount: string;
  MaximumAmountOnce: string;
  OtherRemainAmount: string;
  RemainAmount: string;
  Symbol: string;
};

export type getWithdrawWithRemainResType = {
  Chain: string;
  FeeAmount: string;
};

type withdrawFee = {
  CoinId: string;
  Chain: string;
};

export type getWithdrawFeeResType = {
  Chain: string;
  FeeAmount: string;
};

type withdraw = {
  Passwd?: string;//password
  VerifyHash?: string,//deprecate
  TotpCode?: string,
  MailCode?: string,
  SmsCode?: string,
  ChainId: string,
  Contract: string,
  Address: string,
  Memo: string,
  Amount: string,
}

export type getWithdrawResType = {
  Order: {
    BillId: string;
    MerchantId: string;
    BusinessId: string;
    UserId: string;
    Id: string;
    Symbol: string;
    ChainId: string;
    Contract: string;
    Address: string;
    Memo: string;
    Amount: string;
    FeeMode: string;
    Status: number;
    Type: number;
    NetworkCoin: {
      Id: string;
      Symbol: string;
    };
    NetworkCoinPrice: string;
    EstimatedNetworkFee: string;
    ProfitNetworkFee: string;
    Fee: string;
    CoinPrice: string;
    Remarks: string;
    Reason: string;
    TxId: string;
    TxConfirmedAt: number;
    StatusUpdated: boolean;
    BeforeStatus: number;
    ProfitServiceFeeUpdated: boolean;
  };
  CreatedAt: number;
  Callback: null;
  CallbackUpdated: boolean;
};

type withdrawVerify = {
  pay_pass: string;
  timestamp: number;
  code: string;
};

type depositAdress = {
  ChainId: string;
  Contract: string;
};

export type getDepositAdressFeeResType = {
  Address: string;
  Memo: string;
  GenerateTime: number;
};

type depositStatus = {
  ChainId: string;
  Address: string;
  GenerateTime: number;
};

export type listType = {
  BillId: string;
  MerchantId: string;
  UserId: string;
  BusinessId: string;
  CoinId: string;
  ChainId: string;
  Contract: string;
  Symbol: string;
  Transaction: string;
  SubTransaction: string;
  TxHash: string;
  TxHeight: number;
  TxIndex: number;
  Amount: string;
  Fee: string;
  Address: string;
  FeeAddress: string;
  FromAddrList: string[];
  ToAddrList: string[];
  Nonce: number;
  Status: number;
  BlockTimestamp: number;
  LogicSort: number;
  ActiveFlag: boolean;
  CreatedAt: string;
  UpdateAt: string;
};

export type getDepositStatusFeeResType = {
  List: listType[];
};

type crossFeeType = {
  from_chain: string;
  to_chain: string;
  coin_id: string;
  quantity: string;
};

type feeCoin = {
  chain_id: string;
  contract: string;
  decimal: number; //precision
  id: string;
  logoURI: string;
  name: string;
  symbol: string;
  type: string;
};

export type formType = {
  amount: string;
  [key: string]: any;
};

export type getListTransationDetailResType = {
  chain_id: any;
  created: number; //
  from_coin: feeCoin;
  from_fee: string;
  from_fee_coin: feeCoin;
  from_tx_id: string;
  pay_amount: string;
  payment_type: string;
  status: number;
  to_amount: string;
  to_coin: feeCoin;
  to_fee: string;
  to_fee_coin: feeCoin;
  to_tx_id: string;
  user_id: string;
  order_no: string;
};

export type detaillistType = {
  Id: number;
  MerchantId: string;
  CoinId: string;
  Symbol: string;
  ChainId: string;
  Amount: string;
  Name: string;
  [key: string]: any;
};

export interface getDetailListResType {
  Price: string;
  Balances: string;
  CoinName: string;
  CoinSymbol: string;
  List: detaillistType[];
}

export interface chainType {
  id: string;
  symbol: string;
  contract: string;
  name: string;
  logoURI: string;
  balance: string; // choose链之后get到的balance
  chain_id: string;
  decimal: number; //精度
  type: string;
  target_liquidity: string;
  min_swap_balance: string;
  recommend_quantity: string;
}

export interface getDetailsCrossResType {
  coin_id: string;
  symbol: string;
  chain_list: chainType[];
}
export interface getDetailsCrossFeeResType {
  quote_id: string;
  from_fee_coin: {
    symbol: string;
    contract: string;
    name: string;
    chain_id: string;
  };
  from_fee_enough: boolean;
  from_fee: string;
  pay_amount: string;
  to_fee_coin: {
    id: string;
    symbol: string;
    contract: string;
    name: string;
    chain_id: string;
    logoURI: string;
  };
  to_fee: string;
  to_amount: string;
}
