import { makeObservable, observable, toJS } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';
import { userListApi } from '../api';
import { getUserAssetRecordListResType, getUserListResType } from '../api/userListApi';

interface searchParamProps {
  UserId?: string;
  UserName?: string;
  Status?: number;
  PageIndex?: number;
  PageSize?: number;
}

interface userDetailSearchParamProps {
  CoinId?: string;
  Action?: number;
  UserId?: string;
  BusinessId?: string;
  PageIndex?: number;
  PageSize?: number;
}

class WalletUserListStroe {
  searchParam = {
    UserId: '',
    UserName: '',
    Status: 0,
    PageIndex: 1,
    PageSize: 20,
  };

  userDetailSearchParam = {
    CoinId: '0',
    Action: 0,
    UserId: '',
    BusinessId: '',
    PageIndex: 1,
    PageSize: 20,
  };

  searchLoading = false;

  tableData: getUserListResType = {
    List: [],
    Pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  useDetailTableData: getUserAssetRecordListResType = {
    List: [],
    Pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  isBan = false;

  constructor() {
    makeObservable(this, {
      searchParam: observable,
      userDetailSearchParam: observable,
      tableData: observable,
      searchLoading: observable,
      useDetailTableData: observable,
      isBan: observable,
    });
    this.setSearchParam = this.setSearchParam.bind(this);
    this.queryUserList = this.queryUserList.bind(this);
    this.setUserDetailSearchParam = this.setUserDetailSearchParam.bind(this);
    this.queryUserDetailList = this.queryUserDetailList.bind(this);
    this.setUserActiveorban = this.setUserActiveorban.bind(this);
    this.setIsBan = this.setIsBan.bind(this);
  }

  async setSearchParam(param: searchParamProps) {
    const oldsSearchParam = { ...this.searchParam };
    this.searchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  async setUserDetailSearchParam(param: userDetailSearchParamProps) {
    const oldsSearchParam = { ...this.userDetailSearchParam };
    this.userDetailSearchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  /**
   *Query list data
   * @memberof WalletUserListStroe
   */
  async queryUserList() {
    try {
      this.searchLoading = true;
      const res = await userListApi.getUserList(this.searchParam);
      this.tableData = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   *Query single user details list data
   * @memberof WalletUserListStroe
   */
  async queryUserDetailList() {
    try {
      this.searchLoading = true;
      const res = await userListApi.getUserAssetRecordList({
        ...this.userDetailSearchParam,
        CoinId: this.userDetailSearchParam.CoinId === '0' ? '' : this.userDetailSearchParam.CoinId,
      });

      this.useDetailTableData = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }

  async setUserActiveorban(user_id: string) {
    try {
      await userListApi.setUserActiveorban(user_id);
      await this.setIsBan(true);
      enqueueSnackbar(i18n.t('common.Successful'));
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  async setIsBan(v: boolean) {
    this.isBan = v;
  }
}
export default WalletUserListStroe;
