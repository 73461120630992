import { Box, BoxProps, styled } from '@mui/material';

type Props = {
  label: string;
  type:
    | 'Pending'
    | 'Processing'
    | 'Expired'
    | 'Successful'
    | 'Failed'
    | 'Overpaid'
    | 'Underpaid'
    | 'Refund'
    | 'OverduePayment'
    | 'MultiplePayment'
    | 'Others'
    | 'defalut'
    | 'AwaitingPayment'
    | 'Success'
    | 'Fail'
    | string;
} & BoxProps;

const labelStyle = {
  Pending: {
    color: '#B76E00',
    backgroundColor: 'rgba(255, 171, 0, 0.16)',
  },
  Processing: {
    color: '#919EAB',
    backgroundColor: 'transparent',
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  Expired: {
    color: '#919EAB',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
  },
  Successful: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  Success: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  success: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  Failed: {
    color: '#B71D18',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
  Fail: {
    color: '#B71D18',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
};

export default function StatusLabel({ label, type, ...other }: Props) {
  return (
    <StatusStyleLabel
      {...other}
      component="span"
      sx={{
        ...labelStyle[type],
        ...other.sx,
      }}
    >
      {label}
    </StatusStyleLabel>
  );
}
const StatusStyleLabel = styled(Box)<BoxProps>(({ theme }) => ({
  border: 0,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  fontStyle: 'normal',
  padding: '4px 8px',
  borderRadius: '6px',
  gap: '6px',
  maxWidth: '120px',
}));
