import { makeObservable, observable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';
import { userOrderApi } from '../api';
import {
  ChainListType,
  getCoinListType,
  getDepositListResType,
  getTransferListResType,
  getWithdrawListResType,
} from '../api/userOrders';

class WalletUserOrdersStroe {
  coinList: getCoinListType[] = [];

  chainTypeList: ChainListType[] = [];

  networkListMap: { [key: string]: ChainListType } = {};

  depositTypeValue = 0;

  withdrawTypeValue = 0;

  depositSearchParams = {
    Uid: '',
    Address: '',
    TxId: '',
    ChainId: '0',
    CoinId: '0',
    BeginAt: 0,
    EndAt: 0,
    PageIndex: 1,
    PageSize: 20,
  };

  withdrawSearchParams = {
    Uid: '',
    Address: '',
    BillId: '',
    CoinId: '0',
    Status: 0,
    BeginAt: 0,
    EndAt: 0,
    PageIndex: 1,
    PageSize: 20,
  };

  transferSearchParams = {
    BillId: '',
    FromUserId: '',
    ToUserId: '',
    Status: 0,
    CoinId: '0',
    BeginAt: '',
    EndAt: '',
    PageIndex: 1,
    PageSize: 20,
  };

  depositTableData: getDepositListResType = {
    List: [],
    Pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  withdrawTableData: getWithdrawListResType = {
    List: [],
    Pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  transferTableData: getTransferListResType = {
    List: [],
    Pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  searchLoading = false;

  constructor() {
    makeObservable(this, {
      coinList: observable,
      chainTypeList: observable,
      networkListMap: observable,
      searchLoading: observable,
      depositTableData: observable,
      depositSearchParams: observable,
      depositTypeValue: observable,
      withdrawSearchParams: observable,
      withdrawTableData: observable,
      withdrawTypeValue: observable,
      transferSearchParams: observable,
      transferTableData: observable,
    });
    this.initSearchParams = this.initSearchParams.bind(this);
    this.getCoinList = this.getCoinList.bind(this);
    this.getChainTypeList = this.getChainTypeList.bind(this);
    this.getNetworkList = this.getNetworkList.bind(this);
    this.setDepositSearchParam = this.setDepositSearchParam.bind(this);
    this.queryDepositList = this.queryDepositList.bind(this);
    this.setDepositTypeValue = this.setDepositTypeValue.bind(this);
    this.setWithdrawSearchParam = this.setWithdrawSearchParam.bind(this);
    this.queryWithdrawList = this.queryWithdrawList.bind(this);
    this.setWithdrawTypeValue = this.setWithdrawTypeValue.bind(this);
    this.setTransferSearchParam = this.setTransferSearchParam.bind(this);
    this.queryTransferList = this.queryTransferList.bind(this);
  }

  async initSearchParams() {
    this.depositSearchParams = {
      Uid: '',
      Address: '',
      TxId: '',
      ChainId: '0',
      CoinId: '0',
      BeginAt: 0,
      EndAt: 0,
      PageIndex: 1,
      PageSize: 20,
    };
    this.depositTypeValue = 0;
    this.withdrawSearchParams = {
      Uid: '',
      Address: '',
      BillId: '',
      CoinId: '0',
      Status: 0,
      BeginAt: 0,
      EndAt: 0,
      PageIndex: 1,
      PageSize: 20,
    };
    this.withdrawTypeValue = 0;
    this.transferSearchParams = {
      BillId: '',
      FromUserId: '',
      ToUserId: '',
      Status: 0,
      CoinId: '0',
      BeginAt: '',
      EndAt: '',
      PageIndex: 1,
      PageSize: 20,
    };
  }

  async setDepositTypeValue(v: number) {
    this.depositTypeValue = v;
  }

  async setWithdrawTypeValue(v: number) {
    this.withdrawTypeValue = v;
  }

  async setDepositSearchParam(param: {
    Uid?: string;
    Address?: string;
    TxId?: string;
    ChainId?: string;
    CoinId?: string;
    BeginAt?: number;
    EndAt?: number;
    PageIndex?: number;
    PageSize?: number;
  }) {
    const oldsSearchParam = { ...this.depositSearchParams, ...param };

    this.depositSearchParams = {
      ...oldsSearchParam,
    };
  }

  async setWithdrawSearchParam(param: {
    Uid?: string;
    Address?: string;
    BillId?: string;
    CoinId?: string;
    Status?: number;
    BeginAt?: number;
    EndAt?: number;
    PageIndex?: number;
    PageSize?: number;
  }) {
    const oldsSearchParam = { ...this.withdrawSearchParams, ...param };

    this.withdrawSearchParams = {
      ...oldsSearchParam,
    };
  }

  async setTransferSearchParam(param: {
    BillId?: string;
    FromUserId?: string;
    ToUserId?: string;
    Status?: number;
    CoinId?: string;
    BeginAt?: string;
    EndAt?: string;
    PageIndex?: number;
    PageSize?: number;
  }) {
    const oldsSearchParam = { ...this.transferSearchParams, ...param };

    this.transferSearchParams = {
      ...oldsSearchParam,
    };
  }

  /**
   *查询deposit list 数据
   * @memberof WalletWebhookStroe
   */
  async queryDepositList() {
    try {
      this.searchLoading = true;
      const data = {
        ...this.depositSearchParams,
      };
      if (data.ChainId === '0') {
        data.ChainId = '';
      }
      if (data.CoinId === '0') {
        data.CoinId = '';
      }

      const res = await userOrderApi.getDepositList(data);
      // console.log('queryDepositListres', res);
      // 这里还需要去组装数据
      let arr: any[] = [];
      if (res.List?.length > 0 && this.coinList?.length > 0) {
        res.List.forEach((item) => {
          if (this.coinList.find((cl) => cl.Id === item.coin_id)) {
            const obj = this.coinList.find((cl) => cl.Id === item.coin_id);
            // console.log(this.networkListMap[item.chain_id]);

            arr.push({
              ...item,
              logo: obj?.LogoURI || '',
              network: this.networkListMap[item.chain_id] ? this.networkListMap[item.chain_id] : {},
            });
          }
        });
      }

      this.depositTableData = { ...res, List: arr };
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   *查询withdraw list 数据
   * @memberof WalletWebhookStroe
   */
  async queryWithdrawList() {
    try {
      this.searchLoading = true;
      const data = {
        ...this.withdrawSearchParams,
      };
      if (data.CoinId === '0') {
        data.CoinId = '';
      }

      const res = await userOrderApi.getWithdrawList(data);
      // console.log('queryDepositListres', res);
      // 这里还需要去组装数据
      let arr: any[] = [];
      if (res.List?.length > 0 && this.coinList?.length > 0) {
        res.List.forEach((item) => {
          if (this.coinList.find((cl) => cl.Id === item.Id)) {
            const obj = this.coinList.find((cl) => cl.Id === item.Id);
            arr.push({
              ...item,
              logo: obj?.LogoURI,
            });
          }
        });
      }
      // console.log('arr', arr);

      this.withdrawTableData = { ...res, List: arr };
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log('error', { error });
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   *查询transfer list 数据
   * @memberof WalletWebhookStroe
   */
  async queryTransferList() {
    try {
      this.searchLoading = true;
      const data = {
        ...this.transferSearchParams,
      };
      if (data.CoinId === '0') {
        data.CoinId = '';
      }

      const res = await userOrderApi.getTransferList(data);
      // console.log('queryDepositListres', res);
      // 这里还需要去组装数据
      let arr: any[] = [];
      if (res.List?.length > 0 && this.coinList?.length > 0) {
        res.List.forEach((item) => {
          if (this.coinList.find((cl) => cl.Id === item.coin_id)) {
            const obj = this.coinList.find((cl) => cl.Id === item.coin_id);
            arr.push({
              ...item,
              logo: obj?.LogoURI,
            });
          }
        });
      }
      // console.log('arr', arr);

      this.transferTableData = { ...res, List: arr };
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log('error', { error });
    } finally {
      this.searchLoading = false;
    }
  }

  async getCoinList() {
    try {
      const res = await userOrderApi.getCoinList();
      // console.log('res', res);
      const arr: any = [];
      if (res.List) {
        Object.keys(res.List).forEach((item) => {
          if (res.List[item]) {
            arr.push(res.List[item]);
          }
        });
      }

      this.coinList = arr;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  async getChainTypeList() {
    try {
      const res = await userOrderApi.getDeployChainCList();
      // console.log('res', res);
      const arr: any = [];
      if (res.List) {
        Object.keys(res.List).forEach((item) => {
          if (res.List[item]) {
            arr.push(res.List[item]);
          }
        });
      }

      this.chainTypeList = arr;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  async getNetworkList() {
    try {
      const res = await userOrderApi.getDepositNetworkList();
      // const arr: ChainListType[] = [];
      // Object.keys(res.List).forEach((item) => {
      //   if (res.List[item]) {
      //     arr.push(res.List[item]);
      //   }
      // });
      this.networkListMap = res.List ? res.List : {};
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }
}
export default WalletUserOrdersStroe;
