import { Http } from '@/api/http';
const CryptoJS = require('crypto-js');
const http = new Http('/merchant/console/v1');
export default class developerApi {
  /**
   * reset
   */
  getReset() {
    return http.postJson('/merchant/secret/reset', {});
  }

  /**
   * reset secret
   */
  resetSafetySecret(data: resetSafetySecretParamType) {
    return http.postJson('/safety/secret/reset', {
      ...data,
      Passwd: String(CryptoJS.MD5(data.Passwd?.trim())),
    });
  }

  /**
   * get secret
   */
  getSafetysecret(data: getSafetySecretParamType): Promise<getSafetySecretResType> {
    return http.postJson('/safety/secret/check', data);
  }

  /**
   * //webhook_url、ip_whitelist、lock_unit(1-5min 2-10 min 3-30min 4-1 hour 5-24hour 6-7day)
   * set Safety Config
   */
  setSafetyConfig(data: setSafetyConfigParamType) {
    return http.postJson('/safety/config/set', data);
  }
}

export type setSafetyConfigParamType = {
  Field: 'webhook_url' | 'ip_whitelist' | 'lock_unit';
  Value: string;
};

export type resetSafetySecretParamType = {
  MailCode?: string;
  SmsCode?: string;
  TotpCode?: string;
  Passwd: string;
};

export type getSafetySecretParamType = {
  MailCode?: string;
  SmsCode?: string;
  TotpCode?: string;
};

export type getSafetySecretResType = {
  AppId: string;
  AppSecret: string;
};
