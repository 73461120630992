/**customer service  */

const intercom_key = 'xwzyvsf9';

function Support() {
  window.APP_ID = intercom_key;
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + intercom_key;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

function supportInit({
  email = '',
  name = '',
  user_id,
  background_color = '#c6d7ff',
  action_color = '#3c6ff5',
  user_hash,
  showSupport,
}) {
  Support();
  window.Intercom('boot', {
    app_id: intercom_key,
    email,
    name,
    user_id,
    background_color,
    action_color,
  });
}

export function showIntercom() {
  window.Intercom('show');
}

export function closeIntercom() {
  window.Intercom('shutdown');
}

export default supportInit;
