import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import { Box, InputAdornment, MenuItem, TextField } from '@mui/material';
import RotateArrow from '@/components/rotate-arrow';
import { useLocales } from '@/locales';
import { MyIcon } from '@/assets/icons/MyIcon';

type Props = {
  value: string;
  onChange: (value: string) => Promise<void>;
  query?: () => Promise<void>;
  typeValue: number;
  typeValueOnChange: (value: number) => Promise<void>;
  optionList?: { value: number; label: string }[];
};

export default function SearchInput({
  value,
  onChange,
  query,
  typeValue,
  typeValueOnChange,
  optionList,
}: Props) {
  const { translate: t } = useLocales();
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef();
  const menuOptions =
    optionList && optionList?.length > 0 ? optionList : [
      {
        value: 0,
        label: t('invoices.item_discount_type'),
      },
      {
        value: 1,
        label: t('invoices.item_discount_percent'),
      },
    ];
  return (
    <Box
      sx={{
        minWidth: '200px',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #E6E8E0',
        borderRadius: '8px',
        mr: 2,
        flex: 'auto',
      }}
    >
      {/* selecttype */}
      <Box>
        <TextField
          className="tradSelect"
          select
          // label='tradSelect'
          variant="outlined"
          value={typeValue}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await typeValueOnChange(Number(e.target.value));
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  padding: '4px',
                  ul: {
                    maxHeight: 260,
                    overflow: 'auto',
                    '::-webkit-scrollbar': {
                      width: '6px',
                      backgroundColor: '#fff',
                    },
                    '::-webkit-scrollbar-thumb': {
                      borderRadius: '12px',
                      backgroundColor: ' #b6bcc1',
                    },
                  },
                },
              },
              sx: {
                mt: 1,
                '.Mui-selected': {
                  backgroundColor: '#F0F1EC',
                  color: '#1E1E1E',
                },
              },
            },
            IconComponent: (val, stu) => <RotateArrow val={val} />,
          }}
          sx={{
            height: '56px',
            // border: '1px solid #E6E8E0',
            borderRadius: '8px',
            textAlign: 'center',
            flex: 'auto',
            '.MuiOutlinedInput-input': {
              fontWeight: '500',
              color: '#27291E',
            },
          }}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                mx: 1,
                my: 0.5,
                fontSize: '14px',
                fontWeight: 400,
                borderRadius: '6px',
                typography: 'body2',
                // textTransform: 'capitalize',
                '&:first-of-type': { mt: 0 },
                '&:last-of-type': { mb: 0 },
                ':hover': {
                  backgroundColor: '#rgba(145, 158, 171, 0.08)',
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {/* 输入框 */}
      <TextField
        type="text"
        autoComplete="off"
        value={value}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        sx={{
          ml: 1,
          flex: 1,
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          input: {
            pl: 0,
            '::placeholder': {
              color: '#B6B8B0',
            },
          },
        }}
        inputRef={inputRef}
        focused={isFocus}
        placeholder={'Search'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {value.length > 0 ? (
                <MyIcon
                  name="close"
                  style={{
                    cursor: 'pointer',
                    visibility: value ? 'visible' : 'hidden',
                  }}
                  onClick={async () => {
                    setIsFocus(false);
                    await onChange('');
                    if (query) await query();
                  }}
                />
              ) : (
                <MyIcon
                  name={isFocus ? 'wallet_transaction_search_black' : 'wallet_transaction_search'}
                  size={24}
                />
              )}
            </InputAdornment>
          ),
        }}
        onBlur={() => {
          if (value.length === 0) setIsFocus(false);
          if (value.length > 0) setIsFocus(true);
          if (!isFocus) return;
          if (query) query();
        }}
        onFocus={() => setIsFocus(true)}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
          if (e.code === 'Enter') {
            (inputRef.current as any)?.blur();
            // setIsFocus(false);
            // query();
          }
        }}
      />
    </Box>
  );
}
