import http from './http';

import CryptoJS from 'crypto-js';
import { WhitelistRes } from './payment-api';

/**
 *  wallet
 */
export default class WalletApi {
  /**getToken list ,chain list  */
  getTokenList(): Promise<TokenResponse> {
    return http.postJson('/token/chain/list/all');
  }

  /**get owned assets list  */
  getTokenBlance(): Promise<{
    total_value: string;
    list: Token[];
  }> {
    return http.postJson('/assets/list', {
      name: '',
      symbol: '',
    });
  }

  getIncomeAddress(data: {
    platform: 'wallet';
    chain_id: string;
    contract: string;
  }): Promise<{ address: string; generate_time: number; memo?: string }> {
    return http.postJson('/assets/deposit/address/get', data);
  }

  // sats interface
  // lightning network/creat bill
  getInPaySats(data: {
    chain_id: string;
    contract: string;
    amount: string;
    remarks?: string;
  }): Promise<{ lnurl: string }> {
    return http.postJson('/ln/pay/get', data);
  }

  // lightning network/analyze bill
  getInPayParse(data: { invoice: string }): Promise<getInPayParseResType> {
    return http.postJson('/ln/pay/parse', data);
  }

  //  lightning network/pay bill
  getInPaySubmit(props: PayItem) {
    props.pay_pass = CryptoJS.MD5(props.pay_pass).toString();
    return http.postJson('/ln/pay/submit', props);
  }

  /**get链 list  */
  getNetworkList(): Promise<{
    lists: NetworkType[];
  }> {
    return http.postJson('/token/all/chain');
  }

  /**According to token_id get support chain */
  getSuportNetworkList(token_id: string): Promise<NetworkType[]> {
    return http.postJson('/token/chains', { token_id });
  }

  /**get Withdrawal fee*/
  getWithdrawFee(props: { chain_id: string; contract: string; address: string; memo?: string }) {
    return http.postJson('/assets/withdraw/fee', props);
  }

  /**Create withdrawal order*/
  createWithdraw(): Promise<string> {
    return http.postJson('/assets/withdraw/apply');
  }

  /**Initiate withdrawal*/
  submitWithdraw(props: Withdraw) {
    props.pay_pass = CryptoJS.MD5(props.pay_pass).toString();

    return http.postJson('/assets/withdraw/commit', props);
  }

  /**get withdrawal whitelist list */
  getWhitelistSendList(token_id: string): Promise<WhitelistRes[]> {
    return http.postJson('/assets/withdraw/while_list', { token_id });
  }

  /**get currency exchange from list  */
  getSwapFromList(): Promise<swapFromListType[]> {
    return http.postJson('/assets/swap/from/get');
  }

  /**get currency exchange to list  */
  getSwapToList(): Promise<swapToListType[]> {
    return http.postJson('/assets/swap/to/get');
  }

  /**get currency exchange token rate */
  getSwapTokenRate(data: getSwapTokenRateParamType): Promise<getSwapTokenRateResType> {
    return http.postJson('/assets/swap/token/rate', data);
  }

  /** submit  swap */
  swapCommit(data: swapCommitParamType): Promise<swapCommitResType> {
    data.pay_pass = CryptoJS.MD5(data.pay_pass).toString();
    return http.postJson('/assets/swap/commit', data);
  }

  /** small Withdraw Verify */
  smallWithdrawVerify(
    data: smallWithdrawVerifyParamType
  ): Promise<{ is_verify: boolean; code: string }> {
    return http.postJson('/assets/withdraw/small/verify', data);
  }
}
export enum smallWithdrawVerifyRefundEnum {
  'withdraw' = 0,
  'refund' = 1,
}

export type smallWithdrawVerifyParamType = {
  chain_id: string;
  contract: string;
  value: string;
  /** 0-withdraw 1-refund */
  refund: 0 | 1;
};

export type swapFromListType = {
  logo: string;
  price: string;
  balance: string;
  precision: number;
  chain_id: string;
  name: string;
  token_id: string;
  min: string;
  max: string;
  symbol: string;
  visual_balance: string;
  type_symbol: string;
  market_cap: string;
};

export type swapToListType = {
  logo: string;
  price: string;
  balance: string;
  precision: number;
  chain_id: string;
  name: string;
  token_id: string;
  min: string;
  max: string;
  symbol: string;
  visual_balance: string;
  type_symbol: string;
  market_cap: string;
};

export type swapCommitParamType = {
  from_quantity: string;
  to_quantity: string;
  torrance: string;
  pay_pass: string;
  f2ACode: string;
  pre_id: string;
  from: string;
  to: string;
};

export type swapCommitResType = {
  status: number;
  rate: string;
  from: string;
  to: string;
  from_ico: string;
  to_ico: string;
  from_quantity: string;
  to_quantity: string;
  fee: string;
  order_no: string;
  create_time: number;
  user_id: string;
  from_token_id: string;
  to_token_id: string;
  out_record_id: string;
  in_record_id: string;
  type: number;
};

export type getSwapTokenRateParamType = {
  from_quantity: string;
  to_quantity: string;
  from: string;
  to: string;
};

export type getSwapTokenRateResType = {
  from: string;
  to: string;
  from_rate: string;
  to_rate: string;
  rate: string;
  pre_id: string;
  from_balance: string;
  to_balance: string;
  from_quantity: string;
  to_quantity: string;
  usable_swap_amount: string;
};

interface TokenResponse {
  chains: Record<string, string>;
  tokens: Token[];
  chain_logos: Record<string, string>;
}

export type getInPayParseResType = {
  lnd_type: string;
  invoice: {
    amount: string;
    callback: string;
    chain_id: string;
    commentAllowed: number;
    contract: string;
    maxSendable: number;
    metadata: {};
    minSendable: number;
    symbol: string;
    tag: string;
    token_id: string;
  };
};

export interface Token {
  id: string;
  fee: string;
  max_supply: string;
  total_supply: string;
  official_site: string;
  community: string;
  CreateTime: string;
  fee_self_flag: number;
  belong_chain_logo: string;
  large_logo: string;
  /**链ID*/
  chain_id: string;
  /**链*/
  chain_name: string;
  /**代币全称 */
  name: string;
  /**代币简称 */
  symbol: string;
  /**地址 */
  contract: string;
  /**待定*/
  min: string;
  /**待定*/
  max: string;
  /**图标*/
  logo: string;
  /**币种id*/
  token_id: string;
  /**精度 */
  precision: number;
  /**币种名字+链名字 */
  type_symbol: string;
  /**代币状态*/
  status: number;
  /**代币市值 */
  market_cap: number;
  /**USDT价格*/
  price: number;
  /**代币余额*/
  balance: number;
  /**类型*/
  type: string;
  /**下线时间 */
  down_deadline_time: string;
  /**24小时涨跌*/
  change24?: string;
  /**代币有哪些链*/
  merge_chains?: string[];
  merge_tokens?: MergeToken[];
  /**是否支持memo代币 */
  is_support_memo: boolean;
  can_convert: boolean;
  /**法币价值 */
  value: string;
  quantity?: string;
  amount?: number;
}

interface MergeToken {
  CreateTime: string;
  can_convert: boolean;
  chain_id: string;
  contract: string;
  description: string;
  explorer: string;
  fee: string;
  fee_self_flag: 2;
  id: string;
  is_support_memo: boolean;
  large_logo: string;
  logo: string;
  market_cap: string;
  max: string;
  max_supply: string;
  merged_token_id: string;
  min: string;
  name: string;
  precision: number;
  price: string;
  status: number;
  symbol: string;
  token_id: string;
  total_supply: string;
  type: string;
  type_symbol: string;
  official_site: string;
  community: string;
  address_regex: string;
  memo_regex?: string;
}

export interface NetworkType {
  chain_id: string;
  contract: string;
  symbol: string;
  network: string;
  status: number;
  token_id: string;
  network_detail: string;
  is_support_memo: boolean;
  precision: number;
  chain_logo: string;
  name: string;
}

interface Withdraw {
  token_id: string;
  /**订单ID */
  bill_id: string;
  /**提现到账地址 */
  address: string;
  /**提现金额 */
  value: string;
  /**支付密码*/
  pay_pass: string;
  /**手续费 */
  fee: string;
  f2a_code?: string | null;
  /**闪电 bill  */
  invoice?: string;
  memo?: string;
  submit_type: number;
  code?: string;
  code_str: string;
}

export interface PayItem {
  token_id: string;
  chain_id: string;
  contract: string;
  invoice: string;
  amount: string;
  fee: string;
  pay_pass: string;
  code: string;
  remarks: string;
  code_str?: string;
}
