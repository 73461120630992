// @mui
import { styled } from '@mui/material/styles';
import { Box, BoxProps, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { MyIcon } from '@/assets/icons/MyIcon';
import MenuPopover from '@/components/menu-popover/MenuPopover';
import { useEffect, useState } from 'react';
import { useLocales } from '@/locales';
import DialogAddMerchanttForm from './components/add-merchant-dialog';
import { accountApi } from '@/api';
import { enqueueSnackbar } from 'notistack';
import { MerchantAccountListItemType } from '@/api/accountApi';
import MerchantLoading from './components/merchant-loading';
import { useLocation, useNavigate } from 'react-router';
import { accountStore, walletStroe } from '@/stores';
import default_merchant_head from '@/assets/img/default_merchant_head.svg';
import { observer } from 'mobx-react';
import useSWR from 'swr';
import PaymentApi from '@/api/payment-api';
const paymentClient = new PaymentApi();

const StyledRoot = styled(Box)<BoxProps>(({ theme, sx }) => ({
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'space-around',
  padding: theme.spacing(2, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: '#F2F6F9',
  sx,
}));

export default observer(function NavAccount({ isFold }: { isFold?: boolean }) {
  const { user, updUserInfoToStore } = useAuthContext();
  const { translate: t } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [merchantList, setMerchantList] = useState<MerchantAccountListItemType[]>();
  const [loading, setLoading] = useState(false);
  const [addloading, setAddLoading] = useState(false);
  const paymentConfigSettings = useSWR('paymentConfigSettings', () => paymentClient.getConfig(1), {
    revalidateOnFocus: false,
  });
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickItem = async (merchant_id: number) => {
    handleClosePopover();
    setLoading(true);
    try {
      await accountApi.switchMerchantAccount(merchant_id);
      await localStorage.setItem('isFirst', '1');
      // await getMerchantList();
      // 在跳转之前更新一下本地用户信息
      await updUserInfoToStore();
      paymentConfigSettings.mutate();
      await accountStore.updateSwitchStatus(true);
      await walletStroe.asyncWalletInfo();
      setLoading(false);

      // window.location.href = '/dashboard/index';
      if (!location.pathname.includes('dashboard')) {
        await navigate('/dashboard/index', {
          replace: true,
        });
      }
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(t(`interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const getMerchantList = async () => {
    try {
      const res = await accountApi.getMerchantAccountList();
      // console.log('res', res);
      setMerchantList(res);
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(t(`interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    getMerchantList();
    // eslint-disable-next-line
  }, []);

  //Synchronize merchantSetting upd MerchantTitle
  useEffect(() => {
    if (accountStore.isChangeMerchantName) {
      getMerchantList();
      accountStore.updateChangeNameStatus(false);
    }
    // eslint-disable-next-line
  }, [accountStore.isChangeMerchantName]);

  if (loading) {
    return <MerchantLoading showText={t('nav.Add_New_Merchant_dialog_switching')} />;
  }

  return (
    <>
      {addloading ? (
        <MerchantLoading showText={t('nav.Add_New_Merchant_dialog_creating')} />
      ) : (
        <>
          <StyledRoot sx={{ cursor: 'pointer' }} onClick={handleOpenPopover}>
            <Box style={{ position: 'relative' }}>
              <CustomAvatar
                src={user?.head_url || default_merchant_head}
                alt={user?.nickname}
                name={user?.email}
              />
              <MyIcon
                name={
                  (paymentConfigSettings?.data?.verify_status ?? 0) === (1 | 2 | 4 | 8)
                    ? 'v_succ'
                    : 'v_notsucc'
                }
                size={16}
                style={{ position: 'absolute', right: '-4px', bottom: '0px' }}
              />
            </Box>
            <Box
              sx={{
                ml: isFold ? 0 : 2,
                minWidth: 0,
                transition: 'margin 0.3s cubic-bezier(.21,.39,.63,.84)',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip title={user?.nickname}>
                <Typography
                  variant="subtitle2"
                  className="text-one-overflow-hidden"
                  noWrap
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1E1E1E',
                    mr: 2,
                  }}
                >
                  {user?.nickname}
                </Typography>
              </Tooltip>

              <MyIcon
                name="black_arrow"
                size={14}
                style={{
                  height: '14px',
                  transform: `rotate(${openPopover ? '-270deg' : '-90deg'})`,
                  cursor: 'pointer',
                  transition: 'transform 0.2s cubic-bezier(.21,.39,.63,.84)',
                }}
              />

              {/* <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
        {user?.nickname}
      </Typography> */}
            </Box>
          </StyledRoot>
          <MenuPopover
            open={openPopover}
            onClose={handleClosePopover}
            disabledArrow={true}
            // height: 300, overflow: 'overlay'
            sx={{ width: 250, p: 1, mt: 1, ml: 0 }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className="InteractiveList"
          >
            <Stack
              // , maxHeight: 300, overflow: 'overlay'
              sx={{ px: 0, py: 0, borderRadius: '12px', maxHeight: 300, overflow: 'overlay' }}
            >
              {merchantList &&
                merchantList
                  .filter((item) => item.id !== user?.id)
                  .map((list) => (
                    <MenuItem
                      key={list.id}
                      onClick={() => {
                        handleClickItem(list.id);
                      }}
                      sx={{
                        // borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                        borderRadius: '8px !important',
                        padding: '12px 8px',
                        height: '64px',
                        ':hover': {
                          backgroundColor: 'rgba(145, 158, 171, 0.08)',
                        },
                        // backgroundColor:
                        //   list.id === user?.merchant_id ? 'rgba(145, 158, 171, 0.08)' : '#fff',
                        // ':last-child': {
                        //   borderBottom: 0,
                        // },
                      }}
                    >
                      <CustomAvatar
                        src={list.logo || default_merchant_head}
                        alt={list.merchant_name}
                        name={list.merchant_name}
                        sx={{ mr: 2, ml: 1 }}
                      />

                      <Typography
                        className="text-one-overflow-hidden"
                        sx={{
                          fontSize: '14px !important',
                          fontWeight: '500 !important',
                          letterSpacing: '0.1px',
                          color: '#1E1E1E',
                          lineHeight: '20px !important',
                        }}
                      >
                        {list.merchant_name}
                      </Typography>
                    </MenuItem>
                  ))}
              {merchantList && merchantList?.length < 10 && (
                <MenuItem
                  onClick={() => {
                    setOpen(true);
                    handleClosePopover();
                  }}
                  sx={{
                    fontSize: '14px !important',
                    fontWeight: '500 !important',
                    letterSpacing: '0.1px',
                    color: '#1E1E1E',
                    lineHeight: '20px !important',
                    padding: '12px 8px 12px 0px',
                  }}
                >
                  <MyIcon
                    name="ic_add2"
                    size={24}
                    style={{ marginRight: '20px', marginLeft: '8px' }}
                  />
                  {t('nav.Add_New_Merchant_btn')}
                </MenuItem>
              )}
            </Stack>
          </MenuPopover>
          {open && (
            <DialogAddMerchanttForm
              open={open}
              onClose={async (isConfirm, id) => {
                setOpen(false);
                if (isConfirm && id) {
                  setAddLoading(true);
                  await accountApi.switchMerchantAccount(id);
                  // 在跳转之前更新一下本地用户信息
                  await updUserInfoToStore();
                  await accountStore.updateSwitchStatus(true);
                  await walletStroe.asyncWalletInfo();
                  await getMerchantList();
                  setAddLoading(false);

                  if (!location.pathname.includes('merchatsetting')) {
                    navigate('/merchatsetting/settings', {
                      replace: true,
                    });
                  }
                }
              }}
            />
          )}
        </>
      )}
    </>
  );
});
