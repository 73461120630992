import { useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import useSWR from 'swr';
// utils
import { fDate } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useLocation, useNavigate } from 'react-router';
import { MyIcon } from '@/assets/icons/MyIcon';
import { observer } from 'mobx-react';
import { walletNotificationApi } from '@/pages/WalletApi/common/api';
import { getNotificationReadRecordResItemType } from '@/pages/WalletApi/common/api/notification';
import { walletNotificationEventEnum } from '@/pages/WalletApi/pages/MerchantSetting/notificationSettings';
import WalletNotifyMessageDialog from '@/pages/WalletApi/common/components/notification';
import { useLocales } from '@/locales';

// ----------------------------------------------------------------------

export default observer(function WalletNotificationsPopover() {
  const { translate: t } = useLocales();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const notifyResp = useSWR(
    'notify-list',
    async () => {
      const ret = await walletNotificationApi.getNotificationRecordList({
        page: 0,
        page_size: 20,
      });
      const list = ret.records
        ? [
            ...ret.records,
            // {
            //   Id: 1008,
            //   Mode: 2,
            //   MerchantId: 'i0mxzxCl6euyjx04',
            //   Method: 1,
            //   Event: 100,
            //   ToAccount: '',
            //   ReadCount: 0,
            //   Title: 'Balance Insuffcient',
            //   Content:
            //     '{"content":"balance insuffcient, the user can’t withdraw continuly!","record_id":"2023081611064443482948103573506"}',
            //   ExpiredAt: 0,
            //   CreatedAt: '2023-08-16T23:06:59Z',
            //   UpdatedAt: '2023-08-17T06:05:03Z',
            // },
            // {
            //   Id: 1009,
            //   Mode: 2,
            //   MerchantId: 'i0mxzxCl6euyjx04',
            //   Method: 1,
            //   Event: 101,
            //   ToAccount: '',
            //   ReadCount: 0,
            //   Title: 'Network Fee Insufficient',
            //   Content:
            //     '{"content":"Network fee insufficient, transaction related can’t be sent out to the blockchain.","record_id":"2023081611064443482948103573506"}',
            //   ExpiredAt: 0,
            //   CreatedAt: '2023-08-16T23:06:59Z',
            //   UpdatedAt: '2023-08-17T06:05:03Z',
            // },
            // {
            //   Id: 1010,
            //   Mode: 2,
            //   MerchantId: 'i0mxzxCl6euyjx04',
            //   Method: 1,
            //   Event: 102,
            //   ToAccount: '',
            //   ReadCount: 0,
            //   Title: 'Webhook Notification Failed',
            //   Content:
            //     '{"content":"There is webhook notification unable to respond, please adjust / fix the issue for success receive notification again.","record_id":"2023081611064443482948103573506"}',
            //   ExpiredAt: 0,
            //   CreatedAt: '2023-08-16T23:06:59Z',
            //   UpdatedAt: '2023-08-17T06:05:03Z',
            // },
          ].map((from) => ({
            id: from.Id,
            title: from.Title,
            description: from.Content ? JSON.parse(from.Content)?.content : '',
            avatar: null,
            type: from.Method.toString(),
            createdAt: new Date(from.CreatedAt),
            isUnRead: !from.ReadCount,
            source: from,
          }))
        : [];
      return {
        total: ret?.total,
        list: [...list],
        unReadCount: ret.unread,
      };
    },
    {
      refreshInterval: 60 * 1000,
      // revalidateOnFocus: false
    }
  );

  const notifications = notifyResp.data?.list ?? [];
  const totalUnRead = notifyResp.data?.unReadCount ?? 0;

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    notifyResp.mutate();
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = async () => {
    await walletNotificationApi.putNotificationReadRecord(notifications.map((item) => item.id));
    notifyResp.mutate();
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <MyIcon name="notifition3" size={24} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0, top: '70px !important' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t('wallet.common.Notifications')}</Typography>

            <Typography variant="body2" sx={{ color: '#B6B8B0' }}>
              {t('wallet.common.Notification.desc', { totalUnRead })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'double', borderColor: '#B5B8AC' }} />
        <Box className="notifition-scrollbar" sx={{ pb: 0 }}>
          <Scrollbar sx={{ minHeight: 400, maxHeight: 500, borderRadius: '0px 0px 16px 16px' }}>
            <List disablePadding>
              {notifications.length > 0 &&
                notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    handleClosePopover={handleClosePopover}
                  />
                ))}
            </List>
          </Scrollbar>
        </Box>
      </MenuPopover>
    </>
  );
});

// ----------------------------------------------------------------------

export type NotificationItemProps = {
  id: number;
  title: string;
  description: string;
  avatar: string | null;
  createdAt: Date;
  isUnRead: boolean;
  source: getNotificationReadRecordResItemType;
  tokens?: notiTokenType[];
};

export type notiTokenType = {
  symbol: string;
  logo: string;
  pre_off_date: number;
};

function NotificationItem({
  notification,
  handleClosePopover,
}: {
  notification: NotificationItemProps;
  handleClosePopover: () => void;
}) {
  const { title } = renderContent(notification);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const nav = useNavigate();
  const onClick = async () => {
    if (notification.isUnRead) {
      walletNotificationApi.putNotificationReadRecord([notification.id]);
    }
    if (
      [
        walletNotificationEventEnum.DepositSuccessful,
        walletNotificationEventEnum.WithdrawSuccessful,
      ].includes(notification.source.Event)
    ) {
      nav(
        `${location.pathname}/transaction-detail/${
          JSON.parse(notification.source.Content)?.record_id
        }`,
        {
          state: {
            backgroundLocation: location,
          },
        }
      );
    } else {
      setOpen(true);
      // nav(`${location.pathname}/wallet-notification`, {
      //   state: {
      //     backgroundLocation: location,
      //     notificationData: notification,
      //   },
      // });

      // alert(notification.description);
    }
    // handleClosePopover();
  };

  return (
    <>
      {open && (
        <WalletNotifyMessageDialog
          open={open}
          onClose={(v) => {
            setOpen(false);
            if ([1, 3].includes(v)) {
              nav(
                `${location.pathname}/wallet-deposit/${
                  JSON.parse(notification.source.Content)?.coin_id
                }`,
                {
                  state: {
                    backgroundLocation: location,
                  },
                }
              );
            }
            if ([2].includes(v)) {
              nav(`/wallet/webhook?status=2`);
              handleClosePopover();
            }
          }}
          notification={notification}
        />
      )}

      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          opacity: notification.isUnRead ? 1 : 0.3,
          backgroundColor: notification.isUnRead ? '#F9FAF6' : 'transparent',
          '.MuiTypography-subtitle2': {
            fontWeight: notification.isUnRead ? 700 : 500,
            color: notification.isUnRead ? '#27291E' : '#73756D',
          },
          span: {
            color: notification.isUnRead ? '#27291E' : '#73756D',
          },
          '&:hover': {
            '.MuiTypography-subtitle2': {
              color: '#27291E',
              fontWeight: 700,
            },
            span: {
              color: '#27291E',
            },

            opacity: 1,
            backgroundColor: notification.isUnRead ? '#F9FAF6' : 'transparent',
          },
        }}
        onClick={onClick}
      >
        {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}

        <ListItemText
          disableTypography
          primary={title}
          secondary={
            <Box>
              <Stack
                direction="row"
                sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}
              >
                <MyIcon
                  name="walletLockTime"
                  size={16}
                  style={{ marginRight: '4px', height: '16px' }}
                />
                {/* <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} /> */}

                <Typography variant="caption">
                  {fDate(notification.createdAt, 'dd MMM yyyy kk:mm:ss')}
                </Typography>
              </Stack>
            </Box>
          }
        />
      </ListItemButton>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <br />
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {notification.description}
      </Typography>
    </Typography>
  );

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
  //     title,
  //   };
  // }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
