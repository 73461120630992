import { TransactionTopSearchFormWrap } from './style';
import { Box, Button, Checkbox, MenuItem, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import { walletTransactionStroe } from '@/stores';
import RotateArrow from '@/components/rotate-arrow';
import SearchInput from '../../../../components/search-input';
import SelectNetwork from '../user-order/components/SelectNetwork';
import { transactionApi } from '../../common/api';
import { enqueueSnackbar } from 'notistack';
import SelectCoin from './SelectCoin';
import { transactionTypeEnum } from './transaction';

function TopSearchForm() {
  const { translate: t } = useLocales();
  const { queryList, setSearchParam, searchParam, typeValue, setTypeValue } =
    walletTransactionStroe;

  const [transactinTypeList, setTransactinTypeList] = useState<{ label: string; value: string }[]>(
    []
  );

  const getTransactionTypeList = async () => {
    try {
      const res = await transactionApi.getTransactionTypeList();
      const arr = Object.keys(res.list).map((item) => ({
        label: t(`wallet.transaction.select.${item.split('-').join('.')}`),
        value: item,
      }));
      setTransactinTypeList([
        { label: t('wallet.transaction.select.all.type'), value: 'all' },
        ...arr,
      ]);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const selectUserIdOptions = [
    { value: 'all', label: t('wallet.transaction.select.All.Initiator') },
    { value: 'merchant', label: t('wallet.transaction.select.User') },
    { value: 'system', label: t('wallet.transaction.select.Merchant') },
    { value: 'merchant-user', label: t('wallet.transaction.select.System') },
  ];

  const selecStatuOptions = [
    { value: 0, label: t('wallet.user.orders.all.status') },
    { value: 1, label: t('wallet.common.Processing') },
    { value: 2, label: t('wallet.common.Success') },
    { value: 3, label: t('wallet.common.Failure') },
  ];

  useEffect(() => {
    getTransactionTypeList();
    // eslint-disable-next-line
  }, []);

  const transactinTypeListMemoData = useMemo(() => {
    let arr = [];
    arr = transactinTypeList.filter(
      (item) =>
        ![
          transactionTypeEnum.systemSwapOut.toString(),
          transactionTypeEnum.systemSwapIn.toString(),
          transactionTypeEnum.merchantSwapOut.toString(),
        ].includes(item.value)
    );
    return arr;
  }, [transactinTypeList]);

  return (
    <TransactionTopSearchFormWrap>
      <Box className="top-search-bar">
        {/* 搜索框 */}
        <Box className="left-input">
          <SearchInput
            value={searchParam.search}
            onChange={async (value) => {
              await setSearchParam({ search: value, page_index: 1 });
            }}
            query={queryList}
            typeValue={typeValue}
            optionList={[
              {
                value: 1,
                label: t('wallet.common.search.input.type1'),
              },
              {
                value: 2,
                label: t('wallet.common.search.input.type2'),
              },
              {
                value: 3,
                label: t('wallet.common.search.input.Contact'),
              },
              {
                value: 4,
                label: t('wallet.common.search.input.type3'),
              },
              {
                value: 5,
                label: t('wallet.common.search.input.Business.id'),
              },
            ]}
            typeValueOnChange={async (v) => {
              setTypeValue(v);
              if (v === 1) {
                await setSearchParam({ search_item: 'txId', page_index: 1 });
              }
              if (v === 2) {
                await setSearchParam({ search_item: 'address', page_index: 1 });
              }
              if (v === 3) {
                await setSearchParam({ search_item: 'contract', page_index: 1 });
              }
              if (v === 4) {
                await setSearchParam({ search_item: 'userId', page_index: 1 });
              }
              if (v === 5) {
                await setSearchParam({ search_item: 'businessId', page_index: 1 });
              }
              await queryList();
            }}
          />
        </Box>
        <Box className="right-checkbox">
          <Checkbox
            color="primary"
            checked={Boolean(searchParam.billing_status)}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await setSearchParam({ billing_status: e.target.checked ? 1 : 0, page_index: 1 });
              await queryList();
            }}
          />
          <span>Only show credited transactions</span>
        </Box>
      </Box>
      <Box className="bottom-search-bar">
        <Box className="left-select-wrap">
          {/* type select框 */}
          <TextField
            className="tradSelect"
            select
            variant="outlined"
            value={searchParam.sub_transaction_list[0]}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              let arr = [e.target.value];
              if (e.target.value === 'system-swap-in') {
                arr.push('system-swap-out');
                arr.push('merchant-swap-in');
                arr.push('merchant-swap-out');
              }
              if (e.target.value === 'merchant-swap-in') {
                arr.push('system-swap-out');
                arr.push('system-swap-in');
                arr.push('merchant-swap-out');
              }
              // 因为文案汇总，采用数组传参
              await setSearchParam({ sub_transaction_list: arr, page_index: 1 });
              await queryList();
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    padding: '4px',
                    ul: {
                      maxHeight: 260,
                      overflow: 'auto',
                      '::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: '#fff',
                      },
                      '::-webkit-scrollbar-thumb': {
                        borderRadius: '12px',
                        backgroundColor: ' #b6bcc1',
                      },
                    },
                  },
                },
                sx: {
                  mt: 1,
                  '.Mui-selected': {
                    backgroundColor: '#F0F1EC',
                    color: '#82993C',
                  },
                },
              },
              IconComponent: (val, stu) => <RotateArrow val={val} />,
            }}
            sx={{
              width: '166px',
              height: '56px',
              border: '1px solid #E6E8E0',
              borderRadius: '8px',
              mr: 2,
              mb: 3,
              '.MuiOutlinedInput-input': {
                fontWeight: '500',
                color: '#27291E',
              },
            }}
          >
            {transactinTypeListMemoData.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  fontSize: '14px',
                  fontWeight: 400,
                  borderRadius: '6px',
                  typography: 'body2',
                  // textTransform: 'capitalize',
                  '&:first-of-type': { mt: 0 },
                  '&:last-of-type': { mb: 0 },
                  ':hover': {
                    backgroundColor: '#F9FAF6',
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* 选币select框 */}
          <SelectCoin
            value={searchParam.coin_id}
            onChange={async (v) => {
              await setSearchParam({
                coin_id: v,
                page_index: 1,
              });
              await queryList();
            }}
            parmams={{
              chainList: [],
              isLoading: false,
              width: '195px',
            }}
          />
          {/* 网络 chooseselect框 */}
          <SelectNetwork
            value={searchParam.chain_id}
            onChange={async (v) => {
              await setSearchParam({
                chain_id: v,
              });
              await queryList();
            }}
            parmams={{ chainList: [], isLoading: false, width: '300px' }}
          />
          {/* 用户select框 */}
          <TextField
            className="tradSelect"
            select
            variant="outlined"
            value={searchParam.user_type}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await setSearchParam({ user_type: e.target.value, page_index: 1 });
              await queryList();
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    padding: '4px',
                    ul: {
                      maxHeight: 260,
                      overflow: 'auto',
                      '::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: '#fff',
                      },
                      '::-webkit-scrollbar-thumb': {
                        borderRadius: '12px',
                        backgroundColor: ' #b6bcc1',
                      },
                    },
                  },
                },
                sx: {
                  mt: 1,
                  '.Mui-selected': {
                    backgroundColor: '#F0F1EC',
                    color: '#82993C',
                  },
                },
              },
              IconComponent: (val, stu) => <RotateArrow val={val} />,
            }}
            sx={{
              width: '166px',
              height: '56px',
              border: '1px solid #E6E8E0',
              borderRadius: '8px',
              mr: 2,
              mb: 3,
              '.MuiOutlinedInput-input': {
                fontWeight: '500',
                color: '#27291E',
              },
            }}
          >
            {selectUserIdOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  fontSize: '14px',
                  fontWeight: 400,
                  borderRadius: '6px',
                  typography: 'body2',
                  // textTransform: 'capitalize',
                  '&:first-of-type': { mt: 0 },
                  '&:last-of-type': { mb: 0 },
                  ':hover': {
                    backgroundColor: '#F9FAF6',
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* 状态select框 */}
          <TextField
            className="tradSelect"
            select
            variant="outlined"
            value={searchParam.status}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await setSearchParam({ status: Number(e.target.value), page_index: 1 });
              await queryList();
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    padding: '4px',
                    ul: {
                      maxHeight: 260,
                      overflow: 'auto',
                      '::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: '#fff',
                      },
                      '::-webkit-scrollbar-thumb': {
                        borderRadius: '12px',
                        backgroundColor: ' #b6bcc1',
                      },
                    },
                  },
                },
                sx: {
                  mt: 1,
                  '.Mui-selected': {
                    backgroundColor: '#F0F1EC',
                    color: '#82993C',
                  },
                },
              },
              IconComponent: (val, stu) => <RotateArrow val={val} />,
            }}
            sx={{
              width: '166px',
              height: '56px',
              border: '1px solid #E6E8E0',
              borderRadius: '8px',
              mr: 2,
              mb: 3,
              '.MuiOutlinedInput-input': {
                fontWeight: '500',
                color: '#27291E',
              },
            }}
          >
            {selecStatuOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  fontSize: '14px',
                  fontWeight: 400,
                  borderRadius: '6px',
                  typography: 'body2',
                  // textTransform: 'capitalize',
                  '&:first-of-type': { mt: 0 },
                  '&:last-of-type': { mb: 0 },
                  ':hover': {
                    backgroundColor: '#F9FAF6',
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/* 重置 button  */}
        <Box className="reset-btn-box">
          <Button
            sx={{ height: '34px', color: '#82993C', padding: '4px 5px', whiteSpace: 'nowrap' }}
            onClick={async () => {
              await setSearchParam({
                sub_transaction_list: ['all'],
                user_type: 'all',
                coin_id: '0000-0000-0000-0000',
                chain_id: '0',
                status: 0,
                billing_status: 1,
                search_item: 'txId',
                search: '',
                page_index: 1,
                page_size: 20,
              });
              await queryList();
            }}
          >
            Reset Filter
          </Button>
        </Box>
      </Box>
    </TransactionTopSearchFormWrap>
  );
}
export default observer(TopSearchForm);
