import { ChangeEvent, KeyboardEvent, useState, useEffect, MouseEvent } from 'react';
// @mui
import { InputAdornment, TextField, Grid, MenuItem, Popover } from '@mui/material';
// components
import { styles } from '../style';
import { useLocales } from '@/locales';
import { MyIcon } from '@/assets/icons/MyIcon';
import { formatDate } from '@/utils/formatTime';
import { ManageSearchParams } from '../type';
import PopoverSelectDate from '@/components/popover-static-select-picker';
import { getNewDate, getTitle_range } from '@/utils/until';

type Props = {
  getSearchParams: (params: ManageSearchParams) => void;
  SearchParams: ManageSearchParams;
};

type optionsStatusType = {
  value?: number;
  label: string;
}[];

const initDate = {
  startDate: 0,
  endDate: 0,
};

export default function ManageSearchForm({ getSearchParams, SearchParams }: Props) {
  const [searchInputValue, setSearchInputValue] = useState<string>(SearchParams?.keyword ?? '');
  const [isFocus, setIsFocus] = useState<boolean>(true);
  const [selectValue, setSelectValue] = useState(SearchParams.status);
  const [openDate, setOpenDate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectDate, setSelectDate] = useState<{ startDate?: number; endDate?: number }>({
    ...initDate,
  });
  const { translate } = useLocales();

  const optionsStatus: optionsStatusType = [
    { value: 1, label: translate('invoices.status_Onging') },
    { value: 2, label: translate('invoices.status_Ended') },
    { value: 3, label: translate('invoices.status_Stopped') },
    { value: 0, label: translate('invoices.status_all') },
  ];
  const [selectOptions] = useState<optionsStatusType>([...optionsStatus]);

  useEffect(() => {
    if (searchInputValue.length === 1) {
      setIsFocus(true);
    }
    if (searchInputValue.length === 0) {
      setIsFocus(false);
    }
    // eslint-disable-next-line
  }, [searchInputValue]);

  const id = openDate ? 'simple-popover' : undefined;
  return (
    <Grid container spacing={2}>
      <Grid item container spacing={3}>
        {/* left search box */}
        <Grid item md={'auto'} xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type={'text'}
            value={searchInputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchInputValue(e.target.value);
            }}
            onBlur={() => {
              if (searchInputValue.length === 0) setIsFocus(false);
              if (searchInputValue.length > 0) setIsFocus(true);
              if (!isFocus) return;
              getSearchParams({
                ...SearchParams,
                keyword: searchInputValue,
                pagination: { page_num: 1, page_size: SearchParams.pagination.page_size },
              });
            }}
            onFocus={() => setIsFocus(true)}
            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
              if (e.code === 'Enter') {
                setIsFocus(false);
                getSearchParams({
                  ...SearchParams,
                  keyword: searchInputValue,
                  pagination: { page_num: 1, page_size: SearchParams.pagination.page_size },
                });
              }
            }}
            focused={isFocus}
            placeholder={translate('invoices.search_holder')}
            size="small"
            sx={{
              width: '460px',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isFocus ? (
                    <MyIcon name="search_black" size={17} />
                  ) : (
                    <MyIcon name="search_gray" size={17} />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <MyIcon
                    name="close"
                    style={{
                      cursor: 'pointer',
                      visibility: searchInputValue ? 'visible' : 'hidden',
                    }}
                    onClick={() => {
                      setSearchInputValue('');
                      getSearchParams({
                        ...SearchParams,
                        keyword: '',
                        pagination: { page_num: 1, page_size: SearchParams.pagination.page_size },
                      });
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* drop down search box status*/}
        <Grid item xs={3} md={3}>
          <TextField
            className="ManageSelect"
            select
            size="small"
            value={selectValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSelectValue(Number(e.target.value));
              getSearchParams({
                ...SearchParams,
                status: Number(e.target.value),
                pagination: {
                  ...SearchParams.pagination,
                  page_num: 1,
                },
              });
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    padding: '4px',
                    ul: {
                      maxHeight: 260,
                      overflow: 'auto',
                      '::-webkit-scrollbar': {
                        width: '6px',
                        height: '6px',
                        backgroundColor: '#fff',
                      },
                      '::-webkit-scrollbar-thumb': {
                        borderRadius: '12px',
                        backgroundColor: ' #b6bcc1',
                      },
                    },
                  },
                },
                sx: {
                  mt: 1,
                },
              },
              IconComponent: (val) => (
                <MyIcon name={'arrow'} size={20} {...val} className={`${val.className}`} />
              ),
            }}
            sx={{
              width: '100%',
              backgroundColor: 'rgba(145, 158, 171, 0.08)',
              border: 0,
              borderRadius: '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
                borderRadius: '8px',
              },
            }}
          >
            {selectOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  fontSize: '14px',
                  fontWeight: 500,
                  borderRadius: 0.75,
                  typography: 'body2',
                  '&:first-of-type': { mt: 0 },
                  '&:last-of-type': { mb: 0 },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* Filter time on the right button  */}
        <Grid item xs sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {/* select date */}
          <styles.ColorButton
            size="small"
            variant="outlined"
            startIcon={<MyIcon name="select_date_" size={20} />}
            endIcon={
              selectDate?.startDate ? (
                <MyIcon
                  name="close_fill_"
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectDate({ startDate: 0 });
                    getSearchParams({
                      ...SearchParams,
                      dt_start: 0,
                      pagination: {
                        ...SearchParams.pagination,
                        page_num: 1,
                      },
                    });
                  }}
                />
              ) : null
            }
            sx={{
              height: 'auto',
              color: '#1F2025',
              pl: '12px',
              mr: 3,
              fontWeight: 600,
              fontSize: '14px',
              ':hover': {
                backgroundColor: 'rgba(145, 158, 171, 0.08)',
              },
            }}
            aria-describedby={id}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
              setOpenDate(true);
            }}
          >
            {selectDate?.startDate
              ? getTitle_range(selectDate.startDate as number, selectDate.endDate as number)
              : translate('invoices.search_select_date')}
          </styles.ColorButton>
          <Popover
            id={id}
            open={openDate}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={() => {
              getSearchParams({
                ...SearchParams,
                dt_start: selectDate.startDate,
                dt_end: selectDate.endDate,
                pagination: {
                  ...SearchParams.pagination,
                  page_num: 1,
                },
              });
              setOpenDate(false);
            }}
          >
            <PopoverSelectDate
              onClose={({ startDate, endDate }: { startDate?: number; endDate?: number }) => {
                if (startDate && endDate) {
                  const end = new Date(`${formatDate(endDate, 'yyyy/MM/dd')} 23:59:59`).getTime();
                  getSearchParams({
                    ...SearchParams,
                    dt_start: startDate,
                    dt_end: end,
                    pagination: {
                      ...SearchParams.pagination,
                      page_num: 1,
                    },
                  });
                  setSelectDate({ startDate, endDate });
                }
                setOpenDate(false);
              }}
              minDate={getNewDate('before', 48)}
              confirmLabel={translate('common.Confirm')}
              selectDate={{ ...selectDate }}
            />
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
}
