import { walletStroe } from '@/stores';
import Decimal from 'decimal.js';
import { paysInterface } from './type';

/**
 * @param currency_type  Currency 1 Token 2 Fiat
 * @param currency
 * @param num
 * @returns str
 */
export function getCurrencyShowText(currency_type: number, currency: string, num: number | string) {
  let str = '';
  if (currency_type === 1) {
    str = walletStroe.formateAmount({ num, tokenSymbol: currency }) + ' ' + currency;
  } else {
    str = walletStroe.formateFaitAmount(num) + ' ' + currency;
  }
  return str;
}

/**
 *
 * @param currency_trem number
 * @param total_term number
 * @returns str
 */
export function getTermText(currency_trem: any, total_term: any) {
  let obj = {
    cur: currency_trem ? currency_trem : 0,
    total: total_term ? total_term : '-',
  };
  return obj.cur + '/' + obj.total;
}

/**
 *
 * @param arr
 * @returns arr2
 */
export function getGroup<T>(arr: any[]): Array<T>[] {
  let obj = {};
  arr.forEach((item) => {
    if (!obj[item.symbol]) {
      obj[item.symbol] = [];
    }
    obj[item.symbol].push(item);
  });
  const arr2 = [];

  for (let key in obj) {
    arr2.push(obj[key]);
  }
  return arr2;
}

export function sumAmount(arr: any[], key?: string) {
  if (arr.length < 1) return 0;
  let result = 0;

  result = arr.reduce((pre, cur) => {
    let amount = key ? cur[key] : cur?.amount;

    let deca = new Decimal(amount);
    let dpre = new Decimal(pre);

    return deca.add(dpre);
  }, 0);

  return new Decimal(result).toFixed(8, Decimal.ROUND_DOWN);
}

export function refundDisabled(
  amount: string | number,
  symbol: string,
  refunds: {
    record_id: string;
    chain_id: string;
    contract: string;
    symbol: string;
    refund_amount: string;
    pay_time: number;
    network_fee: string;
    /**
     * Refund handling fee method, 1 deducted from the refund, 2 deducted from the account
     */
    fee_style: number;
    refund_status: number;
    logo: string;
  }[]
) {
  let flag = false;
  if (refunds) {
    const refundItem = getGroup<paysInterface>(refunds).filter((item) =>
      item.filter((item) => item.symbol === symbol)
    )[0];
    const refundAmount = sumAmount(refundItem, 'refund_amount');
    if (refundAmount === amount) {
      flag = true;
    }
  }
  return flag;
}
