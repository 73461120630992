import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Box,
  styled,
  TablePagination,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getTime, settingTxid } from '@/utils/until';
import EmptyContent from '@/components/empty-content';
import { useLocales } from '@/locales';
import useResize from '@/hooks/useResize';
import { handlerDateDurationCurrent } from '@/utils/formatTime';
import StatusLabel from './StatusLabel';
import { walletStroe, walletTransactionStroe, walletChainStroe } from '@/stores';
import CopyButton from '@/components/copy-button';
import { CreditedStatusEnum, transactionStatusEnum, transactionTypeEnum } from './transaction';
import { MyIcon } from '@/assets/icons/MyIcon';
import MenuPopover from '@/components/menu-popover/MenuPopover';
import Iconify from '@/components/iconify/Iconify';
import CreditAssetModal from './components/CreditAssetModal';
import RefundModal from './components/RefundModal';
import { getTransationListType } from '../../common/api/transactionApi';
import { enqueueSnackbar } from 'notistack';
import { coinlistApi, transactionApi, userOrderApi } from '../../common/api';

type coinListType = {
  Id: string;
  symbol: string;
  Symbol: string;
  Name: string;
  LogoURI: string;
  Desc: string;
  Type: number;
  Price: string;
  CMCCoinId: string;
  CMCCoinIdUpdated: boolean;
  PriceUpdated: boolean;
  isAdd?: boolean;
  status?: number;
};

export default observer(function DataTable() {
  const { translate: t } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();
  const [fixedAction, setFixedAction] = useState(false);
  const [isShadow, setIsShadow] = useState(false);
  const [showCreditAssetModal, setShowCreditAssetModal] = useState('');
  const [showRefundModal, setShowRefundModal] = useState('');
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [sortOrder, setSortOrder] = useState('asc'); // 初始排序为升序
  const [reDepositLoading, setReDepositLoading] = useState(false);
  const [coinList, setCoinList] = useState<coinListType[]>([]);

  const handleOpenPopover = (event: MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const reSize = useResize(({ width, height }) => {
    let scrollable = document.getElementById('scrollable');
    if (scrollable) {
      if (width < scrollable.scrollWidth) {
        setIsShadow(true);
      } else {
        setIsShadow(false);
      }
    }
  }, 0);

  useEffect(() => {
    const scrollcon = document.getElementById('scrol_table');
    if (scrollcon) {
      reSize(scrollcon as HTMLDivElement);
    }
  }, [reSize]);

  useEffect(() => {
    const scrollable = document.getElementById('scrollable');
    function scroll(e: any) {
      if (e.target.scrollTop > 0) {
        setFixedAction(true);
      }
      if (e.target.scrollTop === 0) {
        setFixedAction(false);
      }

      if (e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth) {
        setIsShadow(true);
      }
      if (e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth) {
        setIsShadow(false);
      }
    }
    if (scrollable) {
      scrollable.addEventListener('scroll', scroll);
    }
    getCoinList();
    return () => scrollable?.removeEventListener('scroll', scroll);
    // eslint-disable-next-line
  }, []);

  const handlePageChange = async (page: number) => {
    await walletTransactionStroe.setSearchParam({ page_index: page + 1 });
    await walletTransactionStroe.queryList();
  };

  const handlePageSizeChange = async (pageSize: number) => {
    await walletTransactionStroe.setSearchParam({ page_size: pageSize, page_index: 1 });
    await walletTransactionStroe.queryList();
  };

  const selectStatusOptions = [
    { value: -1, label: t('wallet.webhook.select.status.Failed') },
    { value: 1, label: t('wallet.webhook.select.status.Pending') },
    { value: 2, label: t('wallet.webhook.select.status.Success') },
  ];

  const onSort = async () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
      const arr = walletTransactionStroe.tableData.list.sort(
        (a, b) => a.payment_date - b.payment_date
      );
      walletTransactionStroe.setTableData({
        ...walletTransactionStroe.tableData,
        list: arr,
      });
    } else {
      setSortOrder('asc');
      const arr = walletTransactionStroe.tableData.list.sort(
        (a, b) => b.payment_date - a.payment_date
      );
      walletTransactionStroe.setTableData({
        ...walletTransactionStroe.tableData,
        list: arr,
      });
    }
  };

  // Determine whether to support the account
  function isShowCreditAssetMenuItem(data: getTransationListType) {
    let flag = false;
    // token support
    if (coinList.filter((item) => item.Id === data.coin_id).length > 0) {
      // Only those who have not entered the account and the order status is successful can be entered into the account
      if (
        data.status === transactionStatusEnum.Success &&
        data.billing_status === CreditedStatusEnum.failed &&
        data.sub_transaction_type === transactionTypeEnum.userDeposit &&
        isEnabled(data) &&
        data.unrecorded_bill_id
      ) {
        flag = true;
      }
      // Entry conditions for merchant recharge hot wallet
      if (
        data.status === transactionStatusEnum.Success &&
        data.billing_status === CreditedStatusEnum.success &&
        data.sub_transaction_type === transactionTypeEnum.merchantDeposit &&
        isEnabled(data) &&
        !data.into_user_id
      ) {
        flag = true;
      }
      // If you have already entered into a account with a user, you can no longer enter into the account

      if (
        data.status === transactionStatusEnum.Success &&
        data.billing_status === CreditedStatusEnum.success &&
        data.sub_transaction_type === transactionTypeEnum.systemDeposit &&
        isEnabled(data) &&
        !data.into_user_id
      ) {
        flag = true;
      }
    }
    return flag;
  }

  // Determine whether the form is entered into the account
  function isShowCreditAsset(data: getTransationListType) {
    let flag = false;
    // Non-user recharge is credited to the account by filling in the form
    if (
      data.sub_transaction_type === transactionTypeEnum.merchantDeposit ||
      data.sub_transaction_type === transactionTypeEnum.systemDeposit
    ) {
      flag = true;
    }
    return flag;
  }

  function isShowRefund(data: getTransationListType) {
    let flag = false;
    // Success on the chain Not recorded User recharge The system supports token, but the merchant has not added this token and has not operated
    if (
      data.status === transactionStatusEnum.Success &&
      data.billing_status === CreditedStatusEnum.failed &&
      data.sub_transaction_type === transactionTypeEnum.userDeposit &&
      data.unrecorded_hand_flag !== 1 &&
      !isEnabled(data) &&
      data.unrecorded_bill_id
    ) {
      flag = true;
    }
    return flag;
  }

  function isEnabled(data: getTransationListType) {
    // The default is to enter the account
    let flag = true;
    const arr = coinList.filter((item) => item.Id === data.coin_id);
    // If the merchant supports this coin and this coin is not enabled and added
    if ((arr.length > 0 && (!arr[0].isAdd || arr[0].status !== 10)) || arr.length === 0) {
      flag = false;
    }
    return flag;
  }

  async function reDeposit(record_id: string) {
    setReDepositLoading(true);
    try {
      await transactionApi.reDeposit([record_id]);
      await walletTransactionStroe.queryList();
      enqueueSnackbar('Successfully credited!');
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setReDepositLoading(false);
    }
  }

  async function getCoinList() {
    try {
      const res = await userOrderApi.getCoinList();
      let resp = await coinlistApi.getCoinList({
        Key: '',
        Status: 0,
      });
      let arr: coinListType[] = [];
      if (res.List) {
        Object.keys(res.List).forEach((item) => {
          if (res.List[item]) {
            arr.push(res.List[item]);
          }
        });
      }
      if (resp.List?.length > 0) {
        arr = arr.map((item) => {
          const tempList = resp.List.filter((r) => r.Coin.Id === item.Id);
          let obj = {
            ...item,
            status: tempList[0]?.Status || 20,
            isAdd: tempList.length > 0 ? true : false,
          };

          return obj;
        });
      }
      const arr2 = arr.filter((item) => item.isAdd);
      const arr3 = arr.filter((item) => !item.isAdd);
      setCoinList([...arr2, ...arr3]);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  return (
    <TableContainerStyle>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '0px 0px 8px 8px', maxHeight: 720 }}
        id={'scrollable'}
      >
        <Table
          sx={{
            minWidth: 720,
          }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead
            sx={{
              '& .MuiTableCell-root': {
                color: '#919EAB',
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '0.1px',
                // Change table head background color
                backgroundImage: 'linear-gradient(to bottom, #F0F1EC 0%, #F0F1EC 100%)',
              },
            }}
          >
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('wallet.transaction.detail.user.id')}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('wallet.transaction.table.type')}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Network
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Contact
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('wallet.transaction.table.amount')}
              </TableCell>
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('wallet.transaction.detail.txid')}
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                  '.MuiTableSortLabel-root': {
                    fontWeight: '500 !important',
                    fontSize: '14px !important',
                    color: '#73756D !important',
                  },
                }}
              >
                <TableSortLabel
                  IconComponent={(val) => <MyIcon name="ic_table_sort" size={18} {...val} />}
                  active={true}
                  onClick={onSort}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  Update Time
                </TableSortLabel>
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Chain Status
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Credited or Not
              </TableCell>
              <TableCell
                align="left"
                className={isShadow ? 'cell-fixed' : ''}
                sx={{
                  whiteSpace: 'nowrap',
                  zIndex: fixedAction ? 3 : 2,
                }}
              >
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {walletTransactionStroe.tableData.list?.length > 0 ? (
              <>
                {walletTransactionStroe.tableData.list.map((row) => (
                  <>
                    <TableRow
                      key={row.record_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': {
                          backgroundColor: '#F9FAF6',
                        },
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row?.user_id ? (
                          <>
                            {row?.user_id.length > 8 ? settingTxid(row?.user_id, 4) : row?.user_id}
                          </>
                        ) : (
                          <>{row?.user_type}</>
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t(
                          `wallet.transaction.select.${row.sub_transaction_type
                            .split('-')
                            .join('.')}`
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {walletChainStroe.ChainAllMap.Chains[row.chain_id]?.Type
                          ? walletChainStroe.ChainAllMap.Chains[row.chain_id]?.Type
                          : '--'}
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                          component={'div'}
                        >
                          {walletChainStroe.ChainAllMap.Chains[row.chain_id]?.ContractUrl &&
                          row.contract ? (
                            <>
                              <a
                                href={`${walletChainStroe.ChainAllMap.Chains[
                                  row.chain_id
                                ]?.ContractUrl?.replace('%s', '')}${row.contract}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  fontWeight: 500,
                                  fontSize: '16px',
                                  lineHeight: '22px',
                                  color: '#00B8D9',
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                }}
                              >
                                {settingTxid(row.contract, 5)}{' '}
                              </a>
                              {row?.contract ? (
                                <CopyButton source={row.contract} sx={{ ml: 0 }} />
                              ) : (
                                '--'
                              )}
                            </>
                          ) : (
                            <>
                              {settingTxid(row.contract, 5)}{' '}
                              {row?.contract ? (
                                <CopyButton source={row.contract} sx={{ ml: 0 }} />
                              ) : (
                                '--'
                              )}
                            </>
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                        {row?.coin?.LogoURI ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            {/* em 1 in 2 out 0 payment failure and the like */}
                            {row?.action !== 0 && (
                              <span style={{ color: row.action === 1 ? '#36B37E' : '#FF5630' }}>
                                {row.action === 1 ? '+ ' : '- '}
                                {walletStroe.formateAmount({
                                  num: row?.amount || '0',
                                  tokenSymbol: row.symbol,
                                })}{' '}
                                {row?.symbol}
                              </span>
                            )}
                            {row?.action === 0 && '--'}
                          </Box>
                        ) : (
                          '--'
                        )}
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                          component={'div'}
                        >
                          {walletChainStroe.ChainAllMap.Chains[row.chain_id]?.TxIdUrl &&
                          row.tx_hash ? (
                            <>
                              <a
                                href={`${walletChainStroe.ChainAllMap.Chains[
                                  row.chain_id
                                ]?.TxIdUrl?.replace('%s', '')}${row.tx_hash}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  fontWeight: 500,
                                  fontSize: '16px',
                                  lineHeight: '22px',
                                  color: '#00B8D9',
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                }}
                              >
                                {settingTxid(row.tx_hash, 5)}{' '}
                              </a>
                              {row?.tx_hash ? (
                                <CopyButton source={row.tx_hash} sx={{ ml: 0 }} />
                              ) : (
                                '--'
                              )}
                            </>
                          ) : (
                            <>
                              {settingTxid(row.tx_hash, 5)}{' '}
                              {row?.tx_hash ? (
                                <CopyButton source={row.tx_hash} sx={{ ml: 0 }} />
                              ) : (
                                '--'
                              )}
                            </>
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                        {row?.payment_date
                          ? getTime(
                              {
                                ...handlerDateDurationCurrent(row.payment_date),
                                soureDate: row.payment_date,
                              },
                              t
                            )
                          : '--'}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <StatusLabel
                          label={
                            selectStatusOptions.find((item) => item.value === row.status)?.label ||
                            '--'
                          }
                          type={transactionStatusEnum[row.status]}
                        />
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row.unrecorded_hand_flag === 1 ? (
                          <MyIcon name="pending" size={18} style={{ margin: '0 auto' }} />
                        ) : (
                          <>
                            {' '}
                            {row.billing_status === CreditedStatusEnum.success ? (
                              <MyIcon name="successful" size={18} style={{ margin: '0 auto' }} />
                            ) : (
                              <MyIcon name="failed" size={18} style={{ margin: '0 auto' }} />
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', width: '100px' }}
                        align="left"
                        className={`${isShadow ? 'cell-fixed' : ''}`}
                      >
                        <IconButton
                          id={row.record_id}
                          sx={{
                            color:
                              openPopover && openPopover?.id === row.record_id
                                ? '#82993C'
                                : 'default',
                          }}
                          onClick={handleOpenPopover}
                        >
                          <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {openPopover && openPopover?.id === row.record_id && (
                      <MenuPopover
                        open={openPopover}
                        onClose={handleClosePopover}
                        arrow="right-top"
                        sx={{
                          width: 160,
                          boxShadow:
                            '0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
                          '.MuiMenuItem-root': {
                            ':hover': {
                              background: 'rgba(145, 158, 171, 0.08)',
                              borderRadius: '6px',
                              color: '#82993C',
                            },
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            if (row.transaction_type === 'swap') {
                              navigate(`balance-detail/` + row.business_id, {
                                state: {
                                  backgroundLocation: location,
                                },
                              });
                            } else {
                              navigate('transaction-detail/' + row.record_id, {
                                state: {
                                  backgroundLocation: location,
                                },
                              });
                            }

                            handleClosePopover();
                          }}
                        >
                          View Detail
                        </MenuItem>

                        {isShowCreditAssetMenuItem(row) && (
                          <MenuItem
                            disabled={reDepositLoading}
                            onClick={async () => {
                              if (isShowCreditAsset(row)) {
                                setShowCreditAssetModal(row.record_id);
                              } else {
                                // direct deposit
                                await reDeposit(row.record_id);
                              }

                              handleClosePopover();
                            }}
                          >
                            Credit Asset
                            {reDepositLoading && (
                              <CircularProgress
                                variant="indeterminate"
                                color="inherit"
                                size={20}
                                sx={{ ml: 1 }}
                              />
                            )}
                          </MenuItem>
                        )}

                        {/*  Only when the contract exists but has not been recorded and the order status is successful can the asset be returned */}
                        {isShowRefund(row) && (
                          <MenuItem
                            onClick={() => {
                              setShowRefundModal(row.record_id);
                              handleClosePopover();
                            }}
                          >
                            Refund
                          </MenuItem>
                        )}

                        {/* )} */}
                      </MenuPopover>
                    )}
                    {Boolean(showCreditAssetModal) && showCreditAssetModal === row.record_id && (
                      <CreditAssetModal
                        open={
                          Boolean(showCreditAssetModal) && showCreditAssetModal === row.record_id
                        }
                        onClose={(v) => {
                          if (v) {
                            walletTransactionStroe.queryList();
                          }
                          setShowCreditAssetModal('');
                        }}
                        queryData={{
                          record_id: row.record_id,
                          user_id: row.billing_status === 0 ? row.user_id : '',
                          address: '',
                          memo: '',
                        }}
                      />
                    )}

                    {Boolean(showRefundModal) && showRefundModal === row.record_id && (
                      <RefundModal
                        open={Boolean(showRefundModal) && showRefundModal === row.record_id}
                        onClose={(v) => {
                          if (v) {
                            walletTransactionStroe.queryList();
                          }
                          setShowRefundModal('');
                        }}
                        queryData={{
                          record_id: row.record_id,
                          user_id: row.billing_status === 0 ? row.user_id : '',
                          address: '',
                          memo: '',
                        }}
                        baseData={row}
                      />
                    )}
                  </>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <EmptyContent title="No Data" />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />
      {/* paging component */}
      <Box sx={{ p: 0 }}>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 80, 100]}
          component="div"
          count={walletTransactionStroe.tableData.pagination.Total}
          rowsPerPage={walletTransactionStroe.searchParam.page_size}
          page={walletTransactionStroe.searchParam.page_index - 1}
          onPageChange={(e: unknown, newPage: number) => {
            handlePageChange(newPage);
          }}
          onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const page_size = e.target.value;
            handlePageSizeChange(Number(page_size));
          }}
          sx={{
            border: 0,
          }}
        />
      </Box>
    </TableContainerStyle>
  );
});

const TableContainerStyle = styled('div')(({ theme }) => ({
  background: '#fff',
  borderRadius: '16px',

  [`.${tableCellClasses.head}`]: {},
  '.action-row': {
    color: '#3C6FF5',
    fontWeight: 600,
    '& button': {
      cursor: 'pointer',
      marginRight: '16px',
    },
  },
  '.logo': {
    borderRadius: '100px',
    marginRight: '12px',
  },
  '.MuiTableCell-body': {
    fontWeight: 500,
  },
}));
