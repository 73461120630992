import Button, { ButtonProps } from '@mui/material/Button';
import {
  Box,
  BoxProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  styled,
  Typography,
  Checkbox,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const common: React.CSSProperties = {
  border: '0',
  fontSize: '22px',
  fontWeight: 700,
  padding: '0',
};
export const TitleTypography = styled(Typography)(({ theme }) => ({
  height: '25.85px',
  color: '#919EAB',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px',
}));

export const ContentTypography = styled(Typography)(({ theme }) => ({
  color: '#1E1E1E',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));

export const TxidTypography = styled(Typography)(({ theme }) => ({
  height: '24px',
  color: '#1E1E1E',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    background: '#fff',
    color: '#212B36',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    padding: '12px',

    borderRadius: '8px',
    filter: 'drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.36))',
    letterSpacing: '0.1px',
  },
  [`& .MuiTooltip-arrow::before`]: {
    color: '#fff',
  },
});

export const StatusStyleLabel = styled(Box)<BoxProps>(({ theme }) => ({
  border: 0,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  fontStyle: 'normal',
  padding: '8px 0px',
  borderRadius: '6px',
  gap: '6px',
  maxWidth: '120px',
}));

export const styles = {
  webkitLineClamp: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
  },
  type_box: {
    margin: '8px 0 24px 0',
  },
  _btn: {
    padding: '6px 16px',
    marginRight: '24px',
    borderRadius: '8px',
    border: 0,
    fontWeight: 500,
    ':hover': {
      border: 0,
    },
  },
  type_btn: {
    background: 'rgba(145, 158, 171, 0.08)',
    color: '#919EAB',
  },
  type_btn_active: {
    background: 'rgba(60, 111, 245, 0.16)',
    color: '#3C6FF5',
    ':hover': {
      background: 'rgba(60, 111, 245, 0.16)',
      color: '#3C6FF5',
      border: 0,
    },
  },
  loading: {
    width: '100%',
    height: '600px',
    boxShadow: 'none',
  },
  chain_my_icon: {
    position: 'absolute',
    left: '14px',
    bottom: '-2px',
  },
  chain_status: {
    position: 'absolute',
    left: '14px',
    bottom: '-2px',
  },
  hot: {
    padding: '4px 10px',
    borderRadius: '8px',
    marginLeft: '8px',
    fontSize: '12px',
    fontWeight: 500,
  },
  due_time_text: {
    '.Mui-disabled': {
      '-webkit-text-fill-color': '#1E1E1E',
      cursor: 'pointer',
    },
    '.MuiFormHelperText-filled': {
      position: 'absolute',
      top: '54px',
    },
    '.Mui-error': {
      '-webkit-text-fill-color': '#FF5630 !important',
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#FF5630 !important',
      },
    },
  },
  tranX: {
    position: 'absolute',
    top: '100%',
    transform: 'translateY(-100%)',
    right: '0px',
  },
  timeLabel: {
    width: '80px',
    '.MuiInputBase-formControl': { height: '48px' },
    '.MuiOutlinedInput-notchedOutline': { border: '0' },
  },
  timeLabelLeft: {
    '.MuiOutlinedInput-input': { textAlign: 'left', ...common, paddingLeft: '2px' },
  },
  timeLabelRight: {
    '.MuiOutlinedInput-input': { textAlign: 'right', ...common, paddingRight: '2px' },
  },
  timeBox: {
    border: '1px solid #DCE0E4',
    borderRadius: '6px',
    height: '48px',
  },
  timeDot: {
    fontWeight: '600',
    color: '#919EAB',
    fontSize: '24px',
  },
  dateTime: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  date_color: {
    color: '#212B36',
  },
  date_size: {
    fontSize: '16px',
  },
  date_header: {
    padding: '8px 10px 0px',
  },
  date_day: {
    display: 'flex',
    p: 0,
  },
  date_actions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  date_page: {
    '& .MuiPickersCalendarHeader-root': {
      position: 'relative',
      padding: 0,
    },
    '&  .startDate .MuiPickersCalendarHeader-labelContainer': {
      order: 2,
      // marginLeft: '50px',
      justifyContent: 'center',
      width: '100%',
    },

    '&  .endDate .MuiPickersCalendarHeader-labelContainer': {
      justifyContent: 'center',
      width: '100%',
    },
    '& .MuiPickersCalendarHeader-labelContainer button': {
      display: 'none',
    },
    '& .startDate .MuiPickersArrowSwitcher-root': {
      order: 1,
    },
    '& .MuiIconButton-edgeStart': {
      position: 'absolute',
      top: 0,
      right: '12px',
    },
    '& .MuiIconButton-edgeEnd': {
      position: 'absolute',
      left: '12px',
      top: 0,
    },

    '& .MuiPickersArrowSwitcher-spacer': {
      width: 0,
    },

    '& .MuiPickersCalendarHeader-label': {
      mr: 0,
    },
    // '& .startDate .MuiPickersArrowSwitcher-root button:nth-child(3)': {
    //   display: 'none',
    // },
    // '& .MuiPickersArrowSwitcher-root div': {
    //   display: 'none',
    // },
    // '& .endDate .MuiPickersArrowSwitcher-root button:nth-child(1)': {
    //   display: 'none',
    // },
    '& .MuiPickersDay-root': {
      fontWeight: 500,
    },
  },
  timeIcon: {
    '.MuiButton-iconSizeSmall': {
      marginRight: 0,
    },
    '.MuiButton-root': {
      backgroundColor: '#fff !important',
      border: '0 !important',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  onlyBtn: {
    background: 'rgba(0, 184, 217, 0.16)',
    width: '150px',
    height: '24px',
    lineHeight: '16px',
    padding: '4px 10px',
    borderRadius: '8px',
    fontSize: '12px',
    cursor: 'pointer',
    fontWeight: 400,
  },
  editInfo: {
    background: '#F9FFFE',
    color: '#006C9C',
    height: '40px',
    marginTop: '24px',
    padding: '8px 8px 8px 18px',
    border: '1px solid #00B8D9',
    borderRadius: '8px',
    mb: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorBtn: {
    minWidth: '0',
    color: '#1E1E1E',
    pl: '12px',
    mr: 1,
    mt: -7,
    backgroundColor: '#DDE7FF',
    fontWeight: 500,
    fontSize: '14px',
    ':hover': {
      backgroundColor: '#DDE7FF',
    },
  },
  pagerCont: {
    padding: '6px 24px 0 24px',
    marginTop: '20px',
    boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    border: '1px solid #f4f6f8',
  },
  ColorButton: styled(Button)<ButtonProps>(({ theme }) => ({
    border: 0,
    color: 'rgba(145, 158, 171, 0.32)',
    backgroundColor: 'transparent',
    '&:hover': {
      border: 0,
      backgroundColor: 'rgba(145, 158, 171, 0.08)',
    },
    '&:active': {
      border: 0,
    },
    '&:fcous': {
      border: 0,
    },
  })),
  creat_btn: {
    '&:hover': {
      backgroundColor: '#1E387B',
    },
  },
  creat_rote: {
    transform: 'rotate(90deg)',
    flexShrink: 1,
    flexGrow: 0,
    height: '32px',
    cursor: 'pointer',
    marginRight: '8px',
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
  creat_bread: {
    marginTop: '6px',
    '& .MuiBreadcrumbs-separator': {
      fontSize: '30px',
      color: '#363A44',
      fontWeight: 500,
    },
  },
  creat_svg: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    margin: '0 8px',
  },
  creat_mr4: {
    mr: 4,
  },
  creat_top: {
    marginTop: '18px',
  },
  creat_bootm: {
    marginBottom: '20px',
  },
  creat_bootm12: {
    marginBottom: '12px',
  },
  creat_bootm8: {
    marginBottom: '8px',
  },
  creat_desc_length: {
    color: '#DCE0E4',
  },
  creat_form: {
    mt: 2,
    '.Mui-focused': {
      color: '#1E1E1E !important',
    },
  },
  creat_img: {
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    marginRight: '6px',
  },
  creat_item: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
    height: '42px',
  },
  creat_title_1: {
    padding: 0,
    mt: 3,
    boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  },
  creat_menu: {
    p: 1,
    ul: {
      maxHeight: 380,
      overflow: 'auto',
      pr: 1,
      pl: 1,
      '::-webkit-scrollbar': {
        width: '6px',
        backgroundColor: '#fff',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        backgroundColor: ' #b6bcc1',
      },
    },
  },
  creat_menu_props: {
    mt: 1,
    '.MuiMenuItem-root': {
      borderRadius: '12px',
      marginTop: '2px',
    },
  },
  creat_bg: {
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
  },
  creat_ava: {
    width: 12,
    height: 12,
    mr: 1,
    bgcolor: '#3C6FF5',
  },
  creat_freq: {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    '&:first-of-type': { mt: 0 },
    '&:last-of-type': { mb: 0 },
  },
  creat_service: {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
    '&:first-of-type': { mt: 0 },
    '&:last-of-type': { mb: 0 },
  },
  creat_mxh: {
    maxHeight: 220,
  },
  creat_mt: {
    marginTop: '4px',
  },
  creat_token_ava: {
    width: 24,
    height: 24,
    mr: 1,
    ml: 2,
  },
  creat_all: {
    width: 12,
    height: 12,
    ml: 2.75,
    mr: 1.75,
    bgcolor: '#3C6FF5',
    color: '#3C6FF5 !important',
  },
  creat_value: {
    width: 24,
    height: 24,
    mr: 1,
  },
  creat_circ: {
    position: 'absolute',
    right: '10px',
    bottom: '18px',
    width: '20px !important',
    height: '20px !important',
  },
  creat_curr: {
    marginRight: '10px',
    width: 20,
    flexShrink: 0,
  },
  creat_desc: {
    marginTop: '0px',
    '.Mui-error': { color: '#FF5630 !important' },
    '.MuiFormHelperText-root': { color: '#FF5630' },
  },
  creat_div: {
    padding: '12px 24px 24px 24px',
  },
  creat_title_2: {
    display: 'flex',
    alignItems: 'center',
  },
  creat_hr: {
    border: 0,
    borderTop: '2px dashed #DCE0E4',
    margin: '24px 0 0 0',
  },
  creat_red: {
    color: '#FF5630',
  },
  creat_send: {
    marginTop: '12px',
    '.Mui-disabled': {
      '-webkit-text-fill-color': '#1E1E1E',
      cursor: 'pointer',
    },
    '.MuiInputLabel-formControl': {
      '-webkit-text-fill-color': '#919EAB !important',
    },
  },
  popver_absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  creat_due_time: {
    marginTop: '12px',
  },
  creat_next: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '40px',
  },
  creat_type: {
    '&.MuiGrid-item': {
      paddingTop: '0',
    },
  },
  creat_s2: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  creat_label: {
    '.MuiFormControlLabel-label': {
      fontWeight: 500,
    },
  },
  creat_stand: {
    mr: 0,
    '.MuiFormControlLabel-label': {
      fontWeight: 500,
    },
  },
  creat_loading: {
    flexShrink: 0,
    padding: '12px 22px',
    height: '48px',
    marginBottom: '24px',
    ':hover': {
      background: '#1E387B',
    },
  },
  verficStatus: {
    borderRadius: '8px',
    padding: '8px 16px',
    fontWeight: 600,
    fontSize: '14px',
  },
  successful: {
    background: '#D8FBDE',
    color: '#0A5554',
  },
  warning: {
    background: '#FFF5CC',
    color: '#7A4100',
    display: 'flex',
    alignItems: 'center',
  },
  creat_s_2: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  creat_scroll: {
    py: 1,
    boxSizing: 'content-box',
    height: '88px',
  },
  creat_tabs: {
    backgroundColor: '#F4F6F8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
  },
  creat_tab: {
    padding: '4px 8px',
    fontSize: '14px',
    fontWeight: 700,
  },
  creat_blue: {
    marginLeft: '14px',
    color: '#fff',
  },
  totalText: {
    color: '#3C6FF5',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  addBtn: {
    flexShrink: 0,
    padding: '8px 16px',
    fontWeight: 500,
    fontSize: '16px',
    background: 'rgba(145, 158, 171, 0.08)',
    borderRadius: 'px',
    width: '139px',
    height: '40px',
    color: '#1E1E1E',
  },
  deletBtn: {
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FF5630',
    borderRadius: '6px',
    color: '#FF5630',
    cursor: 'pointer',
    ':hover': {
      background: 'rgba(255, 86, 48, 0.16)',
    },
  },
  details_end: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  details_trans: {
    transform: 'rotate(180deg)',
    cursor: 'pointer',
  },
  details_arrow: {
    cursor: 'pointer',
    marginBottom: '4px',
  },
  details_remove: {
    width: '32px',
    height: '56px',
  },
  details_15: {
    width: '15%',
  },
  details_20: {
    width: '20%',
  },
  details_25: {
    width: '25%',
  },
  details_10: {
    width: '10%',
  },
  details_14: {
    width: '14%',
  },
  details_30: {
    width: '30%',
  },
  details_40: {
    width: '40%',
  },
  details_100: {
    width: '100%',
  },
  detail_sx: {
    width: { md: 200 },
  },
  detail_left: {
    marginLeft: '4px',
  },
  mange_press: {
    padding: '4px 16px',
    ml: 2,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#919EAB',
    borderRadius: '8px',
  },
  mange_rotate: {
    transform: 'rotate(90deg)',
    flexShrink: 1,
    flexGrow: 0,
    height: '32px',
    cursor: 'pointer',
    marginRight: '8px',
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
  mange_sepa: {
    marginTop: '6px',
    '& .MuiBreadcrumbs-separator': {
      fontSize: '30px',
      color: '#363A44',
      fontWeight: 500,
    },
  },
  mange_details: {
    display: 'flex',
    alignItems: 'end',
    pb: 1,
    color: '#919EAB',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  mange_link: {
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#3C6FF5',
    ml: 3,
  },
  mange_space: {
    background: '#3C6FF5',
    padding: '12px 24px',
    color: '#fff',
    borderRadius: '8px 8px 0 0',
    fontSize: '14px',
  },
  mange_hr: {
    border: 0,
    borderTop: '2px dashed #DCE0E4',
  },
  mange_cur: {
    cursor: 'pointer',
  },
  mange_detail: {
    width: '100%',
    height: '100vh',
    boxShadow: 'none',
  },
  mange_blue: {
    marginLeft: '16px',
    marginRight: '32px',
  },
  mange_edit: {
    mb: 2,
    display: 'flex',
  },
  mange_col: {
    color: '#3C6FF5',
  },
  mange_cont: {
    mt: 3,
    px: 3,
    py: 1,
    paddingTop: '20px',
  },
  items: {
    marginRight: '16px',
    marginBottom: '12px',
  },
  warp: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nowarp: {
    whiteSpace: 'nowrap',
  },
  mange_btm: {
    mt: 7,
    pb: 3,
  },
  mange_ava: {
    width: 20,
    height: 20,
  },
  mange_mb2: {
    mb: 2,
  },
  down_btn: {
    minWidth: 'auto',
    height: '34px',
    background: 'rgba(60, 111, 245, 0.08)',
    fontSize: '14px',
    marginRight: '24px',
    color: '#212B36',
    fontWeight: 500,
    paddingRight: '16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#CAD8FF',
    },
  },
  down_icon: {
    margin: '0 12px 0 8px',
  },
  rota_box: {
    '.MuiAutocomplete-popupIndicator': {
      color: 'rgb(255 255 255/ 0%)',
    },
    '.MuiAutocomplete-clearIndicator': {
      marginRight: '16px',
    },
    position: 'relative',
    '.MuiFormHelperText-contained': {
      position: 'absolute',
      top: '54px',
    },
  },
  rota_box_item: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '10px',
  },
  display_align: {
    display: 'flex',
    alignItems: 'center',
  },
  item_6: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '.MuiFormControl-root': {
      marginTop: 0,
    },
  },
  pre_remove: {
    position: 'absolute',
    left: '0px',
    bottom: '-2px',
  },
  option_sybmol: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  option_name: {
    textAlign: 'right',
    fontWeight: 400,
    fontSize: '0.875rem',
  },
  details_title: {
    background: '#F4F6F8',
    height: '64px',
    width: '100%',
    marginLeft: '0px',
    marginTop: '0px',
    '.MuiGrid-item': { padding: '0px 24px', height: '24px' },
    borderRadius: '8px 8px 0 0',
    '.MuiOutlinedInput-root': {
      padding: '4px 14px !important',
    },
    '.MuiFormLabel-colorPrimary': {
      marginTop: ' -4px ',
    },
  },
  select_currency: {
    width: '280px',
    // '& .MuiOutlinedInput-notchedOutline': {
    //   borderColor: 'rgba(255, 255, 255, 0) !important',
    // },
    '& .MuiOutlinedInput-input': {
      fontWeight: 600,
    },
  },
};

export const clearBtn = {
  background: '#637381',
  color: '#fff',
  borderRadius: '50%',
  display: 'inline-block',
  width: '20px',
  height: '20px',
  lineHeight: '20px',
  fontSize: '12px',
  cursor: 'pointer',
  '& :hover': {
    backgroundColor: '#fff',
    color: '#000',
  },
};

export const totalStyle = {
  typography: 'h7',
  color: '#fff',
  fontWeight: 600,
};

export const tatalBox = {
  background: '#3C6FF5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '360px',
  height: '52px',
  margin: '0',
  p: 2,
};
export function CheckboxElement({ control, name }: any) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Checkbox
          onBlur={onBlur} // notify when input is touched
          onChange={onChange} // send value to hook form
          checked={value}
          inputRef={ref}
        />
      )}
    />
  );
}
