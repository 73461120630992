import { useEffect, useState } from 'react';
import { Box, Drawer, Tooltip } from '@mui/material';
import { IconButtonAnimate } from '../../../components/animate';
import { useLocation } from 'react-router';
import { MyIcon } from '@/assets/icons/MyIcon';
import { observer } from 'mobx-react';
import { useLocales } from '@/locales';
import MerchantGuide from '@/pages/WalletApi/pages/overview/MerchantGuide/index';
import MerchantGuidePayment from '../MerchantGuide/index';
import { useAuthContext } from '@/auth/useAuthContext';

export default observer(function MerchantGuidePopover() {
  const [openPopover] = useState<HTMLElement | null>(null);
  const { translate: t } = useLocales();
  const { user } = useAuthContext();
  const [anchorFlag, setAnchorFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setAnchorFlag(false);
  }, [location?.pathname]);

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={() => {
          setAnchorFlag(true);
        }}
        sx={{ width: 40, height: 40 }}
      >
        <Tooltip title={t('wallet.beginner.guide')}>
          <MyIcon name="beginner" size={24} />
        </Tooltip>
      </IconButtonAnimate>

      <Drawer
        anchor={'right'}
        open={anchorFlag}
        onClose={() => {
          setAnchorFlag(false);
        }}
        sx={{
          width: 320,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            width: '320px',
            flexShrink: 0,
          }}
        >
          {user?.mode_type === 2 && (
            <MerchantGuide
              setOpenBenginer={() => {
                setAnchorFlag(false);
              }}
            />
          )}

          {user?.mode_type === 1 && (
            <MerchantGuidePayment
              setOpenBenginer={() => {
                setAnchorFlag(false);
              }}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
});
