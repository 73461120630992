import { IconButton, SvgIcon, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line
import { ReactComponent as CopyIcon } from '!!@svgr/webpack!file-loader!@/assets/icons/img/ic_copy.svg';
import { forwardRef } from 'react';
import { useLocales } from '@/locales';

type Props = {
  source: string;
  height?: string;
  sx?: SxProps;
};

const CopyButton = forwardRef(function CopyButton(props: Props, ref: any) {
  const { translate: t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const onClick = async () => {
    try {
      await doCopy(props.source);
      enqueueSnackbar(t('common.Copied'), {
        variant: 'success',
        key: new Date().getTime().toString(),
      });
    } catch (e) {
      enqueueSnackbar(e.message ?? t('common.failed_copy'), { variant: 'error' });
    }
  };
  return (
    <IconButton
      onClick={onClick}
      ref={ref}
      {...props}
      sx={{
        p: 0,
        marginLeft: '8px',
        height: props.height || '44px',
        width: props.height || '44px',
        borderRadius: '50%',
        ...props.sx,
      }}
    >
      <SvgIcon component={CopyIcon} inheritViewBox />
    </IconButton>
  );
});

export default CopyButton;

async function doCopy(text: string) {
  if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost')
    throw new Error('can not copy without ssl context.');
  if (!navigator.clipboard) throw new Error('can not access clipboard!');
  await navigator.clipboard.writeText(text);
}
