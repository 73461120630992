import { MyIcon } from '@/assets/icons/MyIcon';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import ic_payment_sdk from '@/assets/img/ic_payment_sdk.svg';
import ic_payment_info from '@/assets/img/ic_payment_info.svg';
import AccessBusinessSrc from '@/assets/img/access-business.svg';
import SecuritySettingsSrc from '@/assets/img/security-settings.svg';
import ApiInterfaceSrc from '@/assets/img/api-interface.svg';
import CommonProblemSrc from '@/assets/img/common-problem.svg';
import PersonalAccessBusinessSrc from '@/assets/img/personal-Access-business.svg';
import { useNavigate } from 'react-router';
// import { coinlistApi } from '@/pages/WalletApi/common/api';
import { useLocales } from '@/locales';
import { CONTACT_US_URL } from '@/config';

type Porps = {
  setOpenBenginer: () => void;
};

export default function Index({ setOpenBenginer }: Porps) {
  const [openId, setOpenId] = useState('');
  const navigate = useNavigate();
  const { translate } = useLocales();
  // const handleGetList = async () => {
  //   const param = {
  //     Status: 0,
  //   };
  //   try {
  //     let res = await coinlistApi.getNewCoinList(param);
  //     return res.List;
  //   } catch {
  //     console.log('errr');
  //   }
  // };
  const QuickStartOptions = [
    {
      logo: ic_payment_info,
      title: 'Improve business information',
      desc: 'Configuration coins and custom merchant checkout page for your business',
      src: '/merchatsetting/settings',
    },

    {
      logo: AccessBusinessSrc,
      title: 'Access business',
      desc: 'Terminal business docking, view access documents to start docking and debugging',
      src: 'https://doc.ccpayment.com/ccpayment-for-merchant/ccpayment-api-introduction',
    },

    {
      logo: SecuritySettingsSrc,
      title: 'Security Settings',
      desc: 'Enable payment password and two-factor authentication to help secure your account and assets.',
      src: '/account/settings',
    },
  ];

  const startAccessingOption = [
    {
      logo: ApiInterfaceSrc,
      title: 'API interface',
      desc: '',
      src: 'https://doc.ccpayment.com/ccpayment-for-merchant/ccpayment-api-introduction',
    },
    {
      logo: ic_payment_sdk,
      title: 'SDK download',
      desc: '',
      src: 'https://doc.ccpayment.com/ccpayment-for-developer/sdk-instruction',
    },
    {
      logo: CommonProblemSrc,
      title: 'Common problem',
      desc: '',
      src: 'https://doc.ccpayment.com/faq/faq',
    },

    {
      logo: PersonalAccessBusinessSrc,
      title: 'The Integration Service',
      desc: '',
      src: CONTACT_US_URL,
    },
  ];

  const faqOptions = [
    {
      title: `${translate('common.faq_how')}?`,
      desc: `${translate('common.faq_how_desc')}`,
      src: '',
    },
    // {
    //   title: 'What tokens/coins does CCPayment support?',
    //   desc: `CCPayment supports a variety of popular tokens/ coins for deposit and withdrawal. You can find a detailed overview of the supported tokens/coins on CCPayment’s supported tokens/coins list.`,
    //   src: '',
    // },

    // {
    //   title: 'How long does it take to receive withdrawal?',
    //   desc: `CCPayment processes orders instantly upon transfer. The transfer speed depends on the node and gas fee but is typically successful within 30 minutes. BTC transfer needs a few hours.`,
    //   src: '',
    // },

    {
      title: `${translate('common.faq_do')}?`,
      desc: `${translate('common.faq_do_desc')}`,
      src: '',
    },
    {
      title: `${translate('common.faq_how_long')}?`,
      desc: `${translate('common.faq_how_long_desc')}`,
      src: '',
    },
    // {
    //   title: `What is the frequency limit of calling API?`,
    //   desc: `The frequency limit per second: 100, don’t hesitate to get in touch with the customer service team if you want to adjust the limitation.`,
    //   src: '',
    // },
    {
      title: `${translate('common.faq_are')}?`,
      desc: `${translate('common.faq_are_desc')}`,
      src: '',
    },
    {
      title: `${translate('common.faq_can')}?`,
      desc: `${translate('common.faq_can_desc')}`,
      src: '',
      link: CONTACT_US_URL,
      linkDesc: `${translate('common.faq_can_link')}`,
    },
  ];

  return (
    <MerchantGuideWrap>
      <Box className="top-bar" component={'div'}>
        <div className="left-box">
          <MyIcon name="beginner" size={24} />
          <span>{translate('common.faq_beginner')}</span>
        </div>
        <div
          className="right-box"
          onClick={() => {
            setOpenBenginer();
          }}
        >
          <MyIcon name="close" size={14} />
        </div>
      </Box>

      <div className="quick-start-wrap">
        <div className="title">{translate('common.faq_quick')}</div>
        {QuickStartOptions.map((item, index) => (
          <div
            key={item.logo}
            className="quick-start-item"
            onClick={() => {
              if (item.src && !item.src.includes('http')) {
                navigate(item.src);
              } else {
                window.open(item.src);
              }
            }}
          >
            <img src={item.logo} alt="" />
            <div>
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="quick-start-wrap">
        <div className="title">{translate('common.faq_start')}</div>
        {startAccessingOption.map((item) => (
          <div
            key={item.logo}
            className="quick-start-item"
            onClick={() => {
              if (item.src && !item.src.includes('http')) {
                navigate(item.src);
              } else if (item.src && item.src.includes('http')) {
                window.open(item.src);
              }
            }}
          >
            <img src={item.logo} alt="" style={{ width: '24px', height: '24px' }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h4 style={{ margin: '0' }}>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>

      <div className="obtain-app-id">
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/developer/config');
          }}
        >
          {translate('common.faq_obtain')}
        </Button>
      </div>

      <div className="faq-warp">
        <div className="title">{translate('common.faq_faq')}</div>
        {faqOptions.map((item) => (
          <div key={item.title} className="faq-item-wrap">
            <div className="title">
              <span style={{ color: `${openId === item.title ? '#3C6FF5' : '#1E1E1E'}` }}>
                {item.title}
              </span>
              <MyIcon
                name={`${openId === item.title ? 'ic_payment_close' : 'ic_payment_add'}`}
                size={24}
                onClick={() => {
                  if (openId === item.title) {
                    setOpenId('');
                  } else {
                    setOpenId(item.title);
                  }
                }}
              />
            </div>
            {openId === item.title ? (
              <div
                className="desc"
                style={{
                  overflow: openId === item.title ? 'visible' : 'hidden',
                  height: openId === item.title ? 'auto' : '0px',
                  // maxHeight: '200px',
                  // transform: `scale(${openId === item.title ? 1 : 0})`,
                  transition: 'height 0.3s',
                }}
              >
                {item.link ? (
                  <div>
                    {item.desc}{' '}
                    <a
                      rel="noreferrer"
                      href={item.link}
                      target="_blank"
                      style={{ color: '#00B8D9' }}
                    >
                      {item.linkDesc}
                    </a>
                  </div>
                ) : (
                  item.desc
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div
        className="more-help"
        onClick={() => {
          window.open('https://doc.ccpayment.com/faq/faq');
        }}
      >
        <span>{translate('common.faq_more')}</span>
        <MyIcon name="ic_payment_arrow" size={18} />
      </div>
    </MerchantGuideWrap>
  );
}

const MerchantGuideWrap = styled.div`
  padding: 16px 24px;

  background: #f9faf6;
  /* border-radius: 16px; */
  > .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #27291e;

    .left-box {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
    .right-box {
      cursor: pointer;
    }
  }

  > .quick-start-wrap {
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #73756d;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .quick-start-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      :hover {
        cursor: pointer;
        h4 {
          color: #3c6ff5;
        }
      }
      > img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
      > div {
        h4 {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #27291e;
          margin: 0;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #73756d;
          margin: 0;
        }
      }
    }
  }

  .obtain-app-id {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    button {
      color: #3c6ff5;
      border: 1px solid #3c6ff5;
      border-radius: 8px;
      font-weight: 500;
    }
  }

  .faq-warp {
    > .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #73756d;
      margin-bottom: 24px;
    }
    .faq-item-wrap {
      margin-bottom: 24px;
      > .title {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;
        img {
          height: 24px;
          cursor: pointer;
        }
      }
      > .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #73756d;
      }
    }
  }

  .more-help {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: #3c6ff5;
      font-size: 14px;
      font-family: Poppins;
      font-weight: 600;
      line-height: 26px;
      margin-right: 8px;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    /* img {
      transform: rotate(180deg);
    } */
  }
`;
