import { makeObservable, observable, toJS } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';
import { transactionApi } from '../api';
import { getTransationListParamType, getTransationListResType } from '../api/transactionApi';
import { transactionStatusEnum } from '../../pages/transaction/transaction';

interface WalletTransactionSearchParamProps {
  sub_transaction_list?: string[];
  user_type?: string;
  coin_id?: string;
  chain_id?: string;
  status?: number;
  billing_status?: number;
  search_item?: 'txId' | 'address' | 'contract' | 'userId' | 'businessId';
  search?: string;
  page_index?: number;
  page_size?: number;
}

interface WalletTransactionUnblockSearchParamProps {
  chain_id?: string;
  symbol?: string;
  search?: string;
  page_index?: number;
  page_size?: number;
}

class WalletTransactionStroe {
  searchParam: getTransationListParamType = {
    sub_transaction_list: ['all'],
    user_type: 'all',
    coin_id: '0000-0000-0000-0000',
    chain_id: '0',
    status: 0,
    billing_status: 1,
    search_item: 'txId',
    search: '',
    page_index: 1,
    page_size: 20,
  };

  unblockSearchParam = {
    chain_id: '0',
    symbol: 'All Symbol',
    search: '',
    page_index: 1,
    page_size: 20,
  };

  manualSearchParam = {
    chain_id: '0',
    symbol: 'All Symbol',
    search: '',
    page_index: 1,
    page_size: 20,
  };

  searchLoading = false;

  typeValue = 1;

  tableData: getTransationListResType = {
    list: [],
    pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  unblockTableData: getTransationListResType = {
    list: [],
    pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  manualTableData: getTransationListResType = {
    list: [],
    pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  constructor() {
    makeObservable(this, {
      searchParam: observable,
      tableData: observable,
      searchLoading: observable,
      typeValue: observable,
      unblockSearchParam: observable,
      manualSearchParam: observable,
      unblockTableData: observable,
      manualTableData: observable,
    });
    this.setTypeValue = this.setTypeValue.bind(this);
    this.setSearchParam = this.setSearchParam.bind(this);
    this.queryList = this.queryList.bind(this);
    this.setUnblockSearchParam = this.setUnblockSearchParam.bind(this);
    this.queryUnblockList = this.queryUnblockList.bind(this);
    this.setManualSearchParam = this.setManualSearchParam.bind(this);
    this.initSearchParams = this.initSearchParams.bind(this);
    this.queryManualList = this.queryManualList.bind(this);
    this.setTableData = this.setTableData.bind(this);
  }

  async setTypeValue(value: number) {
    this.typeValue = value;
  }

  async initSearchParams() {
    this.unblockSearchParam = {
      chain_id: '0',
      symbol: 'All Symbol',
      search: '',
      page_index: 1,
      page_size: 20,
    };
    this.manualSearchParam = {
      chain_id: '0',
      symbol: 'All Symbol',
      search: '',
      page_index: 1,
      page_size: 20,
    };
  }

  async setSearchParam(param: WalletTransactionSearchParamProps) {
    const oldsSearchParam = { ...this.searchParam };
    this.searchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  async setUnblockSearchParam(param: WalletTransactionUnblockSearchParamProps) {
    const oldsSearchParam = { ...this.unblockSearchParam };
    this.unblockSearchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  async setManualSearchParam(param: WalletTransactionUnblockSearchParamProps) {
    const oldsSearchParam = { ...this.manualSearchParam };
    this.manualSearchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  async setTableData(data: getTransationListResType) {
    this.tableData = {
      ...data,
    };
  }

  /**
   *Query list data
   * @memberof WalletTransactionStroe
   */
  async queryList() {
    try {
      this.searchLoading = true;
      const data = { ...this.searchParam };
      data.sub_transaction_list = data.sub_transaction_list.includes('all')
        ? []
        : data.sub_transaction_list;
      data.user_type = data.user_type === 'all' ? '' : data.user_type;
      data.chain_id = data.chain_id === '0' ? '' : data.chain_id;

      if (data.status === 1) {
        data.status = transactionStatusEnum.Pending;
      }
      if (data.status === 2) {
        data.status = transactionStatusEnum.Success;
      }
      if (data.status === 3) {
        data.status = transactionStatusEnum.Failed;
      }
      const res = await transactionApi.getTransationList(data);
      this.tableData = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   *Query unaccounted list data
   * @memberof WalletTransactionStroe
   */
  async queryUnblockList() {
    try {
      this.searchLoading = true;
      const data = {
        ...this.unblockSearchParam,
        symbol:
          this.unblockSearchParam.symbol === 'All Symbol' ? '' : this.unblockSearchParam.symbol,
        chain_id: this.unblockSearchParam.chain_id === '0' ? '' : this.unblockSearchParam.chain_id,
      };

      console.log('data', toJS(data));
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   *Query manual entry list data
   * @memberof WalletTransactionStroe
   */
  async queryManualList() {
    try {
      this.searchLoading = true;
      const data = {
        ...this.manualSearchParam,
        symbol: this.manualSearchParam.symbol === 'All Symbol' ? '' : this.manualSearchParam.symbol,
        chain_id: this.manualSearchParam.chain_id === '0' ? '' : this.manualSearchParam.chain_id,
      };

      console.log('data', toJS(data));
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }
}
export default WalletTransactionStroe;
