import { Token } from '@/api/WalletApi';
import { fTokenNumber } from './formatNumber';
import { formatChartsDate, formatDate } from './formatTime';
import { useEffect, useState } from 'react';
import Decimal from 'decimal.js';
import { EnqueueSnackbar } from 'notistack';
import { HOMEDOMAINV2 } from '@/config';

export function validateIP(ip: string) {
  // IPv4
  const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
  // IPv6
  const ipv6Regex = /^([0-9a-fA-F]{1,4}:){0,6}(:[0-9a-fA-F]{1,4}){1,7}$/;
  if (ipv4Regex.test(ip) || ipv6Regex.test(ip)) {
    return true; // IP true
  } else {
    return false; // IP false
  }
}

export function newVersionNavigate(authToken: string) {
  if (!authToken) return;
  localStorage.setItem('isLogin', 'false');
  localStorage.setItem('isVerify', 'false');
  localStorage.setItem('isout', 'true');
  if (window.location.href.includes('https')) {
    window.location.href = `${HOMEDOMAINV2}?authtoken=${authToken}`;
  } else {
    window.location.href = `http://localhost:3008?authtoken=${authToken}`;
  }
}

export function newVersionNavigateForgot(param?: string) {
  if (param) {
    if (window.location.href.includes('https')) {
      window.location.href = `${HOMEDOMAINV2}/forgetPassword${param}`;
    } else {
      window.location.href = `http://localhost:3008/forgetPassword${param}`;
    }
  } else {
    if (window.location.href.includes('https')) {
      window.location.href = `${HOMEDOMAINV2}/forgetPassword`;
    } else {
      window.location.href = `http://localhost:3008/forgetPassword`;
    }
  }
}

export function newVersionNavigateRegister() {
  if (window.location.href.includes('https')) {
    window.location.href = `${HOMEDOMAINV2}/register`;
  } else {
    window.location.href = `http://localhost:3008/register`;
  }
}

export function newVersionNavigateLogin() {
  if (window.location.href.includes('https')) {
    window.location.href = `${HOMEDOMAINV2}/login`;
  } else {
    window.location.href = `http://localhost:3008/login`;
  }
}

// SafetyMode int8 // 0 do not verify 1 email 2 google， 3 email & google ，4 phone  ，5 email &phone， 6 google & phone ，7 email &google & phone
export function SafetyModeStatus(modeNumber: number, code: string) {
  if ([2, 3, 6, 7].includes(modeNumber)) {
    return {
      SmsCode: code,
    };
  }
  if ([1, 5].includes(modeNumber)) {
    return {
      MailCode: code,
    };
  }

  if ([4].includes(modeNumber)) {
    return {
      TotpCode: code,
    };
  }
  return {};
}

export function SafetyModeSendStatus(
  isGoogle: Boolean,
  isEmail: Boolean,
  isPhone: Boolean,
  code: string
) {
  if (isGoogle) {
    return {
      TotpCode: code,
    };
  }
  if (isEmail) {
    return {
      MailCode: code,
    };
  }

  if (isPhone) {
    return {
      SmsCode: code,
    };
  }
  return {};
}

export function reverseString(str: string) {
  return str.split('').reverse().join('');
}

export function isSequentialNumber(str: string): boolean {
  const numbers = '0123456789';
  return numbers.includes(str) || reverseString(numbers).includes(str);
}

export function isSequentialLetter(str: string): boolean {
  const alphabets = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return alphabets.includes(str) || reverseString(alphabets).includes(str);
}

export function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function removeInvalidZero(num: string) {
  let result = num;
  if (num.includes('.')) {
    result = result.replace(/\.?0+$/, '');
  }
  return result;
}

/**
 *
 * @param SafetyMode  0 do not verify 1 email 2 google， 3 email & google ，4 phone  ，5 email &phone， 6 google & phone ，7 email &google & phone
 * @returns
 */
export function getSafetyVerifyInfo(SafetyMode: number) {
  const safetyObj = {
    google: false,
    phone: false,
    email: true,
  };

  if (SafetyMode === 0) {
    safetyObj.google = false;
    safetyObj.email = false;
    safetyObj.phone = false;
  }

  if (SafetyMode === 1) {
    safetyObj.google = false;
    safetyObj.email = true;
    safetyObj.phone = false;
  }

  if (SafetyMode === 2) {
    safetyObj.google = true;
    safetyObj.email = false;
    safetyObj.phone = false;
  }

  if (SafetyMode === 3) {
    safetyObj.google = true;
    safetyObj.email = true;
    safetyObj.phone = false;
  }

  if (SafetyMode === 4) {
    safetyObj.google = false;
    safetyObj.email = false;
    safetyObj.phone = true;
  }

  if (SafetyMode === 5) {
    safetyObj.google = false;
    safetyObj.email = true;
    safetyObj.phone = true;
  }

  if (SafetyMode === 6) {
    safetyObj.google = true;
    safetyObj.email = false;
    safetyObj.phone = true;
  }

  if (SafetyMode === 7) {
    safetyObj.google = true;
    safetyObj.email = true;
    safetyObj.phone = true;
  }

  return safetyObj;
}

/** add */
export function numAdd(num: string | number | Decimal, num2: string | number | Decimal) {
  let result: string | Decimal = new Decimal(num);
  let result2 = new Decimal(num2);
  result = result.add(result2).toFixed();
  return result;
}

/** Subtract */
export function numSubtr(num: string | number | Decimal, num2: string | number | Decimal) {
  let result: string | Decimal = new Decimal(num);
  let result2 = new Decimal(num2);
  result = result.minus(result2).toFixed();
  return result;
}

/** multiplied */
export function numTake(num: string | number | Decimal, num2: string | number | Decimal) {
  let result: string | Decimal = new Decimal(num);
  let result2 = new Decimal(num2);
  result = result.times(result2).toFixed();
  return result;
}

/** to divide */
export function numDiv(num: string | number | Decimal, num2: string | number | Decimal) {
  let result: string | Decimal = new Decimal(num);
  let result2 = new Decimal(num2);
  result = result.div(result2).toFixed();
  return result;
}

export function moreThan(num: string) {
  let result = num;
  if (num && Number(num) > 0) {
    if (num.includes('.')) {
      const index = num.indexOf('.');
      let leftStr = num.slice(0, index);
      let rightStr = num.slice(index);
      if (rightStr.length > 9) {
        rightStr = rightStr.slice(0, 9);
      }
      result = leftStr + rightStr;
    }
  }

  return result;
}

export const getTitle_ = (stime: number) => {
  const datestr1 = formatDate(stime, 'MMMM/do/yyyy');
  const dateParts = datestr1.match(/(\d+)(\D+)(\d+)/) || [];
  console.log('dateParts11111', dateParts);
  const day = +dateParts[1] > 9 ? dateParts[1] : '0' + dateParts[1];
  const month = datestr1.split('/')[0].slice(0, 3);
  const year = dateParts[3];
  return `${day} ${month}. ${year}`;
};

export const getTitle = (stime: number, eTime: number) => {
  return `${getTitle_(stime)} ～ ${getTitle_(eTime)}`;
};

export const getTitle_range = (stime: number, eTime: number) => {
  return `${getTitle_(stime)} ～ ${getTitle_(eTime)}`;
};

export const getTime = (
  timeObj: {
    days?: number;
    hours?: number;
    mins?: number;
    Seconds?: number;
    soureDate: number;
  },
  translate: (val: string) => string,
  format?: string
) => {
  if (timeObj?.days) {
    if (timeObj?.days >= 1) {
      return formatChartsDate(timeObj?.soureDate / 1000, format || 'dd MMM yyyy kk:mm') || '--';
    } else {
      return `${timeObj.days} ${translate('tradingOrer.day_ago')}`;
    }
  }
  if (timeObj?.hours) {
    if (timeObj?.hours > 1) {
      return `${timeObj.hours} ${translate('tradingOrer.hours_ago')}`;
    } else {
      return `${timeObj.hours} ${translate('tradingOrer.hour_ago')}`;
    }
  }
  if (timeObj?.mins) {
    if (timeObj.mins > 1) {
      return `${timeObj.mins} ${translate('tradingOrer.mins_ago')}`;
    } else {
      return `${timeObj.mins} ${translate('tradingOrer.min_ago')}`;
    }
  }
  if ((timeObj?.Seconds as number) >= 0) {
    return `1 ${translate('tradingOrer.min_ago')}`;
  }
};

//timeDate  select
export const getTimeTitle = (stime: number) => {
  const datestr1 = formatDate(stime, 'MMMM/do/yyyy');
  return ` ${datestr1.split('/').slice(0, 2).join(' ')}, ${datestr1.split('/').slice(2).join(' ')}`;
};

//timeDate  select
export const webhookGetTimeTitle = (stime: number) => {
  const datestr1 = formatDate(stime, 'MMMM/do/yyyy HH:mm:ss');
  return ` ${datestr1.split('/').slice(0, 2).join(' ')}, ${datestr1.split('/').slice(2).join(' ')}`;
};

//Batch setting form values
export const set_form_value = (formContext: any, items: { [key: string]: any }[]) => {
  for (var i = 0; i < items.length; i++) {
    formContext.setValue(Object.keys(items[i])[0], Object.values(items[i])[0]);
  }
};

//countdown
export function useCountdown(dest: Date) {
  console.log('....', dest);
  const [remainMS, setRemainMS] = useState(0);
  useEffect(
    () => {
      setRemainMS(dest.valueOf() - Date.now());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    let timer: number | null = setInterval(() => {
      const remain = dest.valueOf() - Date.now();
      if (remain < 0 && timer) {
        clearInterval(timer);
        timer = null;
      } else {
        setRemainMS(remain);
      }
    }, 200) as any;
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [dest]);

  const seconds = remainMS > 0 ? Math.ceil(remainMS / 1000) : 0; //Second
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor(seconds % 60);

  return [h, m.toString().padStart(2, '0'), s.toString().padStart(2, '0'), d];
}

export function formartSearchParam(searchParam: string) {
  if (!searchParam) return;
  let strArr = searchParam.slice(1).split('&');
  let obj: any = {};
  strArr.forEach((item) => {
    let keyArr = item.split('=');
    if (!obj[keyArr[0]]) {
      obj[keyArr[0]] = keyArr[1];
    }
  });
  return obj;
}

/**
 * Insert a string at a specified position
 * @param soure string
 * @param start number
 * @param newStr string
 * @returns string
 */
export function insertStr(soure: string, start: number, newStr: string) {
  return soure.slice(0, start) + newStr + soure.slice(start);
}

/**copy*/
export function copyText(text: string) {
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
  const fakeElement = document.createElement('textarea');
  //  Prevent zooming on iOS
  fakeElement.style.fontSize = '12pt';
  //Reset box model
  fakeElement.style.border = '0';
  fakeElement.style.padding = '0';
  fakeElement.style.margin = '0';
  // Moves the element horizontally off screen
  fakeElement.style.position = 'absolute';
  fakeElement.style[isRTL ? 'right' : 'left'] = '-9999px';
  // Move elements vertically to the same position
  const yPosition = window.pageYOffset || document.documentElement.scrollTop;
  fakeElement.style.top = `${yPosition}px`;
  fakeElement.setAttribute('readonly', '');
  fakeElement.value = text;
  document.body.appendChild(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  fakeElement.remove();
}

/* Mailbox desensitization
 ** email The mailbox string to process
 */
export function settingEmail(email: string) {
  if (!email) return '';
  let str = email;
  str = str.replace(/(.{0,3}).*@(.*)/, '$1***@$2');
  return str;
}

/* Phone number desensitization
 **@params phone The mailbox string to process
 */
export function settingPhone(phone: string) {
  if (!phone) return ' ';
  let str = phone;
  str = str.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
  return str;
}

/* txid Desensitization
 **@params txid txid to be processed
 */
export function settingTxid(txid: string, length?: number, middleStr?: string) {
  if (!txid) return ' ';
  const variable = length || 10; // Suppose the variable is 10
  const regexString = `(.{0,${variable}}).*(\\w{${variable}})`; // Insert variable into regex string
  const regex = new RegExp(regexString); // Create a regular expression instance using the RegExp constructor
  let str = txid;
  str = str.replace(regex, `$1${middleStr || '...'}$2`);
  return str;
}

/* Format tokens to eight decimal places, do not round
 ** params Currency strings to process
 */
export function formatCoin(params: string | number) {
  let str = params.toString();
  if (str.includes('.')) {
    let leftStr = str.slice(0, str.indexOf('.'));
    leftStr = fTokenNumber(leftStr);
    let rightStr = str.slice(str.indexOf('.'));
    if (rightStr.length < 3) {
      rightStr = rightStr + '0';
    }
    if (rightStr.length > 9) {
      if (Number(rightStr.slice(1, 10)) === 0) {
        rightStr = '.00';
      } else {
        rightStr = rightStr.slice(0, 9);
      }
    }
    str = leftStr + Number(rightStr).toString().slice(1);
  } else {
    str = fTokenNumber(str) + '.00';
  }
  return str;
}

/* thousandths processing
 ** value The coin string to be processed
 */
export function toLocalString(value: string) {
  // Do thousandths
  let result = value;
  if (Number(value) >= 1000) {
    result = Number(value).toLocaleString('en-US');
  } else {
    result = value;
  }
  return result;
}

/* Format legal currency, keep 2 decimal places, do not round
 ** params The legal currency string to be processed
 */
export function formatAmout(params: string | number) {
  let str = params.toString();
  if (str.includes('.')) {
    let leftStr = str.slice(0, str.indexOf('.'));
    leftStr = fTokenNumber(leftStr);
    let rightStr = str.slice(str.indexOf('.'));
    if (rightStr.length > 3) {
      rightStr = rightStr.slice(0, 3);
    }
    str = leftStr + Number(rightStr).toString().slice(1);
    if (Number(rightStr) === 0) {
      str = leftStr + '.00';
    }
  } else {
    if (Number(str) === 0) {
      return '0.00';
    }
    str = fTokenNumber(str) + '.00';
  }

  return str;
}

/* Token quantity display rule processing
 ** params Currency string to be processed
 */
export function formatToken(params: string) {
  if (!params) return ' ';
  let str = params;
  if (Number(str) === 0) {
    return '0.00';
  }
  if (str.length > 9) {
    let leftStr = str.slice(0, str.indexOf('.'));
    let rightStr = str.slice(str.indexOf('.'));
    if (Number(leftStr) > 0) {
      // if leftStr.length>9 slice
      if (leftStr.length > 9) {
        str = fTokenNumber(leftStr.slice(0, 9)) + '.00';
      } else {
        if (rightStr.length > 9 - leftStr.length) {
          rightStr = rightStr.slice(0, 9 - leftStr.length);
        }
        leftStr = fTokenNumber(leftStr);
        str = leftStr + rightStr;
      }
    }
    if (Number(leftStr) === 0) {
      for (let i = 0; i < rightStr.length; i++) {
        if (Number(rightStr[i]) > 0) {
          str = '0' + rightStr.slice(0, i + 8);
          break;
        }
      }
    }
    if (Number(leftStr) < 0) {
      // if leftStr.length>9 slice
      if (leftStr.length > 9) {
        str = fTokenNumber(leftStr.slice(0, 9)) + '.00';
      } else {
        if (rightStr.length > 9 - leftStr.length) {
          rightStr = rightStr.slice(0, 9 - leftStr.length);
        }
        leftStr = fTokenNumber(leftStr);
        str = leftStr + rightStr;
      }
    }
  } else {
    if (Number(str) > 0) {
      if (str.includes('.')) {
        let leftStr = str.slice(0, str.indexOf('.'));
        let rightStr = str.slice(str.indexOf('.'));
        if (str.length < 4) {
          if (rightStr.length === 2) {
            rightStr = rightStr + '0';
          }
          str = fTokenNumber(leftStr) + rightStr;
        } else {
          leftStr = fTokenNumber(leftStr);
          if (rightStr.length > 2) {
            str = leftStr + rightStr;
          } else {
            str = leftStr + rightStr + '0';
          }
        }
      } else {
        str = fTokenNumber(str) + '.00';
      }
    } else {
      if (str.includes('.')) {
        let leftStr = str.slice(0, str.indexOf('.'));
        let rightStr = str.slice(str.indexOf('.'));
        leftStr = fTokenNumber(leftStr.slice(1));
        if (rightStr.length < 3) {
          rightStr = rightStr + '0';
        }
        str = '-' + leftStr + rightStr;
      } else {
        str = '-' + fTokenNumber(str.slice(1)) + '.00';
      }
    }
  }
  return str;
}

export function formatSelectToken(num: number | string) {
  let str = '';
  if (Number(num) === 0) {
    str = '0.00';
  } else {
    const leftStr = num.toString().slice(0, num.toString().indexOf('.'));
    const rightStr = num.toString().slice(num.toString().indexOf('.'));
    str = fTokenNumber(leftStr) + rightStr;
  }
  return str;
}

/* Get the date of the current day's interval month
 ** flag before after
 ** many months apart
 */
export function getNewDate(flag: string, many: number) {
  const thirtyDays = [4, 6, 9, 11]; // 30 day month

  const thirtyOneDays = [1, 3, 5, 7, 8, 10, 12]; // 31 day month

  const currDate = new Date(); // now

  const year = currDate.getFullYear();

  let month = currDate.getMonth() + 1;

  let targetDateMilli = 0;

  let GMTDate: any = ''; // China Standard Time

  let targetYear = ''; // year

  let targetMonth = ''; // month

  let targetDate = ''; // day

  let dealTargetDays = ''; //target day

  const isLeapYear = !!((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0); // Is it a leap year

  let countDays = 0; // Cumulative days

  for (let i = 0; i < many; i++) {
    if (flag === 'before') {
      month = month - 1 <= 0 ? 12 : month - 1;
    } else {
      month = month + 1 > 12 ? 1 : month + 1;
    }
    thirtyDays.includes(month)
      ? (countDays += 30)
      : thirtyOneDays.includes(month)
      ? (countDays += 31)
      : isLeapYear
      ? (countDays += 29)
      : (countDays += 28);
  }

  targetDateMilli = currDate.setDate(
    currDate.getDate() - (flag === 'before' ? countDays : countDays * -1)
  );

  GMTDate = new Date(targetDateMilli);

  targetYear = GMTDate.getFullYear();

  targetMonth = GMTDate.getMonth() + 1;

  targetDate = GMTDate.getDate();

  targetMonth = targetMonth.toString().padStart(2, '0');

  targetDate = targetDate.toString().padStart(2, '0');

  dealTargetDays = `${targetYear}-${targetMonth}-${targetDate}`;

  return dealTargetDays;
}

/* Get the date with the number of days between the current day
 ** num interval of days
 */

export function getBeforeDate(num: number) {
  let currentDate = new Date();
  const daysAgo = new Date(currentDate.getTime() - num * 24 * 60 * 60 * 1000);

  let year: number | string = daysAgo.getFullYear();
  let month: number | string = daysAgo.getMonth() + 1;
  let day: number | string = daysAgo.getDate();
  let huours: number | string = daysAgo.getHours();
  let minutes: number | string = daysAgo.getMinutes();
  let seconds: number | string = daysAgo.getSeconds();

  if (month < 10) {
    month = '0' + month;
  }

  if (day < 10) {
    day = '0' + day;
  }

  if (huours < 10) {
    huours = '0' + huours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  const formattedDate = `${year}-${month}-${day} ${huours}:${minutes}:${seconds}`;
  return { daysAgo: daysAgo.getTime(), formattedDate, currentDate };
}

export function tryRun(fn: () => any) {
  try {
    return fn();
  } catch (error: any) {
    console.log('failed to run fn:', error.message);
    return null;
  }
}
/**Currency Sort */
export function coinSort(list: Token[]): Token[] {
  let list1: Token[] = [];
  let list2: Token[] = [];
  let list3: Token[] = [];
  list.forEach((item) => {
    if (item.price > 0 && item.balance > 0) {
      list1.push(item);
    } else if (item.price === 0 && item.balance > 0) {
      list2.push(item);
    } else {
      list3.push(item);
    }
  });
  list1.sort((a, b) => b.price * b.balance - a.price * a.balance);
  list2.sort((a, b) => b.balance - a.balance);
  list3.sort((a, b) => b.market_cap - a.market_cap);
  return [...list1, ...list2, ...list3];
}

//Number formatting th st etc eg:1th
export function ordinalSuffix(n: any) {
  if (isNaN(n)) return '';
  var suffix = ['th', 'st', 'nd', 'rd'];
  var lastDigit = n % 10;
  var suffixIndex = lastDigit <= 3 && n !== 11 && n !== 12 ? lastDigit : 0;
  return n + suffix[suffixIndex];
}

//error code
export function getErrMessage(
  num: number,
  translate: (val: string) => string,
  enqueueSnackbar: (arg0: string, arg1: { variant: string }) => any
) {
  const errMessage = {
    224060: translate('interface_err.224060'),
    224045: translate('interface_err.224045'),
    224046: translate('interface_err.224046'),
    224047: translate('interface_err.224047'),
    224049: translate('interface_err.224049'),
    224050: translate('interface_err.224050'),
    224051: translate('interface_err.224051'),
    224052: translate('interface_err.224052'),
    224053: translate('interface_err.224053'),
    224054: translate('interface_err.224054'),
    224055: translate('interface_err.224055'),
    224056: translate('interface_err.224056'),
    224057: translate('interface_err.224057'),
    224058: translate('interface_err.224058'),
    40007: translate('interface_err.40007'),
    224029: translate('interface_err.224029'),
  };
  if (errMessage[num]) {
    return enqueueSnackbar(errMessage[num], { variant: 'error' });
  } else {
    return enqueueSnackbar(translate('interface_err.40007'), { variant: 'error' });
  }
}

//specific Token
export const fixed_token = {
  hotToken: ['BTC', 'ETH', 'BNB', 'XRP'],
  stableToken: ['USDT', 'USDC', 'BUSD', 'DAI'],
  filterToken: ['BTC', 'ETH', 'BNB', 'XRP', 'ADA', 'DOGE', 'SHIB', 'LTC'],
};

export async function downloadFiel(path: string, name: string, callback: (status: number) => void) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', path);
  xhr.responseType = 'blob';
  xhr.send();
  xhr.onload = function () {
    const fileBlob = xhr.response;
    const fileUrl = URL.createObjectURL(fileBlob);
    console.log('fileUrl', fileUrl);
    const ele = document.createElement('a');
    ele.setAttribute('href', fileUrl);
    ele.setAttribute('download', name);
    ele.setAttribute('target', '_blank');
    ele.click();
  };
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      callback(xhr.readyState);
    }
    if (xhr.status !== 200) {
      callback(xhr.status);
    }
  };
}
//Sign and upload
export async function uploadFiel(
  fiel: any,
  items: any,
  callback: (status: number, items: any) => void
) {
  let formData = new FormData();
  formData.append('file', fiel);
  var xhr = new XMLHttpRequest();
  xhr.open('put', items?.upload_url || '');
  let obj = items?.headers;
  for (var i in obj) {
    //set request header
    xhr.setRequestHeader(i, obj[i]);
  }
  xhr.send(formData);
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      if (xhr.status === 200) {
        //The picture is uploaded successfully, set the creation/editing input parameters
        console.log('xhr', xhr);
        callback(xhr.readyState, items);
      } else {
        callback(xhr.readyState, items);
      }
    }
  };
}
export function getAddress(url: string, tw_url: string, option_url: string) {
  if (url.startsWith('https://')) {
    return url;
  } else if (url.includes(option_url) || url.includes('@')) {
    const index = url.indexOf('/');
    const result = url.substring(index + 1);
    return tw_url + result;
  } else {
    return '';
  }
}

//Get random hex color
export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function maskString(str: string) {
  if (str.length <= 12) {
    return str;
  }
  var start = str.substring(0, 6);
  var end = str.substring(str.length - 6, str.length);
  var masked = start + '***' + end;
  return masked;
}

export async function doCopy(
  text: string,
  t: (val: string) => string,
  enqueueSnackbar: EnqueueSnackbar
) {
  if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost')
    throw new Error('can not copy without ssl context.');
  if (!navigator.clipboard) throw new Error('can not access clipboard!');
  await navigator.clipboard.writeText(text);
  enqueueSnackbar(t('common.Copied'));
}
