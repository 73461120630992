import 'whatwg-fetch';
import { EventEmitter } from 'events';
import { v4, version } from 'uuid';
import { newVersionNavigate, sleep } from '@/utils/until';
import { enqueueSnackbar } from 'notistack';
import { encrypt, decrypt } from '@/utils/pcrypto';
import { currentEnv } from '@/config';

const uuid = (() => {
  const id = localStorage.getItem('_payment_fe_uuid');
  if (!id || version(id) !== 4) {
    const freshId = v4();
    localStorage.setItem('_payment_fe_uuid', freshId);
    return freshId;
  }
  return id;
})();

const jumpPage = async (msg: string, code: number, errorRes: any) => {
  // No operation time is up, go to verify interface
  if ([224035].includes(code)) {
    localStorage.setItem('isVerify', 'false');
    // await sleep(3000);
    window.location.reload();
    return;
    // throw new HttpResponseError(msg, code, errorRes);
  }
  // 10040
  if ([10040, 224002, 224114, 10072, 225112].includes(code)) {
    console.log('去login');
    localStorage.setItem('isLogin', 'false');
    localStorage.setItem('isVerify', 'false');
    localStorage.setItem('isout', 'true');
    // enqueueSnackbar('Login Expired', { variant: 'warning' });
    // await sleep(3000);

    window.location.reload();
    // throw new HttpResponseError(msg, code, errorRes);
    return;
  }
};

const walletJumpPage = async (msg: string, code: number, errorRes: any) => {
  if ([10012].includes(code)) {
    localStorage.setItem('isLogin', 'false');
    localStorage.setItem('isVerify', 'false');
    enqueueSnackbar('Login Expired', { variant: 'warning' });
    await sleep(3000);
    window.location.reload();
    // throw new HttpResponseError(msg, code, errorRes);
    return;
  }
};

export class Http extends EventEmitter {
  baseUrl: string = '';

  defaultHeaders: Record<string, string> = {
    'x-device-uuid': uuid,
  };

  /** custom response interceptor */
  resInterceptors: ((packt: Packt) => Packt)[] = [];

  /** Generate the corresponding error prompt content according to the returned package information  */
  getResponseErrorMsg = (packt: Packt) => {
    // eslint-disable-next-line
    const msg = packt.msg;
    return msg;
  };

  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  /** original fetch add token */
  async fetch(url: string, options: RequestInit = {}) {
    let deviceInfo = {
      browserName: '',
      browserVersion: '',
      osName: '',
      osVersion: '',
      browserKernel: '',
      outIp: '',
    };
    deviceInfo = await getDeviceInfo();
    const currentTimes = new Date().getTime();

    options.headers = {
      ...this.defaultHeaders,
      ...options.headers,
      'X-IP': deviceInfo?.outIp || '',
      'X-Platform': deviceInfo ? JSON.stringify(deviceInfo) : JSON.stringify({}),
      'X-Timestamp': currentTimes.toString(),
    };
    try {
      // If it is not a production environment and returns a string
      // /merchant/console/v1
      if (
        currentEnv === 'prd' &&
        `${this.baseUrl}${url}`.includes('/merchant/v1') &&
        !`${this.baseUrl}${url}`.includes('/safety/info/check/email') &&
        typeof options.body === 'string'
      ) {
        options.body = options.body ? encrypt(options.body, currentTimes) : null;
      }
    } catch (error) {
      throw new Error('Encryption Failed!');
    }

    try {
      const res = await fetch(`${this.baseUrl}${url}`, {
        cache: 'no-cache',
        ...options,
      });
      if (!res.ok) {
        // 400 500 error
        throw new Error(`${res.statusText}! ${res.url}`);
      }
      return { res, currentTimes };
    } catch (err) {
      // network error
      throw new Error('Network error!');
    }
  }

  /** post */
  post(url: string, data: any = '', options: RequestInit = {}) {
    options.headers = {
      'content-type': 'application/json',
      ...options.headers,
    };
    return this.fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
      ...options,
    });
  }

  async postForm(url: string, data: FormData, options: RequestInit = {}) {
    options.headers = {
      ...options.headers,
    };
    const promise = await this.fetch(url, {
      ...options,
      body: data,
      method: 'POST',
    });
    return this.decodeJsonPackt(promise.res, url, promise.currentTimes);
  }

  async postJson<T = any>(url: string, data: any = '', options?: RequestInit): Promise<T> {
    const result = await this.post(url, data, options);
    return this.decodeJsonPackt(result.res, url, result.currentTimes);
  }

  /** get */
  get(url: string, options?: RequestInit) {
    return this.fetch(url, {
      method: 'GET',
      ...options,
    });
  }

  getAsUrl(url: string, data: { [key: string]: string | number } = {}) {
    return this.baseUrl + url + `?${this.toUrlQuery(data)}`;
  }

  async getJson<T = any>(url: string, options?: RequestInit): Promise<T> {
    return this.decodeJsonPackt((await this.get(url, options)).res, url);
  }

  toUrlQuery(a: { [key: string]: string | number }) {
    const vs: string[] = [];
    for (const key in a) {
      const value = a[key];
      vs.push(`${encodeURI(key)}=${encodeURI(value + '')}`);
    }
    return vs.join('&');
  }

  async getBodyJson<T = any>(
    url: string,
    data: { [key: string]: string | number } = {},
    options?: RequestInit
  ): Promise<T> {
    return this.decodeJsonPackt(
      (await this.get(url + `?${this.toUrlQuery(data)}`, options)).res,
      url
    );
  }

  async fetchJson<T = any>(url: string, options?: RequestInit): Promise<T> {
    return this.decodeJsonPackt((await this.fetch(url, options)).res, url);
  }

  private async decodeJsonPackt(req: Response, url: string, currentTimes?: number): Promise<any> {
    const res = req;
    let packt = (await res.json()) as Packt;

    // 获取响应请求头中的 Authorization
    const token = res.headers.get('Authorization');
    // 如果有Authorization，则存入localStorage 模拟cookie
    if (token) {
      const tk = localStorage.getItem('Authorization') || JSON.stringify('');
      if (JSON.parse(tk as string) !== token) {
        localStorage.setItem('Authorization', JSON.stringify(token));
        newVersionNavigate(token);
        return { flag: true };
      }
    }

    try {
      // decrypt
      if (packt && typeof packt === 'string') {
        packt = JSON.parse(decrypt(packt, currentTimes));
      }
    } catch (error) {
      throw new Error('Decryption failed');
    }

    if (!packt?.data && (packt as any)?.Data) {
      packt.data = (packt as any).Data;
    }

    if (!packt?.code && (packt as any)?.Code) {
      packt.code = (packt as any).Code;
    }

    if (!packt?.msg && (packt as any)?.Msg) {
      packt.msg = (packt as any).Msg;
    }

    if (this.baseUrl.includes('/merchant/v1')) {
      // 10040、224002 Temporarily abandoned
      if ([10040, 224002, 224035, 224114, 10072, 225112].includes(packt.code)) {
        console.log(packt.code);

        const msg = this.getResponseErrorMsg(packt);
        jumpPage(msg, packt.code, packt);
        return;
      }

      // Login expired, you need to jump to the login page to log in again
      // if ([10072].includes(packt.code)) {
      //   localStorage.setItem('isLogin', 'false');
      //   localStorage.setItem('isVerify', 'false');
      //   enqueueSnackbar('Login Expired', { variant: 'warning' });
      //   await sleep(3000);
      //   window.location.reload();
      //   return;
      // }

      // 10072 Do not prompt this error after automatic logout
      if (![10000].includes(packt.code)) {
        const msg = this.getResponseErrorMsg(packt);
        throw new HttpResponseError(msg, packt.code, packt);
      }
    } else {
      // /merchant/console/v1
      if ([10012].includes(packt.code)) {
        const msg = this.getResponseErrorMsg(packt);
        walletJumpPage(msg, packt.code, packt);
        return;
      }
      if (![10000].includes(packt.code)) {
        const msg = this.getResponseErrorMsg(packt);
        throw new HttpResponseError(msg, packt.code, packt);
      }
    }

    for (let fn of this.resInterceptors) {
      packt = fn(packt);
    }
    // Prevent the interface from being too fast, flashing loading
    await sleep(100);
    return packt.data;
  }
}

// get device information
async function getDeviceInfo() {
  let outIp = '';
  const { userAgent } = navigator;
  // get browser name and version
  let browserName = '';
  let browserVersion = '';
  // get operating system name
  let osName = '';
  // get OS version
  let osVersion = '';
  let browserKernel = '';
  if (userAgent) {
    if (/MSIE/i.test(userAgent)) {
      browserName = 'Internet Explorer';
      browserVersion = userAgent.match(/MSIE (\d+)/)?.[1] || '';
    } else if (/Firefox/i.test(userAgent)) {
      browserName = 'Firefox';
      browserVersion = userAgent.match(/Firefox\/(\d+)/)?.[1] || '';
    } else if (/Chrome/i.test(userAgent)) {
      browserName = 'Chrome';
      browserVersion = userAgent.match(/Chrome\/(\d+(\.\d+)+)/i)?.[1] || '';
    } else if (/Safari/i.test(userAgent)) {
      browserName = 'Safari';
      browserVersion =
        userAgent.match(/Version\/(\d+)/)?.[1] ||
        userAgent.match(/Safari\/(\d+(\.\d+)+)/i)?.[1] ||
        '';
    }

    if (/Windows/i.test(userAgent)) {
      osName = 'Windows';
    } else if (/Macintosh|Mac OS X/i.test(userAgent)) {
      osName = 'Mac OS X';
    } else if (/Linux/i.test(userAgent)) {
      osName = 'Linux';
    } else if (/Android/i.test(userAgent)) {
      osName = 'Android';
    } else if (/iOS/i.test(userAgent)) {
      osName = 'iOS';
    } else if (/Windows Phone/i.test(userAgent)) {
      osName = 'Windows Phone';
    }

    if (/Windows NT/i.test(userAgent)) {
      osVersion = userAgent.match(/Windows NT (\d+.\d+)/)?.[1] || '';
    } else if (/Mac OS X/i.test(userAgent)) {
      osVersion = userAgent.match(/Mac OS X (\d+([._]\d+)+)/i)?.[1]
        ? userAgent
            .match(/Mac OS X (\d+([._]\d+)+)/i)![1]
            .split('_')
            .join('.')
        : '';
    } else if (/Linux/i.test(userAgent)) {
      osVersion = 'Unknown'; // Linux The version of the platform is not easy to get accurately, so set it to "Unknown" first
    } else if (/Android/i.test(userAgent)) {
      osVersion = userAgent.match(/Android (\d+.\d+)/)?.[1] || '';
    } else if (/iOS/i.test(userAgent)) {
      osVersion = userAgent.match(/OS (\d+_\d+)/)?.[1]
        ? userAgent.match(/OS (\d+_\d+)/)![1].replace('_', '.')
        : '';
    } else if (/Windows Phone/i.test(userAgent)) {
      osVersion = userAgent.match(/Windows Phone (\d+.\d+)/)?.[1] || '';
    }

    // Kernel analysis
    if (/AppleWebKit\/(\d+(\.\d+)+)/i.test(userAgent)) {
      const version = userAgent.match(/AppleWebKit\/(\d+(\.\d+)+)/i)?.[1] || '';
      browserKernel = `WebKit ${version}`;
    }
  }

  return {
    browserName,
    browserVersion,
    osName,
    osVersion,
    browserKernel,
    outIp,
  };
}

export class HttpResponseError extends Error {
  constructor(msg: string, public code: number, public response: any) {
    super(msg);
  }
}

const http = new Http('/merchant/v1');

export default http;

interface Packt {
  code: number;
  data: any;
  msg: string;
}
