import { walletApi } from '@/api';
import { NetworkType, Token } from '@/api/WalletApi';
import { coinSort, toLocalString, tryRun } from '@/utils/until';
import Decimal from 'decimal.js';
import { makeObservable, observable } from 'mobx';
import { accountStore } from '.';
import { token } from './staticData';
import { integerTokenArr } from '@/config';

type AmountNum = string | number | Decimal;

type GoSendOrder = (orderId: string) => void;
class WalletStroe {
  inited = false;

  tokens: Token[] = [];

  chains: NetworkType[] = [];

  tokenDict: Record<string, Token> = {};

  totalBalance = '0';

  isOpenTransaction = false;

  WithdrawBtnLoading = false;

  /**Because send is also a modal, you need to keep the navigate of the blance route */
  goSendOrder: GoSendOrder = null as any;

  constructor() {
    makeObservable(this, {
      inited: observable,
      tokens: observable,
      chains: observable,
      totalBalance: observable,
      isOpenTransaction: observable,
      WithdrawBtnLoading: observable,
    });
  }

  /**Return token object according to token_id*/
  getToken(token_id: string) {
    return this.tokenDict[token_id];
  }

  async setWithdrawBtnLoading(par: boolean) {
    this.WithdrawBtnLoading = par;
  }

  async setIsOpenTransaction(par: boolean) {
    this.isOpenTransaction = par;
  }

  async asyncWalletInfo() {
    this.tokens = token.tokens
      // eslint-disable-next-line
      .map((item: any) => {
        return {
          ...item,
          balance: 0,
          value: '0',
        };
      });

    this.inited = true;
    const { tokens } = await walletApi.getTokenList();
    const { lists } = await walletApi.getNetworkList();
    this.chains = lists;
    // The filter here is to filter out the eth token with only one chain
    this.tokens = tokens
      // eslint-disable-next-line
      .map((item) => {
        return { ...item, balance: 0, value: '0' };
      });

    this.tokens.forEach((item) => {
      this.tokenDict[item.token_id] = item;
    });

    await this.syncBalance();
  }

  async syncBalance() {
    if (!accountStore.isLogin() || !accountStore.isVerify()) return Promise.resolve(false);
    const { list, total_value } = (await walletApi.getTokenBlance()) || { list: [] };
    this.totalBalance = total_value;
    list?.forEach((item) => {
      const index = this.tokens.findIndex((j) => j.token_id === item.token_id) || 0;
      if (index < 0) return;
      this.tokens[index].balance = item.balance;
      this.tokens[index].price = item.price;
      this.tokens[index].value = item.value;
    });
    this.tokens.forEach((item) => {
      this.tokenDict[item.token_id] = item;
    });
    this.tokens = coinSort(this.tokens);
  }

  //(<= 8)Output precision control, if it is greater than 8 digits, 8 digits will be reserved, if it is less than 8 digits, the corresponding digits will be displayed, and the default is 8 digits
  formatePrecision(n: AmountNum, precision?: number, tokenSymbol?: string) {
    if (!isNaN(Number(n))) {
      let prec = precision && precision <= 8 ? precision : 8;
      if (tokenSymbol && integerTokenArr.includes(tokenSymbol)) {
        prec = 0;
      }
      return new Decimal(Number(n)).toFixed(prec, Decimal.ROUND_DOWN);
    }
    const num = new Decimal(n).toNumber();
    return num;
  }

  /**
   * (>= 8) formatted amount, number of tokens
   * Keep 8 digits after the decimal point, a total of 10 digits
   */
  formateAmount({
    num = 0,
    precision = 8,
    tokenSymbol = '',
  }: {
    num: AmountNum;
    precision?: number;
    tokenSymbol?: string;
  }) {
    return (
      tryRun(() => {
        const dec = new Decimal(num);
        const { length } = dec.abs().floor().toString();
        const pres = precision;
        precision = precision > 8 ? 9 : precision + 1;
        if (length >= precision) {
          let value = '';
          // Here it is necessary to judge whether it is greater than 1, and if it is greater than 1, directly retain 8 decimal places
          if (dec.greaterThan(1)) {
            value = dec.toFixed(8, Decimal.ROUND_DOWN);
          } else {
            value = dec.toFixed(precision - length, Decimal.ROUND_DOWN);
          }
          value = this.formatePrecision(value, pres).toString();
          // If you round up here, the decimal place will be removed
          // If it is a sats token, round up
          if (integerTokenArr.includes(tokenSymbol)) {
            value = new Decimal(value).toFixed(0, Decimal.ROUND_CEIL);
          }
          // Do thousandths
          let result = '';
          if (typeof value === 'string') {
            if (value.includes('.')) {
              result = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              result = toLocalString(value);
            }
          } else {
            result = value;
          }

          if (Number(num) === 0) {
            return result;
          } else {
            return dec.greaterThan(new Decimal(0)) ? result : '-' + result;
          }
        } else {
          let value = '';
          // Here it is necessary to judge whether it is greater than 1, and if it is greater than 1, directly retain 8 decimal places
          if (dec.greaterThan(1)) {
            value = dec.toFixed(8, Decimal.ROUND_DOWN);
          } else {
            value = dec.toFixed(precision - length, Decimal.ROUND_DOWN);
          }

          value = this.formatePrecision(value, pres).toString();
          // If it is a sats token, round up
          if (integerTokenArr.includes(tokenSymbol)) {
            value = new Decimal(value).toFixed(0, Decimal.ROUND_CEIL);
          }

          // Do thousandths
          let result = '';
          if (typeof value === 'string') {
            if (value.includes('.')) {
              result = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              result = toLocalString(value);
            }
          } else {
            result = value;
          }

          return result;
        }
      }) ?? '0.00'
    );
  }

  /**
   * Format the local fiat currency, with two decimal places by default
   */
  formateFaitAmount(num: AmountNum = 0) {
    return (
      tryRun(() => {
        const dec = new Decimal(num);
        const fnum = dec.toFixed(2, Decimal.ROUND_DOWN);
        // If it is greater than 1000, perform thousandths processing
        if (Number(num) >= 1000) {
          let result = this.toLocalString(fnum);
          if (!result.includes('.')) {
            result = result + '.00';
          }
          return result;
        } else {
          return fnum;
        }
      }) ?? '0.00'
    );
  }

  /**
   *string type fiat currency quantity add thousands separator
   *number type fiat currency quantity add thousands separator use Number.prototype.toLocalString
   * @param {string} num
   * @param {string} delimiter
   * @param {number} size
   * @return {*}  {string}
   * @memberof WalletStore
   */
  toLocalString(num: string | number) {
    return Number(num).toLocaleString('en-US');
  }
}
export default WalletStroe;
