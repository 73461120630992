import { Http } from '@/api/http';
const http = new Http('/merchant/console/v1');

export default class overviewApi {
  /**
   * Get asset map
   * @param CoinIds []string
   * @param Days number
   * @returns {string}
   */
  getCoinBalanceCharts(data: getCoinBalanceChartsParamType) {
    return http.postJson('/merchant/balance/statistics', { ...data });
  }

  /**
   * Get Order Volume Graph
   * @param Days number
   * @returns {string}
   */
  getCoinOrderCharts(data: getCoinOrderChartsParamType) {
    return http.postJson('/merchant/orders/statistics', { ...data });
  }

  /**
   * Get Easy Merchant coins list 
   * @returns {string}
   */
  getMerchantSimple() {
    return http.postJson('/merchant/coin/simple/list', {});
  }

  /**
   * Get Home Page Assets
   * @returns {string}
   */
  getMerchantBalance() {
    return http.postJson('/merchant/coin/balance', {});
  }

  /**
   * Get home page 24h assets
   * @returns {string}
   */
  getMerchantOrderStat() {
    return http.postJson('/merchant/coin/order/stat', {});
  }
}

export type OverviewTopInfoType = {
  Deposit: todayOtherParamType;
  Withdraw: todayOtherParamType;
};

export type OverviewTopBalanceType = {
  TotalValue: string;
  List: {
    MerchantId: string;
    CoinId: string;
    Symbol: string;
    Amount: string; //quantity
    Price: string; // coin price
    Value: string; // Convert usdt money
  }[];
};

export interface coinListRes {
  List: {
    Coin: {
      Id: string;
      Symbol: string;
    };
    MerchantId: string;
    Symbol: string;
    Name: string;
    Fee: string;
    Balance: string;
    Status: number;
    LogoUrl: string;
  }[];
}

type todayOtherParamType = {
  H24: {
    Num: number;
    Value: string;
  };
  Month: {
    Num: number;
    Value: string;
  };
  Today: {
    Num: number;
    Value: string;
  };
};

type getCoinBalanceChartsParamType = {
  CoinIds: string[];
  Days: number;
};

type getCoinOrderChartsParamType = {
  Days: number;
};
