import { fDate, handlerDateDurationCurrent } from '@/utils/formatTime';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useLocales } from '@/locales';
import { MyIcon } from '@/assets/icons/MyIcon';
import { NotificationItemProps } from '@/layouts/dashboard/header/WalletNotificationsPopover';
import { walletNotificationEventEnum } from '@/pages/WalletApi/pages/MerchantSetting/notificationSettings';

type Porps = {
  open: boolean;
  onClose: (v: number) => void;
  notification: NotificationItemProps;
};

export default function WalletNotifyMessageDialog({ open, onClose, notification }: Porps) {
  // const nav = useNavigate();
  // const { state } = useLocation();
  const { translate: t } = useLocales();

  // const notification: NotificationItemProps = useMemo(() => {
  //   return state.notificationData || {};
  // }, [state]);

  const getTime = (
    timeObj: {
      days?: number;
      hours?: number;
      mins?: number;
      Seconds?: number;
      soureDate: number;
    },
    translate: (val: string) => string,
    format?: string
  ) => {
    // console.log(timeObj);
    if (timeObj?.days) {
      return `${timeObj.days} ${translate('tradingOrer.day_ago')}`;
    }
    if (timeObj?.hours) {
      if (timeObj?.hours > 1) {
        return `${timeObj.hours} ${translate('tradingOrer.hours_ago')}`;
      } else {
        return `${timeObj.hours} ${translate('tradingOrer.hour_ago')}`;
      }
    }
    if (timeObj?.mins) {
      if (timeObj.mins > 1) {
        return `${timeObj.mins} ${translate('tradingOrer.mins_ago')}`;
      } else {
        return `${timeObj.mins} ${translate('tradingOrer.min_ago')}`;
      }
    }
    if ((timeObj?.Seconds as number) >= 0) {
      return `1 ${translate('tradingOrer.min_ago')}`;
    }
  };
  return (
    <Dialog
      open={open}
      scroll="paper"
      sx={{ width: 480, mx: 'auto' }}
      PaperProps={{
        sx: { m: 0, width: '100%' },
      }}
    >
      <DialogTitle sx={{ py: 3, pr: 2 }}>
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {notification?.title}
          </Typography>
          <IconButton
            onClick={() => {
              onClose(0);
            }}
          >
            <MyIcon name="close" size={12} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#B6B8B0',
              lineHeight: '20px',
              letterSpacing: '0.1px',
              wordBreak: 'break-all',
              mb: 2,
            }}
          >
            {fDate(notification.createdAt, 'dd MMM yyyy kk:mm:ss')}{' '}
            <span>
              (
              {getTime(
                {
                  ...handlerDateDurationCurrent(notification.createdAt.getTime()),
                  soureDate: notification.createdAt.getTime(),
                },
                t
              )}
              )
            </span>
          </Typography>

          {/* <Typography variant="body2" color="text.secondary" gutterBottom>
            {notification?.createdAt.toUTCString()}({fToNow(notification?.createdAt.valueOf() ?? 0)}
            )
          </Typography> */}

          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#27291E',
              lineHeight: '20px',
              letterSpacing: '0.25px',
              wordBreak: 'break-all',
              mb: 2,
            }}
          >
            {notification.description}
          </Typography>

          <RenderButton notification={notification} onClose={onClose} />

          <Typography
            variant="body2"
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#B6B8B0',
              lineHeight: '20px',
              letterSpacing: '0.25px',
              wordBreak: 'break-all',
            }}
          >
            {t('wallet.common.Notification.footer')}
          </Typography>
        </>
      </DialogContent>
    </Dialog>
  );
}

function RenderButton({
  notification,
  onClose,
}: {
  notification: NotificationItemProps;
  onClose: (v: number) => void;
}) {
  const { translate: t } = useLocales();
  let Content = <></>;
  switch (notification.source.Event) {
    case walletNotificationEventEnum.BalanceInsufficient:
      Content = (
        <Box mb={2}>
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '26px',
            }}
            onClick={() => {
              // nav(-1);
              onClose(1);
              // nav(`${location.pathname}/wallet-deposit`, {
              //   state: {
              //     backgroundLocation: location.state.backgroundLocation,
              //   },
              // });
            }}
          >
            {t('wallet.common.Notification.btn1')}
          </Button>
        </Box>
      );
      break;
    case walletNotificationEventEnum.networkInsufficient:
      Content = (
        <Box mb={2}>
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '26px',
            }}
            onClick={() => {
              // nav(-1);
              onClose(3);
              // nav(`${location.pathname}/wallet-deposit`, {
              //   state: {
              //     backgroundLocation: location.state.backgroundLocation,
              //   },
              // });
            }}
          >
            {t('wallet.common.Notification.btn1')}
          </Button>
        </Box>
      );
      break;
    case walletNotificationEventEnum.WebhookResponseFailed:
      Content = (
        <Box mb={2}>
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '26px',
            }}
            onClick={() => {
              onClose(2);
            }}
          >
            {t('wallet.common.Notification.btn2')}
          </Button>
        </Box>
      );
      break;

    default:
      Content = <></>;
      break;
  }

  return Content;
}

// function renderText(key: string, text: string, notification: any) {
//   if (['email_change', 'mobile_change'].includes(notification.type)) {
//     const { mobile, email } = notification.source.content_params;
//     const keyword = mobile ?? email ?? '\u8fff';
//     const [prefix, suffix] = text.split(keyword);
//     if (suffix || suffix === '') {
//       return (
//         <Typography key={key} variant="body2" color="text.primary" lineHeight="20px">
//           {prefix}
//           <Typography variant="body2" color="primary.main" component="span">
//             {keyword}
//           </Typography>
//           {suffix}
//         </Typography>
//       );
//     }
//   }
//   return (
//     <Typography key={key} variant="body2" color="text.primary" lineHeight="20px">
//       {text || '\u2003'}
//     </Typography>
//   );
// }
