import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocales } from '@/locales';
import { formatChartsDate } from '@/utils/formatTime';
import { ColorButton } from '@/sections/tradingOrder/style';
import MultiSelectView from '@/components/static-date-range-picker';
import { yearMonthDayToNumber } from '@/components/static-date-range-picker/util';
import { MultiSelect, SingleSelect } from '@/components/static-date-range-picker/MultiSelectView';
import { getNewDate } from '@/utils/until';

const getDate = (date: number) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  return { year, month, day };
};

const ininDate = new Date().getTime();

type initConfigDateType = {
  year: number;
  month: number;
  day: number;
};

type Props = {
  confirmLabel?: string;
  selectDate: { startDate?: number; endDate?: number };
  onClose: ({ startDate, endDate }: { startDate?: number; endDate?: number }) => void;
  minDate?: string;
};

const getSelectDate = (params: { year: number; month: number; day: number }) =>
  `${params.year}-${params.month}-${params.day}`;

export default function PopoverSelectDate({ confirmLabel, selectDate, minDate, onClose }: Props) {
  const start = selectDate?.startDate || ininDate;
  const end = selectDate?.endDate || ininDate;
  const initConfigDate: initConfigDateType[] = [
    {
      year: getDate(start).year,
      month: getDate(start).month,
      day: getDate(start).day,
    },
    {
      year: getDate(end).year,
      month: getDate(end).month,
      day: getDate(end).day,
    },
  ];
  const { translate } = useLocales();
  const [configDate, setConfigDate] = useState(
    selectDate?.startDate
      ? [{ ...getDate(selectDate.startDate) }, { ...getDate(selectDate.endDate || 0) }]
      : [...initConfigDate]
  );
  const [tempDate, setTempDate] = useState([...initConfigDate]);
  return (
    <>
      <MultiSelectView
        config={[configDate?.[0], configDate?.[1]]}
        minDate={new Date(minDate || getNewDate('before', 12)).getTime()}
        setConfig={(value) => {
          setConfigDate([...value] as MultiSelect);
          if (value.length === 1) {
            tempDate[0] = { ...value[0] } as SingleSelect;
            setTempDate([...tempDate]);
          } else {
            setTempDate(value as MultiSelect);
          }
        }}
        allowSelect={{
          isConsequent: true,
          allowSelect: (yearMonth, day) => {
            const minDa = new Date(minDate || getNewDate('before', 12));
            const min = yearMonthDayToNumber(
              minDa.getFullYear(),
              minDa.getMonth() + 1,
              minDa.getDate()
            );
            const showTime = new Date(`${yearMonth.year}-${yearMonth.month}-${day}`);
            const sh = yearMonthDayToNumber(
              showTime.getFullYear(),
              showTime.getMonth() + 1,
              showTime.getDate()
            );
            const currentDate = new Date();
            const cur = yearMonthDayToNumber(
              currentDate.getFullYear(),
              currentDate.getMonth() + 1,
              currentDate.getDate()
            );

            if (sh < min || sh > cur) {
              return false;
            }
            return true;
          },
        }}
      />
      <Box sx={{ pl: 3, mt: 3 }}>
        <Typography
          sx={{
            color: '#919EAB',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0.5px',
            mb: 1,
          }}
        >
          {translate('common.date_time_range')}
        </Typography>
        <Typography
          sx={{
            color: '#1E1E1E',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
          }}
        >
          {tempDate?.[0] &&
            formatChartsDate(new Date(getSelectDate(tempDate?.[0])).getTime() / 1000, 'dd MMM yyyy')}{' '}
          {'-'}{' '}
          {tempDate?.[1] && formatChartsDate(new Date(getSelectDate(tempDate?.[1])).getTime() / 1000, 'dd MMM yyyy')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 4,
          mr: 2,
          mb: 2,
        }}
      >
        <ColorButton
          sx={{ color: '#1E1E1E', fontWeight: 500, mr: 1, height: '30px' }}
          onClick={() => {
            onClose({});
          }}
        >
          {translate('common.Cancel')}
        </ColorButton>
        <Button
          variant="contained"
          sx={{ fontWeight: 500, height: '30px' }}
          disabled={configDate.length <= 1}
          onClick={() => {
            if (configDate.length > 1) {
              const startDate = new Date(
                `${configDate[0]?.year}/${configDate[0]?.month}/${configDate[0]?.day} 00:00:01`
              ).getTime();
              const endDate = new Date(
                `${configDate[1]?.year}/${configDate[1]?.month}/${configDate[1]?.day} 23:59:59`
              ).getTime();

              onClose({
                startDate: startDate,
                endDate: endDate,
              });
            }
          }}
        >
          {confirmLabel ? confirmLabel : translate('common.Export')}
        </Button>
      </Box>
    </>
  );
}
