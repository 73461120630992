export const token = {
  chains: {
    AVAX: 'AVAX',
    BCH: 'BCH',
    BSC: 'BSC',
    BSV: 'BSV',
    BTC: 'BTC',
    CELO: 'CELO',
    DASH: 'DASH',
    DCR: 'DCR',
    DGB: 'DGB',
    DOGE: 'DOGE',
    DOT: 'DOT',
    EGLD: 'EGLD',
    ETC: 'ETC',
    ETH: 'ETH',
    FTM: 'FTM',
    ICON: 'ICON',
    IOTX: 'IOTX',
    KSM: 'KSM',
    LTC: 'LTC',
    NEAR: 'NEAR',
    OKT: 'OKT',
    ONE: 'ONE',
    OPTIMISM: 'OPTIMISM',
    POLYGON: 'POLYGON',
    RVN: 'RVN',
    SOL: 'SOL',
    TEZOS: 'TEZOS',
    TOMO: 'TOMO',
    TRX: 'TRX',
    VET: 'VET',
    WAVES: 'WAVES',
    ZIL: 'ZIL',
  },
  chain_logos: {
    AVAX: 'https://resource.cwallet.com/token/icon/avax.png',
    BCH: 'https://resource.cwallet.com/token/icon/bch.png',
    BSC: 'https://resource.cwallet.com/token/icon/wbnb.png',
    BSV: 'https://resource.cwallet.com/token/icon/bsv.png',
    BTC: 'https://resource.cwallet.com/token/icon/btc.png',
    CELO: 'https://resource.cwallet.com/token/icon/celo.png',
    DASH: 'https://resource.cwallet.com/token/icon/dash.png',
    DCR: 'https://resource.cwallet.com/token/icon/dcr.png',
    DGB: 'https://resource.cwallet.com/token/dgb.png',
    DOGE: 'https://resource.cwallet.com/token/icon/doge.png',
    DOT: 'https://resource.cwallet.com/token/icon/dot.png',
    EGLD: 'https://resource.cwallet.com/token/icon/egld.png',
    ETC: 'https://resource.cwallet.com/token/icon/etc.png',
    ETH: 'https://resource.cwallet.com/token/icon/eth.png',
    FTM: 'https://resource.cwallet.com/token/icon/ftm.png',
    ICON: 'https://resource.cwallet.com/token/icx.png',
    IOTX: 'https://resource.cwallet.com/token/icon/iotx.png',
    KSM: 'https://resource.cwallet.com/token/icon/ksm.png',
    LTC: 'https://resource.cwallet.com/token/icon/ltc.png',
    NEAR: 'https://resource.cwallet.com/token/icon/near.png',
    OKT: 'https://resource.cwallet.com/token/icon/okt.png',
    ONE: 'https://resource.cwallet.com/token/icon/one.png',
    OPTIMISM: 'https://resource.cwallet.com/token/op.png',
    POLYGON: 'https://resource.cwallet.com/token/icon/matic.png',
    RVN: 'https://resource.cwallet.com/token/rvn.png',
    SOL: 'https://resource.cwallet.com/token/icon/sol.png',
    TEZOS: 'https://resource.cwallet.com/token/xtz.png',
    TOMO: 'https://resource.cwallet.com/token/icon/tomo.png',
    TRX: 'https://resource.cwallet.com/token/icon/trx.png',
    VET: 'https://resource.cwallet.com/token/vet.png',
    WAVES: 'https://resource.cwallet.com/token/icon/waves.png',
    ZIL: 'https://resource.cwallet.com/token/icon/zil.png',
  },
  tokens: [
    {
      id: 'f36ad1cf-222a-4933-9ad0-86df8069f916',
      chain_id: 'BTC',
      token_id: 'f36ad1cf-222a-4933-9ad0-86df8069f916',
      name: 'Bitcoin',
      symbol: 'BTC',
      status: 1,
      contract: 'BTC',
      price: '23392.51',
      market_cap: '755720654897.2347',
      fee: '0',
      min: '0.00008',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/btc.png',
      official_site: 'https://www.bitcoin.org/',
      community: 'https://reddit.com/r/bitcoin',
      precision: 8,
      explorer: 'https://blockchain.info',
      type: 'NATIVE',
      type_symbol: 'BTC',
      change24: '-1.528690410000000',
      CreateTime: '2021-09-10 06:39:50',
      is_support_memo: false,
      payload: {
        txid_url: 'https://btc.com/btc/transaction/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/btc.png',
        chain_display_network: 'Bitcoin',
        chain_network_desc: 'Bitcoin',
      },
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/btc.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/btc.png',
      address_regex: '^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$',
    },
    {
      id: 'e660b0fd-a1b7-430d-a558-8cbfaf7302c9',
      chain_id: 'BTC',
      token_id: 'e660b0fd-a1b7-430d-a558-8cbfaf7302c9',
      name: 'Satoshi',
      symbol: 'SATS',
      status: 1,
      contract: 'SATS',
      price: '0.0002789098',
      market_cap: '526892781833.57086',
      fee: '0',
      min: '10',
      max: '100000000',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/sats.png',
      community: '{"twitter":"","facebook":"","github":"","reddit":""}',
      explorer: 'https://blockchain.info',
      type: 'LND',
      type_symbol: 'SATS',
      change24: '0.000000000000000',
      CreateTime: '2021-09-29 03:22:32',
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/btc.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/sats.png',
    },
    {
      id: 'e8f64d3d-df5b-411d-897f-c6d8d30206b7',
      chain_id: 'V-CC',
      token_id: 'e8f64d3d-df5b-411d-897f-c6d8d30206b7',
      name: 'Ethereum',
      symbol: 'ETH',
      status: 1,
      contract: 'ETH',
      price: '1642.95',
      market_cap: '516333257070.6673',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/ETH.png',
      official_site: 'https://www.ethereum.org/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-ETH',
      change24: '-0.602706840000000',
      CreateTime: '2021-11-17 09:23:25',
      merge_chains: ['OPTIMISM', 'ETH', 'BSC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/ETH.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/eth.png',
      merge_tokens: [
        {
          id: '1e614496-f9ce-4ec7-8e68-563733deacbf',
          chain_id: 'BSC',
          token_id: '1e614496-f9ce-4ec7-8e68-563733deacbf',
          name: 'Ethereum',
          symbol: 'ETH',
          status: 1,
          contract: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
          price: '1642.95',
          market_cap: '516333257070.6673',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ETH.png',
          official_site: 'https://www.ethereum.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description: 'open source platform to write and distribute decentralized applications.',
          type_symbol: 'BEP20-ETH',
          CreateTime: '2021-11-16 02:30:57',
          merged_token_id: 'e8f64d3d-df5b-411d-897f-c6d8d30206b7',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/eth.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '8addd19b-37df-4faf-bd74-e61e214b008a',
          chain_id: 'ETH',
          token_id: '8addd19b-37df-4faf-bd74-e61e214b008a',
          name: 'Ethereum',
          symbol: 'ETH',
          status: 1,
          contract: '1',
          price: '1642.95',
          market_cap: '355419676871.40796',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ETH.png',
          official_site: 'https://www.ethereum.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'NATIVE',
          description: 'Open source platform to write and distribute decentralized applications.',
          type_symbol: 'ETH',
          CreateTime: '2021-09-10 06:39:40',
          merged_token_id: 'e8f64d3d-df5b-411d-897f-c6d8d30206b7',
          is_support_memo: false,
          payload: {
            txid_url: 'https://etherscan.io/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/eth.png',
            chain_display_network: 'ERC20',
            chain_network_desc: 'Ethereum',
          },
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/eth.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'f36770ed-d8fd-4a21-8141-83478fa44e05',
          chain_id: 'OPTIMISM',
          token_id: 'f36770ed-d8fd-4a21-8141-83478fa44e05',
          name: 'Optimism',
          symbol: 'ETH',
          status: 1,
          contract: '10',
          price: '1642.95',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ETH.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://optimistic.etherscan.io',
          type: 'NATIVE',
          description: 'Optimism is a Layer 2 scaling solution for Ethereum.',
          type_symbol: 'ETH',
          CreateTime: '2022-11-02 08:28:09',
          merged_token_id: 'e8f64d3d-df5b-411d-897f-c6d8d30206b7',
          is_support_memo: false,
          payload: {
            txid_url: 'https://optimistic.etherscan.io/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/op.png',
            chain_display_network: 'OPTIMISM',
            chain_network_desc: 'Optimism',
          },
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/icon/ETH.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '038073e6-a784-4e3e-b6a7-36456a39055f',
      chain_id: 'V-CC',
      token_id: '038073e6-a784-4e3e-b6a7-36456a39055f',
      name: 'Binance',
      symbol: 'BNB',
      status: 1,
      contract: 'BNB',
      price: '297.8',
      market_cap: '98891017276.27516',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/wbnb.png',
      official_site: 'https://www.binance.com/',
      community: 'https://twitter.com/binance',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-BNB',
      change24: '-1.823791900000000',
      CreateTime: '2021-11-17 06:33:32',
      merge_chains: ['BSC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/wbnb.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/bnb.png',
      merge_tokens: [
        {
          id: '7d1ae157-6f3e-4d41-a3ac-519efecd6175',
          chain_id: 'BSC',
          token_id: '7d1ae157-6f3e-4d41-a3ac-519efecd6175',
          name: 'Binance',
          symbol: 'BNB',
          status: 1,
          contract: '56',
          price: '297.8',
          market_cap: '68027738623.457214',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/wbnb.png',
          official_site: 'https://www.binance.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'NATIVE',
          description:
            'A blockchain with a full-fledged environment for developing high-performance decentralized applications and cross-chain compatibility with Binance Chain.',
          type_symbol: 'BNB',
          CreateTime: '2021-09-10 06:39:35',
          merged_token_id: '038073e6-a784-4e3e-b6a7-36456a39055f',
          is_support_memo: false,
          payload: {
            txid_url: 'https://bscscan.com/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/wbnb.png',
            chain_display_network: 'BEP20',
            chain_network_desc: 'Binance Smart Chain',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/bnb.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
      chain_id: 'V-CC',
      token_id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
      name: 'Tether USD',
      symbol: 'USDT',
      status: 1,
      contract: 'USDT',
      price: '1',
      market_cap: '70443762414.92084',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/usdt.png',
      official_site: 'https://tether.to/',
      community: 'https://twitter.com/tether_to',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-USDT',
      change24: '-0.005926680000000',
      CreateTime: '2021-11-08 08:03:52',
      merge_chains: ['TRX', 'POLYGON', 'SOL', 'BSC', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/usdt.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/usdt.png',
      merge_tokens: [
        {
          id: '264f4725-3cfd-4ff6-bc80-ff9d799d5fb2',
          chain_id: 'ETH',
          token_id: '264f4725-3cfd-4ff6-bc80-ff9d799d5fb2',
          name: 'Tether USD',
          symbol: 'USDT',
          status: 1,
          contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
          price: '1',
          market_cap: '83171094813.24289',
          fee: '0',
          min: '0.000001',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdt.png',
          official_site: 'https://tether.to/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          description:
            'usdt is a stablecoin (stable-value cryptocurrency) that mirrors the price of the u.s. dollar, issued by a hong kong-based company tether. the token’s peg to the usd is achieved via maintaining a sum of commercial paper, fiduciary deposits, cash, reserve re',
          type_symbol: 'ERC20-USDT',
          CreateTime: '2021-09-10 09:12:29',
          merged_token_id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdt.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '92b15088-7973-4813-b0f3-1895588a5df7',
          chain_id: 'BSC',
          token_id: '92b15088-7973-4813-b0f3-1895588a5df7',
          name: 'Tether USD',
          symbol: 'USDT',
          status: 1,
          contract: '0x55d398326f99059ff775485246999027b3197955',
          price: '1',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdt.png',
          official_site: 'https://tether.to/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'usdt is a stablecoin (stable-value cryptocurrency) that mirrors the price of the u.s. dollar, issued by a hong kong-based company tether. the token’s peg to the usd is achieved via maintaining a sum of commercial paper, fiduciary deposits, cash, reserve re',
          type_symbol: 'BEP20-USDT',
          CreateTime: '2021-11-16 02:30:55',
          merged_token_id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdt.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'fde35088-d2f8-4670-9f4d-7fd4c6b7dba1',
          chain_id: 'SOL',
          token_id: 'fde35088-d2f8-4670-9f4d-7fd4c6b7dba1',
          name: 'USDT',
          symbol: 'USDT',
          status: 1,
          contract: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
          price: '1',
          market_cap: '0',
          fee: '0',
          min: '0.005',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdt.png',
          official_site: 'https://tether.to/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://explorer.solana.com/',
          type: 'Solana',
          description:
            'usdt is a stablecoin (stable value cryptocurrency) that reflects the price of the u.s. dollar and is issued by hong kong-based company tether. the peg of the token to the u.s. dollar is achieved by maintaining a reserve of commercial paper, trust deposits,',
          type_symbol: 'Solana-USDT',
          CreateTime: '2021-11-16 03:17:11',
          merged_token_id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdt.png',
          address_regex: '^[1-9A-HJ-NP-Za-km-z]{35,44}$',
        },
        {
          id: 'f137d42c-f3a6-4f23-9402-76f0395d0cfe',
          chain_id: 'POLYGON',
          token_id: 'f137d42c-f3a6-4f23-9402-76f0395d0cfe',
          name: 'Tether USD',
          symbol: 'USDT',
          status: 1,
          contract: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
          price: '1',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdt.png',
          official_site: 'https://tether.to/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://polygonscan.com',
          type: 'Polygon',
          description:
            'usdt is a stablecoin (stable-value cryptocurrency) that mirrors the price of the u.s. dollar, issued by a hong kong-based company tether. the token’s peg to the usd is achieved via maintaining a sum of commercial paper, fiduciary deposits, cash, reserve re',
          type_symbol: 'Polygon-USDT',
          CreateTime: '2021-11-16 03:17:12',
          merged_token_id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdt.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '0912e09a-d8e2-41d7-a0bc-a25530892988',
          chain_id: 'TRX',
          token_id: '0912e09a-d8e2-41d7-a0bc-a25530892988',
          name: 'Tether USD',
          symbol: 'USDT',
          status: 1,
          contract: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
          price: '1',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdt.png',
          official_site: 'https://tron.network/usdt',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://tronscan.io/',
          type: 'TRC20',
          description:
            '"usdt is a stablecoin (stable-value cryptocurrency) that mirrors the price of the u.s. dollar, issued by a hong kong-based company tether. the token’s peg to the usd is achieved via maintaining a sum of commercial paper, fiduciary deposits, cash, reserve r',
          type_symbol: 'TRC20-USDT',
          CreateTime: '0001-01-01 00:00:00',
          merged_token_id: '8e5741cf-6e51-4892-9d04-3d40e1dd0128',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdt.png',
          address_regex: '^T[1-9A-HJ-NP-Za-km-z]{33}$',
          memo_regex: 'test',
        },
      ],
    },
    {
      id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
      chain_id: 'V-CC',
      token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
      name: 'USD Coin',
      symbol: 'USDC',
      status: 1,
      contract: 'USDC',
      price: '0.9998',
      market_cap: '34536775287.36522',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/usdc.png',
      official_site: 'https://www.centre.io/usdc',
      community: 'https://medium.com/centre-blog',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-USDC',
      change24: '-0.020199300000000',
      CreateTime: '2021-11-17 05:39:57',
      merge_chains: ['TRX', 'BSC', 'POLYGON', 'SOL', 'AVAX', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/usdc.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/usdc.png',
      merge_tokens: [
        {
          id: '5f3d9bd5-f8e0-4edd-a2ed-04fcac84bd92',
          chain_id: 'ETH',
          token_id: '5f3d9bd5-f8e0-4edd-a2ed-04fcac84bd92',
          name: 'USD Coin',
          symbol: 'USDC',
          status: 1,
          contract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          price: '0.9998',
          market_cap: '49926368214.10954',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdc.png',
          official_site: 'https://www.centre.io/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          description:
            'usd coin (known by its ticker usdc) is a stablecoin that is pegged to the u.s. dollar on a 1:1 basis. every unit of this cryptocurrency in circulation is backed up by $1 that is held in reserve, in a mix of cash and short-term u.s. treasury bonds. the cent',
          type_symbol: 'ERC20-USDC',
          CreateTime: '2021-09-10 06:50:42',
          merged_token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '5d8b7d45-0244-4e9e-a857-0fc282b870bf',
          chain_id: 'AVAX',
          token_id: '5d8b7d45-0244-4e9e-a857-0fc282b870bf',
          name: 'USD Coin',
          symbol: 'USDC',
          status: 1,
          contract: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
          price: '1.0002',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/USDC-1.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://snowtrace.io/',
          type: 'ERC20',
          type_symbol: 'ERC20-USDC',
          CreateTime: '2022-04-20 11:03:00',
          merged_token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'a18d0a76-ed9b-4d47-9699-81afb623d0a2',
          chain_id: 'SOL',
          token_id: 'a18d0a76-ed9b-4d47-9699-81afb623d0a2',
          name: 'USD Coin',
          symbol: 'USDC',
          status: 1,
          contract: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
          price: '0.9998',
          market_cap: '0',
          fee: '0',
          min: '0.005',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdc.png',
          official_site: 'https://www.centre.io/usdc',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://explorer.solana.com/',
          type: 'Solana',
          description:
            'usd coin (known by its ticker usdc) is a stablecoin that is pegged to the u.s. dollar on a 1:1 basis. every unit of this cryptocurrency in circulation is backed up by $1 that is held in reserve, in a mix of cash and short-term u.s. treasury bonds. the cent',
          type_symbol: 'Solana-USDC',
          CreateTime: '2021-11-16 03:17:16',
          merged_token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdc.png',
          address_regex: '^[1-9A-HJ-NP-Za-km-z]{35,44}$',
        },
        {
          id: 'c6b7c878-8a01-4f7f-8d2e-99a72787b1ac',
          chain_id: 'POLYGON',
          token_id: 'c6b7c878-8a01-4f7f-8d2e-99a72787b1ac',
          name: 'USD Coin',
          symbol: 'USDC',
          status: 1,
          contract: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
          price: '0.9998',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdc.png',
          official_site: 'https://www.centre.io/usdc',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://polygonscan.com',
          type: 'Polygon',
          description:
            'usd coin (known by its ticker usdc) is a stablecoin that is pegged to the u.s. dollar on a 1:1 basis. every unit of this cryptocurrency in circulation is backed up by $1 that is held in reserve, in a mix of cash and short-term u.s. treasury bonds. the cent',
          type_symbol: 'Polygon-USDC',
          CreateTime: '2021-11-16 03:17:17',
          merged_token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'e32aa751-e4d9-4303-b365-9dafac5a49ff',
          chain_id: 'BSC',
          token_id: 'e32aa751-e4d9-4303-b365-9dafac5a49ff',
          name: 'USD Coin',
          symbol: 'USDC',
          status: 1,
          contract: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
          price: '0.9998',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdc.png',
          official_site: 'https://www.centre.io/usdc',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'usd coin (known by its ticker usdc) is a stablecoin that is pegged to the u.s. dollar on a 1:1 basis. every unit of this cryptocurrency in circulation is backed up by $1 that is held in reserve, in a mix of cash and short-term u.s. treasury bonds. the cent',
          type_symbol: 'BEP20-USDC',
          CreateTime: '2021-11-16 02:31:03',
          merged_token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/usdc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '1174ad3a-d88b-442b-94af-380aa81c7429',
          chain_id: 'TRX',
          token_id: '1174ad3a-d88b-442b-94af-380aa81c7429',
          name: 'USD Coin',
          symbol: 'USDC',
          status: 1,
          contract: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8',
          price: '1.0002',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/usdc.png',
          precision: 6,
          explorer: 'https://tronscan.io/',
          type: 'TRC20',
          type_symbol: 'TRC20-USDC',
          CreateTime: '2022-11-13 15:39:50',
          merged_token_id: 'ce387d66-1c3b-4333-8bb7-7d6ad98ae9bb',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/icon/usdc.png',
          address_regex: '^T[1-9A-HJ-NP-Za-km-z]{33}$',
        },
      ],
    },
    {
      id: 'bc78e27d-540b-4756-a7fa-69930eca6322',
      chain_id: 'SOL',
      token_id: 'bc78e27d-540b-4756-a7fa-69930eca6322',
      name: 'Solana',
      symbol: 'SOL',
      status: 1,
      contract: 'SOL',
      price: '21.96',
      market_cap: '33917999322.207966',
      fee: '0',
      min: '0.005',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/sol.png',
      official_site: 'https://solana.com/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 9,
      explorer: 'https://explorer.solana.com/',
      type: 'NATIVE',
      type_symbol: 'SOL',
      change24: '-2.880381370000000',
      CreateTime: '2021-11-03 08:07:42',
      is_support_memo: false,
      payload: {
        txid_url: 'https://explorer.solana.com/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/sol.png',
        chain_display_network: 'SOL',
        chain_network_desc: 'Solana',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/sol.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/sol.png',
      address_regex: '^[1-9A-HJ-NP-Za-km-z]{35,44}$',
    },
    {
      id: '1a8b0fcc-2456-430c-9912-35f282385d7e',
      chain_id: 'V-CC',
      token_id: '1a8b0fcc-2456-430c-9912-35f282385d7e',
      name: 'Polkadot Token',
      symbol: 'DOT',
      status: 1,
      contract: 'DOT',
      price: '6.376',
      market_cap: '29847681982.3615',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/BDOT.png',
      official_site: 'https://polkadot.network/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-DOT',
      change24: '-2.452507950000000',
      CreateTime: '2021-11-17 09:05:46',
      merge_chains: ['DOT', 'BSC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/BDOT.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/dot.png',
      merge_tokens: [
        {
          id: 'e08b5354-8cbe-4a45-b602-719fbc876d7e',
          chain_id: 'BSC',
          token_id: 'e08b5354-8cbe-4a45-b602-719fbc876d7e',
          name: 'Polkadot Token',
          symbol: 'DOT',
          status: 1,
          contract: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
          price: '6.376',
          market_cap: '29847681982.3615',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/BDOT.png',
          official_site: 'https://polkadot.network/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          type_symbol: 'BEP20-DOT',
          CreateTime: '2021-11-16 02:31:01',
          merged_token_id: '1a8b0fcc-2456-430c-9912-35f282385d7e',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/dot.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'e714fce5-a5d2-47af-8f01-4558c3979434',
          chain_id: 'DOT',
          token_id: 'e714fce5-a5d2-47af-8f01-4558c3979434',
          name: 'Polkadot',
          symbol: 'DOT',
          status: 1,
          contract: 'DOT',
          price: '6.376',
          market_cap: '20986525804.13779',
          fee: '0',
          min: '1',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/BDOT.png',
          official_site: 'https://polkadot.network/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 10,
          explorer: 'http://polkascan.io',
          type: 'NATIVE',
          description:
            'Polkadot is a blockchain project that aims to connect blockchains, to enable the transfer of value and logic across chains. DOT is the native coin of the network.',
          type_symbol: 'DOT',
          CreateTime: '2021-09-03 08:25:39',
          merged_token_id: '1a8b0fcc-2456-430c-9912-35f282385d7e',
          is_support_memo: false,
          payload: {
            txid_url: 'https://polkadot.subscan.io/extrinsic/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/dot.png',
            chain_display_network: 'DOT',
            chain_network_desc: 'Polkadot',
          },
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/dot.png',
          address_regex: '^(1)[0-9a-z-A-Z]{44,50}$',
        },
      ],
    },
    {
      id: 'a2cd102e-0ade-40fe-b113-bbe300bcb4bd',
      chain_id: 'V-CC',
      token_id: 'a2cd102e-0ade-40fe-b113-bbe300bcb4bd',
      name: 'Dogecoin',
      symbol: 'DOGE',
      status: 1,
      contract: 'DOGE',
      price: '0.08075',
      market_cap: '23699546733.34625',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/doge.png',
      official_site: 'https://dogecoin.com/',
      community: 'https://twitter.com/dogecoin',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-DOGE',
      change24: '-1.884987780000000',
      CreateTime: '2021-11-17 08:56:15',
      merge_chains: ['DOGE', 'BSC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/doge.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/doge.png',
      merge_tokens: [
        {
          id: '4683fd24-365b-45f1-963c-b5cf0e55ca88',
          chain_id: 'BSC',
          token_id: '4683fd24-365b-45f1-963c-b5cf0e55ca88',
          name: 'Dogecoin',
          symbol: 'DOGE',
          status: 1,
          contract: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
          price: '0.08075',
          market_cap: '23699546733.34625',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/doge.png',
          official_site: 'https://dogecoin.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 8,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          type_symbol: 'BEP20-DOGE',
          CreateTime: '2021-11-16 02:31:02',
          merged_token_id: 'a2cd102e-0ade-40fe-b113-bbe300bcb4bd',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/doge.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '5c48ec51-c8d0-45cc-ace9-1a3860bd0e25',
          chain_id: 'DOGE',
          token_id: '5c48ec51-c8d0-45cc-ace9-1a3860bd0e25',
          name: 'Dogecoin',
          symbol: 'DOGE',
          status: 1,
          contract: 'DOGE',
          price: '0.08075',
          market_cap: '17815367775.3001',
          fee: '0',
          min: '1',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/doge.png',
          official_site: 'https://dogecoin.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 8,
          explorer: 'https://blockchair.com/dogecoin',
          type: 'NATIVE',
          description:
            'Dogecoin is an open source peer-to-peer digital currency, favored by Shiba Inus worldwide. Introduced as a joke currency on 6 December 2013, Dogecoin quickly developed its own online community.',
          type_symbol: 'DOGE',
          CreateTime: '2021-09-10 06:39:30',
          merged_token_id: 'a2cd102e-0ade-40fe-b113-bbe300bcb4bd',
          is_support_memo: false,
          payload: {
            txid_url: 'https://dogechain.info/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/doge.png',
            chain_display_network: 'DOGE',
            chain_network_desc: 'DogeCoin',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/doge.png',
          address_regex: '^(D|A|9)[a-km-zA-HJ-NP-Z1-9]{33,34}$',
        },
      ],
    },
    {
      id: '5443af86-50bb-4639-aa1e-a11d9b80a9dc',
      chain_id: 'V-CC',
      token_id: '5443af86-50bb-4639-aa1e-a11d9b80a9dc',
      name: 'Binance USD',
      symbol: 'BUSD',
      status: 1,
      contract: 'BUSD',
      price: '0.9998',
      market_cap: '12987411701.44059',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/busd.png',
      official_site: 'https://www.binance.com/en/busd',
      community: 'https://twitter.com/PaxosGlobal',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-BUSD',
      change24: '-0.010129660000000',
      CreateTime: '2021-11-17 07:58:53',
      merge_chains: ['BSC', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/busd.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/busd.png',
      merge_tokens: [
        {
          id: '2b1619d7-bd0e-48fa-879e-5bf21a1ae539',
          chain_id: 'ETH',
          token_id: '2b1619d7-bd0e-48fa-879e-5bf21a1ae539',
          name: 'Binance USD',
          symbol: 'BUSD',
          status: 1,
          contract: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
          price: '0.9998',
          market_cap: '17577106836.111214',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/busd.png',
          official_site: 'https://www.binance.com/en/busd',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          description:
            'Binance USD (BUSD) is a 1:1 USD-backed stable coin issued by Binance (in partnership with Paxos), Approved and regulated by the New York State Department of Financial Services (NYDFS), The BUSD Monthly Audit Report can be viewed from the official website.',
          type_symbol: 'ERC20-BUSD',
          CreateTime: '2021-11-16 02:31:09',
          merged_token_id: '5443af86-50bb-4639-aa1e-a11d9b80a9dc',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/busd.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'fc0a4d43-e34b-4fe9-a63c-73da6013e1ff',
          chain_id: 'BSC',
          token_id: 'fc0a4d43-e34b-4fe9-a63c-73da6013e1ff',
          name: 'BUSD Token',
          symbol: 'BUSD',
          status: 1,
          contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          price: '0.9998',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/busd.png',
          official_site: 'https://www.paxos.com/busd/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'binance usd (busd) is a 1:1 usd-backed stable coin issued by binance (in partnership with paxos), approved and regulated by the new york state department of financial services (nydfs), the busd monthly audit report can be viewed from the official website.',
          type_symbol: 'BEP20-BUSD',
          CreateTime: '2021-09-10 07:06:01',
          merged_token_id: '5443af86-50bb-4639-aa1e-a11d9b80a9dc',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/busd.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '92dce22f-2365-470b-8292-0b1dd78fc60c',
      chain_id: 'V-CC',
      token_id: '92dce22f-2365-470b-8292-0b1dd78fc60c',
      name: 'Litecoin Token',
      symbol: 'LTC',
      status: 1,
      contract: 'LTC',
      price: '97.36',
      market_cap: '11040491597.696812',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/ltc.png',
      official_site: 'https://litecoin.org/',
      community: 'https://twitter.com/LitecoinProject',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-LTC',
      change24: '0.041854130000000',
      CreateTime: '2021-11-17 11:34:30',
      merge_chains: ['LTC', 'BSC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/ltc.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/ltc.png',
      merge_tokens: [
        {
          id: 'c61bc3be-aedc-47c6-8adc-42edd17afabf',
          chain_id: 'BSC',
          token_id: 'c61bc3be-aedc-47c6-8adc-42edd17afabf',
          name: 'Litecoin Token',
          symbol: 'LTC',
          status: 1,
          contract: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
          price: '97.36',
          market_cap: '11040491597.696812',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ltc.png',
          official_site: 'https://litecoin.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world.',
          type_symbol: 'BEP20-LTC',
          CreateTime: '2021-11-16 02:31:04',
          merged_token_id: '92dce22f-2365-470b-8292-0b1dd78fc60c',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ltc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'e47fc216-dd3e-491b-b36d-7d797d1ee4f6',
          chain_id: 'LTC',
          token_id: 'e47fc216-dd3e-491b-b36d-7d797d1ee4f6',
          name: 'Litecoin',
          symbol: 'LTC',
          status: 1,
          contract: 'LTC',
          price: '97.36',
          market_cap: '7377883652.77607',
          fee: '0',
          min: '0.00008',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ltc.png',
          official_site: 'https://litecoin.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 8,
          explorer: 'https://blockchair.com/litecoin',
          type: 'NATIVE',
          description:
            'Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world.',
          type_symbol: 'LTC',
          CreateTime: '2021-09-10 06:39:36',
          merged_token_id: '92dce22f-2365-470b-8292-0b1dd78fc60c',
          is_support_memo: false,
          payload: {
            txid_url: 'https://blockchair.com/litecoin/transaction/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/ltc.png',
            chain_display_network: 'LTC',
            chain_network_desc: 'LiteCoin',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ltc.png',
          address_regex: '^(L|M)[A-Za-z0-9]{33}$|^(ltc1)[0-9A-Za-z]{39}$',
        },
      ],
    },
    {
      id: 'b2d3d3ac-4048-41de-b7af-c85b677ee223',
      chain_id: 'V-CC',
      token_id: 'b2d3d3ac-4048-41de-b7af-c85b677ee223',
      name: 'Bitcoin Cash Token',
      symbol: 'BCH',
      status: 1,
      contract: 'BCH',
      price: '132.5',
      market_cap: '8840653733.330162',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/bch.png',
      official_site: 'http://bch.info/',
      community: 'https://reddit.com/r/Bitcoincash',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-BCH',
      change24: '-1.757145310000000',
      CreateTime: '2021-11-17 06:16:03',
      merge_chains: ['BCH', 'BSC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/bch.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/bch.png',
      merge_tokens: [
        {
          id: '346a2569-039a-48f4-a98e-9970ad1c5646',
          chain_id: 'BSC',
          token_id: '346a2569-039a-48f4-a98e-9970ad1c5646',
          name: 'Bitcoin Cash Token',
          symbol: 'BCH',
          status: 1,
          contract: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
          price: '132.5',
          market_cap: '8840653733.330162',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/bch.png',
          official_site: 'http://bch.info/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'bitcoin abc is an electronic cash platform that allows peer-to-peer online cash payments. it is a fork (a copy in a way) of bitcoin (btc).',
          type_symbol: 'BEP20-BCH',
          CreateTime: '2021-11-16 02:31:11',
          merged_token_id: 'b2d3d3ac-4048-41de-b7af-c85b677ee223',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/bch.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'ec7623cd-53d6-4ded-8cce-af4c129e223e',
          chain_id: 'BCH',
          token_id: 'ec7623cd-53d6-4ded-8cce-af4c129e223e',
          name: 'Bitcoin Cash',
          symbol: 'BCH',
          status: 1,
          contract: 'BCH',
          price: '132.5',
          market_cap: '5982205696.247165',
          fee: '0',
          min: '0.00008',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/bch.png',
          official_site: 'https://www.bitcoincash.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 8,
          explorer: 'https://blockchair.com/bitcoin-cash',
          type: 'NATIVE',
          description:
            'Bitcoin ABC is an electronic cash platform that allows peer-to-peer online cash payments. It is a fork (a copy in a way) of Bitcoin (BTC).',
          type_symbol: 'BCH',
          CreateTime: '2021-09-10 06:39:09',
          merged_token_id: 'b2d3d3ac-4048-41de-b7af-c85b677ee223',
          is_support_memo: false,
          payload: {
            txid_url: 'https://www.blockchain.com/bch/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/bch.png',
            chain_display_network: 'BCH',
            chain_network_desc: 'Bitcoin Cash',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/bch.png',
          address_regex: '^[1][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[0-9a-z]{42,42}$',
        },
      ],
    },
    {
      id: 'e8879928-5727-4b5d-b02a-d18a57d03aa4',
      chain_id: 'V-CC',
      token_id: 'e8879928-5727-4b5d-b02a-d18a57d03aa4',
      name: 'SHIBA INU',
      symbol: 'SHIB',
      status: 1,
      contract: 'SHIB',
      price: '0.00000819',
      market_cap: '6214512791.103026',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/shib.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-SHIB',
      change24: '-3.256962710000000',
      CreateTime: '0001-01-01 00:00:00',
      merge_chains: ['BSC', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/shib.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/shib.png',
      merge_tokens: [
        {
          id: '3f91e631-935c-44ca-9b62-fb100e999d97',
          chain_id: 'ETH',
          token_id: '3f91e631-935c-44ca-9b62-fb100e999d97',
          name: 'SHIBA INU',
          symbol: 'SHIB',
          status: 1,
          contract: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
          price: '0.00001192',
          market_cap: '13340334127.421492',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/shib.png',
          official_site: 'https://shibatoken.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          description:
            'once upon a time, there was a very special dog. that dog was a shiba inu, and this dog inspired millions of people around the world to invest money into tokens with the dog\'s image on it.\n\naccording to the shiba inu website, shib is the "dogecoin killer" a',
          type_symbol: 'ERC20-SHIB',
          CreateTime: '0001-01-01 00:00:00',
          merged_token_id: 'e8879928-5727-4b5d-b02a-d18a57d03aa4',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/shib.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '6a9062c8-df3b-4b2a-8e9f-c009490dcb5f',
          chain_id: 'BSC',
          token_id: '6a9062c8-df3b-4b2a-8e9f-c009490dcb5f',
          name: 'SHIBA INU',
          symbol: 'SHIB',
          status: 1,
          contract: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
          price: '0.00000819',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/SHIB.png',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          type_symbol: 'BEP20-SHIB',
          CreateTime: '2022-07-07 10:21:10',
          merged_token_id: 'e8879928-5727-4b5d-b02a-d18a57d03aa4',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/shib.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '5245267d-7fb4-4911-bf70-6bb6e1aaf9b5',
      chain_id: 'V-CC',
      token_id: '5245267d-7fb4-4911-bf70-6bb6e1aaf9b5',
      name: 'Fantom Token',
      symbol: 'FTM',
      status: 1,
      contract: 'FTM',
      price: '0.4479',
      market_cap: '5528978170.508626',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/ftm.png',
      official_site: 'https://fantom.foundation/',
      community: 'https://twitter.com/FantomFDN',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-FTM',
      change24: '-1.224183240000000',
      CreateTime: '2021-11-17 09:30:19',
      merge_chains: ['BSC', 'FTM', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/ftm.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/ftm.png',
      merge_tokens: [
        {
          id: '164f4b1a-990f-48d7-a571-418dc487260c',
          chain_id: 'ETH',
          token_id: '164f4b1a-990f-48d7-a571-418dc487260c',
          name: 'Fantom Token',
          symbol: 'FTM',
          status: 1,
          contract: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
          price: '0.4479',
          market_cap: '2780535591.384389',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ftm.png',
          official_site: 'https://fantom.foundation/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          description:
            'fantom is a directed acyclic graph (dag) smart contract platform providing decentralized finance (defi) services to developers using its own bespoke consensus algorithm.\n\ntogether with its in-house token ftm, fantom aims to solve problems associated with s',
          type_symbol: 'ERC20-FTM',
          CreateTime: '2021-11-16 02:31:22',
          merged_token_id: '5245267d-7fb4-4911-bf70-6bb6e1aaf9b5',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ftm.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '54866b22-02d0-4e15-ba47-fdaa2ccac641',
          chain_id: 'FTM',
          token_id: '54866b22-02d0-4e15-ba47-fdaa2ccac641',
          name: 'Fantom',
          symbol: 'FTM',
          status: 1,
          contract: '250',
          price: '0.4479',
          market_cap: '536332133.1160489',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ftm.png',
          official_site: 'https://fantom.foundation/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://ftmscan.com/',
          type: 'NATIVE',
          description:
            'Fantom is a directed acyclic graph (DAG) smart contract platform providing decentralized finance (DeFi) services to developers using its own bespoke consensus algorithm.',
          type_symbol: 'FTM',
          CreateTime: '2022-01-22 05:38:37',
          merged_token_id: '5245267d-7fb4-4911-bf70-6bb6e1aaf9b5',
          is_support_memo: false,
          payload: {
            txid_url: 'https://ftmscan.com/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/ftm.png',
            chain_display_network: 'FTM',
            chain_network_desc: 'Fantom',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ftm.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '7bcd7608-6f1d-4ebe-80a2-b6f4f330029d',
          chain_id: 'BSC',
          token_id: '7bcd7608-6f1d-4ebe-80a2-b6f4f330029d',
          name: 'Fantom',
          symbol: 'FTM',
          status: 1,
          contract: '0xad29abb318791d579433d831ed122afeaf29dcfe',
          price: '0.4479',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ftm.png',
          official_site: 'https://fantom.foundation/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'Fantom is a directed acyclic graph (DAG) smart contract platform providing decentralized finance (DeFi) services to developers using its own bespoke consensus algorithm.',
          type_symbol: 'BEP20-FTM',
          CreateTime: '2021-11-16 02:31:23',
          merged_token_id: '5245267d-7fb4-4911-bf70-6bb6e1aaf9b5',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ftm.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '31e99c72-2788-438d-8c68-f106ecae35bd',
      chain_id: 'V-CC',
      token_id: '31e99c72-2788-438d-8c68-f106ecae35bd',
      name: 'Flow',
      symbol: 'FLOW',
      status: 1,
      contract: 'FLOW',
      price: '1.144',
      market_cap: '2070188933.815745',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/flow.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-FLOW',
      change24: '-3.916441980000000',
      CreateTime: '0001-01-01 00:00:00',
      merge_chains: ['FLOW'],
      is_support_memo: false,
      can_convert: false,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/flow.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/flow.png',
      merge_tokens: [
        {
          id: '446f2d8e-063f-4139-ba76-b9c4b605939c',
          chain_id: 'FLOW',
          token_id: '446f2d8e-063f-4139-ba76-b9c4b605939c',
          name: 'Flow',
          symbol: 'FLOW',
          status: 2,
          contract: 'FLOW',
          price: '1.144',
          market_cap: '2026912179.419297',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cctip.io/token/icon/flow.png',
          official_site: 'https://flow.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 8,
          explorer: 'https://flowscan.org/',
          type: 'NATIVE',
          description:
            '‍Flow is a fast, decentralized, and developer-friendly blockchain, designed as the foundation for a new generation of games, apps, and the digital assets that power them. It is based on a unique, multi-role architecture, and designed to scale without shard',
          type_symbol: 'FLOW',
          CreateTime: '2021-12-28 02:05:32',
          merged_token_id: '31e99c72-2788-438d-8c68-f106ecae35bd',
          is_support_memo: false,
          payload: {
            txid_url: 'https://flowscan.org/transaction/%s',
            chain_logo: 'https://resource.cctip.io/token/icon/flow.png',
            chain_display_network: 'FLOW',
            chain_network_desc: 'Flow',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/flow.png',
          address_regex: '^(0x)[0-9A-Fa-f]{16}$',
        },
      ],
    },
    {
      id: 'be1709f5-313b-42b1-b01c-f99b7cc524cb',
      chain_id: 'ZIL',
      token_id: 'be1709f5-313b-42b1-b01c-f99b7cc524cb',
      name: 'Zilliqa',
      symbol: 'ZIL',
      status: 1,
      contract: 'ZIL',
      price: '0.03044',
      market_cap: '1591618625.566687',
      fee: '0',
      min: '0.000000000001',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/zil.png',
      official_site: 'https://www.zilliqa.com/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 12,
      explorer: 'https://viewblock.io/zilliqa',
      type: 'NATIVE',
      type_symbol: 'ZIL',
      change24: '-4.169439830000000',
      CreateTime: '2021-09-10 06:39:48',
      is_support_memo: false,
      payload: {
        txid_url: 'https://viewblock.io/zilliqa/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/zil.png',
        chain_display_network: 'ZIL',
        chain_network_desc: 'Zilliqa',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/zil.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/zil.png',
      address_regex: 'zil1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{38}',
    },
    {
      id: '7e020b0a-cd31-467c-829b-265ee67eee89',
      chain_id: 'BSV',
      token_id: '7e020b0a-cd31-467c-829b-265ee67eee89',
      name: 'Bitcoin SV',
      symbol: 'BSV',
      status: 1,
      contract: 'BSV',
      price: '41.9365',
      market_cap: '1527555493.001606',
      fee: '0',
      min: '0.01',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/bsv.png',
      official_site: 'https://bitcoinsv.io/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 8,
      explorer: 'https://nile.tronscan.org/',
      type: 'NATIVE',
      type_symbol: 'BSV',
      change24: '0.359577510000000',
      CreateTime: '2021-09-10 06:39:42',
      is_support_memo: false,
      payload: {
        txid_url: 'https://bsv.tokenview.com/en/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/bsv.png',
        chain_display_network: 'BSV',
        chain_network_desc: 'Bitcoin SV',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/bsv.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/bsv.png',
    },
    {
      id: 'c98773c1-a04b-4523-a948-e2934645627b',
      chain_id: 'KSM',
      token_id: 'c98773c1-a04b-4523-a948-e2934645627b',
      name: 'kusama',
      symbol: 'KSM',
      status: 1,
      contract: 'KSM',
      price: '36.71',
      market_cap: '1465302434.3300607',
      fee: '0',
      min: '0.0001',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/ksm.png',
      official_site: 'https://kusama.network/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 12,
      explorer: 'https://kusama.subscan.io/',
      type: 'NATIVE',
      type_symbol: 'KSM',
      change24: '-3.165036340000000',
      CreateTime: '2021-10-13 07:13:30',
      is_support_memo: false,
      payload: {
        txid_url: 'https://kusama.subscan.io/extrinsic/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/ksm.png',
        chain_display_network: 'KSM',
        chain_network_desc: 'Kusama',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/ksm.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/ksm.png',
      address_regex: '^[0-9a-z-A-Z]{44,50}$',
    },
    {
      id: '4ea909a5-1e1a-4157-a0a2-0dee0b743f24',
      chain_id: 'VET',
      token_id: '4ea909a5-1e1a-4157-a0a2-0dee0b743f24',
      name: 'Vechain',
      symbol: 'VET',
      status: 1,
      contract: 'VET',
      price: '0.01612',
      market_cap: '1170041029.6030014',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/vet.png',
      official_site: 'https://www.vechain.org/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      explorer: 'https://explore.vechain.org/',
      type: 'NATIVE',
      type_symbol: 'VET',
      change24: '-4.630874250000000',
      CreateTime: '2022-04-01 07:40:06',
      is_support_memo: false,
      payload: {
        txid_url: 'https://vechainstats.com/transaction/%s/',
        chain_logo: 'https://resource.cwallet.com/token/vet.png',
        chain_display_network: 'VET',
        chain_network_desc: 'VeChain',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/vet.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/vet.png',
      address_regex: '^(0x)[0-9A-Fa-f]{40}$',
    },
    {
      id: 'b5ca0dda-1997-4ac2-b1f8-b3c0c54c660d',
      chain_id: 'DASH',
      token_id: 'b5ca0dda-1997-4ac2-b1f8-b3c0c54c660d',
      name: 'Dash',
      symbol: 'DASH',
      status: 1,
      contract: 'DASH',
      price: '73.1',
      market_cap: '1090258952.1968246',
      fee: '0',
      min: '0.01',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/dash.png',
      official_site: 'https://www.dash.org/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 8,
      explorer: 'https://blockchair.com/dash',
      type: 'NATIVE',
      type_symbol: 'DASH',
      change24: '-0.305101550000000',
      CreateTime: '2021-09-10 06:39:38',
      is_support_memo: false,
      payload: {
        txid_url: 'https://explorer.dash.org/insight/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/dash.png',
        chain_display_network: 'DASH',
        chain_network_desc: 'Dash',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/dash.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/dash.png',
      address_regex: '^[X|7][0-9A-Za-z]{33}$',
    },
    {
      id: '3b3f142e-d906-47af-8366-3aa8a67b3a0c',
      chain_id: 'V-CC',
      token_id: '3b3f142e-d906-47af-8366-3aa8a67b3a0c',
      name: 'IoTeX Network',
      symbol: 'IOTX',
      status: 1,
      contract: 'IOTX',
      price: '0.02721',
      market_cap: '701730423.5463461',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/iotxt.png',
      official_site: 'https://www.iotex.io/',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-IOTX',
      change24: '-1.799323100000000',
      CreateTime: '2022-01-22 08:45:32',
      merge_chains: ['IOTX'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/iotxt.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/iotx.png',
      merge_tokens: [
        {
          id: 'cb185706-c5dd-49f7-9cb2-c0e550410c71',
          chain_id: 'IOTX',
          token_id: 'cb185706-c5dd-49f7-9cb2-c0e550410c71',
          name: 'IoTeX',
          symbol: 'IOTX',
          status: 1,
          contract: '4689',
          price: '0.02095',
          market_cap: '197604664.59369874',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/iotx.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://iotexscan.io/',
          type: 'NATIVE',
          description:
            'IOTX will be a Decentralized Network for Internet of Things. Powered by a Privacy-Centric Blockchain.',
          type_symbol: 'IOTX',
          CreateTime: '2022-04-01 07:40:02',
          merged_token_id: '3b3f142e-d906-47af-8366-3aa8a67b3a0c',
          is_support_memo: false,
          payload: {
            txid_url: 'https://iotexscan.io/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/iotx.png',
            chain_display_network: 'IOTX',
            chain_network_desc: 'IoTeX',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/iotx.png',
          address_regex: 'io1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{38}',
        },
      ],
    },
    {
      id: 'b7a12199-6158-4c77-9810-3da2c91a2c6e',
      chain_id: 'OKT',
      token_id: 'b7a12199-6158-4c77-9810-3da2c91a2c6e',
      name: 'OEC Token',
      symbol: 'OKT',
      status: 1,
      contract: '66',
      price: '29.849910751604213',
      market_cap: '689090186.1437802',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/okt.png',
      official_site: 'https://www.okx.com/oec',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      explorer: 'https://www.oklink.com/en/oec',
      type: 'NATIVE',
      type_symbol: 'OKT',
      change24: '-2.877221670000000',
      CreateTime: '2021-12-28 02:05:17',
      is_support_memo: false,
      payload: {
        txid_url: 'https://www.oklink.com/en/oec/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/okt.png',
        chain_display_network: 'OKT',
        chain_network_desc: 'OKT-OKC',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/okt.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/okt.png',
    },
    {
      id: '66341905-7565-406f-b80e-5c3a6410aa46',
      chain_id: 'DCR',
      token_id: '66341905-7565-406f-b80e-5c3a6410aa46',
      name: 'Decred',
      symbol: 'DCR',
      status: 1,
      contract: 'DCR',
      price: '18.9',
      market_cap: '277583282.57322973',
      fee: '0',
      min: '0.001',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/dcr.png',
      official_site: 'https://decred.org/',
      community:
        '{"twitter":"https://twitter.com/decredproject","facebook":"https://www.facebook.com/1689453224670612","github":"https://github.com/decred","reddit":"https://reddit.com/r/decred","telegram":"","discord":"","medium":""}',
      precision: 8,
      explorer: 'https://dcrdata.decred.org/',
      type: 'NATIVE',
      type_symbol: 'DCR',
      change24: '-2.043309920000000',
      CreateTime: '2022-03-24 06:02:29',
      is_support_memo: false,
      payload: {
        txid_url: 'https://dcrdata.decred.org/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/dcr.png',
        chain_display_network: 'DCR',
        chain_network_desc: 'Decred',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/dcr.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/dcr.png',
      address_regex: '^(Ds|Dc)[0-9A-Za-z]{33}$',
    },
    {
      id: '92471d37-d29f-463e-a06d-2c416c93d9ed',
      chain_id: 'CELO',
      token_id: '92471d37-d29f-463e-a06d-2c416c93d9ed',
      name: 'CELO',
      symbol: 'CELO',
      status: 1,
      contract: '42220',
      price: '0.493',
      market_cap: '237220358.5002062',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/celo.png',
      official_site: 'https://celo.org/',
      community:
        '{"twitter":"https://twitter.com/CeloOrg","facebook":"","github":"https://github.com/celo-org","reddit":"https://reddit.com/r/CeloHQ","telegram":"","discord":"","medium":""}',
      precision: 18,
      explorer: 'https://explorer.celo.org/',
      type: 'NATIVE',
      type_symbol: 'CELO',
      change24: '-3.458994360000000',
      CreateTime: '2022-04-08 01:49:47',
      is_support_memo: false,
      payload: {
        txid_url: 'https://explorer.celo.org/tx/%s/token-transfers',
        chain_logo: 'https://resource.cwallet.com/token/icon/celo.png',
        chain_display_network: 'CELO',
        chain_network_desc: 'CELO',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/celo.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/celo.png',
      address_regex: '^(0x)[0-9A-Fa-f]{40}$',
    },
    {
      id: 'ede59024-5660-4c7d-8708-bbcf7b30b097',
      chain_id: 'RVN',
      token_id: 'ede59024-5660-4c7d-8708-bbcf7b30b097',
      name: 'Ravencoin',
      symbol: 'RVN',
      status: 1,
      contract: 'RVN',
      price: '0.01917',
      market_cap: '229476567.12490213',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/rvn.png',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 8,
      explorer: 'https://ravencoin.network/',
      type: 'NATIVE',
      type_symbol: 'RVN',
      change24: '-1.636599850000000',
      CreateTime: '2022-03-17 02:13:00',
      is_support_memo: false,
      payload: {
        txid_url: 'https://ravencoin.network/rvn/transaction/%s',
        chain_logo: 'https://resource.cwallet.com/token/rvn.png',
        chain_display_network: 'RVN',
        chain_network_desc: 'Ravencoin',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/rvn.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/rvn.png',
      address_regex: '^[Rr]{1}[A-Za-z0-9]{33,34}$',
    },
    {
      id: '90cfd067-a767-42d3-91d9-6910e727a728',
      chain_id: 'WAVES',
      token_id: '90cfd067-a767-42d3-91d9-6910e727a728',
      name: 'Waves',
      symbol: 'WAVES',
      status: 1,
      contract: 'WAVES',
      price: '1.343',
      market_cap: '133556310.7845363',
      fee: '0',
      min: '0.001',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/waves.png',
      official_site: 'https://waves.tech/',
      community:
        '{"twitter":"https://twitter.com/wavesprotocol","facebook":"","github":"https://github.com/wavesplatform","reddit":"https://reddit.com/r/Wavesplatform","telegram":"","discord":"","medium":""}',
      precision: 8,
      explorer: 'https://wavesexplorer.com/',
      type: 'NATIVE',
      type_symbol: 'WAVES',
      change24: '3.700316950000000',
      CreateTime: '2022-03-24 06:10:38',
      is_support_memo: false,
      payload: {
        txid_url: 'https://wavesexplorer.com/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/waves.png',
        chain_display_network: 'WAVES',
        chain_network_desc: 'Waves',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/waves.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/waves.png',
      address_regex: '^(3P)[0-9A-Za-z]{33}$',
    },
    {
      id: 'f406ba00-04f9-4613-b5f4-baeb57d64557',
      chain_id: 'ONE',
      token_id: 'f406ba00-04f9-4613-b5f4-baeb57d64557',
      name: 'harmony',
      symbol: 'ONE',
      status: 1,
      contract: '1666600000',
      price: '0.01027',
      market_cap: '126025898.06491247',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/one.png',
      official_site: 'https://www.harmony.one/',
      community:
        '{"twitter":"https://twitter.com/harmonyprotocol","facebook":"https://www.facebook.com/harmonyoneprotocol/","github":"https://github.com/harmony-one/harmony","reddit":"https://reddit.com/r/harmony_one/","telegram":"","discord":"","medium":""}',
      precision: 18,
      explorer: 'https://explorer.harmony.one/',
      type: 'NATIVE',
      type_symbol: 'ONE',
      change24: '-3.186024040000000',
      CreateTime: '2022-03-24 06:10:35',
      is_support_memo: false,
      payload: {
        txid_url: 'https://explorer.harmony.one/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/icon/one.png',
        chain_display_network: 'ONE',
        chain_network_desc: 'Harmony',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/one.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/one.png',
      address_regex: '^(one1)[a-z0-9]{38}$',
    },
    {
      id: '1bcbbcfb-762e-41ec-acfa-87b723a20646',
      chain_id: 'V-CC',
      token_id: '1bcbbcfb-762e-41ec-acfa-87b723a20646',
      name: 'TomoChain',
      symbol: 'TOMO',
      status: 1,
      contract: 'TOMO',
      price: '0.413',
      market_cap: '99748941.93730456',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/tomo.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-TOMO',
      change24: '-2.686502090000000',
      CreateTime: '0001-01-01 00:00:00',
      merge_chains: ['ETH', 'TOMO'],
      is_support_memo: false,
      can_convert: false,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/tomo.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/tomo.png',
      merge_tokens: [
        {
          id: '9623ad85-5674-4e9b-a3bc-cfe83588af83',
          chain_id: 'TOMO',
          token_id: '9623ad85-5674-4e9b-a3bc-cfe83588af83',
          name: 'TomoChain',
          symbol: 'TOMO',
          status: 1,
          contract: '88',
          price: '0.413',
          market_cap: '104982752.82730298',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/tomo.png',
          official_site: 'https://tomochain.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://blockscout.com/etc/mainnet',
          type: 'NATIVE',
          description:
            'TomoChain is a scalable blockchain powered via Proof-of-Stake Voting consensus and used commercially by companies globally.',
          type_symbol: 'TOMO',
          CreateTime: '2022-01-06 06:21:34',
          merged_token_id: '1bcbbcfb-762e-41ec-acfa-87b723a20646',
          is_support_memo: false,
          payload: {
            txid_url: 'https://scan.tomochain.com/txs/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/tomo.png',
            chain_display_network: 'TOMO',
            chain_network_desc: 'TomoChain',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/tomo.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'ffc0c359-0f6c-4a46-86ed-a12e96f551a1',
          chain_id: 'ETH',
          token_id: 'ffc0c359-0f6c-4a46-86ed-a12e96f551a1',
          name: 'TomoChain',
          symbol: 'TOMO',
          status: 1,
          contract: '0x05d3606d5c81eb9b7b18530995ec9b29da05faba',
          price: '0.2719',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/TOMO.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          type_symbol: 'ERC20-TOMO',
          CreateTime: '2022-04-20 10:56:01',
          merged_token_id: '1bcbbcfb-762e-41ec-acfa-87b723a20646',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/tomo.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: 'ce642637-0966-45b2-baca-24ce3f38c6d6',
      chain_id: 'V-CC',
      token_id: 'ce642637-0966-45b2-baca-24ce3f38c6d6',
      name: 'Travala.com Token',
      symbol: 'AVA',
      status: 1,
      contract: 'AVA',
      price: '0.726',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/ava.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-AVA',
      change24: '-3.729251150000000',
      CreateTime: '2022-02-16 07:35:28',
      merge_chains: ['BSC', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/ava.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/ava.png',
      merge_tokens: [
        {
          id: '6b73a542-69dc-4ec7-8466-c00ac6635c9c',
          chain_id: 'ETH',
          token_id: '6b73a542-69dc-4ec7-8466-c00ac6635c9c',
          name: 'Travala.com Token',
          symbol: 'AVA',
          status: 1,
          contract: '0x442b153f6f61c0c99a33aa4170dcb31e1abda1d0',
          price: '0.726',
          market_cap: '29401350.4300788',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ava.png',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          type_symbol: 'ERC20-AVA',
          CreateTime: '2022-02-16 06:09:50',
          merged_token_id: 'ce642637-0966-45b2-baca-24ce3f38c6d6',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ava.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: 'fcbe53dd-eda8-44af-b05f-4cf2ade42789',
          chain_id: 'BSC',
          token_id: 'fcbe53dd-eda8-44af-b05f-4cf2ade42789',
          name: 'Travala.com Token',
          symbol: 'AVA',
          status: 1,
          contract: '0x13616f44ba82d63c8c0dc3ff843d36a8ec1c05a9',
          price: '0.726',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/ava.png',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          type_symbol: 'BEP20-AVA',
          CreateTime: '2022-02-16 06:09:50',
          merged_token_id: 'ce642637-0966-45b2-baca-24ce3f38c6d6',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/ava.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: 'd9eba3b2-54fb-4ce2-a0a4-4a020fdd772e',
      chain_id: 'V-CC',
      token_id: 'd9eba3b2-54fb-4ce2-a0a4-4a020fdd772e',
      name: 'Avalanche',
      symbol: 'AVAX',
      status: 1,
      contract: 'AVAX',
      price: '17.27',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/avax.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-AVAX',
      change24: '-2.386905120000000',
      CreateTime: '2022-02-16 02:48:24',
      merge_chains: ['BSC', 'AVAX'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/avax.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/avax.png',
      merge_tokens: [
        {
          id: '7f7adce4-df24-40c2-ae0e-eb18ad5701bc',
          chain_id: 'AVAX',
          token_id: '7f7adce4-df24-40c2-ae0e-eb18ad5701bc',
          name: 'Avalanche',
          symbol: 'AVAX',
          status: 1,
          contract: '43114',
          price: '17.27',
          market_cap: '3473370528.3982997',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/avax.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://snowtrace.io/',
          type: 'NATIVE',
          description:
            'Avalanche is an open-source platform for launching highly decentralized applications, new financial primitives, and new interoperable blockchains. This is the C-Chain, the default smart contract blockchain on Avalanche that enables the creation of any Ethe',
          type_symbol: 'AVAX',
          CreateTime: '2022-02-16 01:56:13',
          merged_token_id: 'd9eba3b2-54fb-4ce2-a0a4-4a020fdd772e',
          is_support_memo: false,
          payload: {
            txid_url: 'https://snowtrace.io/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/avax.png',
            chain_display_network: 'AVAX',
            chain_network_desc: 'AVAX-C',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/avax.png',
          address_regex: '^(X-avax)[0-9A-za-z]{39}$',
        },
        {
          id: '7a1f2151-39b9-491e-946f-7b5d9a9b79b3',
          chain_id: 'BSC',
          token_id: '7a1f2151-39b9-491e-946f-7b5d9a9b79b3',
          name: 'Avalanche',
          symbol: 'AVAX',
          status: 1,
          contract: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
          price: '17.27',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/avax.png',
          official_site: 'https://avax.network/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'avalanche is the fastest smart contracts platform in the blockchain industry, as measured by time-to-finality. avalanche is blazingly fast, low cost, and eco-friendly. any smart contract-enabled application can outperform its competition by deploying on av',
          type_symbol: 'BEP20-AVAX',
          CreateTime: '2022-01-22 05:39:25',
          merged_token_id: 'd9eba3b2-54fb-4ce2-a0a4-4a020fdd772e',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/avax.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '948f60a6-f449-4b5e-b5de-be683f2c10fb',
      chain_id: 'ICON',
      token_id: '948f60a6-f449-4b5e-b5de-be683f2c10fb',
      name: 'ICX',
      symbol: 'ICX',
      status: 1,
      contract: 'ICX',
      price: '0.1465',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icx.png',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      explorer: 'https://tracker.icon.foundation/',
      type: 'NATIVE',
      type_symbol: 'ICX',
      change24: '-0.843502770000000',
      CreateTime: '2022-05-11 02:44:25',
      is_support_memo: false,
      payload: {
        txid_url: 'https://tracker.icon.foundation/transaction/%s',
        chain_logo: 'https://resource.cwallet.com/token/icx.png',
        chain_display_network: 'ICX',
        chain_network_desc: 'ICON',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/icx.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/icx.png',
      address_regex: '^(hx)[A-Za-z0-9]{40}$',
    },
    {
      id: '3ed71779-7e43-4cf6-9865-53c2c8316255',
      chain_id: 'V-CC',
      token_id: '3ed71779-7e43-4cf6-9865-53c2c8316255',
      name: 'NEAR Protocol',
      symbol: 'NEAR',
      status: 1,
      contract: 'NEAR',
      price: '2.238',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/near.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-NEAR',
      change24: '-4.165436030000000',
      CreateTime: '2022-02-14 03:48:15',
      merge_chains: ['BSC', 'NEAR'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/near.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/near.png',
      merge_tokens: [
        {
          id: '6e381282-c4d7-4ce9-bdab-7996fc878a53',
          chain_id: 'NEAR',
          token_id: '6e381282-c4d7-4ce9-bdab-7996fc878a53',
          name: 'NEAR Protocol',
          symbol: 'NEAR',
          status: 1,
          contract: 'NEAR',
          price: '2.238',
          market_cap: '1110151164.3264332',
          fee: '0',
          min: '0.002',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/near.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 24,
          explorer: 'https://explorer.near.org/',
          type: 'NATIVE',
          description:
            'NEAR is an open source platform that accelerates the development of decentralized applications.',
          type_symbol: 'NEAR',
          CreateTime: '2022-02-14 03:27:32',
          merged_token_id: '3ed71779-7e43-4cf6-9865-53c2c8316255',
          is_support_memo: false,
          payload: {
            txid_url: 'https://explorer.near.org/transactions/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/near.png',
            chain_display_network: 'NEAR',
            chain_network_desc: 'NEAR Protocol',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/near.png',
          address_regex: '^[a-z0-9_-]{1}[a-z0-9_.-]{0,62}[a-z0-9_-]{1}$',
        },
        {
          id: 'c13438c3-b414-45f8-8054-d838023ca9ed',
          chain_id: 'BSC',
          token_id: 'c13438c3-b414-45f8-8054-d838023ca9ed',
          name: 'NEAR Protocol',
          symbol: 'NEAR',
          status: 1,
          contract: '0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
          price: '2.238',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/near.png',
          official_site: 'https://near.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'near protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interope',
          type_symbol: 'BEP20-NEAR',
          CreateTime: '2022-01-22 05:40:25',
          merged_token_id: '3ed71779-7e43-4cf6-9865-53c2c8316255',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/near.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '27e9aa54-8040-4839-b2cc-4064329d6bd2',
      chain_id: 'DGB',
      token_id: '27e9aa54-8040-4839-b2cc-4064329d6bd2',
      name: 'DigiByte',
      symbol: 'DGB',
      status: 1,
      contract: 'DGB',
      price: '0.008',
      market_cap: '0',
      fee: '0',
      min: '0.1',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/dgb.png',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 8,
      explorer: 'https://digiexplorer.info/',
      type: 'NATIVE',
      type_symbol: 'DGB',
      change24: '-2.134469220000000',
      CreateTime: '2022-04-25 03:18:54',
      is_support_memo: false,
      payload: {
        txid_url: 'https://digiexplorer.info/tx/%s',
        chain_logo: 'https://resource.cwallet.com/token/dgb.png',
        chain_display_network: 'DGB',
        chain_network_desc: 'DigiByte',
      },
      can_convert: false,
      fee_self_flag: 1,
      belong_chain_logo: 'https://resource.cwallet.com/token/dgb.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/dgb.png',
      address_regex: '^[DS][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(dgb1)[0-9A-Za-z]{39,59}$',
    },
    {
      id: '2442d67b-0cdc-4fcb-bc39-6e34c00eb046',
      chain_id: 'V-CC',
      token_id: '2442d67b-0cdc-4fcb-bc39-6e34c00eb046',
      name: 'Ethereum Classic',
      symbol: 'ETC',
      status: 1,
      contract: 'ETC',
      price: '20.84',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/etc.png',
      official_site: 'https://ethereumclassic.org/',
      community: 'https://twitter.com/eth_classic',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-ETC',
      change24: '-2.114057480000000',
      CreateTime: '2021-12-31 09:45:23',
      merge_chains: ['BSC', 'ETC'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/etc.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/etc.png',
      merge_tokens: [
        {
          id: '0bb7b3ef-5acb-4b0c-8fac-59d48260740c',
          chain_id: 'ETC',
          token_id: '0bb7b3ef-5acb-4b0c-8fac-59d48260740c',
          name: 'Ethereum Classic',
          symbol: 'ETC',
          status: 1,
          contract: '61',
          price: '20.84',
          market_cap: '4650508656.276991',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/etc.png',
          official_site: 'https://ethereumclassic.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://blockscout.com/etc/mainnet',
          type: 'NATIVE',
          description:
            'Ethereum Classic is an open-source, public, blockchain-based distributed computing platform featuring smart contract functionality.It is a continuation of the original Ethereum blockchain.',
          type_symbol: 'ETC',
          CreateTime: '2021-12-31 09:17:21',
          merged_token_id: '2442d67b-0cdc-4fcb-bc39-6e34c00eb046',
          is_support_memo: false,
          payload: {
            txid_url: 'https://blockscout.com/etc/mainnet/tx/%s/internal-transactions',
            chain_logo: 'https://resource.cwallet.com/token/icon/etc.png',
            chain_display_network: 'ETC',
            chain_network_desc: 'Ethereum Classic',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/etc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '943e1da3-e0c4-4017-9817-8418ea189b8e',
          chain_id: 'BSC',
          token_id: '943e1da3-e0c4-4017-9817-8418ea189b8e',
          name: 'Ethereum Classic',
          symbol: 'ETC',
          status: 1,
          contract: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
          price: '20.84',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/etc.png',
          official_site: 'https://ethereumclassic.org/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'ethereum classic is an open-source, public, blockchain-based distributed computing platform featuring smart contract functionality.it is a continuation of the original ethereum blockchain.',
          type_symbol: 'BEP20-ETC',
          CreateTime: '2021-11-16 02:31:16',
          merged_token_id: '2442d67b-0cdc-4fcb-bc39-6e34c00eb046',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/etc.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: 'fe79e3a7-65af-4863-bb5d-858801b56676',
      chain_id: 'V-CC',
      token_id: 'fe79e3a7-65af-4863-bb5d-858801b56676',
      name: 'TEZOS',
      symbol: 'XTZ',
      status: 1,
      contract: 'XTZ',
      price: '0.743',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/XTZ.png',
      community:
        '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-XTZ',
      change24: '-3.191356560000000',
      CreateTime: '0001-01-01 00:00:00',
      merge_chains: ['BSC', 'TEZOS'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/XTZ.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/xtz.png',
      merge_tokens: [
        {
          id: '8b454f60-d4ec-4dc4-b010-e1a81816c9c0',
          chain_id: 'TEZOS',
          token_id: '8b454f60-d4ec-4dc4-b010-e1a81816c9c0',
          name: 'TEZOS',
          symbol: 'XTZ',
          status: 1,
          contract: 'XTZ',
          price: '0.743',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/XTZ.png',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://tzkt.io/',
          type: 'NATIVE',
          description:
            'Tezos (XTZ) is a blockchain network linked to a digital token, which is called a "tez" or a "tezzie". Tezos is not based on the mining of tez. Instead, token holders receive a reward for taking part in its proof-of-stake consensus mechanism',
          type_symbol: 'XTZ',
          CreateTime: '2022-05-24 02:45:11',
          merged_token_id: 'fe79e3a7-65af-4863-bb5d-858801b56676',
          is_support_memo: false,
          payload: {
            txid_url: 'https://tzkt.io/%s',
            chain_logo: 'https://resource.cwallet.com/token/xtz.png',
            chain_display_network: 'XTZ',
            chain_network_desc: 'Tezos',
          },
          can_convert: false,
          fee_self_flag: 2,
          large_logo: 'https://resource.cwallet.com/token/large_icon/xtz.png',
          address_regex: '^(tz[1,2,3])[a-zA-Z0-9]{33}$',
        },
        {
          id: 'ca93d0ec-13aa-42e7-93a6-d946535f88b6',
          chain_id: 'BSC',
          token_id: 'ca93d0ec-13aa-42e7-93a6-d946535f88b6',
          name: 'Tezos Token',
          symbol: 'XTZ',
          status: 1,
          contract: '0x16939ef78684453bfdfb47825f8a5f714f12623a',
          price: '1.151',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/xtz.png',
          official_site: 'https://www.tezos.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'tezos is a blockchain network that’s based on smart contracts, in a way that’s not too dissimilar to ethereum. however, there’s a big difference: tezos aims to offer infrastructure that is more advanced — meaning it can evolve and improve over time without',
          type_symbol: 'BEP20-XTZ',
          CreateTime: '2021-11-16 02:31:29',
          merged_token_id: 'fe79e3a7-65af-4863-bb5d-858801b56676',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/xtz.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '217d4c3b-4f84-4416-bf1a-a1d15d7d2f50',
      chain_id: 'V-CC',
      token_id: '217d4c3b-4f84-4416-bf1a-a1d15d7d2f50',
      name: 'TRON',
      symbol: 'TRX',
      status: 1,
      contract: 'TRX',
      price: '0.06932',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/trx.png',
      official_site: 'https://tron.network/',
      community: 'https://twitter.com/justinsuntron',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-TRX',
      change24: '-0.542595920000000',
      CreateTime: '2021-11-17 11:51:32',
      merge_chains: ['BSC', 'TRX'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/trx.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/trx.png',
      merge_tokens: [
        {
          id: '2e6cfa7d-f658-455d-89cd-31ebbcfdfa2c',
          chain_id: 'TRX',
          token_id: '2e6cfa7d-f658-455d-89cd-31ebbcfdfa2c',
          name: 'TRON',
          symbol: 'TRX',
          status: 1,
          contract: 'TRX',
          price: '0.06932',
          market_cap: '6801914676.1871',
          fee: '0',
          min: '0.001',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/trx.png',
          official_site: 'https://tron.network/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 6,
          explorer: 'https://tronscan.io/',
          type: 'NATIVE',
          description:
            'TRON is dedicated to building the infrastructure for a truly decentralized Internet.',
          type_symbol: 'TRX',
          CreateTime: '2021-09-10 06:39:49',
          merged_token_id: '217d4c3b-4f84-4416-bf1a-a1d15d7d2f50',
          is_support_memo: false,
          payload: {
            txid_url: 'https://tronscan.org/#/transaction/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/trx.png',
            chain_display_network: 'TRC20',
            chain_network_desc: 'Tron',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/trx.png',
          address_regex: '^T[1-9A-HJ-NP-Za-km-z]{33}$',
        },
        {
          id: '1d93fa05-a6c0-4bab-9ea4-f579b3d1559c',
          chain_id: 'BSC',
          token_id: '1d93fa05-a6c0-4bab-9ea4-f579b3d1559c',
          name: 'TRON',
          symbol: 'TRX',
          status: 2,
          contract: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
          price: '0.06932',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/trx.png',
          official_site: 'https://tron.network/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'tron is a blockchain-based operating system that aims to ensure this technology is suitable for daily use. whereas bitcoin can handle up to six transactions per second, and ethereum up to 25, tron claims that its network has capacity for 2,000 tps.\n\nto lea',
          type_symbol: 'BEP20-TRX',
          CreateTime: '2021-11-16 02:31:15',
          merged_token_id: '217d4c3b-4f84-4416-bf1a-a1d15d7d2f50',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/trx.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '58f93c4d-ce0b-4c7c-af77-b4b299718715',
      chain_id: 'V-CC',
      token_id: '58f93c4d-ce0b-4c7c-af77-b4b299718715',
      name: 'Matic Token',
      symbol: 'MATIC',
      status: 1,
      contract: 'MATIC',
      price: '1.2156',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/matic.png',
      official_site: 'https://polygon.technology/',
      community: 'https://twitter.com/0xPolygon',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-MATIC',
      change24: '-2.731906670000000',
      CreateTime: '2021-11-17 11:43:44',
      merge_chains: ['POLYGON', 'BSC', 'ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/matic.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/matic.png',
      merge_tokens: [
        {
          id: 'c7e001cd-ad17-4c45-a040-cdaa49ecb1a0',
          chain_id: 'ETH',
          token_id: 'c7e001cd-ad17-4c45-a040-cdaa49ecb1a0',
          name: 'Matic Token',
          symbol: 'MATIC',
          status: 1,
          contract: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
          price: '1.2156',
          market_cap: '9366311973.318153',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/matic.png',
          official_site: 'https://matic.network',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          type_symbol: 'ERC20-MATIC',
          CreateTime: '2021-09-10 06:51:59',
          merged_token_id: '58f93c4d-ce0b-4c7c-af77-b4b299718715',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/matic.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '53320ca5-87f8-4d5c-a9aa-0b7ac5b0bc9e',
          chain_id: 'BSC',
          token_id: '53320ca5-87f8-4d5c-a9aa-0b7ac5b0bc9e',
          name: 'Matic Token',
          symbol: 'MATIC',
          status: 1,
          contract: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
          price: '1.2156',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/matic.png',
          official_site: 'https://polygon.technology/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'polygon (previously matic network) is the first well-structured, easy-to-use platform for ethereum scaling and infrastructure development. its core component is polygon sdk, a modular, flexible framework that supports building multiple types of application',
          type_symbol: 'BEP20-MATIC',
          CreateTime: '2021-11-16 02:31:12',
          merged_token_id: '58f93c4d-ce0b-4c7c-af77-b4b299718715',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/matic.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
        {
          id: '85db36af-3282-4501-9357-67da32691ab7',
          chain_id: 'POLYGON',
          token_id: '85db36af-3282-4501-9357-67da32691ab7',
          name: 'Polygon',
          symbol: 'MATIC',
          status: 1,
          contract: '137',
          price: '1.2156',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/matic.png',
          official_site: 'https://matic.network',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://polygonscan.com',
          type: 'NATIVE',
          description:
            'Polygon (Matic) strives to solve the scalability and usability issues, while not compromising on decentralization and leveraging the existing developer community and ecosystem',
          type_symbol: 'MATIC',
          CreateTime: '2021-11-03 08:07:41',
          merged_token_id: '58f93c4d-ce0b-4c7c-af77-b4b299718715',
          is_support_memo: false,
          payload: {
            txid_url: 'https://polygonscan.com/tx/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/matic.png',
            chain_display_network: 'POLYGON',
            chain_network_desc: 'Polygon',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/matic.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: 'bf7ce760-2846-41fe-adaa-cd6787d185c4',
      chain_id: 'V-CC',
      token_id: 'bf7ce760-2846-41fe-adaa-cd6787d185c4',
      name: 'Dai Token',
      symbol: 'DAI',
      status: 1,
      contract: 'DAI',
      price: '0.9992',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/dai.png',
      official_site: 'http://www.makerdao.com/',
      community: 'https://twitter.com/MakerDAO',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-DAI',
      change24: '-0.060740230000000',
      CreateTime: '2021-11-17 08:44:48',
      merge_chains: ['ETH'],
      is_support_memo: false,
      can_convert: false,
      fee_self_flag: 2,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/dai.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/dai.png',
      merge_tokens: [
        {
          id: 'c9ebf760-313c-494d-ab59-df4337099b9a',
          chain_id: 'ETH',
          token_id: 'c9ebf760-313c-494d-ab59-df4337099b9a',
          name: 'Dai',
          symbol: 'DAI',
          status: 1,
          contract: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
          price: '0.9992',
          market_cap: '8721922143.164877',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/dai.png',
          official_site: 'https://makerdao.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://etherscan.io/',
          type: 'ERC20',
          type_symbol: 'ERC20-DAI',
          CreateTime: '2021-09-10 06:50:15',
          merged_token_id: 'bf7ce760-2846-41fe-adaa-cd6787d185c4',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/dai.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
    {
      id: '1431122a-9b56-479f-b575-1a7839fed182',
      chain_id: 'V-CC',
      token_id: '1431122a-9b56-479f-b575-1a7839fed182',
      name: 'Elrond',
      symbol: 'EGLD',
      status: 1,
      contract: 'EGLD',
      price: '33.51',
      market_cap: '0',
      fee: '0',
      min: '0',
      max: '0',
      max_supply: '0',
      total_supply: '0',
      logo: 'https://resource.cwallet.com/token/icon/egld.png',
      precision: 18,
      type: 'MERGE',
      type_symbol: 'MERGE-EGLD',
      change24: '-4.184299160000000',
      CreateTime: '0001-01-01 00:00:00',
      merge_chains: ['BSC', 'EGLD'],
      is_support_memo: false,
      can_convert: false,
      belong_chain_logo: 'https://resource.cwallet.com/token/icon/egld.png',
      large_logo: 'https://resource.cwallet.com/token/large_icon/egld.png',
      merge_tokens: [
        {
          id: '42028f6d-5a0b-4be3-92b0-fc09329304ef',
          chain_id: 'EGLD',
          token_id: '42028f6d-5a0b-4be3-92b0-fc09329304ef',
          name: 'Elrond',
          symbol: 'EGLD',
          status: 1,
          contract: 'EGLD',
          price: '33.51',
          market_cap: '803915721.6612879',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/egld.png',
          official_site: 'https://elrond.com/',
          community:
            '{"twitter":"https://twitter.com/elrondnetwork","facebook":"","github":"https://github.com/ElrondNetwork","reddit":"https://reddit.com/r/elrondnetwork","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://explorer.elrond.com',
          type: 'NATIVE',
          description:
            'Elrond is a blockchain protocol that seeks to offer extremely fast transaction speeds by using sharding. The project describes itself as a technology ecosystem for the new internet, which includes fintech, decentralized finance and the Internet of Things. Its smart contracts execution platform is reportedly capable of 15,000 transactions per second, six-second latency and a $0.001 transaction cost.\n\nThe blockchain has a native token known as eGold, or EGLD, that is used for paying network fees, staking and rewarding validators.\n\nElrond was first announced in August 2019, and its mainnet went live in July 2020.',
          type_symbol: 'EGLD',
          CreateTime: '2022-04-01 07:40:01',
          merged_token_id: '1431122a-9b56-479f-b575-1a7839fed182',
          is_support_memo: false,
          payload: {
            txid_url: 'https://explorer.elrond.com/transactions/%s',
            chain_logo: 'https://resource.cwallet.com/token/icon/egld.png',
            chain_display_network: 'EGLD',
            chain_network_desc: 'Elrond eGold',
          },
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/egld.png',
          address_regex: '^(erd)[a-z-A-Z0-9]{59}$',
        },
        {
          id: '81454969-98cb-4fe6-9aed-c223b62c3e2f',
          chain_id: 'BSC',
          token_id: '81454969-98cb-4fe6-9aed-c223b62c3e2f',
          name: 'Elrond',
          symbol: 'EGLD',
          status: 1,
          contract: '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
          price: '47.06',
          market_cap: '0',
          fee: '0',
          min: '0',
          max: '0',
          max_supply: '0',
          total_supply: '0',
          logo: 'https://resource.cwallet.com/token/icon/egld.png',
          official_site: 'https://elrond.com/',
          community:
            '{"twitter":"","facebook":"","github":"","reddit":"","telegram":"","discord":"","medium":""}',
          precision: 18,
          explorer: 'https://bscscan.com/',
          type: 'BEP20',
          description:
            'elrond is a blockchain protocol that seeks to offer extremely fast transaction speeds by using sharding. the project describes itself as a technology ecosystem for the new internet, which includes fintech, decentralized finance and the internet of things.',
          type_symbol: 'BEP20-EGLD',
          CreateTime: '2021-11-16 02:31:25',
          merged_token_id: '1431122a-9b56-479f-b575-1a7839fed182',
          is_support_memo: false,
          can_convert: false,
          fee_self_flag: 1,
          large_logo: 'https://resource.cwallet.com/token/large_icon/egld.png',
          address_regex: '^(0x)[0-9A-Fa-f]{40}$',
        },
      ],
    },
  ],
};
