// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import LoadingScreen from './components/loading-screen';

import { observer, Provider } from 'mobx-react';
import { stores, StoresContext, walletStroe } from '@/stores/index';
import { useEffect } from 'react';
import { AuthProvider } from './auth/JwtContext';
import { useLocation } from 'react-router';
import { phoneCodeTimeType } from './components/get-code/phone';
import { emailCodeTimeType } from './components/get-code/email';
import { formartSearchParam } from './utils/until';

export default observer(function App() {
  const location = useLocation();
  const chooseApi = localStorage.getItem('chooseApi')
    ? JSON.parse(localStorage.getItem('chooseApi') as string)
    : 1;

  if (location.search) {
    const searchParam = formartSearchParam(location.search);
    if (searchParam?.isout) {
      localStorage.removeItem('Authorization');
    }
  } else {
    // 新老版本重定向
    // const tk = localStorage.getItem('Authorization')
    //   ? JSON.parse(localStorage.getItem('Authorization') as string)
    //   : '';
    // if (tk) {
    //   newVersionNavigate(tk);
    // }
  }

  function changeFaviconSrc(event: MediaQueryListEvent) {
    const faviconLink = document.querySelector('#favicon-link');

    if (event.matches) {
      if (faviconLink) {
        (faviconLink as any).href = '/favicon/ccpfavicon-white.png';
      }
      // turn white
    } else {
      // turn black
      if (faviconLink) {
        (faviconLink as any).href = '/favicon/ccpfavicon-black.png';
      }
    }
  }
  useEffect(() => {
    window.onload = init;
    // If you are downloading pdf, you do not need to request it.
    if (!location.pathname.includes('invoice-pdf') && [1, '1'].includes(chooseApi)) {
      walletStroe.asyncWalletInfo();
    }
    // Control the favicon icon on the browser tab
    if (window?.matchMedia) {
      const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQueryList.addEventListener('change', changeFaviconSrc);

      const faviconLink = document.querySelector('#favicon-link');
      if (mediaQueryList.matches) {
        if (faviconLink) {
          (faviconLink as any).href = '/favicon/ccpfavicon-white.png';
        }
      } else {
        if (faviconLink) {
          (faviconLink as any).href = '/favicon/ccpfavicon-black.png';
        }
      }
    }
    // console.log('加密： ', encrypt(plainText));
    // console.log('解密： ', decrypt(encrypt(plainText)));
    // console.log(
    //   '解密： ',
    //   decrypt(
    //     'fb50b5636f29ffcce4b5449df1c0a034f0e6e0efd004a274c4cada3cf8b943552e47e32c2e0bc3c22c3d0657a3a525a3d079c1345258c29e41faac2a6d3d384d760a24e91c350fddede0472038bed0b797e5ccee6755d2fe59f9237b28256cf6db6901f4428bebc87106b97e44962c80da5912bf3d8a1573380288e42fb28164f54f73ed66dfbffcc8fa98854d0316e2f38067fd317e4d9f9a616414c961603a'
    //   )
    // );

    // eslint-disable-next-line
  }, []);

  //init send code time
  const init = () => {
    localStorage.setItem('chooseApi', JSON.stringify(1));
    if ([1, '1'].includes(chooseApi)) {
      if (sessionStorage.getItem('phoneCodeTime')) {
        let phoneCodeTimeArr: phoneCodeTimeType[] = JSON.parse(
          sessionStorage.getItem('phoneCodeTime') as string
        );
        const arr = phoneCodeTimeArr.map((item) => ({ ...item, value: 0 }));
        sessionStorage.setItem('phoneCodeTime', JSON.stringify(arr));
      }

      if (sessionStorage.getItem('emailCodeTime')) {
        let emailCodeTimeObj: emailCodeTimeType[] = JSON.parse(
          sessionStorage.getItem('emailCodeTime') as string
        );
        const arr = emailCodeTimeObj.map((item) => ({ ...item, value: 0 }));
        sessionStorage.setItem('emailCodeTime', JSON.stringify(arr));
      }
    } else {
      if (sessionStorage.getItem('walletEmailCodeTime')) {
        let emailCodeTimeObj: emailCodeTimeType[] = JSON.parse(
          sessionStorage.getItem('walletEmailCodeTime') as string
        );
        const arr = emailCodeTimeObj.map((item) => ({ ...item, value: 0 }));
        sessionStorage.setItem('walletEmailCodeTime', JSON.stringify(arr));
      }

      if (sessionStorage.getItem('walletPhoneCodeTime')) {
        let emailCodeTimeObj: phoneCodeTimeType[] = JSON.parse(
          sessionStorage.getItem('walletPhoneCodeTime') as string
        );
        const arr = emailCodeTimeObj.map((item) => ({ ...item, value: 0 }));
        sessionStorage.setItem('walletPhoneCodeTime', JSON.stringify(arr));
      }
    }
  };

  if (
    !walletStroe.inited &&
    !location.pathname.includes('invoice-pdf') &&
    [1, '1'].includes(chooseApi)
  )
    return (
      <MotionLazyContainer>
        <ThemeProvider>
          <LoadingScreen />
        </ThemeProvider>
      </MotionLazyContainer>
    );

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <AuthProvider>
          <ThemeSettings>
            <ThemeLocalization>
              <SnackbarProvider>
                {/* Service component */}
                <Provider {...stores}>
                  {/* service function component */}
                  <StoresContext.Provider value={stores}>
                    <Router />
                  </StoresContext.Provider>
                </Provider>
              </SnackbarProvider>
            </ThemeLocalization>
          </ThemeSettings>
        </AuthProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
});
