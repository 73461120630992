import http from './http';
const CryptoJS = require('crypto-js');
export default class SecurityApi {
  google2FA(data: any) {
    return http.postJson('/safety/setting/2fa/get', {});
  }

  verifyGoogle2FA({ verifyCode }: { verifyCode: string }) {
    return http.postJson('/safety/setting/2fa/verify', {
      verifyCode,
    });
  }

  setupGoogle2FA({
    secretKey: secret_key,
    emailCode: mail_code,
    authCode: totp_code,
  }: {
    secretKey: string;
    emailCode: string;
    authCode: string;
  }) {
    return http.postJson('/safety/setting/2fa/set', {
      secret_key,
      mail_code,
      totp_code,
    });
  }

  closeGoogle2FA(data: { mail_code: string; authCode: string }) {
    return http.postJson('/safety/setting/2fa/close', data);
  }

  changeEmail(data: any) {
    return http.postJson('/safety/setting/email/change', data);
  }

  changePhone(data: any) {
    return http.postJson('/safety/setting/phone/change', data);
  }

  setupPhone(data: any) {
    return http.postJson('/safety/setting/phone/set', data);
  }

  setWithdrawSmall(data: any) {
    return http.postJson('/safety/setting/withdraw_small/set', {
      ...data,
      pay_pass: CryptoJS.MD5(data.pay_pass).toString(),
    });
  }

  setupEmail() {
    return http.postJson('/safety/setting/security_email/set');
  }

  closeEmail2FA(data: { code: string; totp_code?: string }) {
    return http.postJson('/safety/setting/security_email/close', {
      code: data.code,
      totp_code: data?.totp_code ?? '',
    });
  }

  setPhone() {
    return http.postJson('/safety/setting/security_phone/set');
  }

  closePhone2FA(data: { code: string; totp_code?: string }) {
    return http.postJson('/safety/setting/security_phone/close', data);
  }

  changePassword(data: any) {
    return http.postJson('/safety/setting/login-pass/change', {
      ...data,
      login_pass: CryptoJS.MD5(data.login_pass).toString(),
    });
  }

  currentSettings(): Promise<{
    // 1 collected
    complete_status?: number;
    create_time: number;
    /** user mailbox **/
    email: string;
    /** whether to open google 2fa **/
    exists_google_2fa: boolean;
    /** Whether to lock the login **/
    exists_lock_pass: boolean;
    /** Whether to enable password login **/
    exists_login_pass: boolean;
    /** Whether to set payment password **/
    exists_pay_pass: boolean;
    /** google 2fa's key **/
    gg_verify_secret_key: string;
    /** google 2fa's QR code **/
    gg_verify_secret_qr: string;
    /** profile picture **/
    head_url?: string;
    id: number;
    /** 14 days without reason, fasle needs true not needed **/
    is_check: boolean;
    /** Number of passcode locks **/
    lock_try: number;
    /** No operation lockout time  **/
    lock_unit: number;
    nickname: string;
    /** user phone **/
    phone: string;
    phoneArea?: string;
    user_id: string;
    /** Whether to enable login, cash withdrawal, and refund secondary verification email **/
    security_email: 0 | 1;
    /** Whether to enable login, cash withdrawal, and refund secondary verification phone **/
    security_phone: 0 | 1;
    security_withdraw: 0 | 1;
    phone_area: string;
    verify_email_time: number;
    verify_phone_time: number;
    wwl_status: 0 | 1;
    [key: string]: any;
  }> {
    return http.postJson('/user/info', {});
  }

  setLockUnit({ lockUnit: lock_unit }: { lockUnit: 2 | 3 | 4 | 5 }) {
    return http.postJson('/safety/setting/lock_unit/set', {
      lock_unit,
    });
  }

  prevOpDate(): Promise<{ option_time: number }> {
    return http.postJson('/safety/setting/no_ops/get', {});
  }

  toggleWithdrawWhiteList({ status }: { status: 0 | 1 }) {
    return http.postJson('/safety/setting/withdraw_whitelist/set', {
      status,
    });
  }

  sendEmailCode(data: {
    email: string;
    code_type: string;
    captcha_id?: string;
    captcha_code?: string;
  }) {
    return http.postJson('/email/code/send', data);
  }

  sendPhoneCode(data: {
    area: string;
    phone: string;
    code_type: string;
    captcha_id?: string;
    captcha_code?: string;
  }) {
    return http.postJson('/sms/code/send', data);
  }

  toggleWhiteList(data: { status: 0 | 1 }) {
    return http.postJson('/safety/setting/withdraw_whitelist/set', data);
  }
}
