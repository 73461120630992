import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
//
import Iconify from '../../iconify';
import { varFade } from '../../animate';
import FileThumbnail, { fileData } from '../../file-thumbnail';
//
import { UploadProps } from '../types';
import { styles } from '@/pages/Invoice/style';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { downloadFiel } from '@/utils/until';
import { useLocales } from '@/locales';


export default function MultiFilePreview({ thumbnail, files, onRemove, sx, length }: UploadProps) {
  const [downing, setDowning] = useState(-1);
  const { translate } = useLocales();
  if (!files?.length) {
    return null;
  }

  return (
    <AnimatePresence initial={false}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {files.map((file, index) => {
          const { name = '', size = 0, path = '' } = fileData(file);
          // console.log("fileData(file)", fileData(file))
          const isNotFormatFile = typeof file === 'string';
          if (thumbnail) {
            return (
              //上传
              <Stack
                key={name}
                component={m.div}
                {...varFade().inUp}
                alignItems="center"
                display="inline-flex"
                justifyContent="center"
                sx={{
                  m: 0.5,
                  width: 80,
                  height: 80,
                  borderRadius: !length ? 1.25 : '50%',
                  overflow: 'hidden',
                  position: 'relative',
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  ...sx,
                }}
              >
                <FileThumbnail
                  tooltip
                  imageView
                  file={file}
                  sx={{ position: 'absolute' }}
                  imgSx={{ position: 'absolute' }}
                />
                {onRemove && !length && (
                  <IconButton
                    size="small"
                    onClick={() => onRemove(file)}
                    sx={{
                      top: 4,
                      right: 4,
                      p: '1px',
                      position: 'absolute',
                      color: (theme) => alpha(theme.palette.common.white, 0.72),
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      },
                    }}
                  >
                    <Iconify icon="eva:close-fill" width={16} />
                  </IconButton>
                )}
              </Stack>
            );
          }

          return (
            //排列详细预览文件
            <Stack
              key={name}
              // component={m.div}
              // {...varFade().inDown}
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{
                width: 'auto',
                height: 38,
                my: 1,
                px: 1,
                py: 1,
                borderRadius: 0.75,
                marginLeft: '8px',
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                cursor: Object.prototype.toString.call(file) === '[object File]' ? '' : 'pointer',
                '&:hover': {
                  backgroundColor:
                    Object.prototype.toString.call(file) === '[object File]' ? '' : '#CAD8FF',
                },
                ...sx,
              }}
              onClick={() => {
                if (Object.prototype.toString.call(file) === '[object File]') return;
                setDowning(index);
                downloadFiel(path, name, (param) => {
                  if (param === 4) {
                    setDowning(-1);
                    enqueueSnackbar(translate('invoices.down_load_tips'));
                  }
                  if (param !== 200 && param > 4) {
                    enqueueSnackbar(translate('invoices.down_load_fail'), {
                      variant: 'error',
                    });
                  }
                });
              }}
            >
              {Object.prototype.toString.call(file) === '[object File]' ? (
                <FileThumbnail file={file} />
              ) : (
                <MyIcon
                  name={index === downing ? 'loading' : 'ic_down_load'}
                  className={index === downing ? 'loading-icon-rotate' : ''}
                  size={14}
                  style={{ ...styles.down_icon, margin: '0' }}
                />
              )}
              <Typography variant="subtitle2" noWrap sx={{ marginLeft: '6px !important' }}>
                {isNotFormatFile ? file : name}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary', marginLeft: '6px !important' }}
              >
                {isNotFormatFile ? '' : fData(size)}
              </Typography>
              {onRemove && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(file);
                  }}
                >
                  <Iconify
                    icon="eva:close-fill"
                    color={'#fff'}
                    bgcolor={'rgba(0, 0, 0, 0.48)'}
                    borderRadius={'50px'}
                    width={'18px'}
                    height={'18px'}
                  />
                </IconButton>
              )}
            </Stack>
          );
        })}
      </div>
    </AnimatePresence>
  );
}
