import { makeObservable, observable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { coinlistApi } from '../api';
import i18n from 'i18next';
import { getChainAllResType } from '../api/coinlistApi';

class WalletChainStroe {
  inited = false;

  ChainAllMap: getChainAllResType = {
    Chains: {},
  };

  constructor() {
    makeObservable(this, {
      inited: observable,
    });
    this.init = this.init.bind(this);
  }

  /**
   *Query list data
   * @memberof WalletChainStroe
   */
  async init() {
    try {
      this.inited = true;
      const res = await coinlistApi.getChainAll();
      // res.Chains = res.Chains;

      this.ChainAllMap = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.inited = false;
    }
  }
}
export default WalletChainStroe;
