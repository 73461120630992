import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';

//error bound
// import ErrorBoundary from '@/components/error-boundary/index';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import useSessionStorage from '@/hooks/useSessionStorage';
// import { useLocation } from 'react-router';

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const [isFold, setIsFold] = useSessionStorage('isFold', false);

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  // const location = useLocation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isDesktop && isFold) {
      setIsFold(false);
    }
    // eslint-disable-next-line
  }, [isDesktop, isFold]);

  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onOpenNav={handleOpen}
      onCloseNav={handleClose}
      isFold={isFold}
      setIsFold={() => setIsFold(!isFold)}
    />
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />
        {isDesktop ? <NavHorizontal /> : renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />
        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}
          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} isFold={isFold} />
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          {/* <ErrorBoundary> */}
          <Outlet />
          {/* </ErrorBoundary> */}
        </Main>
      </Box>
    </>
  );
}
