// i18n
import './locales/i18n';
// scroll bar
import 'simplebar/src/simplebar.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import './common.scss';
import './shame.scss'
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// components
import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { history } from './stores/history';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <SettingsProvider>
      <HistoryRouter history={history}>
        <ScrollToTop />
        <App />
      </HistoryRouter>
    </SettingsProvider>
  </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
