import WebhookApi from './webhookApi';
import CoinlistApi from './coinlistApi';
import OverviewApi from './overviewApi';
import UserOrder from './userOrders';
import Developer from './developer';
import Balance from './balanceApi';
import TransactionApi from './transactionApi';
import UserListApi from './userListApi';
import AssetCollectionApi from './assetCollectionApi';
import AccountApi from './accountApi';
import safetyApi from './safety';
import notificationApi from './notification';

export const webhookApi = new WebhookApi();
export const coinlistApi = new CoinlistApi();
export const overviewApi = new OverviewApi();
export const developerApi = new Developer();
export const userOrderApi = new UserOrder();
export const transactionApi = new TransactionApi();
export const userListApi = new UserListApi();
export const balanceApi = new Balance();
export const assetCollectionApi = new AssetCollectionApi();
export const walletAccountApi = new AccountApi();
export const walletSafetyApi = new safetyApi();
export const walletNotificationApi = new notificationApi();
