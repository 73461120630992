import { Http } from '@/api/http';
// import type from '@/pages/SuggestAFeature/type';
// import CryptoJS from 'crypto-js';
const http = new Http('/merchant/v1');

export default class vertificationApi {
  /**
   * get website check
   */
  geWebsiteCheck(params: getwebsiteParamType): Promise<getWebsiteResType> {
    return http.postJson('/safety/verify_website/check', { ...params });
  }

  /**
   * get website reset
   */
  geWebsiteReset(data: getwebsiteParamType): Promise<getWebsiteResType> {
    return http.postJson('/safety/verify_website/reset', data);
  }
}

export type getwebsiteParamType = {
  verify_website: string;
};

export type getWebsiteResType = {};

export type getwebsiteResetParamType = {
  verify_website: string;
};
