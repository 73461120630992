import http from './http';
const CryptoJS = require('crypto-js');

enum SignType {
  sha256 = 1,
  sha256WithPrivateKey = 2,
}

export default class PaymentApi {
  addWhiteListItem(data: {
    submit_type: 1 | 2;
    token_id: string;
    address: string;
    remark: string;
    code: string;
    memo: string;
  }) {
    return http.postJson('/payment/withdraw_whitelist/add', data);
  }

  whitelist(): Promise<Array<WhitelistRes>> {
    return http.postJson('/payment/withdraw_whitelist/list', {});
  }

  removeWhilteListItem(data: { id: number }) {
    return http.postJson('/payment/withdraw_whitelist/del', data);
  }

  resetSecrets(data: { password: string; code: string }): Promise<configCheckResType> {
    return http.postJson('/payment/secret/reset', {
      ...data,
      password: CryptoJS.MD5(data.password).toString(),
    });
  }

  setWebhook(value: string) {
    return http.postJson('/payment/info/set', {
      field: 'webhook_url',
      value,
    });
  }

  setIps(value: string) {
    return http.postJson('/payment/info/set', {
      field: 'ips',
      value,
    });
  }

  setPublicKey(value: string, field: string) {
    return http.postJson('/payment/info/set', {
      value,
      field,
    });
  }

  setModetype(value: string) {
    return http.postJson('/payment/info/set', {
      value,
      field: 'mode_type',
    });
  }

  getConfig(mode_type?: number): Promise<{
    id: number;
    merchant_id: number;
    mid: string;
    title: string;
    client_id: string;
    client_secret: string;
    app_id: string;
    api_key: string;
    notify_url: string;
    return_url: string;
    public_key: string;
    ips: string;
    logo: string;
    status: number;
    wwl_status: 0 | 1; // Withdrawal whitelist address switch
    email_notify: number; //email alert setting
    created: string;
    updated: string;
    sign_type: SignType;
    security_email: 0 | 1;
    security_phone: 0 | 1;
    company_address: string;
    company_website: string;
    twitter_url: string;
    telegram_url: string;
    verify_website_hash: string; //hash
    verify_website: string; //website
    verify_status: number; //verficitacion 1 Normal status, cash withdrawal and recharge available  2 invoice 3 api  4 swap
  }> {
    return http.postJson('/payment/config/info', { mode_type });
  }

  async checkWebHook(data: { url: string }) {
    try {
      await http.postJson('/payment/webhook/check', data);
      return true;
    } catch (e) {
      return false;
    }
  }

  async checkCCPaymentUser(data: { id: string }) {
    try {
      const resp = await http.postJson('/payment/checkout/user', data);
      return resp;
    } catch (e) {
      return false;
    }
  }

  /**
    * getPayment API Order list data payment list
    * page_num: page int,
      page_size: size int,
      begin_at: start time ms, int
      begin_at: End Timems, int
      pay_status: payment status (the number greater than 8 is all), 0 unpaid, 1 successful payment, 2 insufficient payment, 3 overpaid, 4 payment failed, 5 expired, 6 overdue payment, 7 multiple payments, number
      symbol: token string array array,
      bill_id: order id string
    *
    */
  getPaymentListInfo({
    page_num,
    page_size,
    pay_status,
    merchant_order_id,
    symbol,
    begin_at,
    end_at,
  }: {
    page_num: number;
    page_size: number;
    pay_status: number;
    merchant_order_id?: string;
    symbol?: string[];
    begin_at: number;
    end_at: number;
  }): Promise<PaymentApiLsitType> {
    return http.postJson('/bill/api/list', {
      page_num,
      page_size,
      pay_status,
      merchant_order_id,
      symbol,
      begin_at,
      end_at,
    });
  }

  /**
   * order details
     bill_id: order id string
   *  
   */
  getPaymentDetail(bill_id: string): Promise<PaymentDetailType> {
    return http.postJson('/bill/api/detail', { bill_id });
  }

  /**
   * order details Remarks saved
     bill_id: order id string
     remark: Remarks string
   *  
   */
  savePaymentMark(bill_id: string, remark: string): Promise<PaymentSaveType> {
    return http.postJson('/bill/api/remark/save', { bill_id, remark });
  }

  /**
    * order list export
      * page_num: page number int,
     page_size: page capacity int,
     begin_at: start time ms, int
     begin_at: End Timems, int
     pay_status: payment status (the number greater than 8 is all), 0 unpaid, 1 successful payment, 2 insufficient payment, 3 overpaid, 4 payment failed, 5 expired, 6 overdue payment, 7 multiple payments, number
     symbol: token string array array,
     bill_id: order id string
   *
    *
    */
  paymentListExport(params: {
    page_num?: number;
    pay_status?: number;
    symbol?: string[];
    bill_id?: string;
    begin_at: number;
    end_at: number;
  }): Promise<string> {
    return http.postJson('/bill/api/list/export', params);
  }

  /**
      * api out Going list
        merchant_order_id: order id string
        status: status number status, 0 all 1 processing, 2 success, 3 failed
        symbol: tokens string[]
        page_num: current page number
        page_size: page size number
      *
      */
  getOutGoingList({
    merchant_order_id = '',
    status = 0,
    symbol = [],
    page_num = 1,
    page_size = 20,
    begin_at = 0,
    end_at = 0,
  }: {
    merchant_order_id: string;
    status: number;
    symbol: any[];
    page_num: number;
    page_size: number;
    begin_at: number;
    end_at: number;
  }): Promise<OutGoingListRestype> {
    return http.postJson('/bill/withdraw/list', {
      merchant_order_id,
      status,
      symbol,
      page_num,
      page_size,
      begin_at,
      end_at,
    });
  }

  /**
    * out order list export
     merchant_order_id: Merchant order id,
     begin_at: start time ms, int
     end_at: End Timems, int
     status: 0,1,2,3
     symbol: token string array array,
   *
    *
    */
  OutListExport(params: {
    merchant_order_id: string;
    page_num: number;
    status: number;
    symbol: string[];
    begin_at: number;
    end_at: number;
  }): Promise<string> {
    return http.postJson('/bill/withdraw/export', params);
  }

  /**
   * outorder details
     bill_id: order id string
   *  
   */
  getOutDetail(bill_id: string): Promise<outDetailType> {
    return http.postJson('/bill/withdraw/detail', { bill_id });
  }

  /**
   * Verify view appid APP Secret
   *
   */
  configCheck(data: configCheckParamType): Promise<configCheckResType> {
    return http.postJson('/payment/config/check', data);
  }
}

export type configCheckParamType = {
  code: string;
  mode_type: number;
};

export type configCheckResType = {
  app_id: string;
  api_key: string;
};

export type outDetailType = {
  bill_id: string;
  merchant_order_id: string;
  network: string;
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  logo: string;
  network_fee: string;
  to_address: string;
  cwallet_id: string;
  email: string;
  txid: string;
  txid_url: string;
  status: number; //status，1 processing，2 success，3 failed
  created: number;
  network_name: string;
};

export type OutGoingListRestype = {
  list: OutGoingList[];
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
};

export interface OutGoingList {
  bill_id: string;
  merchant_order_id: string;
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  logo: string;
  status: number;
  created: number;
  updated: number;
}

export type PaymentSaveType = {
  [key: string]: any;
};
export type PaymentApiLsitType = {
  pagination: any;
  list: {
    id: number;
    bill_id: string;
    merchant_id: number;
    merchant_order_id: string;
    chain_id: string;
    contract: string;
    symbol: string;
    origin_amount: string;
    paid_amount: string;
    created: number;
    logo: string;
    pay_status?: number;
    [key: string]: any;
  }[];
  total: number;
  total_value: string;
};
// order details返参
export type PaymentDetailType = {
  detail: {
    bill_id?: string;
    merchant_id?: string;
    merchant_order_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    origin_amount?: string;
    paid_amount?: string;
    transfer_record_id?: string;
    logo?: string;
    current_rate?: string;
    service_fee?: string;
    type?: string;
    created?: number;
    pay_status?: number;
    total_refund_amount?: string;
    total_refund_fee?: string;
    remain_amount?: string;
    remark?: string;
    token_id?: string;
    fiat_name?: string;
    fiat_amount?: string;
    rate?: string;
    product_name?: string;
  };
  pays: {
    record_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    amount?: string;
    em?: number;
    pay_time: number;
    service_fee?: string;
    pay_status: 'Successful' | 'Pending' | 'Failed';
    logo?: string;
  }[];
  refunds: {
    record_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    refund_amount?: string;
    network_fee?: string;
    pay_time?: number;
    refund_status: number;
    fee_style: number;
  }[];
};
export interface WhitelistRes {
  id: number;
  merchant_id: number;
  type: 1 | 2;
  chain_id: string;
  contract: string;
  address: string;
  logo: string;
  symbol: string;
  remark: string;
  token_status: number;
  precision?: number;
  memo?: string;
}
