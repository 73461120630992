// @mui
import {
  Box,
  Grid,
  styled as MuiStyled,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Stack,
  Button,
  CircularProgress,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import useSetState from '@/hooks/useSetState';
import { MyIcon } from '@/assets/icons/MyIcon';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocales } from '@/locales';
import { walletChainStroe, walletApiStroe } from '@/stores';
import { overviewApi, transactionApi } from '@/pages/WalletApi/common/api/index';
import styled from 'styled-components';
import {
  getEstimateFeeResType,
  getTransationListType,
} from '@/pages/WalletApi/common/api/transactionApi';
import { observer } from 'mobx-react';
import Decimal from 'decimal.js';

type Props = {
  open: boolean;
  onClose: (v?: boolean) => void;
  queryData: {
    record_id: string;
    user_id: string;
    address: string;
    memo?: string;
  };
  baseData: getTransationListType;
};

const RefundModal = ({ open, onClose, queryData, baseData }: Props) => {
  const { ChainAllMap } = walletChainStroe;
  const { enqueueSnackbar } = useSnackbar();
  const { translate: t } = useLocales();
  const [loading, setLoading] = useState(false);
  const [sendData, setSendData] = useSetState({
    record_id: '',
    to_address: '',
    memo: '',
    amount: '',
  });
  const [estimateFeeData, setEstimateFeeData] = useState<getEstimateFeeResType>({
    chainId: '',
    contract: '',
    networkCoinId: '',
    networkCoinSymbol: '',
    networkCoinPrice: '0',
    withdrawCoinId: '',
    withdrawCoinSymbol: '',
    withdrawCoinPrice: '0',
    lowWithdrawFeeAmount: '0',
    normalWithdrawFeeAmount: '0',
    fastWithdrawFeeAmount: '0',
  });
  const [isDeduct, setIsDeduct] = useState(false);
  const [balanceList, setBalanceList] = useState<any[]>([]);

  //  submit
  const submitCredit = async () => {
    setLoading(true);
    try {
      const param = {
        record_id: baseData.record_id,
        to_address: sendData.to_address,
        memo: sendData.memo,
        amount: walletApiStroe.formateAmount({ num: refundAmount }).replace(/,/g, ''),
      };
      await transactionApi.unfundRefund(param);
      onClose(true);
      enqueueSnackbar(t('common.Successful'));
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // get  Network Fee
  const getEstimateFee = async () => {
    try {
      const res = await transactionApi.getEstimateFee({
        Chain: baseData.chain_id,
        CoinId: baseData.coin_id,
      });
      setEstimateFeeData(res);
      if (Number(res.withdrawCoinPrice || '0') > 0) {
        setIsDeduct(true);
      }
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const getBlanceList = async () => {
    try {
      const { List } = (await overviewApi.getMerchantBalance()) || { List: [] };
      setBalanceList(List);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const currentRate = useMemo(() => {
    let rate = '0';
    const networkCoinPrice = new Decimal(estimateFeeData.networkCoinPrice || '0');
    const normalWithdrawFeeAmount = new Decimal(estimateFeeData.normalWithdrawFeeAmount || '0');
    const withdrawCoinPrice = new Decimal(estimateFeeData.withdrawCoinPrice || '0');
    rate = networkCoinPrice.mul(normalWithdrawFeeAmount).div(withdrawCoinPrice).toFixed();
    return rate;
  }, [estimateFeeData]);

  // refund amount
  const refundAmount = useMemo(() => {
    let amount = '0';
    const num = new Decimal(sendData.amount || '0');
    const num2 = new Decimal(currentRate);

    if (isDeduct) {
      amount = num.sub(num2).toFixed();
    } else {
      amount = num.toFixed();
    }
    return amount;
  }, [isDeduct, currentRate, sendData.amount]);

  // Insufficient balance
  const isInsufficient = useMemo(() => {
    let flag = false;
    const normalWithdrawFeeAmount = estimateFeeData.normalWithdrawFeeAmount || '0';
    const balanceAmount =
      balanceList.find((item: any) => item?.CoinId === estimateFeeData.networkCoinId)?.Amount ||
      '0';
    flag = Number(balanceAmount) - Number(normalWithdrawFeeAmount) >= 0 ? false : true;
    return flag;
  }, [estimateFeeData.normalWithdrawFeeAmount, estimateFeeData.networkCoinId, balanceList]);

  const isDisabled = useMemo(() => {
    let flag = false;

    if (!sendData.to_address) {
      flag = true;
    }
    if (!sendData.amount) {
      flag = true;
    }
    if (Number(refundAmount) <= 0) {
      flag = true;
    }

    return flag;
  }, [sendData, refundAmount]);

  useEffect(() => {
    setSendData(queryData);
    // eslint-disable-next-line
  }, [queryData]);

  useEffect(() => {
    getEstimateFee();
    getBlanceList();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalWarp
      open={open}
      onClose={(e: any, r: 'backdropClick' | 'escapeKeyDown') => {
        if (r === 'escapeKeyDown') {
          onClose();
        }
      }}
    >
      <Box sx={{ minHeight: '40px' }} component="div">
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '20px',
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: 700,
            }}
          >
            {t('wallet.refund')}
          </DialogTitle>

          <IconButton
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            sx={{
              width: 30,
              height: 30,
            }}
          >
            <MyIcon name="close" size={14} />
          </IconButton>
        </Grid>
        <PageStyle>
          <CreditAssetModalWrap>
            <div className="top-network">
              <div className="title">{t('wallet.network')}</div>
              <div className="desc">
                {ChainAllMap.Chains[baseData.chain_id]?.Name}{' '}
                {ChainAllMap.Chains[baseData.chain_id]?.Type
                  ? `(${ChainAllMap.Chains[baseData.chain_id]?.Type})`
                  : ''}
              </div>
            </div>
            <Stack spacing={2} mb={8}>
              <TextField
                className="user-address"
                label="To Address"
                fullWidth
                autoComplete="off"
                helperText={<Box className="helper-text">{t('wallet.original')}</Box>}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {sendData.to_address.length > 0 ? (
                        <MyIcon
                          name="close_fill"
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSendData({
                              to_address: '',
                            });
                          }}
                        />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                value={sendData.to_address}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setSendData({
                    to_address: e.target.value,
                  });
                }}
              />

              <TextField
                type="number"
                className="user-address"
                label="Refund Amount"
                fullWidth
                autoComplete="off"
                error={Number(refundAmount) > Number(baseData.amount)}
                helperText={
                  <Box
                    className="helper-text"
                    sx={{
                      color:
                        Number(refundAmount) > Number(baseData.amount)
                          ? '#FF5630 !important'
                          : '#73756d !important',
                    }}
                  >
                    {t('wallet.refundable')} :{' '}
                    {walletApiStroe.formateAmount({
                      num: baseData?.amount || '0',
                      tokenSymbol: baseData?.symbol || '',
                    })}{' '}
                    {baseData?.symbol}
                    <Button
                      sx={{
                        color: '#82993C',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                      onClick={() => {
                        setSendData({
                          amount: baseData?.amount || '0',
                        });
                      }}
                    >
                      {t('wallet.max')}
                    </Button>
                  </Box>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Box className="coin-symbol">{baseData.symbol}</Box>
                    </InputAdornment>
                  ),
                }}
                value={sendData.amount}
                onChange={(e) => {
                  // eslint-disable-next-line
                  let value = e.target.value;
                  const precision = 8;
                  if (
                    value.includes('.') &&
                    value.slice(value.indexOf('.')).length > precision + 1 &&
                    Number(value) >= 0
                  ) {
                  } else {
                    setSendData({ amount: value as string });
                  }
                }}
              />

              <Box className="bottom-checkbox">
                <Checkbox
                  color="primary"
                  checked={isDeduct}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    setIsDeduct(e.target.checked);
                  }}
                />
                <i> {t('wallet.deduct')}</i>
              </Box>

              {Number(estimateFeeData.withdrawCoinPrice || '0') > 0 && (
                <>
                  <Box className="bottom-desc-wrap">
                    <div className="title">{t('wallet.estimated')}:</div>
                    <div className="desc">
                      <span>
                        {walletApiStroe.formateAmount({
                          num: estimateFeeData.normalWithdrawFeeAmount,
                          tokenSymbol: estimateFeeData.networkCoinSymbol,
                        })}{' '}
                        {ChainAllMap.Chains[baseData.chain_id]?.Symbol}
                      </span>
                      {isInsufficient && (
                        <div className="error-insufficient">
                          {t('wallet.insufficient')} {ChainAllMap.Chains[baseData.chain_id]?.Symbol}{' '}
                          {t('wallet.amount')}
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box className="bottom-desc-wrap">
                    <div className="title">{t('wallet.rate')}:</div>
                    <div className="desc">
                      {walletApiStroe.formateAmount({
                        num: estimateFeeData.normalWithdrawFeeAmount,
                        tokenSymbol: estimateFeeData.networkCoinSymbol,
                      })}{' '}
                      {ChainAllMap.Chains[baseData.chain_id]?.Symbol} ≈{' '}
                      {walletApiStroe.formateAmount({
                        num: currentRate,
                        tokenSymbol: estimateFeeData.withdrawCoinSymbol,
                      })}{' '}
                      {baseData.symbol}
                    </div>
                  </Box>
                </>
              )}

              <Box className="bottom-desc-wrap">
                <div className="title">{t('wallet.estimated_refund')}:</div>
                <div className="desc">
                  {Number(refundAmount) > 0
                    ? walletApiStroe.formateAmount({
                        num: refundAmount,
                        tokenSymbol: estimateFeeData.withdrawCoinSymbol,
                      })
                    : '0'}{' '}
                  {baseData.symbol}
                </div>
              </Box>
            </Stack>
            <Button
              variant="contained"
              className="confirm-btn"
              disabled={isDisabled}
              onClick={submitCredit}
            >
              {loading ? (
                <CircularProgress variant="indeterminate" color="inherit" size={20} />
              ) : (
                t('wallet.estimated_confirm')
              )}
            </Button>
          </CreditAssetModalWrap>
        </PageStyle>
      </Box>
    </ModalWarp>
  );
};

const ModalWarp = MuiStyled(Dialog, { skipSx: false })(({ theme }) => ({
  '.MuiDialog-paper': {
    borderRadius: 12,
    margin: 0,
  },
}));

const PageStyle = MuiStyled('div')(({ theme }) => ({
  width: 480,
  maxWidth: '95vw',
  maxHeight: '100%',
  borderRadius: 30,
}));

const CreditAssetModalWrap = styled.div`
  padding: 0px 24px 24px;
  .top-network {
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #b6b8b0;
    }
    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #27291e;
    }
    margin-bottom: 24px;
  }
  .helper-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #73756d;
  }
  .coin-symbol {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27291e;
  }
  .user-address {
    .MuiOutlinedInput-root {
      padding-right: 0;
    }
    input {
      padding-right: 16px;
      overflow: hidden; // If the length of the text exceeds the limited width, the excess content will be hidden
      white-space: nowrap; // Set the text to be displayed on one line without wrapping
      text-overflow: ellipsis; // Specifies that when the text overflows, an ellipsis is displayed to represent the trimmed text
    }
  }

  .bottom-checkbox {
    display: flex;
    align-items: center;
    > i {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #73756d;
    }
  }
  .bottom-desc-wrap {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #73756d;
    }
    .desc {
      text-align: right;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #27291e;
      }
      .error-insufficient {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #ff5630;
      }
    }
  }
  .confirm-btn {
    width: 100%;
    padding: 11px 22px;
    height: 48px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    :hover {
      background-color: #82993c;
    }
  }
`;

export default observer(RefundModal);
