import { createContext, useEffect, useReducer, useCallback, useState } from 'react';
//
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';
import { accountApi } from '@/api/index';
import { accountStore, walletChainStroe, walletStroe, walletUserOrdersStroe } from '@/stores';
import { walletStroe as WalletApiStore } from '@/pages/WalletApi/common/stores';
import useLocalStorage from '@/hooks/useLocalStorage';
import { User } from '@/stores/account';
import intercom, { closeIntercom } from '@/utils/support';
import { enqueueSnackbar } from 'notistack';
import { HttpResponseError } from '@/api/http';
import { useLocation } from 'react-router';
import { formartSearchParam } from '@/utils/until';
import { walletAccountApi } from '@/pages/WalletApi/common/api';
import { registerParamType } from '@/pages/WalletApi/common/api/accountApi';
import { t } from 'i18next';

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  WALLETINITIAL = 'WALLETINITIAL',
  WALLETLOGIN = 'WALLETLOGIN',
  WALLETREGISTER = 'WALLETREGISTER',
  WALLETLOGOUT = 'WALLETLOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    isVerify: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  // WALLET API
  [Types.WALLETINITIAL]: {
    isAuthenticated: boolean;
    isVerify: boolean;
    user: any;
  };
  [Types.WALLETLOGIN]: {
    user: any;
  };
  [Types.WALLETREGISTER]: {
    user: any;
  };
  [Types.WALLETLOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isVerify: false,
  user: {
    create_time: 0,
    /** 用户邮箱 **/
    email: '',
    /** 是否开启google 2fa **/
    exists_google_2fa: false,
    /** 是否锁定登录 **/
    exists_lock_pass: false,
    /** 是否开启密码登录 **/
    exists_login_pass: true,
    /** 是否设置支付密码 **/
    exists_pay_pass: false,
    /** google 2fa的密钥 **/
    gg_verify_secret_key: '',
    /** google 2fa的二维码 **/
    gg_verify_secret_qr: '',
    /** 用户头像 **/
    head_url: '',
    id: 0,
    /** 14天无理由， fasle 需要 true 不需要 **/
    is_check: true,
    /** 密码锁定的次数 **/
    lock_try: 0,
    /** 无操作锁定时间 2-10min 3-30min 4-1hour 5-24hour **/
    lock_unit: 2,
    nickname: '',
    /** 用户电话 **/
    phone: '',
    user_id: '',
    wwl_status: 0,
    security_email: 0,
    security_phone: 0,
    security_withdraw: 0,
    company_address: '',
    mode_type: 1,
  },
};

const setMerchantIdObj = (user: AuthUserType) => {
  if (user?.mode_type === 1) {
    if (user?.main_merchant_id && user?.merchant_id) {
      const obj = {
        walletMerchantId: user?.main_merchant_id,
        ccpMerchantId: user?.merchant_id,
      };
      localStorage.setItem('MerchantIdObj', JSON.stringify(obj));
    }
  }
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    setMerchantIdObj(action.payload.user);
    return {
      isInitialized: true,
      isVerify: action.payload.isVerify,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    setMerchantIdObj(action.payload.user);
    return {
      ...state,
      isAuthenticated: true,
      isVerify: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    setMerchantIdObj(action.payload.user);
    return {
      ...state,
      isAuthenticated: true,
      isVerify: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      isVerify: false,
      user: null,
    };
  }

  // WALLET API
  if (action.type === Types.WALLETINITIAL) {
    return {
      isInitialized: true,
      isVerify: action.payload.isVerify,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.WALLETLOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      isVerify: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.WALLETREGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      isVerify: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.WALLETLOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      isVerify: false,
      user: null,
    };
  }

  return state;
};

export const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [IsLogin, setIsLogin] = useLocalStorage('isLogin', false);
  const [IsVerify, setIsVerify] = useLocalStorage('isVerify', false);
  // 模式 1 ccp 2 wallet
  const [chooseApi, setChooseApi] = useLocalStorage('chooseApi', 1);
  const [captchaData, setCaptchaData] = useState<{ CaptchaKey?: string; CaptchaCode?: string }>({
    CaptchaKey: '',
    CaptchaCode: '',
  });
  const [captchaDialogOpen, setCaptchaDialogOpen] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);

  const location = useLocation();

  const initialize = useCallback(async () => {
    setChooseApi(1);
    const loginOut = () => {
      setIsLogin(false);
      setIsVerify(false);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          isVerify: false,
          user: null,
        },
      });
    };
    try {
      // 登录超过14天，cookie会过期，所以在这里发送请求去获取用户信息
      // console.log('IsLogin', { IsLogin, IsVerify });

      if (IsLogin && IsVerify) {
        // wallet api initialization walletChainStroe

        let res = await accountApi.getUserInfo();

        if (location.search) {
          const params = formartSearchParam(location.search);
          if (params?.merchant_id && params?.merchant_id !== res.merchant_id) {
            await accountApi.switchMerchantAccount(Number(params.merchant_id));
            res = await accountApi.getUserInfo();
            await walletStroe.asyncWalletInfo();
          }
        }

        accountStore.updateUser({ ...res, mode_type: 1 }, true, true);

        // customer service
        intercom({
          email: res?.email,
          name: res?.nickname,
          user_id: res?.email,
          user_hash: '',
          showSupport: true,
          background_color: '#c6d7ff',
          action_color: '#3c6ff5',
        });
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            isVerify: true,
            user: { ...res, mode_type: 1 },
          },
        });
      } else if (IsLogin && !IsVerify) {
        const email = localStorage.getItem('info')
          ? JSON.parse(localStorage.getItem('info') as string)
          : '';
        if (email) {
          const res = await accountApi.getSafetyInfoByEmail(email);
          accountStore.updateUser({ ...res, mode_type: 1 } as User, true, false);
          setIsLogin(true);
          setIsVerify(false);
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              isVerify: false,
              user: { ...res, mode_type: 1 } as AuthUserType,
            },
          });
        } else {
          loginOut();
        }
      } else {
        loginOut();
      }
    } catch (error) {
      if (error?.code === 224035) {
        localStorage.setItem('info', JSON.stringify(error?.response?.data?.email || ''));
        accountStore.updateUser(error?.data, true, false);
        setIsLogin(true);
        setIsVerify(false);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            isVerify: false,
            user: {
              ...error?.data,
              mode_type: 1,
            } as AuthUserType,
          },
        });
      } else {
        // 提示异常
        console.log(error);
        // if (error?.code) {
        //   enqueueSnackbar(t(`interface_err.${error?.code}`) as string, { variant: 'error' });
        // } else {
        //   enqueueSnackbar(error?.message, { variant: 'error' });
        // }
        loginOut();
      }
    }
    // eslint-disable-next-line
  }, []);

  const walletInitialize = useCallback(async () => {
    setChooseApi(2);
    const loginOut = () => {
      setIsLogin(false);
      setIsVerify(false);
      dispatch({
        type: Types.WALLETINITIAL,
        payload: {
          isAuthenticated: false,
          isVerify: false,
          user: null,
        },
      });
    };
    try {
      if (IsLogin && IsVerify) {
        // wallet api initialization walletChainStroe

        await initWalletUserInfo();
      } else {
        loginOut();
      }
    } catch (error) {
      if ([224035, 224114].includes(error?.code)) {
        accountStore.updateUser({ ...error?.data, mode_type: 2 }, true, false);
        localStorage.setItem('info', JSON.stringify(error?.response?.data?.Email || ''));
        setIsLogin(true);
        setIsVerify(false);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            isVerify: false,
            user: { ...error?.response?.data, mode_type: 2 } as AuthUserType,
          },
        });
      } else {
        // 提示异常
        // if (error?.code) {
        //   enqueueSnackbar(t(`wallet_interface_err.${error?.code}`) as string, { variant: 'error' });
        // } else {
        //   enqueueSnackbar(error?.message, { variant: 'error' });
        // }
        loginOut();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ['1', 1].includes(chooseApi) ? initialize() : walletInitialize();
    // eslint-disable-next-line
  }, [initialize, walletInitialize]);

  // ccp

  //登录
  const login = async (email: string, password: string) =>
    accountApi
      .loginV2({ email, password })
      .then(async (res) => {
        accountStore.updateUser(res, true, false);
        localStorage.setItem('info', JSON.stringify(res?.email || ''));
        if (!res?.security_email && !res?.security_phone && !res?.exists_google_2fa) {
          dispatch({
            type: Types.REGISTER,
            payload: {
              user: { ...res, mode_type: 1 },
            },
          });
          localStorage.setItem('verifyType', '1');

          setIsVerify(true);
          setIsLogin(true);
        } else {
          dispatch({
            type: Types.LOGIN,
            payload: {
              user: { ...res, mode_type: 1 },
            },
          });
          setIsLogin(true);
        }
        return { ...res, mode_type: 1 };
      })
      .catch((error) => {
        // console.log('error', { error });
        if (error?.response?.data) {
          setIsLogin(true);
          if ([224037].includes(error?.code)) {
            console.log('这里需要全验证', { error });
            localStorage.setItem('info', JSON.stringify(error?.response?.data?.email || ''));
            dispatch({
              type: Types.LOGIN,
              payload: {
                user: error?.response?.data,
              },
            });
          }
        }
        throw new HttpResponseError(error?.message, error?.code, error?.response);
      });

  // REGISTER
  const register = async (params: {
    email: string;
    password: string;
    repeat_password: string;
    code?: string;
    /**
     * 注册类型： 1 邮件 2 google
     */
    reg_type: 1 | 2;
    name?: string;
    website?: string;
    industry?: string;
    remark?: string;
    platform?: string;
    referral_merchant_id?: string;
  }) => {
    const res = await accountApi.register({
      ...params,
      captcha_code: captchaData.CaptchaCode || '',
      captcha_key: captchaData.CaptchaKey || '',
    });
    if (res) {
      accountStore.updateUser({ ...res, mode_type: 1 }, true, true);
      localStorage.setItem('info', JSON.stringify(res?.email || ''));
      setIsLogin(true);
      setIsVerify(true);
      // initializationcustomer service
      intercom({
        email: res?.email,
        name: res?.nickname,
        user_id: res?.email,
        user_hash: '',
        showSupport: true,
        background_color: '#c6d7ff',
        action_color: '#3c6ff5',
      });
      dispatch({
        type: Types.REGISTER,
        payload: {
          user: { ...res, mode_type: 1 },
        },
      });
      return res;
    }
  };

  // 登录退出并清除cookie
  const logout = async () => {
    try {
      await accountApi.loginOut();
      // localStorage.removeItem('info');
      setIsLogin(false);
      setIsVerify(false);
      closeIntercom();
      dispatch({
        type: Types.LOGOUT,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // 登录安全验证
  const loginVerify = async (
    code: { totp_code?: string; mail_code?: string; sms_code?: string; type: 1 | 2 } = {
      totp_code: '',
      mail_code: '',
      sms_code: '',
      type: 1,
    }
  ) =>
    accountApi.loginVerifyV2({
      ...code,
      captcha_code: captchaData.CaptchaCode || '',
      captcha_key: captchaData.CaptchaKey || '',
    });

  // 更新用户信息
  const updUserInfo = useCallback(
    async (isAuthenticated: boolean, isVerify: boolean, user: AuthUserType) => {
      accountStore.updateUser(user as User, isAuthenticated, isVerify);
      setIsLogin(isAuthenticated);
      setIsVerify(isVerify);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: isAuthenticated,
          isVerify: isVerify,
          user: user ? { ...user, mode_type: 1 } : ({ ...state.user } as AuthUserType),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line
    []
  );

  const updUserInfoToStore = async () => {
    try {
      const res = await accountApi.getUserInfo();
      accountStore.updateUser(res, true, true);
      setIsLogin(true);
      setIsVerify(true);
      // customer service
      intercom({
        email: res?.email,
        name: res?.nickname,
        user_id: res?.email,
        user_hash: '',
        showSupport: true,
        background_color: '#c6d7ff',
        action_color: '#3c6ff5',
      });
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: true,
          isVerify: true,
          user: { ...res, mode_type: 1 },
        },
      });
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(t(`interface_err.${error?.code}`) as string, { variant: 'error' });
      } else {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  // wallet api

  //wallet api 登录
  const walletLogin = async (email: string, password: string) =>
    walletAccountApi
      .login({ Email: email, Passwd: password })
      .then(async (res) => {
        const obj = {
          exists_google_2fa: res?.Google2Fa === -1 ? false : true,
          security_email: res?.SecurityEmail === -1 ? 0 : 1,
          security_phone: res?.SecurityPhone === -1 ? 0 : 1,
          email: res.Email,
          phone: res?.Phone,
          phone_area: res?.PhoneArea,
        };
        localStorage.setItem('info', JSON.stringify(res.Email || ''));
        console.log('obj', obj);
        // 如果不需要验证
        if (res?.SafetyMode === 0) {
          await initWalletUserInfo();
          localStorage.setItem('verifyType', '1');

          setIsVerify(true);
          setIsLogin(true);
        } else {
          dispatch({
            type: Types.WALLETLOGIN,
            payload: {
              user: {
                ...initialState.user,
                ...obj,
                mode_type: 2,
              } as any,
            },
          });
          setIsLogin(true);
        }
        return res;
      })
      .catch((error) => {
        // console.log('error', { error });
        throw new HttpResponseError(error?.message, error?.code, error?.response);
      });

  // wallet api REGISTER
  const walletRegister = async (params: registerParamType) => {
    try {
      const res = await walletAccountApi.register(params);
      await initWalletUserInfo();
      setIsLogin(true);
      setIsVerify(true);

      return res;
    } catch (error) {
      throw new HttpResponseError(error?.message, error?.code, error?.response);
    }
  };

  // wallet api 登录退出并清除cookie
  const walletLogout = async () => {
    try {
      await walletAccountApi.logout();
      localStorage.removeItem('info');
      setIsLogin(false);
      setIsVerify(false);
      closeIntercom();
      dispatch({
        type: Types.WALLETLOGOUT,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // 更新wallet用户信息
  const walletUpdUserInfo = useCallback(
    async (isAuthenticated: boolean, isVerify: boolean, user: AuthUserType) => {
      accountStore.updateUser(user as User, isAuthenticated, isVerify);
      setIsLogin(isAuthenticated);
      setIsVerify(isVerify);
      dispatch({
        type: Types.WALLETINITIAL,
        payload: {
          isAuthenticated: isAuthenticated,
          isVerify: isVerify,
          user: user ? { ...user } : ({ ...state.user } as AuthUserType),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line
    []
  );

  const initWalletUserInfo = async () => {
    try {
      let res = await walletAccountApi.getMerchantInfo();
      let resp = await walletAccountApi.getMerchantConfig();
      localStorage.setItem('info', JSON.stringify(res?.Email || ''));

      walletChainStroe.init();
      walletUserOrdersStroe.getCoinList();
      walletUserOrdersStroe.getChainTypeList();
      WalletApiStore.asyncWalletInfo();

      console.log(res, resp);
      // customer service
      intercom({
        email: res?.Email,
        name: res?.Name,
        user_id: res?.Email,
        user_hash: '',
        showSupport: true,
        background_color: '#e5f9a9',
        action_color: '#82993c',
      });
      dispatch({
        type: Types.WALLETINITIAL,
        payload: {
          isAuthenticated: true,
          isVerify: true,
          user: {
            ...{
              create_time: 0,
              /** 用户邮箱 **/
              email: res?.Email,
              /** 是否开启google 2fa **/
              exists_google_2fa: resp?.Google2Fa === -1 ? false : true,
              /** 是否锁定登录 **/
              exists_lock_pass: false,
              /** 是否开启密码登录 **/
              exists_login_pass: true,
              /** 是否设置支付密码 **/
              exists_pay_pass: resp?.IsBindPayPasswd,
              /** google 2fa的密钥 **/
              gg_verify_secret_key: '',
              /** google 2fa的二维码 **/
              gg_verify_secret_qr: '',
              /** 用户头像 **/
              head_url: res?.HeadUrl,
              id: 0,
              /** 14天无理由， fasle 需要 true 不需要 **/
              is_check: true,
              /** 密码锁定的次数 **/
              lock_try: 0,
              /** 无操作锁定时间 2-10min 3-30min 4-1hour 5-24hour **/
              lock_unit: resp?.LockUnit,
              nickname: res?.Name,
              /** 用户电话 **/
              phone: res?.Phone || resp?.Phone || '',
              user_id: res?.PhoneArea || resp?.PhoneArea || '',
              wwl_status: resp?.WwlStatus || -1,
              security_email: resp.SecurityEmail === -1 ? 0 : 1,
              security_phone: resp.SecurityEmail === -1 ? 0 : 1,
              safetyMode: resp?.SafetyMode,
              company_address: '',
              mode_type: 2,
            },
          },
        },
      });
    } catch (error) {
      console.log(error);

      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error?.code}`) as string, { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error?.message, { variant: 'error' });
      // }
    }
  };

  const walletUpdUserInfoToStore = async () => {
    try {
      await initWalletUserInfo();
      // accountStore.updateUser(res, true, true);
      setIsLogin(true);
      setIsVerify(true);
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(t(`wallet_interface_err.${error?.code}`) as string, { variant: 'error' });
      } else {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const changeCaptchaData = (data: { CaptchaKey?: string; CaptchaCode?: string }) => {
    setCaptchaData(data);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginWithGoogle: () => { },
        loginWithGithub: () => { },
        loginWithTwitter: () => { },
        logout,
        loginVerify,
        register,
        updUserInfo,
        updUserInfoToStore,
        chooseApi,
        setChooseApi,
        walletLogin,
        walletRegister,
        walletLogout,
        walletUpdUserInfo,
        walletUpdUserInfoToStore,
        captchaData,
        changeCaptchaData,
        captchaDialogOpen,
        setCaptchaDialogOpen,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
