import { Http } from '@/api/http';
const http = new Http('/merchant/console/v1');

export default class TransactionApi {
  /**
   * get transaction list 
   * @returns {string}
   */
  getTransationList(data: getTransationListParamType): Promise<getTransationListResType> {
    return http.postJson('/transaction/list', data);
  }

  /**
   * get transaction details
   * @returns {string}
   */
  getTransationDetail({
    record_id = '',
    business_id = '',
  }: {
    record_id: string;
    business_id: string;
  }): Promise<getTransationDetailResType> {
    return http.postJson('/transaction/detail', { record_id, business_id });
  }

  /**
   *  get transaction details
   * @returns {string}
   */
  getUnfundSymbolList(): Promise<getUnfundSymbolListResType> {
    return http.postJson('/transaction/unfund/symbol/list', {});
  }

  /**
   * get transaction Transaction Type Classification
   * @returns {string}
   */
  getTransactionTypeList(): Promise<getTransactionTypeListResType> {
    return http.postJson('/transaction/cats', {});
  }

  /**
   *  Merchant manual check-in
   * @returns {string}
   */
  manualDeposit(data: manualDepositParamType): Promise<any> {
    return http.postJson('/transaction/manual/deposit', data);
  }

  /**
   *  Get Estimated Fees
   * @returns {string}
   */
  getEstimateFee(data: getEstimateFeeParamType): Promise<getEstimateFeeResType> {
    return http.postJson('/coin/fee/estimate', data);
  }

  /**
   *  original refund
   * @returns {string}
   */
  unfundRefund(data: unfundRefundParamtype): Promise<any> {
    return http.postJson('/transaction/unfund/refund', data);
  }

  /**
   *  recharge
   * @returns {string}
   */
  reDeposit(list: string[]): Promise<any> {
    return http.postJson('/transaction/unfund/redeposit', { list });
  }
}

export type getEstimateFeeResType = {
  chainId: string;
  contract: string;
  networkCoinId: string;
  networkCoinSymbol: string;
  networkCoinPrice: string;
  withdrawCoinId: string;
  withdrawCoinSymbol: string;
  withdrawCoinPrice: string;
  lowWithdrawFeeAmount: string;
  normalWithdrawFeeAmount: string;
  fastWithdrawFeeAmount: string;
};

export type unfundRefundParamtype = {
  record_id: string;
  to_address: string;
  memo?: string;
  amount: string;
};

export type getEstimateFeeParamType = {
  CoinId: string;
  Chain: string;
};

export type manualDepositParamType = {
  record_id: string;
  user_id: string;
  address: string;
  memo?: string;
};

export type getTransactionTypeListResType = {
  list: {
    'collect-in': 'collect-in';
    'merchant-deposit': 'merchant-deposit';
    'merchant-return': 'merchant-return';
    'merchant-swap-in': 'merchant-swap-in';
    'merchant-swap-out': 'merchant-swap-out';
    'merchant-withdraw': 'merchant-withdraw';
    'pre-fee-in': 'pre-fee-in';
    'system-deposit': 'system-deposit';
    'system-swap-in': 'system-swap-in';
    'system-swap-out': 'system-swap-out';
    'user-deposit': 'user-deposit';
    'user-withdraw': 'user-withdraw';
  };
};

export type getUnfundSymbolListResType = {
  list: string[];
};
export const defalutTransationDetailResTmp = {
  coin_id: '',
  chain_id: '',
  contract: '',
  symbol: '',
  record_id: '',
  business_id: '',
  tx_hash: '',
  tx_index: 0,
  tx_height: 0,
  transaction_type: '',
  sub_transaction_type: '',
  user_type: '',
  user_id: '',
  action: 1,
  amount: '0',
  estimated_network_fee: '0',
  actual_network_fee: '0',
  profit_network_fee: '0',
  coin_fee: '0',
  network_coin_fee: '0',
  fee: '0',
  network_coin_price: '0',
  coin_price: '0',
  payment_date: 0,
  status: 1,
  billing_status: 1,
  from_address: '',
  to_address: '',
  memo: '',
  remarks: '',
  extra: {
    addr_notify_id: '',
    fee_address: '',
    memo: '',
    to_address: '',
  },
  into_user_id: '',
  into_deposit_bill_id: '',
  coin: {
    Id: '',
    Symbol: '',
    Name: '',
    LogoURI: '',
    Desc: '',
    Type: 0,
    Price: '1',
    Status: 1,
    CMCCoinId: '',
    CMCCoinIdUpdated: false,
    PriceUpdated: false,
  },
  coin_network: {
    Id: '',
    Symbol: '',
    ChainId: '',
    Contract: '',
    Status: 10,
    Type: '',
    BaseMinimumWithdraw: '0',
  },
  chain_network: {
    Id: '',
    Symbol: '',
    ChainId: '',
    Contract: '',
    Status: 10,
    Type: '',
    BaseMinimumWithdraw: '0',
  },
};

export type getTransationDetailResType = {
  updateLoing?: boolean;
  coin_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  record_id: string;
  business_id: string;
  tx_hash: string;
  tx_index: number;
  tx_height: number;
  transaction_type: string;
  sub_transaction_type: string;
  user_type: string;
  user_id: string;
  action: number;
  amount: string;
  estimated_network_fee: string;
  actual_network_fee: string;
  profit_network_fee: string;
  coin_fee: string;
  network_coin_fee: string;
  fee: string;
  network_coin_price: string;
  coin_price: string;
  payment_date: number;
  status: number;
  billing_status: number;
  from_address: string;
  to_address: string;
  memo: string;
  remarks: string;
  extra: {
    addr_notify_id: string;
    fee_address: string;
    memo: string;
    to_address: string;
    [key: string]: any;
  };
  into_user_id: string;
  into_deposit_bill_id: string;
  coin: Coin;
  coin_network: Network;
  chain_network: Network;
};

export type getTransationListParamType = {
  sub_transaction_list: string[];
  user_type: string;
  coin_id: string;
  chain_id: string;
  status: number;
  billing_status: number;
  search_item: 'txId' | 'address' | 'contract' | 'userId' | 'businessId';
  search: string;
  page_index: number;
  page_size: number;
};

export type getTransationListResType = {
  list: getTransationListType[];
  pagination: {
    PageIndex: number;
    PageSize: number;
    Total: number;
  };
};

export interface getTransationListType {
  record_id: string;
  coin_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  business_id: string;
  tx_hash: string;
  transaction_type: string;
  sub_transaction_type: string;
  user_type: string;
  user_id: string;
  action: number;
  amount: string;
  payment_date: number;
  status: number;
  billing_status: number;
  coin: Coin;
  network: Network;
  unrecorded_hand_flag: number;
  unrecorded_bill_id: string;
  into_user_id: string;
  into_user_address: string;
  into_deposit_bill_id: string;
}

export interface Coin {
  Id: string;
  Symbol: string;
  Name: string;
  LogoURI: string;
  Desc: string;
  Type: number;
  Price: string;
  Status: number;
  CMCCoinId: string;
  CMCCoinIdUpdated: boolean;
  PriceUpdated: boolean;
}

export interface Network {
  Id: string;
  Symbol: string;
  ChainId: string;
  Contract: string;
  Status: number;
  Type: string;
  BaseMinimumWithdraw: string;
}
