import { SystemChain } from '@/api/token';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import { userOrderApi } from '@/pages/WalletApi/common/api';
import { ChainListType } from '@/pages/WalletApi/common/api/userOrders';
import {
  Box,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  styled,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
// import useSWR from "swr";

type SelectNetworkProps = {
  parmams: {
    key?: string;
    type?: string;
    helperText?: string;
    width?: string;
    chainList: SystemChain[];
    isLoading: boolean;
  };
  value: string;
  onChange: (value: string) => Promise<void>;
};

export default function SelectNetwork({ value, onChange, parmams }: SelectNetworkProps) {
  const { translate: t } = useLocales();
  const [text, setText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [networkList, setNetworkList] = useState<ChainListType[]>([]);
  const [searchList, setSearchList] = useState<ChainListType[]>([]);

  async function getDepositNetworkList() {
    try {
      const res = await userOrderApi.getDepositNetworkList();
      const arr: ChainListType[] = [];
      if (res.List) {
        Object.keys(res.List).forEach((item) => {
          if (res.List[item]) {
            arr.push(res.List[item]);
          }
        });
      }

      setNetworkList([
        {
          Chain: '0',
          Symbol: '',
          Name: t('wallet.user.orders.all.network'),
          Explorer: '',
          Status: 10,
          TxIdUrl: '',
          ChainLogo: '',
          Type: '',
        },
        ...arr,
      ]);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    //search method
    if (!text) {
      setSearchList(networkList);
    } else {
      let serch: Array<any> = [];
      networkList.forEach((item, index) => {
        if (
          item.Symbol.toUpperCase().includes(text.toUpperCase()) ||
          item.Type.toUpperCase().includes(text.toUpperCase()) ||
          item.Name.toUpperCase().includes(text.toUpperCase())
        ) {
          serch.push(item);
          return true;
        }
      });
      console.log('arr', serch);
      // setChains(parmams.chainList);
      setSearchList(serch);
    }

    // eslint-disable-next-line
  }, [text, networkList]);

  useEffect(() => {
    getDepositNetworkList();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <SelectTokenWrap sx={{ width: parmams?.width || '100%' }}>
      <Box
        component={'div'}
        id="popver-search-select"
        sx={{
          justifyContent: 'space-between',
          background: 'transparent',
          color: '#212B36',
          border: '1px solid #E6E8E0',
          borderRadius: '8px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          padding: '15px 12px',
          mr: 2,
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
          if (networkList.length > 0) {
            setAnchorEl(event.currentTarget);
          }
        }}
      >
        <span
          style={{
            width: '92%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: '#27291E',
          }}
        >
          {networkList.find((item) => item.Chain === value)?.Name}
        </span>
        {networkList.length > 0 ? (
          <Box sx={{ transform: `${!open ? 'rotate(-90deg)' : 'rotate(90deg)'}`, flexShrink: 0 }}>
            <MyIcon name="back_arrow" size={6} />
          </Box>
        ) : (
          <CircularProgress color="inherit" size={20} />
        )}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        container={() => document.querySelector('#popver-search-select')}
        sx={{
          mt: 1,
          '.MuiPaper-root': {
            padding: '12px 8px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TextField
            autoComplete="off"
            type={'text'}
            value={text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setText(e.target.value);
            }}
            onBlur={() => {
              if (text.length === 0) setIsFocus(false);
              if (text.length > 0) setIsFocus(true);
              if (!isFocus) return;
            }}
            onFocus={() => setIsFocus(true)}
            focused={isFocus}
            placeholder={t('invoices.search_holder')}
            size="small"
            sx={{
              width: `calc(${parmams.width} - 32px)`,
              height: '56px',
            }}
            InputProps={{
              sx: {
                '&.MuiOutlinedInput-root': {
                  height: '36px',
                  lineHeight: '36px',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                  },
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <MyIcon name={`${isFocus ? 'search_black' : 'search_gray'}`} size={17} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <MyIcon
                    name="close"
                    style={{
                      cursor: 'pointer',
                      visibility: text ? 'visible' : 'hidden',
                    }}
                    onClick={() => {
                      setText('');
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <List
          sx={{
            bgcolor: 'background.paper',
            margin: 0,
            padding: 0,
            // paddingRight: '4px',
            height: '200px',
            overflow: 'overlay',
            '::-webkit-scrollbar': {
              width: '6px',
              height: '6px',
              backgroundColor: '#fff',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '12px',
              backgroundColor: ' #b6bcc1',
            },
          }}
        >
          {searchList.length > 0 &&
            searchList.map((item) => {
              const labelId = `checkbox-list-label-${item.Chain}`;
              return (
                <ListItem
                  key={item.Chain}
                  disablePadding
                  sx={{
                    '.MuiButtonBase-root': {
                      padding: '6px 0px 6px 16px',
                      // border: '1px solid',
                      borderRadius: '6px',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      backgroundColor: value !== item.Chain ? '#fff' : '#F0F1EC',
                      color: value !== item.Chain ? '#27291E' : '#82993C',
                      ':hover': {
                        backgroundColor: '#F9FAF6',
                      },
                    },
                  }}
                >
                  <ListItemButton
                    // disabled={item.disabled}
                    role={undefined}
                    onClick={() => {
                      onChange(item.Chain);
                      handleClose();
                    }}
                    dense
                  >
                    {/* <Avatar src={item.logo_url} sx={{ width: 20, height: 20, margin: '0 8px 0 4px' }} /> */}
                    <ListItemText
                      id={labelId}
                      primary={`${item.Name} ${item.Type ? '(' + item.Type + ')' : ''}`}
                      sx={{
                        '.MuiTypography-root': {
                          width: '98%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Popover>
    </SelectTokenWrap>
  );
}

const SelectTokenWrap = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.search-box': {
    '.MuiOutlinedInput-root': {
      padding: 0,
    },
    borderRadius: '8px',
  },

  '.check-box': {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    maxHeight: 280,
    overflow: 'auto',
    zIndex: 999999,
    top: 60,
    left: 0,
    padding: '4px',
    paddingRight: '2px',
    boxShadow:
      '0px 3px 5px -1px rgba(145, 158, 171, 0.2), 0px 5px 8px 0px rgba(145, 158, 171, 0.14), 0px 1px 14px 0px rgba(145, 158, 171, 0.12)',

    '.check-box-container': {
      borderRadius: '8px',
      '.check-box-item': {
        fontSize: '14px',
        fontWeight: 400,
        padding: '8px',
      },
    },
    '.MuiMenuItem-root': {
      borderRadius: '12px',
      marginTop: '2px',
    },

    '::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      backgroundColor: '#fff',
      borderRadius: '0px 8px 8px 0px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      backgroundColor: ' #b6bcc1',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '8px',
    },
  },

  '.Mui-focused': {
    color: '#1E1E1E !important',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E1E1E !important',
      borderWidth: '1px !important',
    },
  },

  '.Mui-selected': {
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    ':hover': {
      backgroundColor: 'rgba(145, 158, 171, 0.08)',
    },
  },
}));
