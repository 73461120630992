import { makeObservable, observable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { webhookApi } from '../api';
import i18n from 'i18next';
import { getWebhookListResType } from '../api/webhookApi';

interface searchParamProps {
  Key?: string;
  Type?: number;
  Status?: number;
  BeginAt?: number;
  EndAt?: number;
  PageIndex?: number;
  PageSize?: number;
}

class WalletWebhookStroe {
  searchParam = {
    Key: '',
    Type: 0,
    Status: 0,
    PageIndex: 1,
    PageSize: 20,
    BeginAt: 0,
    EndAt: 0,
  };

  searchLoading = false;

  isResend = false;

  tableData: getWebhookListResType = {
    List: [],
    Pagination: { PageIndex: 1, PageSize: 20, Total: 0 },
  };

  constructor() {
    makeObservable(this, {
      searchParam: observable,
      tableData: observable,
      searchLoading: observable,
      isResend: observable,
    });
    this.setSearchParam = this.setSearchParam.bind(this);
    this.queryList = this.queryList.bind(this);
    this.setIsResend = this.setIsResend.bind(this);
  }

  async setSearchParam(param: searchParamProps) {
    const oldsSearchParam = { ...this.searchParam };
    this.searchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  async setIsResend(data: boolean) {
    this.isResend = data;
  }

  /**
   *get list data
   * @memberof WalletWebhookStroe
   */
  async queryList() {
    try {
      this.searchLoading = true;
      const res = await webhookApi.getWebhookList(this.searchParam);
      this.tableData = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      this.searchLoading = false;
    }
  }
}
export default WalletWebhookStroe;
