import { Http } from '@/api/http';
const CryptoJS = require('crypto-js');
const http = new Http('/merchant/console/v1');
export default class AccountApi {
  /**
   * google get auth2
   */
  getWalletAuthUrl({ CallbackUrl }: { CallbackUrl: string }): Promise<AuthUrlType> {
    return http.postJson('/safety/auth/url', { CallbackUrl, Platform: 'google' });
  }

  /**
   * Login via google code
   */
  googleWalletAuthLogin({ Code }: { Code: string }): Promise<any> {
    return http.postJson('/safety/auth/login', { Code, Platform: 'google' });
  }

  /**
   * login
   * SafetyMode int8 // 0 no verification 1 email, 2 google, 3 email & google, 4 phone, 5 email &phone, 6 google & phone, 7 email &google & phone
   */
  login({ Email, Passwd }: { Email: string; Passwd: string }): Promise<loginResType> {
    return http.postJson('/safety/login', { Email, Passwd: String(CryptoJS.MD5(Passwd?.trim())) });
  }

  /**
   * register
   */
  register(data: registerParamType) {
    return http.postJson('/safety/register', {
      ...data,
      Passwd: String(CryptoJS.MD5(data.Passwd?.trim())),
      RepeatPasswd: String(CryptoJS.MD5(data.RepeatPasswd?.trim())),
    });
  }

  /**
   * forget the password
   */
  forgetPassword({
    Email = '',
    Passwd = '',
    RepeatPasswd = '',
    MailCode = '',
    SmsCode = '',
    TotpCode = '',
  }: forgetPasswordParamType) {
    return http.postJson('/safety/login_passwd/forget', {
      Email,
      Passwd: String(CryptoJS.MD5(Passwd?.trim())),
      RepeatPasswd: String(CryptoJS.MD5(RepeatPasswd?.trim())),
      MailCode,
      SmsCode,
      TotpCode,
    });
  }

  /**
   * sign out
   */
  logout() {
    return http.postJson('/safety/logout', {});
  }

  /**
   * Get business information
   */
  getMerchantInfo(): Promise<getMerchantInfoResType> {
    return http.postJson('/safety/info', {});
  }

  /**
   * Get merchant configuration information
   */
  getMerchantConfig(): Promise<getMerchantConfigResType> {
    return http.postJson('/safety/config', {});
  }

  /**
   * Get merchant configuration information
   */
  sendEmailCode(data: sendEmailCodeParamType) {
    return http.postJson('/safety/email/send/code', data);
  }

  /**
   * safety verification
   */
  safetyVerify(data: safetyVerifyParamType) {
    return http.postJson('/safety/login/verify', data);
  }

  /**
   * Query security information by email
   */
  getSafetyVerifyByEmail(Email: string): Promise<getSafetyVerifyByEmailResType> {
    return http.postJson('/safety/info/email', { Email });
  }
}

export type forgetPasswordParamType = {
  Email: string;
  Passwd: string;
  RepeatPasswd: string;
  MailCode: string;
  SmsCode: string;
  TotpCode: string;
};

export type getSafetyVerifyByEmailResType = {
  SafetyMode: number;
  Phone?: string;
  PhoneArea?: string;
  Email: string;
};

export type getMerchantConfigResType = {
  MerchantId: string;
  Title: string;
  IPWhitelist: string;
  LockUnit: number;
  WwlStatus: number;
  Google2Fa: number;
  SecurityPhone: number;
  SecurityEmail: number;
  SecurityWithdraw: number;
  LatestLoginIp: string;
  SafetyMode: number;
  IsBindPayPasswd: boolean;
  PhoneArea?: string;
  Phone?: string;
  [key: string]: any;
};

export type getMerchantInfoResType = {
  MerchantId: string;
  Name: string;
  Email: string;
  HeadUrl: string;
  [key: string]: any;
};

export type safetyVerifyParamType = {
  MailCode: string;
  SmsCode: string;
  TotpCode: string;
  Trust: boolean;
  /** 0 no verification 1 email, 2 google, 3 email & google, 4 phone, 5 email &phone, 6 google & phone, 7 email &google & phone */
  SafetyMode: number;
};

export type loginResType = {
  /** 0 no verification 1 email, 2 google, 3 email & google, 4 phone, 5 email &phone, 6 google & phone, 7 email &google & phone */
  SafetyMode: number;
  Google2Fa: number;
  SecurityEmail: number;
  SecurityPhone: number;
  Email: string;
  Phone?: string;
  PhoneArea?: string;
};

export type sendEmailCodeParamType = {
  Email: string;
  /** 1 Registered 2 Non-registered */
  SendType: 1 | 2;
};

export type registerParamType = {
  Email: string;
  Passwd: string;
  RepeatPasswd: string;
  /** google no code */
  Code?: string;
  /** 1 email 2 google */
  RegType: 1 | 2;
};

export type AuthUrlType = {
  Url: string;
};
