import { format, getTime, formatDistanceToNow } from 'date-fns';

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatDate(date: InputValue, type: string) {
  return date ? format(new Date(date), type) : '';
}

export function formatChartsDate(date: number, type: string) {
  return date ? format(new Date(date * 1000), type) : '';
}

export function handlerDateDurationCurrent(time: number) {
  let d1 = new Date(time);
  let d2 = new Date();

  let cha = Math.abs(d2.getTime() - d1.getTime());
  let days = parseInt((cha / (24 * 60 * 60 * 1000)).toString());
  let hours = parseInt(((cha % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)).toString());
  let mins = parseInt(((cha % (60 * 60 * 1000)) / (60 * 1000)).toString());
  let Seconds = parseInt(((cha % (60 * 1000)) / 1000).toString());
  if (days) {
    return {
      days,
      hours,
      mins,
    };
  } else if (hours) {
    return {
      hours,
      mins,
    };
  } else if (mins) {
    return {
      mins,
      Seconds,
    };
  } else {
    return {
      Seconds,
    };
  }
}

export function handlerDateCurrentDay(time: number) {
  if (!time) return 0;
  let num = 0;
  const currentTime = new Date(time).getDate();
  num = currentTime;
  return num;
}
