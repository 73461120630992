import http from './http';
export default class OrderApi {
  getTradeSelectInfo(): Promise<selectAndFilterType> {
    return http.postJson('/trade/cons');
  }

  /**
    * getorder list data
    * page: page number int,
      page_size: page capacity int,
      begin_at: start time int,
      end_at: End Time int,
      types: transaction type string array,
      stats: transaction status string array,
      token: token string array,
      bil_type: order type int,
      bill_id: order string
    *
    */
  getTradeListInfo({
    page,
    page_size,
    begin_at,
    end_at,
    types,
    tokens,
    stats,
    bill_type,
    bill_id,
  }: {
    page: number;
    page_size: number;
    begin_at?: number;
    end_at?: number;
    types?: string[];
    stats?: string[];
    tokens?: string[];
    bill_type?: number;
    bill_id?: string;
  }): Promise<OrderLsitType> {
    return http.postJson('/trade/list', {
      page,
      page_size,
      begin_at,
      end_at,
      types,
      tokens,
      stats,
      bill_type,
      record_id: bill_id,
    });
  }

  /**
    * Transaction order export excel
    * page: page number int,
      page_size: page capacity int,
      begin_at: start time int,
      end_at: End Time int,
      types: transaction type string array,
      stats: transaction status string array,
      token: token string array,
      bil_type: order type int,
      bill_id: order string
    *
    */
  tradeExport(params: {
    begin_at?: number;
    end_at?: number;
    types?: string[];
    stats?: string[];
    token?: string[];
    bill_type?: number;
    bill_id?: string;
  }): Promise<string> {
    return http.postJson('/trade/export', params);
  }

  /**
    * Transaction order details
      bill_id: order id string
    *
    */
  getTradeDetail(record_id: string): Promise<orderDetailTypeOld> {
    return http.postJson('/trade/detail', { record_id });
  }

  /**
   * Recharge result query
   * @param {string} address
   * @param {number} generate_time
   * @returns checkDepositListType
   */
  checkDeposit(data: { address: string; generate_time: number }): Promise<checkDepositListType[]> {
    return http.postJson('/trade/deposit/check', data);
  }

  /**
   * Recharge result block height query
   * @param {string} chain
   * @param {string} address
   * @returns queryBlockHeightRes
   */
  queryBlockHeight(chain: string, address: string, symbol: string): Promise<queryBlockHeightRes> {
    return http.postJson('/trade/block/height', { chain, address, symbol });
  }

  /**
  * get network list 
  */
  queryNetwork(): Promise<queryNetworkType[]> {
    return http.postJson('/trade/network/list');
  }

  /**
  * get Permanent Address Management list 
  */
  queryAddressList(params: addressParamsType): Promise<adressListRes> {
    return http.postJson('/trade/address/direct/list', { ...params });
  }

}

export type addressParamsType = {
  key?: string, network?: string, page: number, page_size: number
}
export interface adressListRes {
  list: {
    user_id: string;
    network: string;
    address: string;
    memo: string;
    created: number;
    received_times: number;
    [key: string]: any;
  }[];
  address_total: number;
  remain_address_total: number;
  received_total: number;
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
}

export type queryNetworkType = {
  chain_id: string;
  network: string;
}
export interface queryBlockHeightRes {
  chain: string;
  current_chain_height: number;
  tx_confirm_block_num: number;
  block_height: number;
  paid_amount: string;
  symbol: string;
}

export type checkDepositListType = {
  record_id: string;
  bill_id: string;
  type: string;
  action: string;
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  status: string;
  em: number;
  txid?: string;
  txid_url?: string;
};

export type selectAndFilterType = {
  bill_type: {
    label: string;
    value?: number;
  }[];
  stats: string[];
  tokens: string[];
  types: string[];
};

export type OrderLsitType = {
  list: {
    id: number;
    merchant_id: number;
    merchant_name: string;
    /** trading ID*/
    bill_id: string;
    symbol: string;
    /**Chain ID*/
    chain_id: string;
    contract: string;
    logo: string;
    amount: string;
    em: number;
    created: number;
    updated: number;
    record_id: string;
    type: string;
    /**
      * Status: Pending pending payment;
      * Processing has detected user payment and is waiting for transaction;
      * Expired expired;
      * Success The transaction is successful;
      * Failed Transaction failed (including payment amount < order amount). Expires are not included here
      * */
    status:
    | 'Pending'
    | 'Processing'
    | 'Expired'
    | 'Successful'
    | 'Failed'
    | 'Overpaid'
    | 'Underpaid'
    | 'Refund';
    pay_time: number;
    token_price: string;
  }[];
  total: number;
  total_value: string;
};

export type orderDetailType = {
  bill_id?: string;
  action?: string;
  amount?: string;
  chain_id?: string;
  contract?: string;
  created?: number;
  from_address?: string;
  from_cwallet_id?: string;
  logo?: string;
  origin_amount?: string;
  pay_time?: number;
  record_id?: string;
  refund_mark?: string;
  refund_records?: null;
  remark?: string;
  symbol?: string;
  to_address?: string;
  to_cwallet_id?: string;
  /**Operation type
    * swap currency exchange
    * API/Email Billing/Checkout Button/Checkout URL to place an order
    * Refund Refund
    * Deposit recharge
    * Withdraw Withdrawal
    */
  type?:
  | 'Withdrawal'
  | 'Swap'
  | 'Refund'
  | 'Deposit'
  | 'API'
  | 'Email Billing'
  | 'Checkout Button'
  | 'Checkout URL';
  rate?: string;
  swap_from_amount?: string;
  swap_from_chain_id?: string;
  swap_from_contract?: string;
  swap_from_symbol?: string;
  swap_to_amount?: string;
  swap_to_chain_id?: string;
  swap_to_contract?: string;
  swap_to_symbol?: string;
  detail?: {
    bill_id?: string;
    type?:
    | 'Withdrawl'
    | 'Swap'
    | 'Refund'
    | 'Deposit'
    | 'API'
    | 'Email Billing'
    | 'Checkout Button'
    | 'Checkout URL';
    action?: string;
    refund_amount?: string;
    to_address?: string;
    to_cwallet_id?: string;
    is_inner_tx?: string;
    txid?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    logo?: string;
    created?: number;
    pay_time?: number;
    paid_amount?: string;
    origin_amount?: string;
    from_address?: string;
    from_cwallet_id?: string;
    refund_status?: string;
    can_refund?: boolean;
    [key: string]: any;
  };
  from_record?: {
    bill_id?: string;
    amount?: string;
    origin_amount?: string;
    from_address?: string;
    from_cwallet_id?: string;
    em?: number;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    logo?: string;
    created?: number;
    pay_time?: number;
    [key: string]: any;
  };
  refund_record?: {
    amount?: string;
    to_address?: string;
    created?: number;
    pay_time?: number;
    status?: string;
    em?: number;
    to_cwallet_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    bill_id?: string;
    logo?: string;
    [key: string]: any;
  }[];
  extra_trade_record?: {
    chain_id?: string;
    contract?: string;
    symbol?: string;
    amount?: string;
    from_address?: string;
    is_inner_tx?: number;
    txid?: string;
    service_fee?: string;
    logo?: string;
    created?: number;
    [key: string]: any;
  }[];
  [key: string]: any;
};
export type collectedFeesType = {
  value: string, symbol: string
}

export type orderDetailTypeOld = {
  id?: number;
  merchant_id?: number;
  merchant_name?: string;
  /**order id*/
  bill_id?: string;
  /**token*/
  symbol?: string;
  /**chain ID*/
  chain_id?: string;
  contract?: string;
  logo?: string;
  /**Actual transaction amount*/
  amount?: string;
  /**Direction of capital flow, 1 for incoming, 2 for outgoing*/
  em?: number;
  /**On-chain transaction id*/
  txid?: string;
  created?: number;
  updated?: number;
  from_address?: string;
  to_address?: string;
  from_memo?: string;
  /**When canceling an order, the original order id*/
  refund_bill_id?: string;
  deposit_bill_id?: string;
  uuid?: string;
  trade_info?: string;
  /**total transaction record id*/
  record_id?: string;
  /**Operation type
     * swap currency exchange
     * API/Email Billing/Checkout Button/Checkout URL to place an order
     * Refund Refund
     * Deposit recharge
     * Withdraw Withdrawal
     */
  type: string;
  /**Refund*/
  action?: string;
  network?: string;
  /**The amount of the order placed, the amount of the refund application*/
  origin_amount?: string;
  /**source wallet - internal, such as payment*/
  from_cwallet_id?: string;
  /**Into wallet - internal, such as refund*/
  to_cwallet_id?: string;
  /**Transaction time milliseconds ms*/
  pay_time?: number;
  /**Order expiration time in milliseconds ms*/
  expired_timestamp?: number;
  /**
    * Status: Pending pending payment;
    * Processing has detected user payment and is waiting for transaction;
    * Expired expired;
    * Success The transaction is successful;
    * Failed Transaction failed (including payment amount < order amount). Expires are not included here
    * */
  status?:
  | 'Pending'
  | 'Processing'
  | 'Expired'
  | 'Successful'
  | 'Failed'
  | 'Overpaid'
  | 'Underpaid'
  | 'Refund';
  /** Network Fee*/
  network_fee?: string;
  /**Fiat currency at the time of payment*/
  fiat_name?: string;
  /**When paying, 1 US dollar =? French currency*/
  fiat_rate?: string;
  remark?: string;
  to_memo?: string;
  /**The current unit price of the token is usd*/
  token_price?: string;
  service_fee?: string;
  /**exchange rate*/
  rate?: string;
  is_inner_tx?: boolean;
  /**Amount in fiat currency when placing an order*/
  fiat_amount?: string;
  /**Platform service fee record id*/
  service_fee_record_id?: string;
  invoice: string;
  /** merchant true   user false */
  merchant_pays_fee?: boolean;
  address_number?: number;
  collected_fees?: collectedFeesType[]
  [key: string]: any;
};
