import { configure } from 'mobx';
import { createContext, useContext } from 'react';
import WalletStroe from './WalletStroe';
import WalletWebhookStroe from '@/pages/WalletApi/common/stores/webhook';
import WalletTransactionStroe from '@/pages/WalletApi/common/stores/transaction';
import WalletUserOrdersStroe from '@/pages/WalletApi/common/stores/user-orders';
configure({ enforceActions: 'never' });

export const walletWebhookStroe = new WalletWebhookStroe();

export const walletTransactionStroe = new WalletTransactionStroe();

export const walletUserOrdersStroe = new WalletUserOrdersStroe();

export const walletStroe = new WalletStroe();

function createStores() {
  return {
    walletStroe,
    walletWebhookStroe,
    walletTransactionStroe,
    walletUserOrdersStroe,
  };
}
const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export { stores, useStores };
