import styled from 'styled-components';

import { Dialog, DialogContent, styled as MuiStyled, Typography } from '@mui/material';

export const WebhookWrap = styled.div`
  .top-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    margin-top: 24px;
    .left {
      > .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #27291e;
        margin-bottom: 4px;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #73756d;
      }
    }
    .right {
      display: flex;
      align-items: end;
      .right-btn {
        padding: 6px 16px;
        border: 1px solid #82993c;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #82993c;
      }
    }
  }

  > .data-table {
    background: #ffffff;
    box-shadow: 0px 0px 32px rgba(182, 184, 176, 0.24);
    border-radius: 16px;
  }
`;

export const TopSearchFormWrap = styled.div`
  padding: 24px 20px 0px;
  display: flex;
  /* flex-wrap: wrap; */
`;

export const TransactionTopSearchFormWrap = styled.div`
  padding: 24px 20px 0px;
  .top-search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    .left-input {
      flex-shrink: 0;
      width: 500px;
    }
    .right-checkbox {
      display: flex;
      align-items: center;
      i {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
    }
  }
  .bottom-search-bar {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    .left-select-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .reset-btn-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 56px;
    }
  }
`;

export const RefundDialog = MuiStyled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },

  '& .MuiDivider-root': {
    borderStyle: 'dashed',
  },

  '& .refundDialogContent': {
    padding: '0px 0px 24px',
  },
}));

export const RefundDialogContent = MuiStyled(DialogContent)(({ theme }) => ({
  width: 480,
  '::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '12px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
    backgroundColor: 'rgba(99, 115, 129, 0.3)',
  },
}));

export const TitleTypography = MuiStyled(Typography)(({ theme }) => ({
  color: '#919EAB',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px',
}));

export const ContentTypography = MuiStyled('span')(({ theme }) => ({
  wordWrap: 'break-word',
  color: '#1E1E1E',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));
