import { Http } from '@/api/http';
const http = new Http('/merchant/console/v1');

export default class assetCollectionApi {
  /**
   * Get Token Relationship list 
   */
  getCoinTokenList(): Promise<getCoinTokenListResType> {
    return http.postJson('/coin/token/list', {});
  }

  /**
   * Get token collection configuration list 
   */
  getTokenConfigList(): Promise<getTokenConfigListResType> {
    return http.postJson('/collect/token/config/list', {});
  }

  /**
   * Get token collection configuration edit list 
   */
  getTokenConfigEditList(): Promise<getTokenConfigEditListResType> {
    return http.postJson('/collect/token/config/edit/list', {});
  }

  /**
   * Get the collection master configuration
   */
  getCollectCofig(): Promise<getCollectCofigResType> {
    return http.postJson('/collect/config/get', {});
  }

  /**
   * Save the collection master configuration
   */
  saveCollectCofig(data: getCollectCofigResType) {
    return http.postJson('/collect/config/save', data);
  }

  /**
   * Submit collection task
   */
  submitCollectConfig(data: submitCollectConfigParamType) {
    return http.postJson('/collect/token/config/submit', data);
  }

  /**
   * Modify collection tasks in batches
   */
  submitBatchCollectConfig(data: submitBatchCollectConfigParamType[]) {
    return http.postJson('/collect/token/config/batch/save', { Data: data });
  }

  /**
   * get network fee
   */
  getCollectNetworkFee(data: getCollectNetworkFeeParamType): Promise<getCollectNetworkFeeResType> {
    return http.postJson('/collect/network/fee', data);
  }

  /**
   * Query Supplementary Network Fee
   */
  querySupplyCollectNetworkFee(
    data: querySupplyCollectNetworkFeeParamType
  ): Promise<querySupplyCollectNetworkFeeResType> {
    return http.postJson('/transaction/supply/network_fee/list', data);
  }

  /**
   * Get the configuration details of a single token collection
   */
  getCurrentCollectConfig(
    data: getCurrentCollectConfigParamType
  ): Promise<getCurrentCollectConfigResType> {
    return http.postJson('/collect/token/config/get', data);
  }

  /**
   * Collection records list 
   */
  getRecordsList(data: getRecordsListParamType): Promise<getRecordsListResType> {
    return http.postJson('/collect/records/list', data);
  }

  /**
   * Collection records list  details
   */
  getRecordsListDetails(
    data: getRecordsListDetailsParamType
  ): Promise<getRecordsListDetailsResType> {
    return http.postJson('/collect/records/detail', data);
  }

  /**
   * Query address assets
   */
  getCollectAddressBalance(
    data: getCollectAddressBalanceParamType
  ): Promise<getCollectAddressBalanceResType> {
    return http.postJson('/collect/address/balance', data);
  }
}

export type getCollectAddressBalanceResType = {
  Balance: string;
};

export type getCollectAddressBalanceParamType = {
  ChainId: string;
  Contract: string;
  Address: string;
};

export type detailsType = {
  TransactionId: number;
  ChainId: string;
  Contract: string;
  Fee: string;
  Status: number;// Started: 0, In Progress: 1, Success: 2, Partial Success: 3, Failed -3
  Amount: string;
  TxId: string;
  TransferFeeAmount: string;
  FailReason: string;
  FeeChainId: string;
  FeeContract: string;
  Stage: number;
  StageList: {
    TransactionId: number;
    TxId: string;
    TransactionStatus: number;
    Fee: string;
    Amount: string;
    FailReason: string;
    FeeChainId: string;
    FeeContract: string;
    Stage?: number;
  }[];
};

export type getRecordsListDetailsResType = {
  List: detailsType[];
};

export type getRecordsListDetailsParamType = {
  MainScheduleId: number;
  ChainId: string;
  Contract: string;
};
export type recordslistType = {
  Id: number;
  ChainId: string;
  Contract: string;
  CollectAddressNumber: number;
  FinishAddressNumber: number;
  CollectTotalAsset: string;
  Progress: number; //Task completion progress
  Status: number;
  CreateTime: number;
};

export type getRecordsListResType = {
  Pagination: {
    PageIndex: number;
    PageSize: number;
    Total: number;
  };
  List: recordslistType[];
};

export type getRecordsListParamType = {
  Status: number;
  PageIndex: number;
  PageSize: number;
};

export type getCurrentCollectConfigResType = {
  FeeChainId: string;
  FeeContract: string;
  TotalAsset: string;
  EstimatedTotalFee: string;
  EstimatedPayFee: string;
  CollectAddressNumber: number;
  CollectTotalAsset: string;
  PayFeeAddress: string;
  PayFeeBalance: string;
  NeedFee: string;
  NeedDepositFee: boolean;
  TransferFeeAmount: string;
  Failure: any[];
};

export type getCurrentCollectConfigParamType = {
  ChainID: string;
  Contract: string;
  MiniImputationAmount: string;
  CollectEfficiency: number;
  MaxCollectFeeRate: string;
  RefillNetFeeMultiple: number;
};

export type querySupplyCollectNetworkFeeResType = {
  List: querySupplyCollectNetworkFeeItemType[];
};

export type querySupplyCollectNetworkFeeItemType = {
  MerchantId: string;
  ChainId: string;
  Contract: string;
  Symbol: string;
  TxId: string;
  Address: string;
  Amount: string;
  Extra: {
    [key: string]: any;
  };
  CreatedAt: number;
  Status: number;
};

export type querySupplyCollectNetworkFeeParamType = {
  ChainId: string;
  Address: string;
  BeginAt: number;
};

export type getCollectNetworkFeeResType = {
  FeeChainId: string;
  FeeContract: string;
  ProtocolFamily: string;
  FeeRate: string;
  FeeLimit: number;
  FeeAmount: string;
  FixedCost: string;
  ConstantFlag: boolean;
};

export type getCollectNetworkFeeParamType = {
  ChainId: string;
  Contract: string;
};

export type submitBatchCollectConfigParamType = {
  Id: number;
  ChainId: string;
  Contract: string;
  AutoCollectAmount: string;
  MiniImputationAmount: string;
  CollectionEfficiency: number;
};

export type submitCollectConfigParamType = {
  ChainID: string;
  Contract: string;
  MinimumCollectAmountPerOne: string;
  CollectEfficiency: number;
  MaxCollectFeeRate: string;
  CollectAddressNumbers: number;
};

export type getTokenConfigEditListResType = {
  List: getTokenConfigEditListItemType[];
};

export type getTokenConfigEditListItemType = {
  Id: number;
  ChainId: string;
  Contract: string;
  ProtocolFamily: string;
  AutoCollectAmount: string;
  MinimumImputationAmount: string;
  CollectionEfficiency: number;
  MaxNetworkFee: string;
};

export type getCollectCofigResType = {
  AutoCollectionForWithdrawal: number;
  RefillNetFeeMultiple: number;
  AutoCollectionFrequency: number;
};

export type getCoinTokenListResType = {
  Tokens: {
    [key: string]: CoinTokenType;
  };
};

export type CoinTokenType = {
  ChainId: string;
  Contract: string;
  Symbol: string;
  Type: string;
  CoinId: string;
  CoinName: string;
  CoinSymbol: string;
};

export type getTokenConfigListResType = {
  List: getTokenConfigListItemType[];
};

export type getTokenConfigListItemType = {
  Id: number;
  ChainId: string;
  Contract: string;
  ProtocolFamily: string;
  AutoCollectAmount: string;
  MinimumImputationAmount: string;
  CollationEfficiency: number;
  CollectionEfficiency: number;
  /** Estimated collection amount*/
  EstimatedCollectionAmount: string;
  /** Maximum network fee limit*/
  MaxNetworkFee: string;
  FeeChainId: string;
  FeeContract: string;
  /** gwei*/
  FeeRate: string;
  FeeAmount: string;
};
