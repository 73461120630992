import AccountApi from './accountApi';
import WalletApi from './WalletApi';
import SecurityApi from './security';
import DashboardApi from './dashboardApi';
import OrderApi from './orderApi';
import TokenApi from './token';
import InvoiceApi from './invoiceApi';
import SuggestApi from './suggestApi';
import WebhookApi from './webhook-api';
import PaymentApi from './payment-api';
import ReferralApi from './ReferralApi';
import VertificationApi from './vertificationApi';
export { default as http } from './http';

export const accountApi = new AccountApi();
export const walletApi = new WalletApi();
export const securityApi = new SecurityApi();
export const dashboardApi = new DashboardApi();
export const orderApi = new OrderApi();
export const tokenApi = new TokenApi();
export const invoiceApi = new InvoiceApi();
export const suggestApi = new SuggestApi();
export const webhookApi = new WebhookApi();
export const paymentApi = new PaymentApi();
export const referralApi = new ReferralApi();
export const vertificationApi = new VertificationApi();