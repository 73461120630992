import { MyIcon } from '@/assets/icons/MyIcon';
import { walletNotificationApi } from '@/pages/WalletApi/common/api';
import { Alert, LoadingButton } from '@mui/lab';
import { Button, Card, Checkbox, FormControlLabel } from '@mui/material';
import { createContext, useEffect, useMemo, useState } from 'react';
import { NotificationSettingsWrap } from './style';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useLocales } from '@/locales';
import MerchantLoadingNoPosition from '@/layouts/dashboard/nav/components/merchant-loading-no-position';
import DialogBindTgTokenForm from './dialog/bind-tg-token-form';
import DialogChangeTgTokenConfirm from './dialog/change-tg-token-confirm';
import DialogChangeTgTokenForm from './dialog/change-tg-token-form';
import DialogBindInviteTgForm from './dialog/bind-invite-tg-form';
import DialogChangeInviteTgForm from './dialog/change-invite-tg-form';
import DialogRemoveTgConfirm from './dialog/remove-tg-comfirm';
import { botStatusResType } from '@/pages/WalletApi/common/api/notification';
import { TGBASEURL } from '@/config';

export enum walletNotificationEventEnum {
  'BalanceInsufficient' = 100,
  'networkInsufficient' = 101,
  'WebhookResponseFailed' = 102,
  'DepositSuccessful' = 103,
  'WithdrawSuccessful' = 104,
}

export const NotificationContext = createContext<{
  BotName: string;
  GroupName: string;
  BotCode: string;
}>({ BotName: '', GroupName: '', BotCode: '' });

export default function Index() {
  const { translate: t } = useLocales();
  const [tgStatus, setTgStatus] = useState<botStatusResType>({
    Status: 1,
    BotName: '',
    GroupName: '',
    BotCode: '',
  });

  const [settingList, setSettingList] = useState([
    {
      value: walletNotificationEventEnum.WithdrawSuccessful,
      title: t('wallet.account.setting.notification.list.title1'),
      desc: t('wallet.account.setting.notification.list.desc1'),
      email: 0,
      system: 0,
      tg: 0,
    },
    {
      value: walletNotificationEventEnum.networkInsufficient,
      title: t('wallet.account.setting.notification.list.title2'),
      desc: t('wallet.account.setting.notification.list.desc2'),
      email: 0,
      system: 0,
      tg: 0,
    },
    {
      value: walletNotificationEventEnum.BalanceInsufficient,
      title: t('wallet.account.setting.notification.list.title3'),
      desc: t('wallet.account.setting.notification.list.desc3'),
      email: 0,
      system: 0,
      tg: 0,
    },
    {
      value: walletNotificationEventEnum.WebhookResponseFailed,
      title: t('wallet.account.setting.notification.list.title4'),
      desc: t('wallet.account.setting.notification.list.desc4'),
      email: 0,
      system: 0,
      tg: 0,
    },
  ]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  // const [configLoading, setConfigLoading] = useState(false);
  const [bindTgTokenFormOpen, setBindTgTokenFormOpen] = useState(false);
  const [changeTgTokenConfirmOpen, setChangeTgTokenConfirmOpen] = useState(false);
  const [changeTgTokenFormOpen, setChangeTgTokenFormOpen] = useState(false);
  const [bindInviteTgFormOpen, setBindInviteTgFormOpen] = useState(false);
  const [changeInviteTgFormOpen, setChangeInviteTgFormOpen] = useState(false);
  const [removeTgConfirmOpen, setRemoveTgConfirmOpen] = useState(false);

  const getNotificationConfig = async () => {
    // setConfigLoading(true);
    try {
      const res = await walletNotificationApi.getNotificationConfig();
      if (res) {
        const arr = res
          .map((item) => {
            const tempObj = { ...item, email: 0, system: 0, tg: 0 };
            switch (item.Method) {
              case 1:
                tempObj.system = 1;
                break;
              case 2:
                tempObj.email = 2;
                break;
              case 3:
                tempObj.email = 2;
                tempObj.system = 1;
                break;
              case 4:
                tempObj.tg = 4;
                break;
              case 5:
                tempObj.system = 1;
                tempObj.tg = 4;
                break;
              case 6:
                tempObj.email = 2;
                tempObj.tg = 4;
                break;
              case 7:
                tempObj.email = 2;
                tempObj.system = 1;
                tempObj.tg = 4;
                break;
              default:
                break;
            }

            return tempObj;
          })
          .filter((item) => item.Event !== 103);

        const arr2 = settingList.map((item) => {
          const obj = { ...item };
          if (arr.filter((it) => it.Event === item.value).length > 0) {
            const findObj = arr.filter((it) => it.Event === item.value)[0];
            obj.email = findObj.email;
            obj.system = findObj.system;
            obj.tg = findObj.tg;
          }
          return obj;
        });
        setSettingList(arr2);
      }
      console.log('res', res);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log('error', { error });
    } finally {
      // setConfigLoading(false);
    }
  };

  const botStatus = async () => {
    try {
      const res = await walletNotificationApi.botStatus();
      setTgStatus(res);
    } catch (error) {
      console.log('error', { error });
    }
  };

  const tgFlag = useMemo(() => {
    let flag = false;
    if ([2].includes(tgStatus.Status)) {
      flag = true;
    }
    return flag;
  }, [tgStatus.Status]);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    console.log('settingList', settingList);
    try {
      const param = settingList.map((item) => ({
        Event: item.value,
        Method: item.email | item.system | item.tg,
      }));
      await walletNotificationApi.putNotificationConfig([
        ...param,
        {
          Event: walletNotificationEventEnum.DepositSuccessful,
          Method:
            param.find((item) => item.Event === walletNotificationEventEnum.WithdrawSuccessful)
              ?.Method || 1,
        },
      ]);
      setSubmitLoading(false);
      enqueueSnackbar(t('common.Successful'));
      await getNotificationConfig();
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setSubmitLoading(false);
    }
  };

  const getTgAlert = (Status: number) => {
    let Content = <></>;
    switch (Status) {
      case 1:
        Content = (
          <Alert
            severity="info"
            variant="outlined"
            action={
              <div className="alert-action-wrap">
                <Button
                  onClick={() => {
                    setBindTgTokenFormOpen(true);
                  }}
                >
                  {t('wallet.account.setting.notification.alert.Start')}
                </Button>
              </div>
            }
          >
            {t('wallet.account.setting.notification.alert.desc1')}
          </Alert>
        );
        break;
      case 2:
        Content = (
          <Alert
            severity="info"
            variant="outlined"
            action={
              <div className="alert-action-wrap">
                <Button
                  onClick={() => {
                    setChangeTgTokenConfirmOpen(true);
                  }}
                >
                  {t('wallet.account.setting.notification.alert.Change')}
                </Button>
                <Button
                  className="remove-btn"
                  variant="outlined"
                  onClick={() => {
                    setRemoveTgConfirmOpen(true);
                  }}
                >
                  {t('wallet.account.setting.notification.alert.Remove')}
                </Button>
              </div>
            }
          >
            {t('wallet.account.setting.notification.alert.desc2', {
              GroupName:
                tgStatus.GroupName ||
                t('wallet.account.setting.notification.alert.TelegramgroupName'),
            })}
          </Alert>
        );
        break;
      case 3:
        Content = (
          <Alert
            className="alert-abnormal"
            severity="warning"
            variant="outlined"
            action={
              <div className="alert-action-wrap">
                <Button
                  onClick={() => {
                    init();
                  }}
                >
                  {t('wallet.account.setting.notification.alert.Check')}
                </Button>
                <Button
                  className="abnormal-change-btn"
                  variant="outlined"
                  onClick={() => {
                    setChangeInviteTgFormOpen(true);
                  }}
                >
                  {t('wallet.account.setting.notification.alert.Change')}
                </Button>
              </div>
            }
          >
            {t('account.setting.notification.alert.desc3')}{' '}
            <strong
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                tgStatus.BotName && window.open(`${TGBASEURL}${tgStatus.BotName}`);
              }}
            >
              @{tgStatus.BotName || t('account.setting.notification.alert.merchantbot')}
            </strong>{' '}
            {t('account.setting.notification.alert.desc4')}
          </Alert>
        );
        break;
      default:
        <></>;
        break;
    }
    return Content;
  };

  const init = async () => {
    setInitLoading(true);
    try {
      await botStatus();
      await getNotificationConfig();
    } catch (error) {
      console.log('error', error);
    } finally {
      setInitLoading(false);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  if (initLoading) {
    return (
      <Card sx={{ width: '100%', height: '100vh', boxShadow: 'none' }}>
        <MerchantLoadingNoPosition width="100%" />
      </Card>
    );
  }

  return (
    <NotificationContext.Provider
      value={{
        BotName: tgStatus.BotName,
        GroupName: tgStatus.GroupName,
        BotCode: tgStatus.BotCode,
      }}
    >
      <NotificationSettingsWrap>
        <div className="top-wrap">
          {/* tg top info start */}
          {getTgAlert(tgStatus.Status)}
          {/* tg top info start */}

          {/* setting list  */}
          <div className="setting-wrap">
            {settingList.length > 0 &&
              settingList.map((item, index) => (
                <div className="setting-item-wrap" key={item.value}>
                  <div className="left-wrap">
                    <div className="top-title">{item.title}</div>
                    <div className="bottom-desc">{item.desc}</div>
                  </div>
                  <div className="right-wrap">
                    {/* email */}
                    {item.value === walletNotificationEventEnum.WebhookResponseFailed && (
                      <FormControlLabel
                        // className="check-readonly"
                        onChange={(e: any) => {
                          const arr = cloneDeep(settingList);
                          if (e.target?.checked) {
                            arr[index].email = 2;
                          } else {
                            arr[index].email = 0;
                          }
                          setSettingList(arr);
                        }}
                        control={<Checkbox checked={Boolean(item.email)} />}
                        label={t('wallet.account.setting.notification.alert.Email')}
                      />
                    )}

                    {/* system */}
                    {item.value === walletNotificationEventEnum.WithdrawSuccessful ? (
                      <FormControlLabel
                        className="check-readonly"
                        control={<MyIcon name="check_box_ok" size={24} />}
                        label={t('wallet.account.setting.notification.alert.Reminders')}
                      />
                    ) : (
                      <FormControlLabel
                        onChange={(e: any) => {
                          const arr = cloneDeep(settingList);
                          if (e.target?.checked) {
                            arr[index].system = 1;
                          } else {
                            arr[index].system = 0;
                          }
                          setSettingList(arr);
                        }}
                        control={<Checkbox checked={Boolean(item.system)} />}
                        label={t('wallet.account.setting.notification.alert.Reminders')}
                      />
                    )}
                    {/* tg */}
                    <FormControlLabel
                      onChange={(e: any) => {
                        const arr = cloneDeep(settingList);
                        if (e.target?.checked) {
                          arr[index].tg = 4;
                        } else {
                          arr[index].tg = 0;
                        }
                        setSettingList(arr);
                      }}
                      control={
                        <Checkbox
                          checked={Boolean(item.tg)}
                          readOnly={!tgFlag}
                          disabled={!tgFlag}
                          indeterminate={!tgFlag}
                        />
                      }
                      label={t('wallet.account.setting.notification.alert.Telegram.group')}
                    />
                  </div>
                </div>
              ))}
          </div>
          {removeTgConfirmOpen && (
            <DialogRemoveTgConfirm
              open={removeTgConfirmOpen}
              onClose={(v) => {
                if (v) {
                  init();
                }
                setRemoveTgConfirmOpen(false);
              }}
            />
          )}
          {bindInviteTgFormOpen && (
            <DialogBindInviteTgForm
              code={tgStatus.BotCode}
              open={bindInviteTgFormOpen}
              onClose={(v) => {
                if (v) {
                  init();
                }
                setBindInviteTgFormOpen(false);
              }}
            />
          )}

          {changeInviteTgFormOpen && (
            <DialogChangeInviteTgForm
              code={tgStatus.BotCode}
              open={changeInviteTgFormOpen}
              onClose={(v) => {
                if (v) {
                  init();
                }
                setChangeInviteTgFormOpen(false);
              }}
            />
          )}

          {changeTgTokenConfirmOpen && (
            <DialogChangeTgTokenConfirm
              open={changeTgTokenConfirmOpen}
              onClose={(v) => {
                if (v === 1) {
                  setChangeTgTokenFormOpen(true);
                } else if (v === 2) {
                  setChangeInviteTgFormOpen(true);
                }
                setChangeTgTokenConfirmOpen(false);
              }}
            />
          )}

          {changeTgTokenFormOpen && (
            <DialogChangeTgTokenForm
              open={changeTgTokenFormOpen}
              onClose={(v) => {
                if (v) {
                  setTgStatus({
                    ...tgStatus,
                    BotCode: v.Code,
                    BotName: v.BotName,
                  });

                  setBindInviteTgFormOpen(true);
                }
                setChangeTgTokenFormOpen(false);
              }}
            />
          )}

          {bindTgTokenFormOpen && (
            <DialogBindTgTokenForm
              open={bindTgTokenFormOpen}
              onClose={(v) => {
                if (v) {
                  setTgStatus({
                    ...tgStatus,
                    BotCode: v.Code,
                    BotName: v.BotName,
                  });
                  setBindInviteTgFormOpen(true);
                }
                setBindTgTokenFormOpen(false);
              }}
            />
          )}
        </div>
        {/* save btn */}
        <div className="bottom-wrap loading-btn-green">
          <LoadingButton
            variant="contained"
            color="primary"
            loadingIndicator={
              <MyIcon name="loading_green" className="loading-icon-rotate" size={18} />
            }
            loading={submitLoading}
            onClick={handleSubmit}
          >
            <span style={{ opacity: submitLoading ? 0 : 1 }}>
              {t('wallet.account.setting.notification.Save.Settings')}
            </span>
          </LoadingButton>
        </div>
      </NotificationSettingsWrap>
    </NotificationContext.Provider>
  );
}
