import { Http } from '@/api/http';
import { coinAllListRes } from './coinAllListRes';
const http = new Http('/merchant/console/v1');

export default class coinlistApi {
  /**
   * add coin
   * @param coin_id string
   * @param symbol string
   * @param name string
   * @param logo_url string
   * @returns {string}
   */
  addCoin(data: addCoinListParamType) {
    return http.postJson('/coin/list', { ...data });
  }

  /**
   * edit coin
   * @param coin_id string
   * @param symbol string
   * @param name string
   * @param logo_url string
   * @returns {string}
   */
  editCoin(data: editCoinListParamType) {
    return http.postJson('/coin/edit', { ...data });
  }

  /**
   * check coin token
   * @param chain_id string
   * @param contract string
   * @param symbol string
   * @param type string
   * @returns {string}
   */
  checkCoinToken(data: checkCoinTokenParamType) {
    return http.postJson('/coin/token/check', { ...data });
  }

  /**
   * one token control
   * @param chain_id string
   * @param contract string
   * @param status number
   * @returns {string}
   */
  oneTokenControl(data: oneTokenControlParamType) {
    return http.postJson('/coin/token/control', { ...data });
  }

  /**
   * batch token control
   * @param tokens {}[]
   * @param chain_id string
   * @param contract string
   * @param status number
   * @returns {string}
   */
  batchTokenControl(data: batchTokenControlParamType) {
    return http.postJson('/coin/token/batch_control', { ...data });
  }

  /**
   * coin token list
   * @param coin_id string
   * @returns {string}
   */
  coinTokenListControl(data: coinTokenListParamType) {
    return http.postJson('/coin/token/batch_control', { ...data });
  }

  /**
   * coin token upload file
   * @param file file
   * @returns {string}
   */
  upLoad(data: { file: File }) {
    let formData = new FormData();
    formData.append('file', data.file);
    return http.postForm('/coin/file/upload', formData);
  }

  /**
   * get coin list type
   * @param token_id string
   * @param status number
   * @returns {string}
   */
  getCoinListType(data: getCoinListTypeParamType) {
    return http.postJson('/coin/type/list', { ...data });
  }

  /**
   * get coin networks list
   * @returns {string}
   */
  getCoinNetworksList() {
    return http.postJson('/coin/networks/list');
  }

  /**
   * edit coin token
   * @param coin_id string
   * @param chain_id string
   * @param contract string
   * @param type string
   * @returns {string}
   */
  editCoinToken(data: editCoinTokenParamType) {
    return http.postJson('/coin/token/edit', { ...data });
  }

  /**
   * delete coin token
   * @param coin_id string
   * @param chain_id string
   * @param contract string
   * @returns {string}
   */
  deleteCoinToken(data: deleteCoinTokenParamType) {
    return http.postJson('/coin/token/delete', { ...data });
  }

  /**
   * get coin all
   * @returns {string}
   */
  getCoinAll(): Promise<coinAllListRes> {
    return http.postJson('/coin/all', {});
  }

  /**
   * coin list
   * @param Key string
   * @param Status number
   * @returns {string}
   */
  getCoinList(data: getCoinListParamType): Promise<coinListRes> {
    return http.postJson('/coin/list', { ...data });
  }

  /**
   * coin list network under token list 
   * @param CoinId string
   * @returns {string}
   */
  getCoinChainList(data: getCoinChainListParamType): Promise<CoinChainListRes> {
    return http.postJson('/coin/chain/list', { ...data });
  }

  /**
   * get all  chain
   */
  getChainAll(): Promise<getChainAllResType> {
    return http.postJson('/coin/chain/all', {});
  }

  // 20230623
  /** coin list  list 
   * Status number
   */
  getNewCoinList(data: getNewCoinListParamType): Promise<newCoinChainListRes> {
    return http.postJson('/coinlist/list', { ...data });
  }

  /** Single/batch enable/disable withdrawal
   * CoinId string[]
   * Status number
   */
  getNewOpenorbanWithdraw(data: getNewOpenorParamType) {
    return http.postJson('/coinlist/openorban_withdraw', { ...data });
  }

  /** coin info add coin list list 
   */
  getNewCoinInfo(): Promise<getCoinResType> {
    return http.postJson('/coinlist/coininfo', {});
  }

  /**  list  delist
   * CoinId string
   * Status number 30 10
   */
  getNewDelistorlist(data: getNewDelistorlistParamType): Promise<newCoinChainListRes> {
    return http.postJson('/coinlist/delistorlist', { ...data });
  }

  /** coin list info  details  
   * CoinId string
   */
  getNewInfoListDetails(
    data: getNewInfoListDetailsParamType
  ): Promise<gettNewInfoListDetailsResType> {
    return http.postJson('/coinlist/coindetails', { ...data });
  }

  /** coin list info details
   *
   */
  getNewSaveDetails(data: getNewSaveDetailsParamType) {
    return http.postJson('/coinlist/save_coindetails', { ...data });
  }

  /** Get the minimum exchange number of coins
   *
   */
  getWithdrawmin(data: getWithdrawminParamType) {
    return http.postJson('/coinlist/getcoin_withdrawmin', { ...data });
  }
}

export type getWithdrawminParamType = {
  CoinId: string;
  Symbol: string;
  ChainId: string;
  Contract: string;
};

//20230623
export type getNewSaveDetailsParamType = {
  CoinId: string;
  Fee: number;
  Status: number; //10:Enabled 20:Disable
  DetailMap: {
    [key: string]: infoListType;
  };
};

export type infoListType = {
  Contract: string;
  BaseMinimumWithdraw: number;
  Status: number; //auto cross   0 1
  MinKeepBalance: string;
  MinValuePerTime: string;
  MaximumNetworkFee: number;
  WithdrawMin?: string;
  ProtocolFamily?: string;
}

export type gettNewInfoListDetailsResType = {
  Fee: string;
  DetailMap: {
    [key: string]: infoListType;
  };
};

type getNewInfoListDetailsParamType = {
  CoinId: string;
};

type getNewDelistorlistParamType = {
  CoinId: string;
  IsDelist: number;
};

export type infoItem = {
  Id: string;
  Symbol: string;
  Contract: string;
  Status: number;
  Type: string;
  BaseMinimumWithdraw: string;
  LogoUrl?: string;
  Name?: string;
  isAdd?: boolean;
}

type coinInfoType = infoItem[];

export type getCoinResType = {
  List: {
    [key: string]: coinInfoType;
  };
};

type getNewOpenorParamType = {
  CoinId: string[];
  Status: number;
};

type getNewCoinListParamType = {
  Status: number; //0 all  10 Enabled,20 Disable,30 Delist
};
export interface newCoinChainListRes {
  List: {
    MerchantId: string;
    Coin: {
      Id: string;
      Symbol: string;
    };
    Status: number;
    Fee: string;
    Balance: string;
    ChainsNum: number; //>=1  auto cross 
    IsDelist: Number; //1 delist，0 list
  }[];
}

export type getChainAllResType = {
  Chains: {
    [key: string]: {
      ChainId: string;
      Symbol: string;
      Name: string;
      Explorer: string;
      TxIdUrl: string;
      ChainLogo: string;
      ContractUrl: string;
      Type: string;
    };
  };
};
export interface Payload {
  txid_url: string;
  chain_logo: string;
  chain_network_desc: string;
  chain_display_network: string;
}

export interface CoinChainListRes {
  List: {
    BaseMinimumWithdraw: string;
    Id: string;
    ChainId: string;
    Symbol: string;
    Contract: string;
    Explorer: string;
    Status: number;
    Type: string;
    FeeSwitch: boolean;
  }[];
}
export type filesListRes = {
  BaseMinimumWithdraw?: string;
  Contract?: string;
  MaximumNetworkFee?: string;
  MinKeepBalance?: string;
  MinValuePerTime?: string;
  Symbol?: string;
  Status?: number;
  Type?: string;
  id?: string;
  network?: string;
  targetLiquidity?: number;
  ProtocolFamily?: string;
}

export interface coinListRes {
  List: {
    Coin: {
      Id: string;
      Symbol: string;
    };
    MerchantId: string;
    Symbol: string;
    Name: string;
    Fee: string;
    Balance: string;
    Status: number;
    LogoUrl: string;
  }[];
}
export interface NetworkType {
  merchant_id: string;
  chain_id: string;
  status: number;
  name: string;
  label: string;
}
export interface netWorkRes {
  list: NetworkType[];
}

export type addListParamType = {
  CoinIds: string[];
};

type getCoinListParamType = {
  Key: string;
  Status: number;
};

type getCoinChainListParamType = {
  CoinId: string;
};

type addCoinListParamType = {
  coin: {
    coin_id: string;
    symbol: string;
    name: string;
    logo_url: string;
  };
  network: {
    chain_id: string;
    contract: string;
    type: string;
    fee: string;
    status: number; //20 disable 10 enable
  };
};
type editCoinListParamType = {
  coin_id: string;
  symbol: string;
  name: string;
  logo_url: string;
};

type editCoinTokenParamType = {
  coin_id: string;
  chain_id: string;
  contract: string;
  type: string;
  fee: string;
  status: number;
  old_chain_id: string;
  old_contract: string;
};

type deleteCoinTokenParamType = {
  coin_id: string;
  chain_id: string;
  contract: string;
};

type checkCoinTokenParamType = {
  chain_id: string;
  contract: string;
  symbol: string;
  type: string;
};

type oneTokenControlParamType = {
  chain_id: string;
  contract: string;
  status: number;
};
type batchTokenControlParamType = {
  tokens: {
    chain_id: string;
    contract: string;
    status: number;
  }[];
};
type coinTokenListParamType = {
  coin_id: string;
};
type getCoinListTypeParamType = {
  token_id: string;
  status: number;
};
