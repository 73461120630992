// @mui
import { Typography, Stack, StackProps } from '@mui/material';
//
import Image from '../image';
import { useAuthContext } from '@/auth/useAuthContext';



interface EmptyContentProps extends StackProps {
  title: string;
  img?: string;
  description?: string;
  type?: number;
  onOK?: () => void;
}

export default function EmptyContent({ title, description, img, type, sx, onOK, ...other }: EmptyContentProps) {
  const { user, } = useAuthContext();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        textAlign: 'center',
        padding: type === 1 ? '64px 16px 20px 16px' : (theme) => theme.spacing(8, 2),
        ...sx,
      }}
      {...other}
    >
      <Image
        disabledEffect
        alt="empty content"
        src={img || (user?.mode_type === 1 ? '/assets/illustrations/illustration_empty_content.svg' : '/assets/illustrations/illustration-wallet-nodata.svg')}
        sx={{ height: 160, mb: type === 1 ? 2 : 3 }}
      />

      <Typography variant="h5" gutterBottom sx={{ fontSize: '16px !important', fontWeight: 500 }}>
        {type === 1 &&
          <span style={{ color: '#27291E', fontSize: '16px', fontWeight: 500 }}>{title}</span> || title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
