// routes
import { PATH_DASHBOARD, PATH_WALLET } from './routes/paths';
// API
export enum PaySecurityVerificationFormEnum {
  Swap = 'swap',
  Deposit = 'deposit',
  Withdraw = 'Withdraw',
}

export enum MerchantStatusEnum {
  DisabledStatus = 0,
  GeneralStatus = 1,
  InvStatus = 2,
  APIStatus = 4,
  SwapStatus = 8,
}

export const CCpIntercomColor = { background_color: '#c6d7ff', action_color: '#3c6ff5' };
export const WalletIntercomColor = { background_color: '#e5f9a9', action_color: '#82993c' };
export const TESTEMAILACCOUNTS =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? [
      'mr3oaks@protonmail.com',
      'shirley130@proton.me',
      'jenkinsins@protonmail.com',
      'herculew@protonmail.com',
      'denglw381@gmail.com',
      'benson-ly@protonmail.com',
      'lygit@protonmail.com',
      '6hang@proton.me',
      'momek50304@proton.me',
      'Jasees@proton.me',
      'ccjasee.me@gmail.com',
      'an20cloud@gmail.com',
      'gmou028@gmail.com',
      'cceric@proton.me',
      'rdlucasgm@gmail.com',
      'jared0718@proton.me',
    ]
    : [];

export const TGBASEURL = 'https://t.me/';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === 'true' ? true : false;

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const CCP_URL =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://ccpayment.com/'
    : 'https://712071620cpaymenttest.cwallet.com/ccpayment';

export const currentEnv = process.env.REACT_APP_ENVIRONMENT === 'true' ? 'prd' : 'test';

export const AUTH_CALLBACK_URL =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://admin.ccpayment.com/login_callback.html'
    : 'https://ebc65a6dtestpaymentadmin.ccpayment.com/login_callback.html';

export const AUTH_REFERRAL_URL =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://admin.ccpayment.com'
    : 'https://ebc65a6dtestpaymentadmin.ccpayment.com';

export const HOMEDOMAINV2 =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://console.ccpayment.com'
    : 'https://admin-test-v2.ccpayment.com';

export const CONTACT_US_URL = 'https://t.me/CCPaymentSupportBot';
/**
 * integerToken array of integer tokens
 */
export const integerTokenArr = ['SATS'];

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.one;

export const WALLET_PATH_AFTER_LOGIN = PATH_WALLET.overview;

// LAYOUT

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  W_DRAWER_FIRST: 480,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

/** Contains upper and lower case, special symbols, numbers, 8-16 bits */
export const PWDREG = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/;
/** At least one uppercase letter, one lowercase letter, and one number */
export const PWDREG2 = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
/** Password cannot have spaces  */
export const PWDREG3 = /^(?!\s)(?!.*\s)[^\s]+$/;

/** Whether it is a number, and the length is not greater than 10 */
export const NUMREG = /^[0-9]\d{0,9}$/;
/** Whether it is a number, and the length is not greater than 28 */
export const NUMREG2 = /^[0-9]\d{0,26}$/;

/** */
export const MTMODALREGTEST =
  // eslint-disable-next-line
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const WEBHOOKURLRULE =
  /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
