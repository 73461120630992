import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// components
import { useLocales } from '@/locales';
import { useSettingsContext } from '@/components/settings';
import { Outlet } from 'react-router';
import { observer } from 'mobx-react';
import { walletAssetCollectionStroe } from '@/stores';
import { useEffect } from 'react';



export default observer(function Index() {
  const { themeStretch } = useSettingsContext();
  const { translate } = useLocales();
  const { queryList, getCollectCofig } = walletAssetCollectionStroe;
  useEffect(() => {
    queryList();
    getCollectCofig();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate('seo_title.helmet_coin_asset_collection')}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Outlet />
      </Container>
    </>
  );
});
