import { Http } from '@/api/http';
const http = new Http('/merchant/console/v1');

export default class WebhookApi {
  /**
   * webhook list 
   * @param key string
   * @param type number
   * @param status number
   * @param page_index number
   * @param page_size number
   * @param begin_at number
   * @param end_at number
   * @returns string
   */
  getWebhookList(data: getWebhookListParamType): Promise<getWebhookListResType> {
    return http.postJson('/webhook/list', { ...data });
  }

  /**
   * webhook details
   * @param BillId string
   * @returns string
   */
  getWebhookDetail(BillId: string): Promise<WebhookDetailData> {
    return http.postJson('/webhook/get', { BillId });
  }

  /**
   * webhook Resend
   * @param BillId string
   * @returns string
   */
  resendWebhook(BillId: string): Promise<WebhookDetailData> {
    return http.postJson('/webhook/resend', { BillId });
  }
}

type getWebhookListParamType = {
  Key: string;
  Type: number;
  Status: number;
  BeginAt: number;
  EndAt: number;
  PageIndex: number;
  PageSize: number;
};

export type getWebhookListResType = {
  List: List[];
  Pagination: Pagination;
};

export interface List {
  WebhookId: string;
  MerchantId: string;
  BillId: string;
  Type: number;
  Times: number;
  Status: number;
  NoticeMsg: string;
  ResponseMsg: string;
  NoticeTime: number;
  logo?: string;
}

export interface Pagination {
  PageIndex: number;
  PageSize: number;
  Total: number;
}

export interface WebhookDetailData {
  WebhookId: string;
  BillId: string;
  MerchantId: string;
  Type: number;
  Times: number;
  Status: number;
  NoticeMsg: string;
  ResponseLog: any;
  NoticeTime: number;
}
