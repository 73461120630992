import { Suspense, lazy, ElementType } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

export const PageSwap = Loadable(lazy(() => import('@/pages/balance/Swap')));
export const PageSend = Loadable(lazy(() => import('@/pages/balance/SendSwitch')));
export const PageDeposit = Loadable(lazy(() => import('@/pages/balance/Deposit')));
export const OrderDetail = Loadable(lazy(() => import('@/components/OrderDetail/Index')));
export const WebHookDetail = Loadable(lazy(() => import('@/components/OrderDetail/WebHookDetail')));
export const PageAddRecipient = Loadable(
  lazy(() =>
    import('@/sections/MerchantSetting/dialog/add-recipient').then((m) => ({
      default: m.DialogAddRecipientForm,
    }))
  )
);
export const NotifyDetail = Loadable(lazy(() => import('@/components/notification/index')));
export const TokenPreRemove = Loadable(
  lazy(() => import('@/components/notification/TokenPreRemove'))
);

export const SATSTokenPreRemove = Loadable(
  lazy(() => import('@/components/notification/SATSTokenPreRemove'))
);
export const SuggestAFeature = Loadable(lazy(() => import('@/pages/SuggestAFeature/index')));
export const PrepareOfflineForm = Loadable(
  lazy(() => import('@/pages/balance/dialog/PrepareOfflineForm'))
);
export const MaintenanceConfirm = Loadable(
  lazy(() => import('@/pages/balance/dialog/MaintenanceConfirm'))
);
export const MtModal = Loadable(lazy(() => import('@/components/merchant-settings/MtModal')));

export const ApiDetails = Loadable(lazy(() => import('@/pages/PaymentApiOrderDetailsPage')));

export const CreateRefundPage = Loadable(lazy(() => import('@/sections/CreateRefund/Index')));

// wallet api

// WebHookDetail
export const WalletWebHookDetail = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/webhook/WebhookDetail'))
);
//transaction detail
export const WalletTransactionDetail = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/transaction/TransactionDetail'))
);
//balance detail
export const WalletBalanceDetail = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/balance/dialog/ListTransactionDetails'))
);
//cross - chain
export const CrossChain = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/balance/dialog/CrossChain'))
);
//add - coin
export const AddCoin = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/coinList/dialog/DialogConfig'))
);
// WithdrawDetail
export const WalletWithdrawDetail = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/user-order/detail/WithdrawDetail'))
);
//wallet deposit
export const PageWalletDeposit = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/balance/Deposit'))
);
//wallet Send withdraw
export const PageWalletNetworkSend = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/balance/SendSwitch'))
);
// wallet user ban
export const WalletBanUserPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/userList/modal/DialogBan'))
);

//referral program
export const PageReferralNetworkSend = Loadable(
  lazy(() => import('@/pages/ReferralProgram/SendSwitch'))
);

export const WalletNotifyDetail = Loadable(
  lazy(() => import('@/pages/WalletApi/common/components/notification/index'))
);

export default function ModalRoute() {
  const location = useLocation();
  const path = location.state.backgroundLocation.pathname;

  return (
    <Routes>
      <Route path={`${path}/swap`} element={<PageSwap />} />
      <Route path={`${path}/swap/:token_id`} element={<PageSwap />} />
      <Route path={`${path}/send/:token_id`} element={<PageSend />} />
      <Route path={`${path}/send/:token_id/add-recipient`} element={<PageAddRecipient />} />
      <Route path={`${path}/deposit`} element={<PageDeposit />} />
      <Route path={`${path}/deposit/:token_id`} element={<PageDeposit />} />
      <Route path={`${path}/order/:record_id`} element={<OrderDetail />} />
      <Route path={`${path}/notifi/:id`} element={<WebHookDetail />} />
      <Route path={`${path}/add-recipient`} element={<PageAddRecipient />} />
      <Route path={`${path}/notification/:notify_id`} element={<NotifyDetail />} />
      <Route path={`${path}/notification-token/:notify_id`} element={<TokenPreRemove />} />
      {/* 临时 sats pre */}
      <Route path={`${path}/notification-token-sats`} element={<SATSTokenPreRemove />} />
      <Route path={`${path}/suggestAFeature`} element={<SuggestAFeature />} />
      <Route path={`${path}/Prepare-Offline`} element={<PrepareOfflineForm />} />
      <Route path={`${path}/token-maintain`} element={<MaintenanceConfirm />} />
      <Route path={`${path}/mt-modal`} element={<MtModal />} />
      {/* api order */}
      <Route path={`${path}/api-details`} element={<ApiDetails />} />
      {/* refund */}
      <Route path={`${path}/api-details/refund`} element={<CreateRefundPage />} />
      <Route path={`${path}/refund`} element={<CreateRefundPage />} />
      {/* Referral NetworkSend */}
      <Route path={`${path}/referral-send/:token_id`} element={<PageReferralNetworkSend />} />
      {/* wallet api */}
      <Route path={`${path}/webhook-detail/:id`} element={<WalletWebHookDetail />} />
      <Route path={`${path}/transaction-detail/:id`} element={<WalletTransactionDetail />} />
      <Route
        path={`${path}/wallet-send/:token_id/transaction-detail/:id`}
        element={<WalletTransactionDetail />}
      />
      <Route path={`${path}/withdraw-detail/:id`} element={<WalletWithdrawDetail />} />
      <Route path={`${path}/cross-chain/:id`} element={<CrossChain />} />
      <Route
        path={`${path}/cross-chain/:id/balance-detail/:id`}
        element={<WalletBalanceDetail />}
      />
      <Route path={`${path}/balance-detail/:id`} element={<WalletBalanceDetail />} />
      <Route path={`${path}/add-coin/:id`} element={<AddCoin />} />
      <Route path={`${path}/wallet-deposit`} element={<PageWalletDeposit />} />
      <Route path={`${path}/wallet-deposit/:token_id`} element={<PageWalletDeposit />} />
      <Route path={`${path}/wallet-send/:token_id`} element={<PageWalletNetworkSend />} />
      <Route path={`${path}/user-ban/:user_id`} element={<WalletBanUserPage />} />
      {/* <Route path={`${path}/wallet-notification`} element={<WalletNotifyDetail />} /> */}
    </Routes>
  );
}
