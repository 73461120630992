import { SystemToken } from '@/api/token';

// Invoice 1 Ordinary order (single/recurring) ManageInvoice 2 master bill
export enum SearchTypeEnum {
  Invoice = 1,
  ManageInvoice = 2,
}

//single list 
export type searchOneParamsType = {
  page_num: number;
  page_size: number;
  keyword: string;
  type: number;
  status: number;
  begin_at: number;
};

export interface recurrySearchParams {
  type: number;
  keyword: string;
  status: number;
  begin_at: number;
  end_at: number;
  page_num: number;
  page_size: number;
  [key: string]: any;
}
export interface ManageSearchParams {
  keyword: string;
  dt_start?: number;
  dt_end?: number;
  status: number;
  pagination: {
    page_num: number;
    page_size: number;
  };
}

export type optionsStatusType = {
  value: number;
  label: string;
}[];

export type MarkAsDoneType = {
  bill_id: string;
  remark: string;
};

export interface paysInterface {
  record_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  amount: string;
  em: number;
  pay_time: number;
  service_fee: string;
  pay_status: string;
  logo: string;
}

export interface refundsInterface {
  record_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  refund_amount: string;
  pay_time: number;
  network_fee: string;
  fee_style: number;
  refund_status: number;
  logo: string;
}
export type IInvoiceAddress = {
  id: string;
  name: string;
  address: string;
  company: string;
  email: string;
  phone: string;
};
export type IInvoiceItem = {
  currency: any;
  title: string;
  price: string;
  quantity: number;
  total: string;
  discount_mode?: number;
  discount_number?: string;
};
export type attItem = {
  name: string;
  path: string;
  size: number;
};
export type IInvoice = {
  payment_url: string;
  attachment: attItem[];
  currency_type: number;
  invoice_id: string;
  send_time: number;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  desc: string;
  item_list: IInvoiceItem[];
  bill_id: string;
  currency: string;
  extra_fee: string
};
export type formType = {
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  desc: string;
  token_list: SystemToken[];
  invoice_type: number;
  send_time: string;
  due_time: string;
  emailNotifyTrade: boolean;
  emailNotifyWebhook: boolean;
  period: number;
  is_standing: boolean;
  due_time_issued?: any;
  company_address: string;
  company_website: string;
  twitter_url: string;
  telegram_url: string;
  currency: any;
  [key: string]: any;
};
