import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useContext } from 'react';
import { NotificationContext } from '../index';
import { TGBASEURL } from '@/config';

function DialogChangeTgTokenConfirm({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (v?: 1 | 2 | null) => void;
}) {
  const { translate: t } = useLocales();
  const { BotName, GroupName } = useContext(NotificationContext);

  return (
    <Dialog
      open={open}
      onClose={() => onClose(null)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ width: 480, mx: 'auto' }}
      PaperProps={{
        sx: { m: 0, width: '100%' },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('wallet.account.setting.notification.dialog.change.title')}
          </Typography>
          <IconButton onClick={() => onClose(null)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ContenWrap>
          <div className="top-desc">
            <div>
              {t('wallet.account.setting.notification.dialog.change.desc1')}{' '}
              <span
                onClick={() => {
                  BotName && window.open(`${TGBASEURL}${BotName}`);
                }}
              >
                @{BotName || 'Merchantbot'}
              </span>
            </div>
            <div>
              {t('wallet.account.setting.notification.dialog.change.desc2')}{' '}
              <span>@{GroupName || 'TelegramGroupName'}</span>
            </div>
          </div>
          <div className="action-btn">
            <Button
              variant="contained"
              onClick={() => {
                onClose(2);
              }}
            >
              {t('wallet.account.setting.notification.dialog.change.btn.text1')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onClose(1);
              }}
            >
              {t('wallet.account.setting.notification.dialog.change.btn.text2')}
            </Button>
          </div>
        </ContenWrap>
      </DialogContent>
    </Dialog>
  );
}

const ContenWrap = styled.div`
  padding-bottom: 24px;
  .top-desc {
    color: #27291e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    margin-bottom: 32px;
    span {
      color: #adcb53;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      cursor: pointer;
    }
  }
  .action-btn {
    display: flex;
    flex-direction: column;
    button {
      :first-child {
        margin-bottom: 16px;
      }
      padding: 11px 22px;
      border-radius: 8px;
      background: #adcb53;
      color: #fff;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 173.333% */
    }
  }
`;

export default observer(DialogChangeTgTokenConfirm);
