import { dataArrType } from '@/sections/dashboard/List';
import http from './http';

export default class DashboardApi {
  //getdashboard top data
  getDashboardTopInfo(): Promise<DashboardTopInfoType> {
    return http.postJson('/dashboard/index/stat');
  }

  //getbalance chart data
  getDashboardBalanceInfo(day: number) {
    return http.postJson('/dashboard/balance/stat', { dd: day });
  }

  //getorder chart data
  getDashboardOrderInfo(day: number) {
    return http.postJson('/dashboard/bill/stat', { dd: day });
  }

  //Confirm Token pre-delisting notification
  setTokenConfirm() {
    return http.postJson('/dashboard/info/confirm');
  }

  //Error log modification
  setErrorfirm(data: errorParamsType) {
    return http.postJson('/dashboard/error/save', { ...data });
  }

  //getorder chart data
  getDashboardDirectDepositInfo(
    data: getDashboardDirectDepositInfoParamType
  ): Promise<getDashboardDirectDepositInfoResType> {
    return http.postJson('/dashboard/direct_deposit/stat', data);
  }
}

export type getDashboardDirectDepositInfoParamType = {
  start_date: string;
  coin_ids: string[];
};

export type getDashboardDirectDepositInfoResType = {
  [key: string]: {
    /** num */
    Count: number;
    /** price of usdt */
    Amount: number;
  };
};

export interface preOffTokenInterface {
  symbol: string;
  pre_off_date: number;
  logo: string;
}

export type errorParamsType = {
  Url: string;
  Err: string;
};

export type DashboardTopInfoType = {
  api_deposit_stat: {
    num_24: number;
    amount_24: string;
    today_num: number;
    today_amount: string;
    month_num: number;
    month_amount: string;
  };
  api_withdraw_stat: {
    num_24: number;
    amount_24: string;
    today_num: number;
    today_amount: string;
    month_num: number;
    month_amount: string;
  };
  token_stat: {
    total_balance: string;
    token: dataArrType[];
  };
  pre_off_token: preOffTokenInterface[];
  order_stat: {
    total: string;
    today: string;
    month: string;
    earned_total: string;
    earned_today: string;
    earned_month: string;
  };
};
