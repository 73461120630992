

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_TRADING_ORDER = '/transaction';
const ROOTS_BALANCES = '/balances';
const ROOTS_INVOICE = '/invoice';
const ROOTS_SUGGESTAFEATURE = '/suggestAFeature';
const ROOTS_WEB_HOOK = '/webhook';
const ROOTS_CHECK = '/check';
const ROOTS_PERSONAL_STORE = '/personalstore';
const ROOTS_PAYMENT_API = '/payment';
const ROOTS_DEVELOPER = '/developer';
const ROOTS_MERCHANT_SETTIMGS = '/merchatsetting';
const ROOTS_SUPPORT = '/support';
//wallet
const ROOTS_WALLET = '/wallet';

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, '/index'),
};

export const PATH_TRADING_ORDER = {
  root: ROOTS_TRADING_ORDER,
  one: path(ROOTS_TRADING_ORDER, '/index'),
  refund: path(ROOTS_TRADING_ORDER, '/create-refund'),
};

export const PATH_BALANCES = {
  root: ROOTS_BALANCES,
  one: path(ROOTS_BALANCES, '/index'),
};

export const PATH_INVOICE = {
  root: ROOTS_INVOICE,
  one: path(ROOTS_INVOICE, '/list/recurring'),
};

export const PATH_SUGGESTAFEATURE = {
  root: ROOTS_SUGGESTAFEATURE,
  one: path(ROOTS_SUGGESTAFEATURE, '/index'),
};

export const PATH_WEB_HOOK = {
  root: ROOTS_WEB_HOOK,
  one: path(ROOTS_WEB_HOOK, '/index'),
};

export const PATH_CHECK = {
  root: ROOTS_CHECK,
  one: path(ROOTS_CHECK, '/index'),
};
export const PATH_PERSONAL_STORE = {
  root: ROOTS_PERSONAL_STORE,
  one: path(ROOTS_PERSONAL_STORE, '/index'),
};
export const PATH_PAYMENT_API = {
  root: ROOTS_PAYMENT_API,
  one: path(ROOTS_PAYMENT_API, '/paymentApiOrder/outgoing'),
};

export const PATH_DEVELOPER = {
  root: ROOTS_DEVELOPER,
  one: path(ROOTS_DEVELOPER, '/config'),
};

export const PATH_MERCHANT_SETTIMGS = {
  root: ROOTS_MERCHANT_SETTIMGS,
  one: path(ROOTS_MERCHANT_SETTIMGS, '/settings'),
};

export const PATH_SUPPORT = {
  root: ROOTS_SUPPORT,
  one: path(ROOTS_SUPPORT, '/index'),
};

// wallet
export const PATH_WALLET = {
  root: ROOTS_WALLET,
  developer: path(ROOTS_WALLET, '/developer'),
  coinList: path(ROOTS_WALLET, '/coin-list'),
  coinListDetails: path(ROOTS_WALLET, '/coinListDetails'),
  networkConfiguration: path(ROOTS_WALLET, '/network-configuration'),
  webhook: path(ROOTS_WALLET, '/webhook'),
  transaction: path(ROOTS_WALLET, '/transaction'),
  overview: path(ROOTS_WALLET, '/overview'),
  user_order: path(ROOTS_WALLET, '/user-order'),
  user_list: path(ROOTS_WALLET, '/user-list'),
  balance: path(ROOTS_WALLET, '/balance'),
  asset_collection: path(ROOTS_WALLET, '/asset-collection'),
  account_setting: path(ROOTS_WALLET, '/account'),
};
