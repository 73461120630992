import { Http } from '@/api/http';
// import { MD5 } from 'crypto-js';
// const CryptoJS = require('crypto-js');
const http = new Http('/merchant/console/v1');

export default class UserListApi {
  /**
   * state set
   */
  getStatusList(): Promise<getStatusListRestype> {
    return http.postJson('/userlist/search/statuslist', {});
  }

  /**
   * user list
   */
  getUserList(data: getUserListParamType): Promise<getUserListResType> {
    return http.postJson('/userlist/userwallets', data);
  }

  /**
   * set user status
   */
  setUserActiveorban(UserId: string): Promise<{ Res: boolean }> {
    return http.postJson('/userlist/activeorban', { UserId });
  }

  /**
   * Get user asset details
   */
  getUserAssetsDetail(UserId: string): Promise<getUserAssetsDetailResType> {
    return http.postJson('/userlist/userassets_detail', { UserId });
  }

  /**
   * Get user wallet address list 
   */
  getUserAddressList(UserId: string): Promise<getUserAddressListResType> {
    return http.postJson('/userlist/useraddress', { UserId });
  }

  /**
   * Get user asset flow
   */
  getUserAssetRecordList(
    data: getUserAssetRecordListParamType
  ): Promise<getUserAssetRecordListResType> {
    return http.postJson('/userlist/userassetrecords', data);
  }

  /**
   * Get user asset flow type list 
   */
  getUserAssetRecordTypeList(): Promise<{
    List: {
      [key: string]: string;
    };
  }> {
    return http.postJson('/userlist/search/typelist', {});
  }

  /**
   * Get basic user information
   */
  getUserDetailInfo(UserId: string): Promise<{
    Detail: getUserDetailInfoType;
  }> {
    return http.postJson('/userlist/getuserwallet', { UserId });
  }
}

export type getUserDetailInfoType = {
  UserId: string;
  UserName: string;
  Privilege: number;
  Permission: Permission;
  Status: string;
  CreatedAt: string;
};

export type getUserAssetRecordListResType = {
  List: UserAssetRecordListType[];
  Pagination: Pagination;
};

export type UserAssetRecordListType = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: any;
  MerchantId: string;
  UserId: string;
  BusinessId: string;
  Action: number;
  Coin: Coin;
  Desc: string;
  BeforeAvailable: string;
  AfterAvailable: string;
  BeforeFrozen: string;
  AfterFrozen: string;
  Amount: string;
  Version: number;
};

export type getUserAssetRecordListParamType = {
  UserId: string;
  CoinId: string;
  Action: number;
  BusinessId: string;
  PageIndex: number;
  PageSize: number;
};

export type getUserAddressListResType = {
  List: UserAddressListType[];
};

export type UserAddressListType = {
  MerchantId: string;
  UserId: string;
  ChainId: string;
  Address: string;
  Memo: string;
  Type: number;
  Status: number;
  ExpiredAt: number;
  Desc: string;
};

export type getUserAssetsDetailResType = {
  List: {
    MerchantId: string;
    UserId: string;
    UserName: string;
    Assets: Asset[];
    Balance_Available: string;
    Balance_Frozen: string;
  };
};

export interface Asset {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: any;
  MerchantId: string;
  UserId: string;
  Coin: Coin;
  Available: string;
  Frozen: string;
  Version: number;
  Variate: any;
}

export type UserAssetsDetailListType = {
  MerchantId: string;
  UserId: string;
  Coin: Coin;
  Available: string;
  Frozen: string;
  Version: number;
  Variate: any;
  Snapshot: any;
};

export interface Coin {
  Id: string;
  Symbol: string;
}

export type getUserListResType = {
  List: UserListItem[];
  Pagination: Pagination;
};

export interface UserListItem {
  UserId: string;
  UserName: string;
  Privilege: number;
  Permission: Permission;
  Status: string;
  CreatedAt: string;
  Balance_Available: string;
  Balance_Frozen: string;
}

export interface Permission {
  Deposit: boolean;
  Withdraw: boolean;
  Transfer: boolean;
  TransferSend: boolean;
  TransferReceive: boolean;
}

export interface Pagination {
  PageIndex: number;
  PageSize: number;
  Total: number;
}

export type getUserListParamType = {
  UserId: string;
  UserName: string;
  Status: number;
  PageIndex: number;
  PageSize: number;
};

export type getStatusListRestype = {
  List: {
    [key: string]: string;
  };
};
