import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Dialog,
  Typography,
  InputAdornment,
  FormControlLabel,
  Grid,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { useLocales } from '@/locales';
import { CheckboxElement, TextFieldElement, useForm, FormContainer } from 'react-hook-form-mui';
import ImageAdd from '@/components/image-add';
// auth
import { useAuthContext } from '@/auth/useAuthContext';
import { LoadingButton } from '@mui/lab';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useSnackbar } from 'notistack';
import suggestApi from '@/api/suggestApi';
import { style } from './style';
const suggestClient = new suggestApi();
function SuggestAFeature({ open, handleOpen }: { open: boolean; handleOpen: () => void }) {
  const formContext = useForm({
    defaultValues: {
      suggestion: false,
      text: '',
    },
  });
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { translate: t } = useLocales();
  const [isSubmit, setIsSubmit] = useState(false);
  const [fileArr, setFileArr] = useState();
  const [maxLength, setLength] = useState(0);

  const text = formContext.watch('text');
  const handleOk = async (data: any) => {
    setIsSubmit(true);
    try {
      setIsSubmit(true);
      //First upload the picture and call the creation interface after success
      let params = {
        message: text,
        is_communicate: data?.suggestion === true ? 1 : 0,
        to_email: user?.email || '',
        files: [],
      };
      if (fileArr) {
        let res = await suggestClient.uploadFile({ file: fileArr, maxLength });
        params.files = res;
        await suggestClient.creatSuggest(params);
        enqueueSnackbar(t('suggest.submit_tip'));
        clear();
      } else {
        //no feedback image
        await suggestClient.creatSuggest(params);
        enqueueSnackbar(t('suggest.submit_tip'));
        clear();
      }
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(t(`interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setIsSubmit(false);
    }
  };
  const clear = () => {
    formContext.setValue('suggestion', false);
    formContext.setValue('text', '');
    setLength(0);
    setIsSubmit(false);
    handleOpen();
  };

  return (
    <>
      <Helmet>
        <title>{t('seo_title.helmet_suggest')}</title>
      </Helmet>
      <Container sx={{ ...style.container }}>
        <Dialog
          fullWidth
          open={open}
          sx={{ ...style.dialog }}
        >
          {/* title */}
          <DialogTitle id="scroll-dialog-title" sx={{ padding: '24px 0' }}>
            <Box className="flex justify-between items-center">
              <Typography variant="h5" component="span">
                {t('suggest.title')}
              </Typography>
              <IconButton
                onClick={() => {
                  clear();
                }}
              >
                <MyIcon name="close" size={14} />
              </IconButton>
            </Box>
          </DialogTitle>
          <Typography variant="body2" className="color-text" style={{ fontSize: '16px' }}>
            {t('suggest.desc')}
          </Typography>
          <FormContainer
            formContext={formContext}
            handleSubmit={formContext.handleSubmit(handleOk)}
          >
            <TextFieldElement
              multiline
              name="text"
              label={t('suggest.suggestion')}
              placeholder={t('suggest.please_holder')}
              autoFocus
              fullWidth
              sx={{
                ...style.text,
              }}
              inputProps={{
                maxLength: 1500,
              }}
              validation={{ required: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={{ ...style.text_length }}>
                      <span style={{ color: text?.length > 1500 ? '#FF5630' : '' }}>
                        {text?.length || 0}
                      </span>
                      /<span>1500</span>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="subtitle1" className="color-text" sx={{ mt: 3 }}>
              {t('suggest.images_title')}
            </Typography>
            <Typography variant="body2" className="color-text2" sx={{ mb: 1 }}>
              {t('suggest.images_desc')}({maxLength}/5)
            </Typography>

            <Box mt={2}>
              <ImageAdd
                attachments={[]}
                onClose={(length: number, arr: any) => {
                  setLength(length);
                  setFileArr(arr);
                }}
              />
            </Box>
            <FormControlLabel
              control={<CheckboxElement name="suggestion" control={formContext.control} />}
              label={
                <Box className="flex flex-column">
                  <Typography variant="subtitle1" className="color-text">
                    {t('suggest.images_stion')}
                  </Typography>
                </Box>
              }
              sx={{ ...style.suggest }}
            />
            <Grid
              item
              xs={12}
              className="flex flex-column loading-btn"
              justifyContent={'center'}
              mt={3}
            >
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmit}
                loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
                sx={{ ...style.loading_btn }}
              >
                {t('suggest.submit')}
              </LoadingButton>
            </Grid>
          </FormContainer>
        </Dialog>
      </Container>
    </>
  );
}
export default SuggestAFeature;
