import http from './http';
const CryptoJS = require('crypto-js');
export default class AccountApi {
  /** cate // -1 不存在  1 老  2新 */
  checkUser(email: string): Promise<{ cate: number }> {
    return http.postJson('/safety/info/check/email', { email });
  }

  //get user info
  getUserInfo(): Promise<loginInfo> {
    return http.postJson('/user/info');
  }

  // login
  login(params: { email: string; password: string }): Promise<loginInfo> {
    return http.postJson('/user/login', {
      ...params,
      password: String(CryptoJS.MD5(params.password.trim())),
    });
  }

  // login
  loginV2(params: { email: string; password: string }): Promise<loginInfo> {
    return http.postJson('/user/login_v2', {
      ...params,
      password: String(CryptoJS.MD5(params.password.trim())),
    });
  }

  // login out
  loginOut() {
    return http.postJson('/user/logout', {});
  }

  // loginVerify type 1 login Verify 2 full verification
  loginVerify({
    mail_code = '',
    sms_code = '',
    totp_code = '',
    type = 1,
    captcha_key = '',
    captcha_code = '',
  }: {
    mail_code?: string;
    sms_code?: string;
    totp_code?: string;
    type: 1 | 2;
    captcha_key?: string;
    captcha_code?: string;
  }) {
    return http.postJson('/user/login/safety/verify', {
      mail_code,
      sms_code,
      totp_code,
      type,
      captcha_key,
      captcha_code,
    });
  }

  // loginVerify type 1 login Verify 2 full verification
  loginVerifyV2({
    mail_code = '',
    sms_code = '',
    totp_code = '',
    type = 1,
    captcha_key = '',
    captcha_code = '',
  }: {
    mail_code?: string;
    sms_code?: string;
    totp_code?: string;
    type: 1 | 2;
    captcha_key?: string;
    captcha_code?: string;
  }) {
    return http.postJson('/user/login/safety/verify_v2', {
      mail_code,
      sms_code,
      totp_code,
      type,
      captcha_key,
      captcha_code,
    });
  }

  // register
  register(params: {
    email: string;
    password: string;
    repeat_password: string;
    /**
     * google not need
     */
    code?: string;
    /**
     * register type： 1 email 2 google
     */
    reg_type: 1 | 2;
    name?: string;
    website?: string;
    industry?: string;
    remark?: string;
    captcha_key?: string;
    captcha_code?: string;
    platform?: string;
    referral_merchant_id?: string;
  }) {
    return http.postJson('/user/register', {
      ...params,
      password: String(CryptoJS.MD5(params.password)),
      repeat_password: String(CryptoJS.MD5(params.repeat_password)),
    });
  }

  // Verify Human-Computer Interaction
  captchaVerify({ code }: { code: string }) {
    return http.postJson('/captcha/code/verify', { code });
  }

  // Verify Human-Computer Interaction
  getCaptchaInfo(email: string): Promise<getCaptchaInfoResType> {
    return http.postJson('/captcha/get', { email });
  }

  checkCaptcha(data: checkCaptchaInfoParamType) {
    return http.postJson('/captcha/check', data);
  }

  // get google  auth2
  getAuthUrl({ callback_url }: { callback_url: string }): Promise<AuthUrlType> {
    return http.postJson('/user/auth/url', { callback_url, platform: 'google' });
  }

  // login by google code
  googleAuthLogin({ code }: { code: string }): Promise<any> {
    return http.postJson('/user/auth/login', { code, platform: 'google' });
  }

  // login by google code
  googleAuthLoginV2({ code }: { code: string }): Promise<any> {
    return http.postJson('/user/auth/login_v2', { code, platform: 'google' });
  }

  // get userinfo by google code
  getGoogleAuthInfo({ code }: { code: string }): Promise<GoogleAuthInfoType> {
    return http.postJson('/user/auth/code/get', { code, platform: 'google' });
  }

  // forgot password
  forgetPassword(params: {
    email: string;
    password: string;
    repeat_password: string;
    mail_code?: string;
    sms_code?: string;
    totp_code?: string;
  }) {
    return http.postJson('/safety/setting/login-pass/forget', {
      ...params,
      password: String(CryptoJS.MD5(params.password)),
      repeat_password: String(CryptoJS.MD5(params.repeat_password)),
    });
  }

  // send email code
  SendCodeByEmail({ email, code_type }: { email: string; code_type: string }) {
    return http.postJson('/email/code/send', { email, code_type });
  }

  // get current account SafetyInfo
  getSafetyInfo() {
    return http.postJson('/safety/setting/info/get');
  }

  // get Safety Info By Email -- Login free
  getSafetyInfoByEmail(email: string) {
    return http.postJson('/safety/setting/info/email', { email });
  }

  changePassword(data: {
    old_login_pass: string;
    login_pass: string;
    verify_code: {
      mail_code_type: string;
      mail_code: string;
      sms_code_type: string;
      sms_code: string;
      totp_code: string;
    };
  }) {
    return http.postJson('/safety/setting/login-pass/change', data);
  }

  // Verify that the password is correct
  verifyPassword(password: string) {
    return http.postJson('/safety/setting/login-pass/verify', {
      login_pass: String(CryptoJS.MD5(password)),
    });
  }

  // Notify
  notifyList(data: { page: number; page_size: number }): Promise<{
    total_num: number;
    unread_num: number;
    lists: Array<NotifyItemType>;
  }> {
    return http.postJson('/notify/message/list', data);
  }

  markNotifyReaded(data: { message_id: string }) {
    return http.postJson('/notify/message/mark_read', data);
  }

  getNotifyDetail(data: { message_id: string }): Promise<NotifyItemType> {
    return http.postJson('/notify/message/detail', data).then((resp) => resp.message);
  }

  /**
   * Create/Modify Merchant
   * @param  merchant_name string;
   * @param  id?number
   * @returns
   */
  resetMerchantAccount(data: { merchant_name: string; id?: number }) {
    return http.postJson('/user/account/reset', data);
  }

  /**
   * Get merchant list
   * @returns
   */
  getMerchantAccountList(): Promise<MerchantAccountListItemType[]> {
    return http.postJson('/user/account/list');
  }

  /**
   * switch merchant
   * @param merchant_id number
   * @returns
   */
  switchMerchantAccount(merchant_id: number) {
    return http.postJson('/user/account/switch', { merchant_id });
  }
}

export type checkCaptchaInfoParamType = {
  captcha_key: string;
  captcha_code: string;
};

export type getCaptchaInfoResType = {
  captcha_key: string;
  thumb_base_64: string;
  image_base_64: string;
};

export type MerchantAccountListItemType = {
  id: number;
  merchant_name: string;
  logo: string;
};

export type AuthUrlType = {
  url: string;
};

export type GoogleAuthInfoType = {
  platform: number;
  thirdUserId: string;
  thirdUserName: string;
  thirdAvatar: string;
  email: string;
};

export type NotifyItemType = {
  args: {
    msg_id?: string;
    bill_id?: string;
  };
  body: string;
  content_params: {
    header?: string;
    content: string;
    footer: string;
    ip_address: string;
    system: string;
    time: string;
    title: string;
    email?: string;
    mobile?: string;
    tokens?: string;
  };
  created: number;
  merchant_id: number;
  message_id: string;
  readed: boolean;
  title: string;
  type: MessageTypeEnum;
};

enum MessageTypeEnum {
  MessageTypeIpChange = 'ip_change',
  MessageTypeDepositSuccess = 'deposit_success',
  MessageTypeWithdrawSuccess = 'withdraw_success',
  MessageTypeSwapSuccess = 'swap_success',
  MessageTypeRefundInner = 'refund_inner',
  MessageTypeRefundOnChain = 'refund_on_chain',
  MessageTypeSystemNotify = 'system_notify',
  MessageTypeGoogleCodeShut = 'google_code_shut',
  MessageTypeEmailChange = 'email_change',
  MessageTypeMobileChange = 'mobile_change',
}

interface loginInfo {
  complete_status?: number;
  create_time: number;
  /** user mailbox **/
  email: string;
  /** whether to open google 2fa **/
  exists_google_2fa: boolean;
  /** Whether to lock the login **/
  exists_lock_pass: boolean;
  /**  whether to open password login **/
  exists_login_pass: boolean;
  /** Whether to set payment password **/
  exists_pay_pass: boolean;
  /** google 2fa secret_key **/
  gg_verify_secret_key: string;
  /** google 2fa qr **/
  gg_verify_secret_qr: string;
  /** user head_url **/
  head_url?: string;
  id: number;
  /** 14 days without reason, fasle needs true not needed **/
  is_check: boolean;
  /** Number of passcode locks **/
  lock_try: number;
  /** No operation lockout time **/
  lock_unit: number;
  nickname: string;
  /** user phone **/
  phone: string;
  phoneArea?: string;
  user_id: string;
  wwl_status: number;
  /** Whether to enable login, cash withdrawal, refund secondary verification email **/
  security_email: 0 | 1;
  /** Whether to enable login, cash withdrawal, refund secondary verification phone **/
  security_phone: 0 | 1;
  security_withdraw: 0 | 1;
  merchant_id: number;
  mode_type: number;
  [key: string]: any;
}
