import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
// import Login from '../pages/LoginPage';
import { useAuthContext } from './useAuthContext';
import { PATH_AFTER_LOGIN, WALLET_PATH_AFTER_LOGIN } from '@/config';



type AuthGuardProps = {
  children: ReactNode;
};

export default function WalletAuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, isVerify, user, chooseApi } = useAuthContext();

  const { pathname, state } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  // console.log('AuthGuard', {
  //   isInitialized,
  //   isAuthenticated,
  //   isVerify,
  //   pathname,
  //   requestedLocation,
  //   user,
  // });

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // 没有登录和没有验证都去login
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    // debugger;
    return <Navigate to={'/login'} />;
  }

  // 登录步骤过后只能定向到验证界面
  if (isAuthenticated && !isVerify && pathname !== '/verification') {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    // debugger;
    return <Navigate to={'/verification'} replace={true} state={state} />;
  }

  // 如果到验证页面一个安全验证方式都没绑并且没有登录，就跳回login,   除了全验证
  if (isAuthenticated && !isVerify && pathname === '/verification' && user?.SafetyMode === 0) {
    // console.log('如果到验证页面一个安全验证方式都没绑并且没有登录，就跳回login', verifyType);
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
  }
  // console.log('pathname', pathname, isAuthenticated, isVerify);

  // 验证成功后去验证页面直接回到主页面
  if (isAuthenticated && isVerify && ['/verification', '/login', '/register'].includes(pathname)) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
  }

  //Prevent jumping to each other
  if (isAuthenticated && isVerify && pathname.includes('wallet') && ['1', 1].includes(chooseApi)) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  //Prevent jumping to each other
  if (isAuthenticated && isVerify && !pathname.includes('wallet') && ['2', 2].includes(chooseApi)) {
    return <Navigate to={WALLET_PATH_AFTER_LOGIN} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    debugger;
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
