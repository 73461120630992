import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Box, BoxProps, ToggleButton, ToggleButtonProps } from '@mui/material';
import { LoadingButtonProps } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: '1px solid rgba(145, 158, 171, 0.32)',
  color: 'rgba(145, 158, 171, 0.32)',
  backgroundColor: 'transparent',
  '&:hover': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
    backgroundColor: 'rgba(145, 158, 171, 0.08)',
  },
  '&:active': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  '&:fcous': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
}));

export const LoadingColorButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  border: '1px solid rgba(145, 158, 171, 0.32)',
  color: 'rgba(145, 158, 171, 0.32)',
  backgroundColor: 'transparent',
  '&:hover': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
    backgroundColor: 'rgba(145, 158, 171, 0.08)',
  },
  '&:active': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  '&:fcous': {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
}));

export const TextButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
  border: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)',
  },
}));

export const StatusStyleLabel = styled(Box)<BoxProps>(({ theme }) => ({
  border: 0,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  fontStyle: 'normal',
  padding: '8px 0px',
  borderRadius: '6px',
  gap: '6px',
  maxWidth: '120px',
}));

export const styles = {
  creat_bread: {
    '& .MuiBreadcrumbs-separator': {
      fontSize: '30px',
      color: '#363A44',
      fontWeight: 500,
    },
  },
  mange_cur: {
    cursor: 'pointer',
    color: '#1E1E1E',
    fontWeight: 500,
  },
  containerItem: {
    padding: '10px 24px',
    borderRadius: '6px',
    position: 'relative',
  },
  containerText: {
    marginTop: '24px',
    fontSize: '32px',
    fontWeight: '700 !important',
  },
  item32: {
    // '.MuiGrid-grid-md-4': {
    //   minWidth: '32% !important',
    //   maxWidth: '32% !important',
    //   flexBasis: '32% !important',
    // }
  },
  creat_rote: {
    transform: 'rotate(90deg)',
    flexShrink: 1,
    flexGrow: 0,
    height: '32px',
    cursor: 'pointer',
    marginRight: '8px',
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
};
