import { MyIcon } from '@/assets/icons/MyIcon';
import { coinlistApi, userOrderApi } from '@/pages/WalletApi/common/api';
import { getDepositNetworkListType } from '@/pages/WalletApi/common/api/userOrders';
import {
  Box,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Popover,
  TextField,
  styled,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

type SelectNetworkProps = {
  parmams: {
    key?: string;
    type?: string;
    helperText?: string;
    width?: string;
    chainList: getDepositNetworkListType[];
    isLoading: boolean;
  };
  value: string;
  onChange: (value: string) => Promise<void>;
};

type coinListType = {
  Id: string;
  symbol: string;
  Symbol: string;
  Name: string;
  LogoURI: string;
  Desc: string;
  Type: number;
  Price: string;
  CMCCoinId: string;
  CMCCoinIdUpdated: boolean;
  PriceUpdated: boolean;
  isAdd?: boolean;
};

export default function SelectCoin({ value, onChange, parmams }: SelectNetworkProps) {
  const [text, setText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [coinList, setCoinList] = useState<coinListType[]>([]);
  const [oldList, setOldList] = useState<coinListType[]>([]);

  useEffect(() => {
    //search method
    if (!text) {
      setOldList(coinList);
    } else {
      let serch: Array<any> = [];
      coinList.forEach((item, index) => {
        if (
          (item?.symbol || item?.Symbol).toUpperCase().includes(text.toUpperCase()) ||
          item.Name.toUpperCase().includes(text.toUpperCase())
        ) {
          serch.push(item);
        }
      });
      setOldList(serch);
    }

    // eslint-disable-next-line
  }, [text, coinList]);

  const handleClose = () => {
    setText('');
    setAnchorEl(null);
  };

  async function getCoinList() {
    try {
      const res = await userOrderApi.getCoinList();
      let resp = await coinlistApi.getCoinList({
        Key: '',
        Status: 0,
      });
      let arr: coinListType[] = [];
      if (res.List) {
        Object.keys(res.List).forEach((item) => {
          if (res.List[item]) {
            arr.push(res.List[item]);
          }
        });
      }
      if (resp.List?.length > 0) {
        arr = arr.map((item) => {
          const tempList = resp.List.filter((r) => r.Coin.Id === item.Id);
          let obj = {
            ...item,
          };
          if (tempList.length > 0) {
            obj.isAdd = true;
          }
          return obj;
        });
      }

      const arr2 = arr.filter((item) => item.isAdd);
      const arr3 = arr.filter((item) => !item.isAdd);

      setCoinList([
        {
          Id: '0000-0000-0000-0000',
          Symbol: 'All Coin',
          symbol: 'All Coin',
          Name: 'All Coin',
          LogoURI: '',
          Desc: '',
          Type: 0,
          Price: '0',
          CMCCoinId: '',
          CMCCoinIdUpdated: false,
          PriceUpdated: false,
          isAdd: false,
        },
        ...arr2,
        ...arr3,
      ]);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    getCoinList();
    // eslint-disable-next-line
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <SelectCoinWrap sx={{ width: parmams?.width || '100%' }}>
      <Box
        component={'div'}
        id="popver-search-select"
        sx={{
          justifyContent: 'space-between',
          background: 'transparent',
          color: '#212B36',
          border: '1px solid #E6E8E0',
          borderRadius: '8px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          padding: '15px 12px',
          mr: 2,
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
          if (coinList.length > 0) {
            setAnchorEl(event.currentTarget);
          }
        }}
      >
        <span
          style={{
            width: '92%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: '#27291E',
          }}
        >
          {coinList.find((item) => item.Id === value)?.Symbol}
        </span>

        {coinList.length > 0 ? (
          <Box sx={{ transform: `${!open ? 'rotate(-90deg)' : 'rotate(90deg)'}`, flexShrink: 0 }}>
            <MyIcon name="back_arrow" size={6} />
          </Box>
        ) : (
          <CircularProgress color="inherit" size={20} />
        )}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        container={() => document.querySelector('#popver-search-select')}
        sx={{
          mt: 1,
          '.MuiPaper-root': {
            padding: '12px 8px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TextField
            autoComplete="off"
            type={'text'}
            value={text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setText(e.target.value);
            }}
            onBlur={() => {
              if (text.length === 0) setIsFocus(false);
              if (text.length > 0) setIsFocus(true);
              if (!isFocus) return;
            }}
            onFocus={() => setIsFocus(true)}
            focused={isFocus}
            placeholder={t('invoices.search_holder')}
            size="small"
            sx={{
              width: `calc(${parmams.width} - 32px)`,
              height: '56px',
            }}
            InputProps={{
              sx: {
                '&.MuiOutlinedInput-root': {
                  height: '36px',
                  lineHeight: '36px',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                  },
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <MyIcon
                    name={`${
                      isFocus ? 'wallet_transaction_search_black' : 'wallet_transaction_search'
                    }`}
                    size={24}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <MyIcon
                    name="close"
                    style={{
                      cursor: 'pointer',
                      visibility: text ? 'visible' : 'hidden',
                    }}
                    onClick={() => {
                      setText('');
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            margin: 0,
            padding: 0,
            height: '200px',
            overflow: 'overlay',
            '::-webkit-scrollbar': {
              width: '6px',
              height: '6px',
              backgroundColor: '#fff',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '12px',
              backgroundColor: ' #b6bcc1',
            },
          }}
        >
          {oldList.length > 0 &&
            oldList.map((item) => {
              const labelId = `checkbox-list-label-${item.Id}`;
              return (
                <ListItem
                  key={labelId}
                  disablePadding
                  sx={{
                    '.MuiButtonBase-root': {
                      padding: '6px 0px 6px 16px',
                      // border: '1px solid',
                      borderRadius: '6px',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      backgroundColor: value !== item.Id ? '#fff' : '#F0F1EC',
                      color: value !== item.Id ? '#27291E' : '#82993C',
                      ':hover': {
                        backgroundColor: '#F9FAF6',
                      },
                    },
                  }}
                >
                  <ListItemButton
                    // disabled={item.disabled}
                    role={undefined}
                    onClick={() => {
                      onChange(item.Id);
                      handleClose();
                    }}
                    dense
                    sx={{}}
                  >
                    {/* {item?.LogoURI && (
                      <Avatar
                        src={item?.LogoURI}
                        sx={{ width: 24, height: 24, marginRight: '8px' }}
                      />
                    )} */}
                    <span style={{ marginRight: '8px' }}>{item.Symbol}</span>
                    {/* <ListItemText id={labelId} primary={`${item.Symbol}`} /> */}
                    {item.isAdd && (
                      <Box
                        sx={{
                          width: '48px',
                          padding: '2px 4px',
                          background: '#F4F6FF',
                          borderRadius: '50px',
                          fontWeight: 400,
                          color: '#7C8EDA',
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        Listed
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Popover>
    </SelectCoinWrap>
  );
}

const SelectCoinWrap = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.search-box': {
    '.MuiOutlinedInput-root': {
      padding: 0,
    },
    borderRadius: '8px',
  },

  '.check-box': {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    maxHeight: 280,
    overflow: 'auto',
    zIndex: 999999,
    top: 60,
    left: 0,
    padding: '4px',
    paddingRight: '2px',
    boxShadow:
      '0px 3px 5px -1px rgba(145, 158, 171, 0.2), 0px 5px 8px 0px rgba(145, 158, 171, 0.14), 0px 1px 14px 0px rgba(145, 158, 171, 0.12)',

    '.check-box-container': {
      borderRadius: '8px',
      '.check-box-item': {
        fontSize: '14px',
        fontWeight: 400,
        padding: '8px',
      },
    },
    '.MuiMenuItem-root': {
      borderRadius: '12px',
      marginTop: '2px',
    },

    '::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      backgroundColor: '#fff',
      borderRadius: '0px 8px 8px 0px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      backgroundColor: ' #b6bcc1',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '8px',
    },
  },

  '.Mui-focused': {
    color: '#1E1E1E !important',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E1E1E !important',
      borderWidth: '1px !important',
    },
  },

  '.Mui-selected': {
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    ':hover': {
      backgroundColor: 'rgba(145, 158, 171, 0.08)',
    },
  },
}));
