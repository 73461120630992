import styled from 'styled-components';

export const NotificationSettingsWrap = styled.div`
  .MuiCheckbox-root {
    color: #73756d;
  }
  .Mui-checked {
    color: #adcb53;
  }
  .top-wrap {
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #f0f1ec;
    background: #fff;
    box-shadow: 0px 6px 12px -4px rgba(240, 241, 236, 0.32);
    .MuiAlert-root {
      border-radius: 8px;
      border: 1px solid #00b8d9;
      background: rgba(202, 253, 245, 0.16);
      .MuiAlert-message {
        color: #006c9c;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      .alert-action-wrap {
        button {
          padding: 4px 10px;
          border-radius: 8px;
          background: rgba(0, 184, 217, 0.16);
          color: #006c9c;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        .remove-btn {
          padding: 4px 10px;
          border-radius: 8px;
          border: 1px solid rgba(0, 184, 217, 0.48);
          background-color: transparent;
          color: #00b8d9;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-left: 16px;
        }
        .abnormal-change-btn {
          padding: 4px 10px;
          border-radius: 8px;
          border: 1px solid rgba(255, 171, 0, 0.48);
          background-color: transparent;
          color: #ffab00;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-left: 16px;
        }
      }
    }
    .alert-abnormal {
      border: 1px solid #ffab00;
      background: rgba(255, 245, 204, 0.16);
      .MuiAlert-message {
        color: #b76e00;
      }
      .alert-action-wrap {
        button {
          background: rgba(255, 171, 0, 0.16);
          color: #b76e00;
        }
      }
    }
    .setting-wrap {
      .setting-item-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        padding: 8px 0px;
        border-bottom: 1px solid #eef0e8;
        :last-child {
          border: none;
        }
        .left-wrap {
          .top-title {
            color: #27291e;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
          }
          .bottom-desc {
            color: #73756d;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
        }
        .right-wrap {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          .check-readonly {
            cursor: default;
            img {
              margin-right: 8px;
            }
          }

          .MuiFormControlLabel-root {
            margin-right: 24px;
            :last-child {
              margin-right: 0px;
            }

            .MuiCheckbox-indeterminate {
              color: #b6b8b0;
            }
            .MuiFormControlLabel-label {
              color: #27291e;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .bottom-wrap {
    display: flex;
    padding: 24px 0px 24px 40px;
    justify-content: flex-end;
    align-items: center;

    align-self: stretch;
    button {
      padding: 11px 40px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      border-radius: 8px;
    }
  }
`;
