import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';
import { accountStore } from '@/stores';

const Loadable = (Component: ElementType, isFallback?: boolean) => (props: any) => {
  const { user } = accountStore;
  return (
    <Suspense fallback={isFallback || user ? null : <LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export const LoginPage = Loadable(lazy(() => import('@/pages/LoginPage')));

export const VerifitionPage = Loadable(lazy(() => import('@/pages/VerifitionPage')));

export const ForgotPasswordPage = Loadable(
  lazy(() => import('@/pages/ForgotPassword/ForgotPasswordPage'))
);
export const RegisterPage = Loadable(lazy(() => import('@/pages/RegisterPage')));

// Referral Program src/pages/ReferralProgram
export const ReferralProgramPage = Loadable(lazy(() => import('@/pages/ReferralProgram')));

// WithdrawRecord
export const WithdrawRecordPage = Loadable(
  lazy(() => import('@/pages/ReferralProgram/WithdrawRecord'))
);

// dashboard
export const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));

// trading order
export const TradingOrderPage = Loadable(lazy(() => import('@/pages/TradingOrderPage')));

// trading order AddressList
export const AddressListPage = Loadable(lazy(() => import('@/sections/tradingOrder/AddressList')));

//CreateRefundPage

export const CreateRefundPage = Loadable(lazy(() => import('@/pages/CreateRefundPage')));

//Balances
export const BalancesPage = Loadable(lazy(() => import('@/pages/balance')));

//Invoice
export const InvoicePage = Loadable(lazy(() => import('@/pages/Invoice')));

//InvoiceOneDetails
export const InvoiceOneDetailsPage = Loadable(
  lazy(() => import('@/pages/Invoice/details/OneTime'))
);

// RecurringDetail
export const RecurringDetailsPage = Loadable(
  lazy(() => import('@/pages/Invoice/details/RecurringDetail'))
);

//ManageDetail
export const ManageDetailsPage = Loadable(
  lazy(() => import('@/pages/Invoice/details/ManageDetail'))
);

//Invoice Create
export const CreateInvoicePage = Loadable(lazy(() => import('@/pages/Invoice/CreateInvoice')));

//Invoice PDFpage
export const InvoicePDFpage = Loadable(
  lazy(() => import('@/pages/Invoice/components/PDFpage')),
  true
);

//EmailBilling
export const EmailBillingPage = Loadable(lazy(() => import('@/pages/EmailBillingPage')));

//CheckPage
export const CheckPage = Loadable(lazy(() => import('@/pages/CheckPage')));

//PersonalStore
export const PersonalStorePage = Loadable(lazy(() => import('@/pages/PersonalStorePage')));

//PaymentApi
export const PaymentApiPage = Loadable(lazy(() => import('@/pages/PaymentApiPage')));

//PaymentApiConfig
export const PaymentApiConfigPage = Loadable(lazy(() => import('@/pages/PaymentApiConfigPage')));

//Account Settings
export const AccountSettingsPage = Loadable(lazy(() => import('@/pages/AccountSettingsPage')));

//PaymentApiOrderDetails
export const PaymentApiOrderDetailsPage = Loadable(
  lazy(() => import('@/pages/PaymentApiOrderDetailsPage'))
);

//MerchantSetting MerchantSettingPage
export const MerchantSettingPage = Loadable(lazy(() => import('@/pages/MerchantSettingPage')));

//WebHookPage
export const WebHookPage = Loadable(lazy(() => import('@/pages/WebHook')));

//Support
export const SupportPage = Loadable(lazy(() => import('@/pages/SupportPage')));

//error Page
export const Page404 = Loadable(lazy(() => import('../pages/Errors/Page404')));
export const Page403 = Loadable(lazy(() => import('../pages/Errors/Page403')));
export const Page500 = Loadable(lazy(() => import('../pages/Errors/Page500')));

//wallet
export const WalletDeveloperPage = Loadable(lazy(() => import('@/pages/WalletApi/DeveloperPage')));
export const WalletCoinListPage = Loadable(lazy(() => import('@/pages/WalletApi/CoinListPage')));
export const CoinInfoPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/coinList/CoinInfo'))
); //coin list -coin info
export const CoinSettingsPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/coinList/CoinSettings'))
); //coin list -coin CoinSettings

export const CoinListDetailsPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/coinList/CoinListDetails'))
);
export const SelectPlatformPage = Loadable(lazy(() => import('@/pages/SelectPlatform/Index')));
export const NetworkConfigurationPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/networkConfiguration/index'))
);
export const ViewTokensPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/networkConfiguration/viewTokens'))
);
export const WebhookPage = Loadable(lazy(() => import('@/pages/WalletApi/pages/webhook/index')));
export const WalletTransactionPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/transaction/index'))
);
export const WalletUnclearFundsnPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/transaction/unclear-funds/index'))
);

export const WalletOverviewPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/overview/index'))
);

export const WalletBalancePage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/balance/index'))
); //balance
export const WalletBalanceDetailsPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/balance/details/index'))
); //balance details

export const WalletUserOrderPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/user-order/index'))
);

export const WalletUserListPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/userList/index'))
);
export const WalletUserDetailPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/userList/detail/index'))
);

export const WalletAssetCollectionPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/asset-collection/AssetCollection'))
);

export const WalletAssetConfigPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/asset-collection/AssetConfig'))
);

export const WalletAssetRecordPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/asset-collection/AssetRecord'))
);

//Account Settings
export const WalletAccountSettingsPage = Loadable(
  lazy(() => import('@/pages/WalletApi/pages/MerchantSetting/AccountSettingsPage'))
);
