import { throttle } from 'lodash-es';
import { useCallback, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * getdom尺寸，这是一个resize-observer的监听实现
 */
// eslint-disable-next-line
export default function <T extends Element = HTMLDivElement>(fn: (rect: Rect) => void, delay = 0) {
  const stateRef = useRef<State<T>>({
    width: 0,
    height: 0,
    el: null,
    fn,
  });
  stateRef.current.fn = delay > 0 ? throttle(fn, delay) : fn;
  const setRect = useCallback(({ width, height }: Rect) => {
    const state = stateRef.current;
    if (height !== state.height || width !== state.width) {
      Object.assign(state, { width, height });
      state.fn({ width, height });
    }
  }, []);
  const [observer] = useState(
    () =>
      new ResizeObserver((entries: any) => {
        for (const entry of entries) {
          setRect(entry.contentRect);
        }
      })
  );
  const [ref] = useState(() => (el: T | null) => {
    const state = stateRef.current;
    if (state.el) {
      observer.unobserve(state.el);
    }
    if (el) {
      state.el = el;
      setRect({ width: el.clientWidth, height: el.clientHeight });
      observer.observe(el);
    } else {
      state.el = null;
    }
  });

  return ref;
}

interface Rect {
  width: number;
  height: number;
}

interface State<T> extends Rect {
  el: T | null;
  fn(rect: Rect): void;
}
