import { Http } from '@/api/http';
import CryptoJS from 'crypto-js';
const http = new Http('/merchant/referral/v1');
export default class ReferralApi {
  /**
   * info  
   */
  getInfo(): Promise<getInfoResType> {
    return http.postJson('/info', {});
  }

  /**
 * get MerchantList 
 */
  getMerchantList(data: getMerchantListParamType): Promise<getMerchantListResType> {
    return http.postJson('/merchant/list', data);
  }

  /**
 * balance  
 */
  getBalance(): Promise<getInfoResType> {
    return http.postJson('/info', {});
  }

  /**
 * RecordList  
 */
  getRecordList(page: number): Promise<getRecordResType> {
    return http.postJson('/referral_record_list', { page, });
  }

  /**
 * withdrawList  
 */
  getWithdrawList(params: getWithdrawParamType): Promise<getWithdrawResType> {
    return http.postJson('/withdraw_list', { ...params });
  }

  /**
* MerchantBalance
*/
  getMerchantBalance(): Promise<getMerchantBalanceResType> {
    return http.postJson('/merchant_balance', {});
  }

  /**
* WithdrawApply 
*/
  getWithdrawApply(): Promise<getWithdrawApplyResType> {
    return http.postJson('/withdraw_apply', {});
  }

  /**
* WithdrawFee get
*/
  getWithdrawFee(params: getWithdrawFeeType): Promise<getWithdrawFeeResType> {
    return http.postJson('/withdraw_fee', { ...params });
  }

  /**
* WithdrawInfo get
*/
  getWithdrawInfo(params: getWithdrawInfoType): Promise<getWithdrawInfoResType> {
    return http.postJson('/withdraw_info', { ...params });
  }

  /**
* Withdraw submit 
*/
  getWithdrawSubmit(params: getWithdrawSubmitType): Promise<getWithdrawSubmitResType> {
    params.pay_pass = CryptoJS.MD5(params.pay_pass).toString();
    return http.postJson('/withdraw_submit', { ...params });
  }

}


export type getInfoResType = {
  referral_num: number;
  referral_url: string
}

export type getMerchantListParamType = {
  Page: number;
  PageSize: number;
};

export type getMerchantListType = {
  create_at: number;
  rebate_mode: number;
  referral_merchant_id: string
};

export type getMerchantListResType = {
  list: getMerchantListType[],
  total: number
}

export type getRecordListType = {
  created: number;
  record_id: string;
  amount: string
};

export type getRecordResType = {
  list: getRecordListType[],
  count: number
}

export type getWithdrawType = {
  status: number;
  created: number;
  record_id: string;
  amount: string;
  tx_id: string
}

export type getWithdrawParamType = {
  page: number;
  pageSize?: number;
  start?: number;
  end?: number;
  status?: number;//-1 all, 0 in progress, 1 success, 2 failure
};

export type getWithdrawResType = {
  list: getWithdrawType[],
  count: number
}

export type getMerchantBalanceResType = {
  list: List[];
  status: Statu;
  total_value: string;
}
export type List = {
  balance: string;
  chain_id: string;
  change24: string;
  contract: string;
  created: string;
  id: number;
  logo: string;
  market_cap: string;
  merchant_id: number;
  name: string;
  out_balance: string;
  out_value: string;
  price: string;
  symbol: string;
  token_id: string;
  updated: string;
  value: string;
}

export type Statu = {
  code: number;
  error_msg: string;
}

export type getWithdrawApplyResType = {
  bill_id: string
}

export type getWithdrawFeeType = {
  address: string;
  chain_id: string;
  contract: string;
  memo: string;
}

export type getWithdrawFeeResType = {
  chain_id: string;
  contract: string;
  precision: string;
  symbol: string;
  token_id: string;
  value: string;
  virtual_value: string;
}

export type getWithdrawInfoType = {
  bill_id: string
}

export type AdditionInfo = {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export type AdditionalProp1 = {
  dec: string;
  facade_amount: string;
  send_amount: string;
  status: string;
  tax_text: string;
}

export interface AdditionalProp2 {
  dec: string;
  facade_amount: string;
  send_amount: string;
  status: string;
  tax_text: string;
}

export interface AdditionalProp3 {
  dec: string;
  facade_amount: string;
  send_amount: string;
  status: string;
  tax_text: string;
}

export interface Cate_arr {
  additionalProp1: AdditionalProp1;
  additionalProp2: AdditionalProp2;
  additionalProp3: AdditionalProp3;
}

export interface Fee_info {
  chain_id: string;
  contract: string;
  precision: string;
  symbol: string;
  token_id: string;
  value: string;
  virtual_value: string;
}

export type To_token = {
  createTime: string;
  address_regex: string;
  balance: string;
  belong_chain_logo: string;
  can_convert: boolean;
  chain_id: string;
  chain_name: string;
  change24: string;
  community: string;
  contract: string;
  decimal: number;
  description: string;
  down_deadline_time: number;
  explorer: string;
  fee: string;
  fee_self_flag: number;
  id: string;
  is_off: boolean;
  is_stop_withdraw: boolean;
  is_support_memo: boolean;
  large_logo: string;
  logo: string;
  market_cap: string;
  max: string;
  max_supply: string;
  memo_regex: string;
  merge_chains: string[];
  merge_tokens: string[];
  merged: string;
  merged_token_id: string;
  min: string;
  name: string;
  official_site: string;
  payload: Payload;
  precision: number;
  price: string;
  recommend: number;
  recovered: number;
  social_profiles: string;
  sort: number;
  source_code: string;
  status: number;
  status_maintain_source: number;
  symbol: string;
  symbol_chain_name: string;
  token_id: string;
  total_supply: string;
  type: string;
  type_symbol: string;
  un_confirmed_balance: string;
  visual_balance: string;
  visual_un_confirmed_balance: string;
  white_paper: string;
}

export type Payload = {
  chain_display_network: string;
  chain_logo: string;
  chain_network_desc: string;
  txid_url: string;
}

export type Token = {
  createTime: string;
  address_regex: string;
  balance: string;
  belong_chain_logo: string;
  can_convert: boolean;
  chain_id: string;
  chain_name: string;
  change24: string;
  community: string;
  contract: string;
  decimal: number;
  description: string;
  down_deadline_time: number;
  explorer: string;
  fee: string;
  fee_self_flag: number;
  id: string;
  is_off: boolean;
  is_stop_withdraw: boolean;
  is_support_memo: boolean;
  large_logo: string;
  logo: string;
  market_cap: string;
  max: string;
  max_supply: string;
  memo_regex: string;
  merge_chains: string[];
  merge_tokens: string[];
  merged: string;
  merged_token_id: string;
  min: string;
  name: string;
  official_site: string;
  payload: Payload;
  precision: number;
  price: string;
  recommend: number;
  recovered: number;
  social_profiles: string;
  sort: number;
  source_code: string;
  status: number;
  status_maintain_source: number;
  symbol: string;
  symbol_chain_name: string;
  token_id: string;
  total_supply: string;
  type: string;
  type_symbol: string;
  un_confirmed_balance: string;
  visual_balance: string;
  visual_un_confirmed_balance: string;
  white_paper: string;
}

export type getWithdrawInfoResType = {
  operator: string;
  action: string;
  additionInfo: AdditionInfo;
  airdrop_value: string;
  app_id: string;
  append_ix: string;
  article_id: string;
  block_number: string;
  business_id: string;
  cate_arr: Cate_arr;
  chain_id: string;
  code_name: string;
  confirm_desc: string;
  confirmed: boolean;
  contract: string;
  current_block_height: number;
  discord_group_id: string;
  donation_code: string;
  draw_type: number;
  errno: string;
  facade_amount: string;
  fee: string;
  fee_chain_id: string;
  fee_contract: string;
  fee_desc: string;
  fee_info: Fee_info;
  from: string;
  from_account_id: string;
  from_address: string;
  from_discord: string;
  from_invoice: string;
  from_or_to: string;
  from_slack: string;
  from_sns_id: string;
  from_telegram: string;
  from_twitter: string;
  hand_status: string;
  has_been_claimed: string;
  hash: string;
  id: string;
  invoice_title: string;
  is_inner_tx: boolean;
  is_recharge_refund: string;
  jump_detail: boolean;
  life_crypt_desc: string;
  link: string;
  log_index: string;
  mobile: string;
  network: string;
  nft_token_id: string;
  order_id: string;
  order_no: string;
  origin_record_id: string;
  origin_record_type: number;
  payee_count: string;
  platform: string;
  present_id: string;
  rate: string;
  receive_currency_iso: string;
  recharge_fee: string;
  recharge_refund_amount: string;
  recharge_status: string;
  record_id: string;
  refund_amount: string;
  refund_txid: string;
  remaining_time: number;
  rewardSubtype: number;
  send_amount: string;
  send_currency_iso: string;
  slack_group_id: string;
  source: number;
  status: string;
  sub_type: string;
  telegram_group_id: string;
  timestamp: string;
  tip_remark: string;
  to: string;
  toValue: string;
  to_account_id: string;
  to_address: string;
  to_discord: string;
  to_invoice: string;
  to_slack: string;
  to_sns_id: string;
  to_telegram: string;
  to_token: To_token;
  to_twitter_id: string;
  token: Token;
  token_amount: string;
  total_value: string;
  transaction_rate: string;
  transaction_value: string;
  twitter_id: string;
  tx_block_height: number;
  tx_need_confirm_block_num: number;
  txid: string;
  txid_url: string;
  type: number;
  value: string;
}

export type getWithdrawSubmitType = {
  address: string;
  bill_id: string;
  code: string;
  comment: string;
  fee: string;
  invoice: string;
  memo: string;
  pay_pass: string;
  remarks: string;
  submit_type: number;
  timestamp: number;
  token_id: string;
  value: string;
}

export type getWithdrawSubmitResType = {
  bill_id: string;
  network_fee: string;
  type: string;
}