import { MyIcon } from '@/assets/icons/MyIcon';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import CoinWalletSrc from '@/assets/img/Wallet.svg';
import AssetCircleSrc from '@/assets/img/circle.svg';
import AccessBusinessSrc from '@/assets/img/access-business.svg';
import SecuritySettingsSrc from '@/assets/img/security-settings.svg';
import ApiInterfaceSrc from '@/assets/img/api-interface.svg';
import CommonProblemSrc from '@/assets/img/common-problem.svg';
import PersonalAccessBusinessSrc from '@/assets/img/personal-Access-business.svg';
import { useNavigate } from 'react-router';
import { coinlistApi } from '@/pages/WalletApi/common/api';
import { useLocales } from '@/locales';
import { CONTACT_US_URL } from '@/config';

type Porps = {
  setOpenBenginer: () => void;
};

export default function Index({ setOpenBenginer }: Porps) {
  const [openId, setOpenId] = useState('');
  const navigate = useNavigate();
  const { translate } = useLocales();
  const handleGetList = async () => {
    const param = {
      Status: 0,
    };
    try {
      let res = await coinlistApi.getNewCoinList(param);
      return res.List;
    } catch {
      console.log('errr');
    }
  };
  const QuickStartOptions = [
    {
      logo: CoinWalletSrc,
      title: 'Add coin',
      desc: 'Configure tokens that suit your business',
      src: '/wallet/coin-list',
    },
    // Enable asset management policies
    {
      logo: AssetCircleSrc,
      title: 'Enable asset management policies',
      desc: 'Excellent asset management configuration can effectively reduce network fees',
      src: '/wallet/asset-collection/index',
    },

    {
      logo: AccessBusinessSrc,
      title: 'Access business',
      desc: 'Terminal business docking, view access documents to start docking and debugging',
      src: '/wallet/developer',
    },

    {
      logo: SecuritySettingsSrc,
      title: 'Security Settings',
      desc: 'Enable payment password and two-factor authentication to help secure your account and assets.',
      src: '/wallet/account/settings',
    },
  ];

  const startAccessingOption = [
    {
      logo: ApiInterfaceSrc,
      title: 'API interface',
      desc: '',
      src: 'https://documenter.getpostman.com/view/27947870/2s93zE4LAL',
    },

    {
      logo: CommonProblemSrc,
      title: 'Common problem',
      desc: '',
      src: 'https://wallet-api.ccpayment.com/faq',
    },

    {
      logo: PersonalAccessBusinessSrc,
      title: 'Access business',
      desc: '',
      src: '/wallet/developer',
    },
  ];

  const faqOptions = [
    {
      title: `How to Integrate API on Your Website/Application?`,
      desc: `Check out CCPayment’s API/SDK tutorial for quick integration, and don’t hesitate to contact the CCPayment customer service team for any issues you may encounter.`,
      src: '',
    },

    {
      title: 'How to get technical support？',
      desc: `Support Mail: support@ccpayment.comContact our official technical support team for in-depth assistance.`,
      src: '',
    },

    {
      title: `What is asset collection? What are the advantages?`,
      desc: `The asset collection plan aims to collect the assets of various addresses into a single address. This plan is designed to minimize the risk of errors in account management, lower management costs, and enhance the user experience.`,
      src: '',
    },

    {
      title: `Can I exchange Crypto for Fiat via CCPayment?`,
      desc: `CCPayment can provide the conversion service of crypto to fiat for CCPayment’s key clients. Please get in touch with the `,
      src: '',
      link: CONTACT_US_URL,
      linkDesc: 'CCPayment Service Team.',
    },
  ];

  return (
    <MerchantGuideWrap>
      <Box className="top-bar" component={'div'}>
        <div className="left-box">
          <MyIcon name="beginner" size={24} />
          <span>{translate('wallet.beginner.guide')}</span>
        </div>
        <div
          className="right-box"
          onClick={() => {
            setOpenBenginer();
          }}
        >
          <MyIcon name="close" size={14} />
        </div>
      </Box>

      <div className="quick-start-wrap">
        <div className="title">{translate('wallet.quick.start')}</div>
        {QuickStartOptions.map((item, index) => (
          <div
            key={item.logo}
            className="quick-start-item"
            onClick={() => {
              if (item.src && !item.src.includes('http')) {
                if (index === 0) {
                  handleGetList().then((result) => {
                    if (result) {
                      navigate('/wallet/coin-list/coinInfo', {
                        state: {
                          list: result || [],
                        },
                      });
                    }
                  });
                  return;
                }
                navigate(item.src);
                setOpenBenginer();
              }
            }}
          >
            <img src={item.logo} alt="" style={{ width: '32px', height: '32px' }} />
            <div>
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="quick-start-wrap">
        <div className="title">{translate('wallet.start.accessing')}</div>
        {startAccessingOption.map((item) => (
          <div
            key={item.logo}
            className="quick-start-item"
            onClick={() => {
              if (item.src && !item.src.includes('http')) {
                navigate(item.src);
                setOpenBenginer();
              } else if (item.src && item.src.includes('http')) {
                window.open(item.src);
              }
            }}
          >
            <img src={item.logo} alt="" style={{ width: '24px', height: '24px' }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h4>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>

      <div className="obtain-app-id">
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/wallet/developer');
            setOpenBenginer();
          }}
          sx={{
            background: 'rgba(173, 203, 83, 0.08)',
            color: '#82993C',
            border: '0 !important',
            fontWeight: 500,
          }}
        >
          {translate('wallet.obtain.app.id')}
        </Button>
      </div>

      <div className="faq-warp">
        <div className="title">{translate('wallet.faq')}</div>
        {faqOptions.map((item) => (
          <div key={item.title} className="faq-item-wrap">
            <div className="title">
              <span>{item.title}</span>
              <MyIcon
                name={`${openId === item.title ? 'faqFold' : 'faqAdd'}`}
                size={24}
                onClick={() => {
                  if (openId === item.title) {
                    setOpenId('');
                  } else {
                    setOpenId(item.title);
                  }
                }}
              />
            </div>
            {openId === item.title ? (
              <div
                className="desc"
                style={{
                  overflow: openId === item.title ? 'visible' : 'hidden',
                  height: openId === item.title ? 'auto' : '0px',
                  transition: 'height 0.3s',
                }}
              >
                {item.link ? (
                  <div>
                    {item.desc}{' '}
                    <a
                      rel="noreferrer"
                      href={item.link}
                      target="_blank"
                      style={{ color: '#00B8D9' }}
                    >
                      {item.linkDesc}
                    </a>
                  </div>
                ) : (
                  item.desc
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div
        className="more-help"
        onClick={() => {
          window.open('https://wallet-api.ccpayment.com/faq');
        }}
      >
        <span>{translate('wallet.more.help')}</span>
        <MyIcon name="green_arrow" size={6} />
      </div>
    </MerchantGuideWrap>
  );
}

const MerchantGuideWrap = styled.div`
  padding: 16px 24px;
  background: #f9faf6;
  height: 100%;
  > .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #27291e;
    .left-box {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
    .right-box {
      cursor: pointer;
    }
  }

  > .quick-start-wrap {
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #73756d;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .quick-start-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      :hover {
        cursor: pointer;
        h4 {
          color: #82993c;
        }
      }
      > img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
      > div {
        h4 {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #27291e;
          margin: 0;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #73756d;
          margin: 0;
        }
      }
    }
  }

  .obtain-app-id {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    button {
      color: #82993c;
      border: 1px solid #82993c;
      border-radius: 8px;
    }
  }

  .faq-warp {
    > .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #73756d;
      margin-bottom: 24px;
    }
    .faq-item-wrap {
      margin-bottom: 24px;
      > .title {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #27291e;
        margin-bottom: 8px;
        img {
          height: 24px;
          cursor: pointer;
        }
      }
      > .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #73756d;
      }
    }
  }

  .more-help {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      color: #82993c;
      font-size: 14px;
      font-family: Poppins;
      font-weight: 500;
      line-height: 26px;
      margin-right: 8px;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    img {
      transform: rotate(180deg);
    }
  }
`;
