import { observable, makeObservable } from 'mobx';

export interface User {
  create_time: number;
  email: string;
  exists_google_2fa: boolean;
  exists_lock_pass: boolean;
  exists_login_pass: boolean;
  exists_pay_pass?: boolean;
  gg_verify_secret_key: string;
  gg_verify_secret_qr: string;
  id: number;
  is_check: boolean;
  lock_try: number;
  lock_unit: number;
  nickname: string;
  phone: string;
  phoneArea?: string;
  user_id: string;
  wwl_status: number;
  [key: string]: any;
}
export class AccountStore {
  // Whether the user has successfully logged in
  isAuthenticated: boolean = false;

  // Whether the merchant chooses successfully
  isSwitchMerchant: boolean = false;

  // Has the merchant name changed?
  isChangeMerchantName: boolean = false;

  // Is the user verified successfully?
  Verify: boolean = false;

  /**是否重定向到api订单详情 */
  isRedirectApiOrder: boolean = false;

  // Basic user information
  user: User = {
    create_time: 0,
    email: '',
    // Whether to open Google 2fa
    exists_google_2fa: false,
    // Whether to lock
    exists_lock_pass: false,
    //Whether password login
    exists_login_pass: false,
    exists_pay_pass: false,
    //Did you enable the Google 2FA key
    gg_verify_secret_key: '',
    //Did you enable the Google 2FA chat
    gg_verify_secret_qr: '',
    // 14 days without reason, fasle needs true not needed
    is_check: true,
    // Number of password locks
    lock_try: 0,
    // No operation lockout time
    lock_unit: 0,
    nickname: '',
    phone: '',
    user_id: '',
    id: 0,
    wwl_status: 0,
  };

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      isSwitchMerchant: observable,
      isChangeMerchantName: observable,
      isRedirectApiOrder: observable,
      Verify: observable,
      user: observable,
    });
  }

  // initialization User data
  initData() {
    this.user = {
      create_time: 0,
      email: '',
      exists_google_2fa: false,
      exists_lock_pass: false,
      exists_login_pass: false,
      exists_pay_pass: false,
      gg_verify_secret_key: '',
      gg_verify_secret_qr: '',
      id: 0,
      is_check: true,
      lock_try: 0,
      lock_unit: 0,
      nickname: '',
      phone: '',
      user_id: '',
      wwl_status: 0,
    };
    this.isAuthenticated = false;
    this.isSwitchMerchant = false;
    this.isChangeMerchantName = false;
    this.isRedirectApiOrder = false;
  }

  // Are you logged in
  isLogin() {
    return this.isAuthenticated;
  }

  //Are you logged in
  isVerify() {
    return this.Verify;
  }

  // Update login user information
  async updateUser(user: User, isAuthenticated: boolean, IsVerify: boolean) {
    this.user = { ...user };
    this.isAuthenticated = isAuthenticated;
    this.Verify = IsVerify;
  }

  // Update choose merchant status
  async updateSwitchStatus(status: boolean) {
    this.isSwitchMerchant = status;
  }

  // Update Selected Merchant Name Change Status
  async updateChangeNameStatus(status: boolean) {
    this.isChangeMerchantName = status;
  }

  async updateIsRedirectApiOrder(status: boolean) {
    this.isRedirectApiOrder = status;
  }
}
