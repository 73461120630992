import { Action, Location, createBrowserHistory } from 'history';
import { makeObservable, observable, runInAction } from 'mobx';

export const history = createBrowserHistory();

export const historyStore = makeObservable(
  {
    current: undefined as unknown as {
      action: Action;
      location: Location;
    },
  },
  { current: observable }
);

history.listen((update) => {
  runInAction(() => {
    historyStore.current = update;
  });
});
