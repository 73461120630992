import { useCallback, useState } from 'react';
import useMountedState from './useMountedState';

/**
 * setState对象合并形式，类似传统类组件setState
 * https://github.com/streamich/react-use/blob/master/src/useSetState.ts
 */
export default function useSetState<T>(
  initialState: T | (() => T)
): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] {
  const [state, set] = useState<T>(initialState);
  const isMounted = useMountedState();
  return [
    state,
    useCallback(
      (patch) => {
        if (isMounted()) {
          set((prevState: any) => {
            const newState = patch instanceof Function ? patch(prevState) : patch;
            const needUpdate =
              Object.keys(newState).findIndex((it) => prevState[it] !== (newState as any)[it]) !==
              -1;
            if (!needUpdate) return prevState;
            return Object.assign({}, prevState, newState);
          });
        }
      },
      // eslint-disable-next-line
      [set]
    ),
  ];
}
