import { makeObservable, observable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { assetCollectionApi } from '../api';
import i18n from 'i18next';
import { getCoinTokenListResType, getCollectCofigResType } from '../api/assetCollectionApi';

class AssetCollectionStroe {
  tableData: getCoinTokenListResType = {
    Tokens: {},
  };

  collectConfig = {
    AutoCollectionForWithdrawal: -1,
    RefillNetFeeMultiple: -1,
    AutoCollectionFrequency: -1,
  };

  constructor() {
    makeObservable(this, {
      tableData: observable,
      collectConfig: observable,
    });
    this.queryList = this.queryList.bind(this);
    this.getCurrentTokenCoin = this.getCurrentTokenCoin.bind(this);
    this.getCollectCofig = this.getCollectCofig.bind(this);
    this.saveCollectCofig = this.saveCollectCofig.bind(this);
    this.getNetworkUnit = this.getNetworkUnit.bind(this);
  }

  getCurrentTokenCoin(key: string) {
    const obj = this.tableData.Tokens[key] || {};
    return obj;
  }

  /**
   * Get network fee unit and conversion ratio
   * @param Network Fee Type Known ETHEREUM
   * @returns
   */
  getNetworkUnit(key: string) {
    const obj = {
      name: ['ETHEREUM'].includes(key) ? 'GWEI' : 'SATS',
      ratio: ['ETHEREUM'].includes(key) ? 1000000000 : 100000000,
    };
    return obj;
  }

  /**
   *Query token relationship list data
   * @memberof AssetCollectionStroe
   */
  async queryList() {
    try {
      const res = await assetCollectionApi.getCoinTokenList();
      this.tableData = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  /**
   * Query and collect the main configuration
   * @memberof AssetCollectionStroe
   */
  async getCollectCofig() {
    try {
      const res = await assetCollectionApi.getCollectCofig();
      this.collectConfig = res;
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  /**
   * Save the collection master configuration
   * @memberof AssetCollectionStroe
   */
  async saveCollectCofig(data: getCollectCofigResType) {
    try {
      await assetCollectionApi.saveCollectCofig(data);
      enqueueSnackbar(i18n.t(`common.Successful`));
      await this.getCollectCofig();
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(i18n.t(`wallet_interface_err.${error.code}`) as string, {
      //     variant: 'error',
      //   });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }
}
export default AssetCollectionStroe;
