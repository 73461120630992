import { useLocation, matchPath } from 'react-router-dom';



type ReturnType = {
  active: boolean;
  isExternalLink: boolean;
};

export default function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();
  const rootParhArr = [
    'transaction',
    'dashboard',
    'balances',
    'payment',
    'merchatsetting',
    'invoice',
    'webhook',
    'developer',
    'network-configuration',
    'coin-list',
    'overview',
    'user-order',
    'user-list',
    'balance',
    'asset-collection',
  ];

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  //解决 导航栏路由下还有其他页面导致导航栏不被选中的问题
  const deepActive = path
    ? rootParhArr.filter((item) => pathname.includes(item) && path.includes(item)).length > 0
    : false;
  // const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  return {
    active: deep ? deepActive : normalActive,
    isExternalLink: path.includes('http'),
  };
}
