import { useState } from 'react';
import { Token, NetworkType, swapFromListType } from '@/api/WalletApi';
import { Alert, Box, Typography } from '@mui/material';
import { WhitelistRes } from '@/api/payment-api';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import Decimal from 'decimal.js';
import { integerTokenArr } from '@/config';
import { walletStroe } from '@/stores';
import { numAdd, numSubtr } from '@/utils/until';
// 1 Normal 2 Maintenance 3 Pre-removal
export enum ChainStatus {
  Normal = 1,
  Maintain = 2,
  PrepareOffline = 3,
}
export interface SendDataProps {
  selectOpen: boolean;
  payOpen: boolean;
  token: Token | undefined;
  chainList: NetworkType[];
  network: string;
  chain_id: string;
  contract: string;
  address: string;
  id_address: string;
  precision: number;
  sendLoading: boolean;
  amount: string;
  feeInfo: {
    symbol: string;
    token_id: string;
    value: string;
    virtual_value: string;
  };
  submit_type: number;
  pay_pass: string;
  code: string;
  /**Withdrawal whitelist switch */
  wwl_type: number;
  wwl_list: WhitelistRes[];
  check_wwl_id: number;
  memo?: string;
  is_support_memo?: boolean;
  error?: boolean;
}

export interface DepositDataProps {
  selectOpen: boolean;
  token: Token | undefined;
  chainList: NetworkType[];
  network: string;
  chain_id: string;
  chainName: string;
  chainSymbol: string;
  contract: string;
  address: string;
  generate_time: number;
  status: number;
  token_status?: number;
  memo?: string;
}

export interface SwapDataProps {
  fromSelectOpen: boolean;
  toSelectOpen: boolean;
  fromToken: swapFromListType | undefined;
  toToken: swapFromListType | undefined;
  status?: number;
  token_status?: number;
  memo?: string;
  fromAmount: string;
  toAmount: string;
  payOpen: boolean;
}

export const defaultDepositData = {
  selectOpen: false,
  token: undefined,
  chainList: [],
  network: '',
  chain_id: '',
  chainName: '',
  chainSymbol: '',
  contract: '',
  address: '',
  generate_time: 0,
  status: 1,
  token_status: 1,
};

export const defaultSwapData = {
  fromSelectOpen: false,
  toSelectOpen: false,
  fromToken: undefined,
  toToken: undefined,
  status: 1,
  token_status: 1,
  fromAmount: '',
  toAmount: '',
  payOpen: false,
};

export const defaulSendData = {
  selectOpen: false,
  payOpen: false,
  sendLoading: false,
  token: undefined,
  error: false,
  chainList: [],
  chain_id: '',
  contract: '',
  network: '',
  address: '',
  id_address: '',
  amount: '',
  precision: 8,
  feeInfo: {
    symbol: '',
    token_id: '',
    value: '0',
    virtual_value: '0',
  },
  submit_type: 1,
  pay_pass: '',
  code: '',
  wwl_type: 1,
  wwl_list: [],
  check_wwl_id: 0,
};

export const feeAmount = (type: string, data: {
  feeInfo: { value: string },
  token: { symbol: string },
  precision: number,
  amount: string
}) => {
  //0 - 1 +
  let fee: number = Number(walletStroe.formateAmount({
    num: data.feeInfo.value,
    tokenSymbol: data.token?.symbol,
    precision: data.precision,
  }))

  let amount: string = '0'

  if (type === '0') amount = numSubtr(data?.amount || 0, fee)
  if (type === '1') amount = numAdd(data?.amount || 0, fee)

  return Number(amount)
}

export const getResAmount = (num: string = '0', fee: string = '0', precision: number = 8) => {
  let resAount = '0';
  if (!Number(num)) return (resAount = '0');
  const networkFee = new Decimal(fee);
  const value = num.includes(',') ? num.replace(/,/gi, '') : num;
  const nValue = new Decimal(value);
  resAount = nValue.minus(networkFee).toFixed(precision, Decimal.ROUND_DOWN);
  resAount = Number(resAount) < 0 ? '0' : resAount;
  return resAount;
};

export const setAmount = (num: string = '0', precision: number = 8) => {
  if (typeof num !== 'string') return '0';
  let result = num;
  if (num.includes('.') && num.slice(num.indexOf('.')).length > precision + 1 && Number(num) >= 0) {
    const index = result.indexOf('.');
    result = result.slice(0, index) + result.slice(index, index + precision + 1);
  }
  return result;
};

export const sendDisable = (data: SendDataProps) => {
  let disabled = false;
  let errMsg = '';
  // eslint-disable-next-line
  const fee = data.feeInfo.value;
  // eslint-disable-next-line
  const amount = data.amount;

  const minD = new Decimal(data.token?.min ?? '0');
  const feeD = new Decimal(fee ?? '0');
  const minAmount = walletStroe.formateAmount({
    num: minD.add(feeD),
    tokenSymbol: data.token?.symbol,
    precision: data.precision,
  });

  if (!amount || !data.token) {
    disabled = true;
    return {
      disabled,
      errMsg,
    };
  }

  if (data.submit_type === 1 && Number(amount) < Number(fee)) {
    disabled = true;
    errMsg = 'amount higher than the network fee.';
  }

  //Cash withdrawal amount + handling fee is greater than the total amount is not allowed
  // if (Number(amount) + Number(fee) > Number(data.token?.balance)) {
  //   disabled = true;
  //   errMsg = 'Insufficient balance';
  // }

  if (
    Number(data.token?.balance) - Number(amount) < Number(fee) &&
    Number(data.token?.balance) - Number(amount) < 0
  ) {
    disabled = true;
    errMsg = 'Insufficient balance';
  }

  if (
    !integerTokenArr.includes(data.token?.symbol ?? '') &&
    data.submit_type === 1 &&
    (!data.chain_id || !data.address)
  ) {
    disabled = true;
  }
  if (
    !integerTokenArr.includes(data.token?.symbol ?? '') &&
    data.submit_type === 2 &&
    !data.id_address
  ) {
    disabled = true;
  }
  if (data.error) {
    disabled = true;
  }
  // There is no minimum amount limit for internal transfers
  if (Number(amount) - Number(fee) < Number(data.token.min) && data.submit_type === 1) {
    disabled = true;
    errMsg = `The minimum payment amount is ${minAmount} ${data.token.symbol}. `;
  }

  if (
    integerTokenArr.includes(data.token?.symbol ?? '') &&
    !/^(?!0)[1-9]\d{1,7}$|^[1-9][0-9]{7}$|100000000$/.test(data?.amount || '')
  ) {
    disabled = true;
  }

  //Operation is not allowed when the amount is 0
  if (Number(amount) === 0) {
    disabled = true;
  }
  //The operation is not allowed in the case of negative numbers eg: -0.000123
  if (Number(amount) - Number(fee) <= 0) {
    disabled = true;
  }

  return {
    disabled,
    errMsg,
  };
};

export const CwalletTip = ({ fee_value }: { fee_value: string }) => {
  const [show, setShow] = useState(true);
  const { translate: t } = useLocales();
  if (Number(fee_value) === 0) {
    return null;
  }

  return (
    <>
      {show && (
        <Alert
          variant="outlined"
          severity="info"
          sx={{
            fontWeight: 500,
            fontSize: 12,
            mt: 1,
            '& .MuiAlert-action': { display: 'flex', alignItems: 'center' },
          }}
          onClose={() => {
            setShow(false);
          }}
        >
          {t('referral.referral_withdraw_tips')}
        </Alert>
      )}
    </>
  );
};

export const MaintainBox = () => {
  const { translate: t } = useLocales();
  return (
    <Box
      sx={{
        border: '1px solid #FFAB00',
        borderRadius: '8px',
        padding: '4px 8px',
        height: '28px',
        whiteSpace: 'nowrap',
        display: 'flex',
      }}
    >
      <MyIcon name="ic_token_maintain" size={20} />
      <Typography
        component={'span'}
        sx={{
          color: '#FFAB00',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          letterSpacing: '0.1px',
        }}
      >
        {t('common.token_In_maintenance')}
      </Typography>
    </Box>
  );
};

export const PrepareOfflineBox = () => {
  const { translate: t } = useLocales();
  return (
    <Box sx={{ display: 'flex', mt: 2 }}>
      <MyIcon name="ic_token_Withdrawal_warning" size={16} />
      <Typography
        component={'span'}
        sx={{
          color: '#FFAB00',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.1px',
          ml: 1,
        }}
      >
        {t('common.token_In_Prepare_Offline_desc')}
      </Typography>
    </Box>
  );
};
