import http from './http';

export default class WebhookApi {
  getWebhookLogList(params: WebhookLogListSearchParam): Promise<WebhookLogListRes> {
    return http.postJson('/webhook/log/list', params);
  }

  getWebhookLogDetail(id: number): Promise<WebhookLogDetailRes> {
    return http.postJson('/webhook/log/detail', { id });
  }

  resendOneLog(id: number) {
    return http.postJson('/webhook/log/resend/one', { id });
  }

  resendBatchLog(data: resendBatchLogParamType) {
    return http.postJson('/webhook/log/resend/batch', data);
  }
}
export type resendBatchLogParamType = {
  all: 1 | 0 | number;
  result: 'success' | 'failed' | string;
  begin_at: number;
  end_at: number;
};
export interface WebhookLogListSearchParam {
  /** API Deposit、API Withdrawal、Invoice、Direct Deposit */
  type: 'API Deposit' | 'API Withdrawal' | 'Invoice' | 'Direct Deposit' | string;
  /** pending、processing、success */
  status: 'pending' | 'processing' | 'success' | string;
  /** record_id、notify_url */
  key: 'record_id' | 'notify_url';
  value: string;
  /** success、failed */
  result: 'success' | 'failed' | string;
  page_num: number;
  page_size: number;
  begin_at: number;
  end_at: number;
}

export interface WebhookLogListRes {
  list: WebhookLogList[];
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
}

export interface WebhookLogList {
  record_id: string;
  type: string;
  times: number;
  result: string;
  id: number;
  notify_url: string;
  timestamp: number;
  pay_status: string;
  result_remark: number;
}

export interface WebhookLogDetailRes {
  record_id: string;
  times: number;
  result: string;
  send_data: string;
  type: string;
  timestamp: number;
  result_remark: number;
  notify_url: string;
  pay_status: string;
}
