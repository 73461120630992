import { configure } from 'mobx';
import { createContext, useContext } from 'react';
import { AccountStore } from './account';
import WalletStroe from './WalletStroe';
import System from './System';
import CCPWebhookStroe from './webhook';
import WalletWebhookStroe from '@/pages/WalletApi/common/stores/webhook';
import WalletTransactionStroe from '@/pages/WalletApi/common/stores/transaction';
import WalletUserOrdersStroe from '@/pages/WalletApi/common/stores/user-orders';
import WalletChainStroe from '@/pages/WalletApi/common/stores/chain-list';
import WalletUserListStroe from '@/pages/WalletApi/common/stores/user-list';
import WalletApiStroe from '@/pages/WalletApi/common/stores/WalletStroe';
import AssetCollectionStroe from '@/pages/WalletApi/common/stores/asset-collection';

configure({ enforceActions: 'never' });

export const walletStroe = new WalletStroe();

export const accountStore = new AccountStore();

export const systemStore = new System();

export const ccpWebhookStroe = new CCPWebhookStroe();

export const walletWebhookStroe = new WalletWebhookStroe();

export const walletTransactionStroe = new WalletTransactionStroe();

export const walletUserOrdersStroe = new WalletUserOrdersStroe();

export const walletChainStroe = new WalletChainStroe();

export const walletUserListStroe = new WalletUserListStroe();

export const walletApiStroe = new WalletApiStroe();

export const walletAssetCollectionStroe = new AssetCollectionStroe();

function createStores() {
  return {
    walletStroe,
    accountStore,
    systemStore,
    ccpWebhookStroe,
    walletWebhookStroe,
    walletTransactionStroe,
    walletUserOrdersStroe,
    walletUserListStroe,
    walletApiStroe,
    walletAssetCollectionStroe,
  };
}
const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export { stores, useStores };
