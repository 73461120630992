import { alpha } from '@mui/material/styles';

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'walletPrimary'
  | 'walletSecondary';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    light: string;
    main: string;
    dark: string;
    darker: string;
    contrastText: string;
    bg: string;
    color: string;
    hover: string;
    navHover: string;
    navTextColor: string;
  }

  interface Palette {
    walletPrimary: PaletteColor;
    walletSecondary: PaletteColor;
  }
}

// SETUP COLORS

export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#1E1E1E',
};

// const PRIMARY = {
//   lighter: '#C8FACD',
//   light: '#5BE584',
//   main: '#00AB55',
//   dark: '#007B55',
//   darker: '#005249',
//   contrastText: '#fff',
// };

export const PRIMARY = {
  lighter: '#DDE7FF',
  light: '#779AF8',
  main: '#3C6FF5',
  dark: '#3A5093',
  darker: '#1E387B',
  contrastText: '#fff',
  bg: '#DDE7FF', //bg
  color: '#3C6FF5', //color
  hover: '#3C6FF5',
  navHover: 'rgba(220, 224, 228, 0.32)',
  navTextColor: '#637381',
};

export const SECONDARY = {
  lighter: '#ECFCBC',
  light: '#D9FF65',
  main: '#CDF160',
  dark: '#A2BF5B',
  darker: '#5B6B32',
  contrastText: '#fff',
};

export const WALLETPRIMARY = {
  lighter: '#E5F9A9',
  light: '#C1E065',
  main: '#ADCB53', //icon nav
  dark: '#82993C',
  darker: '#546424',
  contrastText: '#fff',
  bg: '#EEF0E8', //bg
  color: '#82993C', //color
  hover: '#82993C',
  navHover: '#F9FAF6',
  navTextColor: '#73756D',
};

export const WALLETSECONDARY = {
  lighter: '#C0C5EB',
  light: '#7C8EDA',
  main: '#5371CB',
  dark: '#3A5093',
  darker: '#31457F',
  contrastText: '#fff',
  bg: '#C0C5EB',
};

export const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#fff',
};

export const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};

export const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

export const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  walletPrimary: WALLETPRIMARY,
  walletSecondary: WALLETSECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: '#1E1E1E',
      secondary: '#919EAB',
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
