import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { walletNotificationApi } from '@/pages/WalletApi/common/api';
import { NotificationContext } from '..';
import { TGBASEURL } from '@/config';
import { enqueueSnackbar } from 'notistack';

function DialogRemoveTgConfirm({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (v?: boolean) => void;
}) {
  const { translate: t } = useLocales();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { BotName, GroupName } = useContext(NotificationContext);

  const onOK = async () => {
    setSubmitLoading(true);
    try {
      await walletNotificationApi.botLeaveGroup();
      onClose(true);
    } catch (error) {
      // if (error?.code) {
      //   enqueueSnackbar(t(`wallet_interface_err.${error.code}`), { variant: 'error' });
      // } else {
      //   enqueueSnackbar(error.message, { variant: 'error' });
      // }
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log('error', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ width: 480, mx: 'auto' }}
      PaperProps={{
        sx: { m: 0, width: '100%' },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('wallet.account.setting.notification.dialog.remove.title')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          // paddingTop: '24px !important',
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E6E8E0',
            span: {
              color: '#73756D',
            },
          },
          '.MuiFormLabel-root': {
            color: '#73756D',
          },
          '.MuiFormHelperText-root': {
            color: '#73756D',
          },
        }}
      >
        <ContentWrap>
          <div className="top-desc">
            {t('wallet.account.setting.notification.dialog.remove.desc1')}{' '}
            <i
              onClick={() => {
                BotName && window.open(`${TGBASEURL}${BotName}`);
              }}
            >
              {' '}
              @{BotName || t('wallet.account.setting.notification.dialog.Merchantbot')}
            </i>{' '}
            {t('wallet.account.setting.notification.dialog.remove.desc2')}{' '}
            <i>@{GroupName || t('wallet.account.setting.notification.dialog.MerchantGroupName')}</i>
            ?
          </div>
          <div
            className={`loading-btn-green action-btns ${
              submitLoading ? '' : 'wallet-loading-btn-disabled'
            }`}
          >
            <Button
              variant="outlined"
              className="cancel-btn"
              onClick={() => {
                onClose(false);
              }}
            >
              {t('common.Cancel')}
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              onClick={onOK}
              className="sub-btn"
              loadingIndicator={
                <MyIcon name="loading_green" className="loading-icon-rotate" size={18} />
              }
              loading={submitLoading}
            >
              {!submitLoading && t('common.Confirm')}
            </LoadingButton>
          </div>
        </ContentWrap>
      </DialogContent>
    </Dialog>
  );
}

const ContentWrap = styled.ul`
  .top-desc {
    color: #27291e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    i {
      color: var(--primary-main, #adcb53);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      cursor: pointer;
    }
  }
  .action-btns {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 6px 16px;
      border-radius: 8px;
    }
    .cancel-btn {
      border: 1px solid rgba(145, 158, 171, 0.32);
      background-color: transparent;
      color: #27291e;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-right: 16px;
    }
    .sub-btn {
      background: #adcb53;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export default observer(DialogRemoveTgConfirm);
