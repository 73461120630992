import { Http } from '@/api/http';
// import { MD5 } from 'crypto-js';
// const CryptoJS = require('crypto-js');
const http = new Http('/merchant/console/v1');
export default class notificationApi {
  /**
   * get Notification Config
   */
  getNotificationConfig(): Promise<getNotificationConfigResType> {
    return http.postJson('/notification/get/config', {});
  }

  /**
   * put Notification Config
   */
  putNotificationConfig(data: putNotificationConfigParamType) {
    return http.postJson('/notification/put/config', data);
  }

  /**
   * putNotificationReadRecord
   */
  putNotificationReadRecord(ids: number[]) {
    return http.postJson('/notification/put/read/record', ids);
  }

  /**
   * gettNotificationReadRecord
   */
  getNotificationRecordList(
    data: getNotificationRecordParamType
  ): Promise<getNotificationRecordResType> {
    return http.postJson('/notification/get/record', data);
  }

  /**
   * 设置telegram机器人token
   */
  botPutToken(Token: string): Promise<botPutTokenResType> {
    return http.postJson('/bot/put/token', { Token });
  }

  /**
   * 绑定telegram群组
   */
  botPutGroup(Captcha: string): Promise<{ GroupName: string }> {
    return http.postJson('/bot/put/group', { Captcha });
  }

  /**
   * 将机器人移除群
   */
  botLeaveGroup() {
    return http.postJson('/bot/leave/group', {});
  }

  /**
   * 机器人状态 1-inactivated 2-normal 3-abnormal
   */
  botStatus(): Promise<botStatusResType> {
    return http.postJson('/bot/status', {});
  }
}

export type botStatusResType = {
  Status: 1 | 2 | 3;
  BotName: string;
  GroupName: string;
  BotCode: string;
};

export type botPutTokenResType = {
  BeforeToken: string;
  BotName: string;
  Code: string;
};

export type getNotificationRecordParamType = {
  page: number;
  page_size: number;
};

export type getNotificationRecordResType = {
  total: number;
  unread: number;
  records: getNotificationReadRecordResItemType[];
};

export type getNotificationReadRecordResItemType = {
  Id: number;
  Mode: number;
  MerchantId: string;
  Method: number;
  Event: number;
  ToAccount: string;
  ReadCount: number;
  Title: string;
  Content: string;
  ExpiredAt: number;
  CreatedAt: string;
  UpdatedAt: string;
  symbol?: string;
  chain_name?: string;
  txid?: string;
};

export type putNotificationConfigParamType = putNotificationConfigParamItemType[];

export type putNotificationConfigParamItemType = {
  Event: number;
  Method: number;
};

export type getNotificationConfigResType = Daum[];

export interface Daum {
  Mode: number;
  Class: number;
  Name: string;
  Event: number;
  Method: number;
  Title: string;
  Template: Template;
  Duration: number;
}

export interface Template {
  Sys: string;
  Email: string;
  Bot: string;
}
