import { useEffect } from 'react';
import TopSearchForm from './TopSearchForm';
import DataTable from './DataTable';
import { Card, Typography } from '@mui/material';
import { WebhookWrap } from './style';
import { walletTransactionStroe } from '@/stores';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import MerchantLoadingNoPosition from '@/layouts/dashboard/nav/components/merchant-loading-no-position';
import { useLocation, useNavigate } from 'react-router';
import { formartSearchParam } from '@/utils/until';
// import { useNavigate } from 'react-router';

// system-deposit 系统充值 非用户充值 Unidentified deposit
// system-swap-in 自动跨链入金 Auto cross-chain conversion
// system-swap-out 自动跨链出金 Auto cross-chain conversion
// merchant-swap-in 手动跨链入金 Manual cross-chain conversion
// merchant-swap-out 手动跨链出金 Manual cross-chain conversion
// merchant-deposit 商户充值 Merchant deposit
// merchant-return 资产返还 Refund
// merchant-withdraw 商户提现 Merchant withdrawal
// user-deposit 用户充值 User deposit
// user-withdraw 用户提现 User withdrawal
// collect-in 资产归集 Assets collection
// pre-fee-in 补充手续费 Refill network fee

export enum transactionTypeEnum {
  collectIn = 'collect-in',
  merchantDeposit = 'merchant-deposit',
  merchantReturn = 'merchant-return',
  merchantSwapIn = 'merchant-swap-in',
  merchantSwapOut = 'merchant-swap-out',
  merchantWithdraw = 'merchant-withdraw',
  preFeeIn = 'pre-fee-in',
  systemDeposit = 'system-deposit',
  systemSwapIn = 'system-swap-in',
  systemSwapOut = 'system-swap-out',
  userDeposit = 'user-deposit',
  userWithdraw = 'user-withdraw',
}

export enum transactionStatusEnum {
  'Failed' = -1,
  'Pending' = 1,
  'Success' = 2,
}

export enum CreditedStatusEnum {
  'success' = 1,
  'failed' = 0,
}

// export enum transactionTypeEnum {
//   'deposit' = 1,
//   'withdraw' = 2,
//   'collect' = 3,
// }
export enum userTypeEnum {
  'merchant' = 1,
  'system' = 2,
  'merchant-user' = 3,
}

export default observer(function Transaction() {
  const { translate: t } = useLocales();
  const location = useLocation();

  const navigate = useNavigate();

  const init = async () => {
    await walletTransactionStroe.setSearchParam({
      sub_transaction_list: ['all'],
      user_type: 'all',
      coin_id: '0000-0000-0000-0000',
      chain_id: '0',
      status: 0,
      billing_status: 1,
      search_item: 'txId',
      search: '',
      page_index: 1,
      page_size: 20,
    });
    await walletTransactionStroe.queryList();
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (location.search) {
      const record_id = formartSearchParam(location.search)?.record_id || '';
      if (record_id) {
        navigate('transaction-detail/' + record_id, {
          state: {
            backgroundLocation: location,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [location.search]);
  return (
    <WebhookWrap>
      <div className="top-section">
        <div className="left">
          <Typography className="title"> {t('wallet.transaction.top.title')}</Typography>
          {/* <Typography className="desc">{t('wallet.transaction.top.desc')}</Typography> */}
        </div>
        <div className="right">
          {/* 跳转未入账 list  */}
          {/* <Button
            variant="outlined"
            className="right-btn"
            onClick={() => {
              navigate('/wallet/transaction/unclear-funds');
            }}
          >
            {t('wallet.transaction.unblock.page.title')}
          </Button> */}
        </div>
      </div>

      <div className="data-table">
        <TopSearchForm />
        {walletTransactionStroe.searchLoading ? (
          <Card sx={{ width: '100%', height: '600px', boxShadow: 'none' }}>
            {/* <PageLoading /> */}
            <MerchantLoadingNoPosition width="100%" />
          </Card>
        ) : (
          <DataTable />
        )}
      </div>
    </WebhookWrap>
  );
});
