import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import {
  Box,
  Stack,
  Drawer,
  Button,
  // alpha,
  // Dialog,
  Link,
  useTheme,
  // Tooltip,
  //  Typography
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { CCP_URL, NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import SuggestAFeature from '@/pages/SuggestAFeature';
//
import navConfig, { walletNavConfig } from './config';
// import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import { useLocales } from '@/locales';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useAuthContext } from '@/auth/useAuthContext';
import { observer } from 'mobx-react';
// import { useNavigate } from 'react-router';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
  onOpenNav: VoidFunction;
  isFold?: boolean;
  setIsFold: () => void;
};

export default observer(function NavVertical({
  openNav,
  onCloseNav,
  isFold,
  setIsFold,
  onOpenNav,
}: Props) {
  // const { pathname } = useLocation();
  const { user } = useAuthContext();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleOpen = () => {
    setOpen(false);
  };

  const FoldIconName = useMemo(() => {
    let name = 'ic_nav_flod_right';
    if (isFold) {
      name = user?.mode_type === 2 ? 'ic_nav_wallet_flod_right' : 'ic_nav_flod_right';
    } else {
      name = user?.mode_type === 2 ? 'ic_nav_wallet_flod_left' : 'ic_nav_flod_left';
    }
    return name;
  }, [user, isFold]);

  const renderContent = (
    <Scrollbar
      sx={{
        // position: 'relative',
        height: 1,
        '& .simplebar-content': {
          height: 1,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1200,
        '::-webkit-scrollbar': {
          display: 'none',
          width: '6px',
          height: '6px',
          backgroundColor: '#fff',
        },

        '::-webkit-scrollbar-thumb': {
          borderRadius: '12px',
          backgroundColor: '#b6bcc1',
        },
      }}
    >
      <Box>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            // height: '32px',
            // boxSizing: 'content-box',
            padding: '0px 28px 0px 28px',
            // padding: '0px 0px 0px 8px',
            justifyContent: !isFold ? 'inherit' : 'center',
            alignItems: !isFold ? 'inherit' : 'center',
            margin: !isFold ? '32px 0 40px 0' : '32px 0 40px',
          }}
          spacing={3}
        >
          <Logo sx={{ width: '32px', height: '32px', flexShrink: 0 }} />
          {/* 折叠btn */}
          <Button
            sx={{
              position: 'absolute',
              top: '8px',
              // 修复导航菜单收起后头部导航按钮被隐藏需要hover才显示的bug
              right: !isDesktop ? '8px' : '-15px',
              p: 0,
              width: '32px',
              height: '32px',
              minWidth: '20px',
              borderRadius: '50%',
              zIndex: 1200,
              backgroundColor: '#fff',
              border: ' 0.75px dashed rgba(145, 158, 171, 0.24)',
              ':hover': {
                // background: 'alpha(theme.palette.primary.main, 0.08)',
                backgroundColor: `${user?.mode_type === 1 ? '#EFF3FE' : '#F8FBF1'}`,
              },
            }}
            onClick={() => {
              // 小屏幕时变为导航栏关闭事件
              if (isDesktop) {
                setIsFold();
              } else {
                if (openNav) {
                  onCloseNav();
                } else {
                  onOpenNav();
                }
              }
            }}
          >
            <MyIcon
              name={FoldIconName as any}
              size={16}
              style={{
                cursor: 'pointer',
              }}
            />
          </Button>

          {/* 折叠时不展示ccpayment */}
          {!isFold && (
            <Link href={CCP_URL} sx={{ display: 'contents' }}>
              {/* <Tooltip title={translate('nav.Official_website')} placement="right-start"> */}
              <MyIcon name="ccpayment" size={140} style={{ marginLeft: '16px' }} />
              {/* </Tooltip> */}
            </Link>
          )}
        </Stack>
        {user?.mode_type !== 2 && (
          <Stack
            mb={5}
            pl={isFold ? 1 : 2}
            pr={isFold ? 1 : 2}
            sx={{
              transition: 'padding 0.3s cubic-bezier(.21,.39,.63,.84)',
            }}
          >
            <NavAccount isFold={isFold} />
          </Stack>
        )}

        <NavSectionVertical
          data={user?.mode_type === 2 ? walletNavConfig : navConfig}
          isFold={isFold}
        />

        <Box sx={{ flexGrow: 1 }} />
      </Box>
      {/* SuggestAFeature nav */}
      {/* 折叠时 !isFold 反馈建议*/}
      <Box
        sx={{
          // position: 'absolute',
          // left: '34px',
          // bottom: '38px',
          // display: 'flex',
          // alignItems: 'center',
          // cursor: 'pointer',
          // paddingLeft: !isFold ? '46px' : '14px',
          padding: isFold ? '0 8px 16px 8px' : '0 16px 16px 16px',
          mb: 2,
          transition: 'padding 0.3s cubic-bezier(.21,.39,.63,.84)',
        }}
      >
        {/* SuggestAFeature nav */}
        {user?.mode_type === 1 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: isFold ? 'center' : '',
              cursor: 'pointer',
              mb: isFold ? 1 : 0,
              width: isFold ? 'calc(88px - 16px)!important' : 'calc(280px - 32px)!important',
              height: '48px',
              transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
              ':hover': {
                background: 'rgba(220, 224, 228, 0.32)',
                borderRadius: '8px',
              },
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Button
              sx={{
                ':hover': {
                  background: 'rgba(255, 255, 255, 0)',
                },
                display: isFold ? 'flex' : '',
                flexDirection: isFold ? 'column' : '',
                padding: isFold ? '0px' : '8px 16px',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              <MyIcon
                name={open ? 'ic_suggest_blue' : 'ic_suggest'}
                size={24}
                style={{ marginRight: !isFold ? '8px' : '0px', cursor: 'pointer' }}
              />
              <span
                className="text-one-overflow-hidden"
                style={{
                  width: '100%',
                  display: 'inline-block',
                  color: open ? '#3C6FF5' : '#637381',
                  fontSize: '14px',
                }}
              >
                {translate('nav.suggest_icon')}
              </span>
            </Button>
          </Box>
        )}
        {/* Help Center nav */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isFold ? 'center' : '',
            cursor: 'pointer',
            width: isFold ? 'calc(88px - 16px)!important' : 'calc(280px - 32px)!important',
            height: '48px',
            transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
            ':hover': {
              // background: 'rgba(220, 224, 228, 0.32)',
              background: theme.palette.primary.navHover,
              borderRadius: '8px',
            },
          }}
          onClick={() => {
            // console.log('跳转到帮助文档链接');
            window.open('https://doc.ccpayment.com/ccpayment-for-merchant/home');
          }}
        >
          <Button
            sx={{
              ':hover': {
                background: 'rgba(255, 255, 255, 0)',
              },
              display: isFold ? 'flex' : '',
              flexDirection: isFold ? 'column' : '',
              padding: isFold ? '0px' : '8px 16px',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            <MyIcon
              name={`${user?.mode_type === 1 ? 'ic_help_center' : 'ic_help_center3'}`}
              size={24}
              style={{ marginRight: !isFold ? '8px' : '0px', cursor: 'pointer' }}
            />
            <span
              className="text-one-overflow-hidden"
              style={{
                width: '100%',
                display: 'inline-block',
                color: theme.palette.primary.navTextColor,
                fontSize: '14px',
              }}
            >
              {translate('nav.Help_Center')}
            </span>
          </Button>
        </Box>
      </Box>
      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: isFold ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD,
        maxWidth: NAV.W_DASHBOARD,
        borderRight: '1px dashed rgba(145, 158, 171, 0.24)',
        transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: isFold ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRight: 0,
              // borderRightStyle: 'dashed',
              overflowY: 'visible',
              transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
            },
          }}
        >
          {renderContent}
          {/* <Box
            sx={{
              position: 'absolute',
              top: '28px',
              right: '-15px',
              width: 30,
              height: 30,
              border: '1px dashed rgba(145, 158, 171, 0.24)',
              borderRadius: '50%',
              backgroundColor: '#fff',
              zIndex: 1200,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transform: isFold ? 'rotate(-90deg)' : 'rotate(90deg)',
              transition: 'transform 0.25s',
            }}
            onClick={() => {
              setIsFold();
            }}
          >
            <MyIcon name="ic_nav_flod_left" size={8} style={{ transform: 'rotate(-90deg)' }} />
          </Box> */}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
      {open && <SuggestAFeature open={open} handleOpen={handleOpen} />}
    </Box>
  );
});
